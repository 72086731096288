import React, { Component } from 'react';
import {
  Container,
  Card,
  Row,
  Col,
  ButtonGroup,
  Button,
  DropdownButton,
  Dropdown,
  Table,
  Form,
  Spinner,
  Badge,
  Image,
  Modal,
} from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import { GET, POST } from '../../api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFilePdf,
  faPencil,
  faPlus,
  faSearch,
  faTimesCircle,
  faTrash,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import {
  faEye,
  faEyeSlash,
  faFileExcel,
} from '@fortawesome/free-regular-svg-icons';
import { renderTableBody, renderTableHead } from '../../helpers/table_helper';
import { brand_colors } from '../../helpers/brand_colors_helper';
import Pagination from '../_partials/Pagination';
import { toast } from 'react-toastify';
import { handleFormErrors } from '../../helpers/form_helpers';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import moment from 'moment';
var Chance = require('chance');
var chance = new Chance();
let cancelToken;

const portalURL = process.env.REACT_APP_PORTAL_URL;

class AwardsWarning extends Component {
  searchFieldRef = React.createRef();
  award_picture = React.createRef();

  defaultFormFields = {
    award_id: 0,
    award_name: '',
    award_type: 1, // 1 = Award, 2 = Trophy, 3 = Warning, 4 = Redeem
    award_points: '',
    award_picture: '',
    award_picture_prev: '',
  };

  state = {
    ...this.defaultFormFields,
    working: false,
    isEditing: false,
    showFormModal: false,
    cdt: {
      fetching: false,
      search: '',
      rows: 10,
      offset: 0,
      order: 'award.award_id',
      dir: 'DESC',
      lastPage: 0,
      page: 0,
      totalRecords: 0,
      rowsOptions: [10, 25, 50, 'All'],
      columns: [
        {
          id: 'award.award_id',
          column: 'award_id',
          name: 'Actions',
          visibility: true,
          sortable: true,
        },
        {
          id: 'award.award_name',
          column: 'award_name',
          name: 'Name',
          visibility: true,
          sortable: true,
        },
        {
          id: 'award.award_type',
          column: 'award_type',
          name: 'Type',
          visibility: true,
          sortable: true,
        },
        {
          id: 'award.award_points',
          column: 'award_points',
          name: 'Type',
          visibility: true,
          sortable: true,
        },
        {
          id: 'award.award_picture',
          column: 'award_picture',
          name: 'Image',
          visibility: true,
          sortable: true,
        },
      ],
      data: [],
    },
  };

  componentDidMount() {
    const { search, rows: limit, order, dir } = this.state.cdt;
    this.fetch({
      search,
      limit,
      order,
      dir,
      page: 0,
    });
  }

  fetch = async (params) => {
    this.setState({
      cdt: {
        ...this.state.cdt,
        fetching: true,
      },
    });

    if (cancelToken) {
      cancelToken.abort();
    }
    cancelToken = new AbortController();

    try {
      const res = await GET('awards-warnings/get', {
        signal: cancelToken.signal,
        params: {
          search: params.search,
          order: params.order,
          dir: params.dir,
          limit: params.limit,
          offset: parseInt(params.page) * parseInt(params.limit),
        },
      });

      if (res.status === 200) {
        this.setState({
          cdt: {
            ...this.state.cdt,
            fetching: false,
            search: params.search,
            rows: parseInt(params.limit),
            offset: parseInt(params.page) * parseInt(params.limit),
            order: params.order,
            dir: params.dir,
            page: params.page,
            totalRecords: parseInt(res.data.total_count_filtered),
            lastPage: Math.ceil(
              parseInt(res.data.total_count_filtered) / parseInt(params.limit)
            ),
            data: res.data.data.map((item) => {
              return {
                uid: chance.guid(),
                ...item,
              };
            }),
          },
        });
      }
    } catch (err) {
      console.log('err', err);
      this.setState({
        cdt: {
          ...this.state.cdt,
          fetching: false,
        },
      });
    }
  };

  handleOpenFormModal = () => {
    this.setState({
      showFormModal: true,
    });
  };

  handleCloseFormModal = () => {
    this.setState({
      showFormModal: false,
      isEditing: false,
      ...this.defaultFormFields,
    });
  };

  showHideColumn = (column_index) => {
    const columns = this.state.cdt.columns;
    columns[column_index].visibility = !columns[column_index].visibility;
    this.setState({ ctd: { ...this.state.ctd, columns } });
  };

  exportExcel = async () => {
    const excelData = this.state.cdt.data.map((item) => {
      const row = {};
      this.state.cdt.columns
        .filter((column) => column.visibility)
        .map((column) => {
          if (column.name !== 'Actions') {
            row[column.name] = item[column.column];
          }
        });
      return row;
    });

    const fileName =
      'Communication Source Export ' + moment().format('DD-MM-YYYY');
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(excelData);

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  };

  saveUpdate = async () => {
    const { authData } = this.props;
    const { center_id } = authData.centerData;

    const { award_id, award_name, award_type, award_points, award_picture } =
      this.state;

    if (!award_name) {
      toast.error(t('Name is required'));
      return;
    }

    if (!award_points) {
      toast.error(t('Points are required'));
      return;
    }

    this.setState({ working: true });

    const fData = new FormData();
    fData.append('center_id', center_id);
    fData.append(
      'awardsWarningsData',
      JSON.stringify({
        award_id,
        award_name,
        award_type,
        award_points,
        award_picture,
      })
    );
    fData.append('award_picture', award_picture);

    try {
      const res = await POST(
        'awards-warnings/' + (this.state.isEditing ? 'update' : 'save'),
        fData
      );
      if (res.data.type) {
        this.setState({ working: false });
        toast.success(t(res.data.message));
        this.fetch({
          search: '',
          limit: this.state.cdt.rows,
          order: this.state.cdt.order,
          dir: this.state.cdt.dir,
          page: 0,
        });
        this.handleCloseFormModal();
      } else {
        // toast.error(t(res.data.message));
        handleFormErrors(res.data.data);
        this.setState({ working: false });
      }
    } catch (err) {
      console.log('err', err);
      toast.error(t('Something went wrong!'));

      this.setState({ working: false });
    }
  };

  edit = (data) => {
    this.setState({
      isEditing: true,
      award_id: data.award_id,
      award_name: data.award_name,
      award_type: data.award_type,
      award_points: data.award_points,
      award_picture: data.award_picture,
      award_picture_prev:
        this.props.authData.centerData.center_uuid + '/award/' + data.award_picture,
    });
    this.handleOpenFormModal();
  };

  delete = async (data) => {
    try {
      const res = await GET('awards-warnings/delete', {
        params: {
          award_id: data.award_id,
        },
      });
      this.setState({
        cdt: {
          ...this.state.cdt,
          data: this.state.cdt.data.filter((item) => item.uid !== data.uid),
        },
      });
      toast.success(t(res.data.message));
    } catch (err) {
      console.log(err);
      toast.error(t('Something went wrong!'));
    }
  };

  previewFile = (e) => {
    const reader = new FileReader();

    const selectedFile = e.target.files[0];

    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
    }

    console.log('selectedFile', selectedFile.type);

    if (
      !selectedFile.type.includes('image') &&
      !selectedFile.type.includes('pdf')
    ) {
      toast.error(t('Only image and pdf file is allowed!'));
      return;
    }

    reader.onload = (readerEvent) => {
      if (selectedFile.type.includes('image')) {
        this.setState({
          [e.target.name + '_prev']: readerEvent.target.result,
          [e.target.name]: e.target.files[0],
        });
      } else if (selectedFile.type.includes('pdf')) {
        this.setState({
          [e.target.name + '_prev']: 'pdf',
          [e.target.name]: e.target.files[0],
        });
      }
    };
  };

  renderCell = (column_data, column_id, column_index, dataObj) => {
    const { authData } = this.props;
    const { center_id } = authData.centerData;

    let tdData;

    if (column_id == 'award_id') {
      tdData = (
        <>
          <Button
            onClick={() => this.delete(dataObj)}
            variant='danger'
            size='sm'
            className='me-1'>
            <FontAwesomeIcon icon={faTrash} />
          </Button>
          <Button
            onClick={() => this.edit(dataObj)}
            variant='warning'
            size='sm'
            className='me-1'>
            <FontAwesomeIcon icon={faPencil} />
          </Button>
        </>
      );
    } else if (column_id == 'award_type') {
      let type = <Badge bg='info'>{t('Award')}</Badge>;

      if (column_data == 2) {
        type = <Badge bg='success'>{t('Trophy')}</Badge>;
      }

      if (column_data == 3) {
        type = <Badge bg='danger'>{t('Warning')}</Badge>;
      }

      if (column_data == 4) {
        type = <Badge bg='dark'>{t('Redeem')}</Badge>;
      }

      tdData = type;
    } else if (column_id == 'award_picture') {
      tdData = (
        <Image
          src={`${portalURL + this.props.authData.centerData.center_uuid}/award/${column_data}`}
          thumbnail
          width='100'
        />
      );
    } else {
      tdData = column_data;
    }

    return <td key={column_index}>{tdData}</td>;
  };

  render() {
    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content'>
            <Header lite={true} heading={t('Awards/Warnings')} backBtn />
            <Card className='border-0' style={{ borderRadius: '20px' }}>
              <Card.Header
                style={{
                  backgroundColor: '#fff',
                  borderTopLeftRadius: '20px',
                  borderTopRightRadius: '20px',
                }}>
                <Row>
                  <Col md={6} className='d-flex align-items-center'>
                    <ButtonGroup size='sm' className='me-3 float-left'>
                      {/* <Button><FontAwesomeIcon icon={faFilePdf} /> {t('PDF')}</Button> */}
                      <Button onClick={() => this.exportExcel()}>
                        <FontAwesomeIcon icon={faFileExcel} /> {t('Excel')}
                      </Button>
                      {/* <Button><FontAwesomeIcon icon={faPrint} /> {t('Print')}</Button> */}
                      <DropdownButton
                        autoClose={'outside'}
                        size='sm'
                        as={ButtonGroup}
                        title={t('Column Visibility')}>
                        {this.state.cdt.columns.map((column, column_index) => (
                          <Dropdown.Item
                            key={column_index}
                            className={
                              column.visibility
                                ? 'column-name-item'
                                : 'column-name-item active'
                            }
                            onClick={() => this.showHideColumn(column_index)}>
                            <FontAwesomeIcon
                              icon={column.visibility ? faEye : faEyeSlash}
                            />{' '}
                            {column.name}
                          </Dropdown.Item>
                        ))}
                      </DropdownButton>
                    </ButtonGroup>
                    <div style={{ display: 'inline-block' }}>
                      {t('Show')}
                      <Form.Select
                        value={this.state.cdt.rows}
                        size='sm'
                        onChange={(e) =>
                          this.fetch({
                            search: this.state.cdt.search,
                            limit: e.target.value,
                            order: this.state.cdt.order,
                            dir: this.state.cdt.dir,
                            page: this.state.cdt.page,
                          })
                        }
                        style={{
                          width: 70,
                          marginInline: 5,
                          display: 'inline-block',
                        }}>
                        {this.state.cdt.rowsOptions.map((row, row_index) => (
                          <option key={row_index}>{row}</option>
                        ))}
                      </Form.Select>
                      {t('Records')}
                    </div>
                  </Col>
                  <Col md={6} className='d-flex justify-content-end'>
                    <div className='cdt-search'>
                      <input
                        type='text'
                        ref={this.searchFieldRef}
                        defaultValue={this.state.cdt.search}
                        onChange={(e) =>
                          this.fetch({
                            search: e.target.value,
                            limit: this.state.cdt.rows,
                            order: this.state.cdt.order,
                            dir: this.state.cdt.dir,
                            page: 0,
                          })
                        }
                        placeholder={t('Search...')}
                      />
                      <button
                        onClick={() => {
                          this.fetch({
                            search: '',
                            limit: this.state.cdt.rows,
                            order: this.state.cdt.order,
                            dir: this.state.cdt.dir,
                            page: 0,
                          });
                          this.searchFieldRef.current.value = '';
                        }}>
                        <FontAwesomeIcon
                          icon={
                            this.state.cdt.search == '' ? faSearch : faXmark
                          }
                          color={
                            brand_colors[this.props.defaultTheme.theme_id]
                              .color18
                          }
                        />
                      </button>
                    </div>
                    <button
                      onClick={this.handleOpenFormModal}
                      className='btn btn-success btn-sm ms-3'>
                      <FontAwesomeIcon
                        icon={faPlus}
                        color={
                          brand_colors[this.props.defaultTheme.theme_id].color8
                        }
                        style={{ fontSize: 12 }}
                      />
                    </button>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body style={{ paddingTop: 8 }}>
                <Row>
                  {this.state.cdt.fetching && (
                    <Col
                      md={12}
                      className='py-5 d-flex flex-column align-items-center'>
                      <Spinner as='span' animation='grow' size='sm' />{' '}
                      {t('Loading Data')}
                    </Col>
                  )}
                  {!this.state.cdt.fetching &&
                    this.state.cdt.data.length === 0 && (
                      <Col
                        md={12}
                        className='py-5 d-flex flex-column align-items-center'>
                        {t('No Data Found')}
                      </Col>
                    )}
                  {!this.state.cdt.fetching &&
                    this.state.cdt.data.length !== 0 && (
                      <Col md={12} className='pt-2'>
                        <div
                          id='datatable-list'
                          className='mb-3'
                          style={{ width: 'initial' }}>
                          <Table responsive>
                            {renderTableHead(this)}
                            {renderTableBody(this)}
                          </Table>
                        </div>
                      </Col>
                    )}
                </Row>
                <Pagination
                  pageCount={this.state.cdt.lastPage || 1}
                  forcePage={this.state.cdt.page}
                  callbackParams={{
                    search: this.state.cdt.search,
                    limit: this.state.cdt.rows,
                    order: this.state.cdt.order,
                    dir: this.state.cdt.dir,
                  }}
                  callback={this.fetch}
                />
              </Card.Body>
            </Card>
          </div>
        </div>
        <Modal
          backdrop='static'
          keyboard={false}
          size={'md'}
          show={this.state.showFormModal}
          onHide={this.handleCloseFormModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              {this.state.isEditing ? t('Edit') : t('Add')} {t('Award/Warning')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='custom-styled-form'>
            <Row>
              <Col md='6'>
                <Row>
                  <Col md='12'>
                    <Form.Group className='mb-3'>
                      <Form.Label>{t('Description')}</Form.Label>
                      <Form.Control
                        type='text'
                        defaultValue={this.state.award_name}
                        onChange={(e) =>
                          this.setState({ award_name: e.target.value })
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col md='12'>
                    <Form.Group className='mb-3'>
                      <Form.Label>{t('Points')}</Form.Label>
                      <Form.Control
                        type='number'
                        defaultValue={this.state.award_points}
                        onChange={(e) =>
                          this.setState({ award_points: e.target.value })
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col md='12'>
                    <div>
                      <Form.Check
                        onClick={() => this.setState({ award_type: 1 })}
                        checked={this.state.award_type == 1}
                        name='type'
                        id='award'
                        type='radio'
                        label={t('Award')}
                      />
                    </div>
                    <div>
                      <Form.Check
                        onClick={() => this.setState({ award_type: 2 })}
                        checked={this.state.award_type == 2}
                        name='type'
                        id='trophy'
                        type='radio'
                        label={t('Trophy')}
                      />
                    </div>
                    <div>
                      <Form.Check
                        onClick={() => this.setState({ award_type: 3 })}
                        checked={this.state.award_type == 3}
                        name='type'
                        id='warning'
                        type='radio'
                        label={t('Warning')}
                      />
                    </div>
                    <div>
                      <Form.Check
                        onClick={() => this.setState({ award_type: 4 })}
                        checked={this.state.award_type == 4}
                        name='type'
                        id='redeem'
                        type='radio'
                        label={t('Redeem')}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Form.Group className='mb-3'>
                  <Form.Label>{t('Image')}</Form.Label>
                  <div
                    style={{
                      flexDirection: 'column',
                    }}
                    className='pic-prev d-flex justify-content-center'>
                    <div
                      style={{
                        borderRadius: 5,
                        borderStyle: 'dashed',
                        borderWidth: 2,
                        borderColor: '#ced4da',
                        height: 192,
                        display: 'flex',
                        overflow: 'hidden',
                      }}>
                      {this.state.award_picture !== '' &&
                        this.state.award_picture_prev !== '' ? (
                        <div
                          className='d-flex justify-content-center align-items-center'
                          style={{
                            flex: 1,
                            position: 'relative',
                          }}>
                          <button
                            style={{
                              padding: 3,
                              border: 3,
                              background: 'none',
                              position: 'absolute',
                              top: 0,
                              right: 0,
                            }}
                            onClick={() => {
                              this.setState({
                                award_picture: '',
                                award_picture_prev: '',
                              });
                              this.award_picture.current.value = null;
                            }}>
                            <FontAwesomeIcon
                              icon={faTimesCircle}
                              color='rgb(220, 53, 69)'
                              style={{
                                fontSize: 20,
                              }}
                            />
                          </button>
                          {this.state.award_picture_prev == 'pdf' ? (
                            <FontAwesomeIcon
                              icon={faFilePdf}
                              style={{
                                fontSize: 60,
                              }}
                            />
                          ) : (
                            <img
                              src={this.state.award_picture_prev}
                              alt={t('Preview')}
                              width='100'
                            />
                          )}
                        </div>
                      ) : (
                        <button
                          onClick={() => this.award_picture.current.click()}
                          style={{
                            background: 'none',
                            border: 0,
                            flex: 1,
                          }}>
                          <FontAwesomeIcon
                            icon={faPlus}
                            style={{
                              fontSize: 30,
                            }}
                          />
                        </button>
                      )}
                    </div>
                    <Form.Control
                      className='d-none'
                      ref={this.award_picture}
                      type='file'
                      onChange={this.previewFile}
                      name='award_picture'
                      accept='.jpg, .jpeg, .png, .pdf'
                    />
                  </div>
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={this.handleCloseFormModal}>
              {t('Close')}
            </Button>
            <Button
              disabled={this.state.working}
              variant='success'
              onClick={() => this.saveUpdate()}>
              {t('Save')}{' '}
              {this.state.working ? (
                <Spinner
                  as='span'
                  animation='grow'
                  size='sm'
                  role='status'
                  aria-hidden='true'
                />
              ) : (
                ''
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(AwardsWarning);
