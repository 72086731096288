import React, { Component } from 'react';
import { Container, Card, Row, Col, Badge, Button, Modal, Form, InputGroup, Spinner } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEllipsis, faEllipsisV, faFilePdf, faPlus, faTimesCircle, faTrashAlt, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { brand_colors } from '../../helpers/brand_colors_helper';
import { handleFormErrors, setSwitchField, setTxtField } from '../../helpers/form_helpers';
import Select from 'react-select';
import axios from 'axios';
import SweetAlert from 'react-bootstrap-sweetalert';
import Paginate from '../_partials/Paginate/_Paginate';

// Images
import imgAddButton from '../../assets/images/add-button-small.png';
import imgHyperlink from '../../assets/images/hyperlink-small.png';
import imgAttachment from '../../assets/images/attachment-small.png';
import imgLabelTag from '../../assets/images/label-tag.png';
import imgCalendar from '../../assets/images/calendar.png';
import imgColor from '../../assets/images/color.png';
import imgCoefficient from '../../assets/images/coefficient.png';
import imgDescription from '../../assets/images/description.png';
import imgStatus from '../../assets/images/status.png';
import imgAsterisk from '../../assets/images/asterisk.png';
import { GET } from '../../api';
import GeneralCard from '../../helpers/GeneralCard';
import { faThumbsDown, faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import { setSubject } from "../../redux/slices/selectedClassSlice";
// Vars
const apiURL = process.env.REACT_APP_API_URL;
const portalURL = process.env.REACT_APP_PORTAL_URL;
const controller = 'academic-classmain-subject';
const imgDir = 'academic_classmain_subject';

/**
 * Subjects Component
 */
class Subjects extends Component {
  //  States
  state = {
    deleteModal: { show: false, id: 0, action: () => { }, title: null, desc: null },
    showAddEditForm: false,
    working: false,
    editing: false,
    showPicker: false,

    limit: 50,
    totalRecords: 0,
    academicClassmainSubjects: null,
    academicSubjectsList: [],
    academicClassmainSubjectsDDL: [],
    // Form fields
    id: 0,
    name: '',
    code: '',
    coefficient: 0,
    url: '',
    sessionsPerWeek: '',
    acs_credits: 0,
    mandatory: 1,
    status: 1,
    description: '',
    image: '',
    image_prev: '',
    image_deleted: 0,
    totalCoeff: 0,
    subject: 0,
    parent_acs_id_flag: 0,
    parent_acs_id: null,
    subjectsSessions: 0
  };

  /**
   * Constructor
   */
  constructor() {
    super();
    this.image = React.createRef();
  }

  /**
   * Component Did Mount
   */
  componentDidMount() {
    this.getAcademicClassmainSubjects();
    this.getAcademicSubjects();
    this.getParentAcademicClassmainSubjects();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.selectedClass) {
      if (prevProps.selectedClass.class.class_id != this.props.selectedClass.class.class_id) {
        this.getAcademicClassmainSubjects()
      }
    }

    if (this.props.showAddEditForm != prevProps.showAddEditForm && this.props.showAddEditForm) {
      this.handleAddEditFormOpen()
    }

  }

  /**
   * Get Subjects
   */
  getAcademicClassmainSubjects = async (limit = this.state.limit, offset = 0) => {
    this.setState({
      academicClassmainSubjects: null
    });

    const { authData, selectedClass } = this.props;
    const { auth_key, user_id, role_id } = authData.loginData;
    const { employee_id } = authData.userData;
    const { center_id } = authData.centerData;
    let agegroup_id = (selectedClass) ? selectedClass.class.agegroup_id : null;
    let class_id = (selectedClass) ? selectedClass.class.class_id : null;
    if (this.props.agegroup_id) {
      agegroup_id = this.props.agegroup_id
    }

    const fData = new FormData();
    fData.append('center_id', center_id);
    fData.append('agegroup_id', agegroup_id);
    fData.append('class_id', class_id);
    fData.append('employee_id', employee_id);
    fData.append('user_id', user_id);
    fData.append('role_id', role_id);
    fData.append('limit', limit);
    fData.append('offset', offset);

    try {
      const res = await axios.post(apiURL + controller + '/get', fData, {
        params: {
          auth_key: auth_key,
          appname: process.env.REACT_APP_NAME,
        },
      });
      if (res.status == 200) {

        this.setState({
          academicClassmainSubjects: res.data.data,
          totalRecords: parseInt(res.data.total_count),
          subjectsSessions: res.data.data
            .filter(item => !item.parent_acs_id)
            .reduce((acc, obj) => acc + obj.acs_sessions_per_week, 0)
        });
      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while fetching subjects!'));

    }
  };

  /**
   * Get Academic Subjects
   */
  getAcademicSubjects = async () => {
    const { authData } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id } = authData.centerData;

    try {
      const res = await GET(controller + '/get-academic-subjects', {
        params: {
          center_id,
          auth_key,
          appname: process.env.REACT_APP_NAME,
        },
      });

      if (res.status == 200) {
        this.setState({
          academicSubjectsList: res.data.map((item) => {
            return {
              value: item.as_id,
              label: item.as_name + ' (' + item.ab_name + ')',
            };
          }),
        });
      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while fetching academic subjects!'));

    }
  };

  getParentAcademicClassmainSubjects = async (editingData_acs_id) => {
    const { selectedClass } = this.props;
    let agegroup_id = (selectedClass) ? selectedClass.class.agegroup_id : null;
    if (this.props.agegroup_id) {
      agegroup_id = this.props.agegroup_id
    }

    try {
      if (this.getParentAcademicClassmainSubjectsReq) {
        this.getParentAcademicClassmainSubjectsReq.abort();
      }
      this.getParentAcademicClassmainSubjectsReq = new AbortController();
      let res = await GET(controller + '/get-parent-academic-classmain-subjects', {
        params: { agegroup_id },
        signal: this.getParentAcademicClassmainSubjectsReq.signal,
      })



      let academicClassmainSubjectsDDL = []
      res.data.map((v, i) => {
        if (editingData_acs_id != v.acs_id) {
          academicClassmainSubjectsDDL.push({
            label: v.acs_name,
            value: v.acs_id,
          })
        }
      })
      this.setState({
        academicClassmainSubjectsDDL
      });
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while fetching academic subjects!'));

    }
  };

  /**
   * Get Total Coefficient
   */
  getTotalCoefficient = async (as_id) => {
    const { authData, selectedClass } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id } = authData.centerData;
    let agegroup_id = (selectedClass) ? selectedClass.class : null;
    if (this.props.agegroup_id) {
      agegroup_id = this.props.agegroup_id
    }

    try {
      const res = await GET(controller + '/get-total-coefficient', {
        params: {
          center_id,
          agegroup_id,
          as_id,
          auth_key,
          appname: process.env.REACT_APP_NAME,
        },
      });

      if (res.status == 200) {
        this.setState({
          totalCoeff: res.data.total_acs_coefficient,
        });
      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while fetching total coefficient!'));

    }
  };

  /**
   * On Change Academic Subject
   * @param {Object} obj
   */
  onChangeAcademicSubject = (obj) => {
    this.setState({ subject: obj });
    this.getTotalCoefficient(obj.value);
  };

  /**
   * On Change Coefficient
   * @param {Event} event
   */
  onChangeCoeff = (event) => {
    const coeff = parseInt(event.target.value);
    let coefficient = parseInt(coeff)
    const { totalCoeff } = this.state;

    if ((parseFloat(totalCoeff) + parseFloat(coeff)) > 100) {
      toast.error(t('Already used remaining coefficient!'));
    } else {
      this.setState({ coefficient: coefficient });
    }
  };

  /**
   * Handle Add / Edit form
   */
  handleAddEditFormOpen = (isEditing = false, editingData = {}) => {
    // If Editing
    if (isEditing) {
      const { academicSubjectsList } = this.state;

      const subject = academicSubjectsList.find((item) => {
        return item.value == editingData.as_id;
      });

      if (editingData.parent_acs_id) {
        editingData.parent_acs_id = this.state.academicClassmainSubjectsDDL.find((v, i) => v.value == editingData.parent_acs_id)
      }

      this.setState({
        id: editingData.acs_id,
        name: editingData.acs_name,
        code: editingData.acs_code,
        coefficient: parseInt(editingData.acs_coefficient),
        url: editingData.acs_url,
        sessionsPerWeek: editingData.acs_sessions_per_week,
        acs_credits: editingData.acs_credits,
        mandatory: parseInt(editingData.acs_mandatory),
        parent_acs_id: editingData.parent_acs_id,
        parent_acs_id_flag: (editingData.parent_acs_id) ? 1 : 0,
        status: parseInt(editingData.acs_status),
        description: editingData.acs_desc || '',
        image: editingData.acs_image,
        image_prev: portalURL + this.props.authData.centerData.center_uuid + '/' + imgDir + '/' + editingData.acs_image,
        subject,
        editing: isEditing,
        showAddEditForm: true,
      });
    } else {
      this.setState({
        name: '',
        code: '',
        coefficient: 0,
        url: '',
        sessionsPerWeek: '',
        acs_credits: 0,
        mandatory: 1,
        parent_acs_id: null,
        status: 1,
        description: '',
        image: '',
        image_prev: '',
        totalCoeff: 0,
        subject: 0,
        editing: isEditing,
        showAddEditForm: true,
      });
    }
    this.getParentAcademicClassmainSubjects(editingData.acs_id)
  };
  handleAddEditFormClose = () => {
    this.setState({
      showAddEditForm: false,
    });
  };

  /**
   * Preview Upload File
   * @returns
   */
  previewFile = (e) => {
    const reader = new FileReader();

    const selectedFile = e.target.files[0];

    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
    }

    if (!selectedFile.type.includes('image')) {
      toast.error(t('Only image file is allowed!'));
      return;
    }

    reader.onload = (readerEvent) => {
      this.setState({
        [e.target.name + '_deleted']: 0,
        [e.target.name + '_prev']: readerEvent.target.result,
        [e.target.name]: e.target.files[0],
      });
    };
  };

  openColorPicker = () => {
    this.setState({
      showPicker: !this.state.showPicker,
    });
  };

  closeColorPicker = () => {
    this.setState({
      showPicker: false,
    });
  };

  onChangeColor = (color) => {
    this.setState({
      color: color.rgb,
    });
  };



  /**
   * Insert
   */
  insert = async () => {
    this.setState({ working: true });

    let { subject, name, code, coefficient, url, sessionsPerWeek, mandatory, status, image, description, acs_credits, parent_acs_id, parent_acs_id_flag } = this.state;
    const { authData, selectedClass } = this.props;
    const { auth_key, user_id } = authData.loginData;
    const { center_id, center_timezone } = authData.centerData;
    let agegroup_id = (selectedClass) ? selectedClass.class : null;
    if (this.props.agegroup_id) {
      agegroup_id = this.props.agegroup_id
    }

    if (parent_acs_id_flag == 1) {
      parent_acs_id = (parent_acs_id) ? parent_acs_id.value : null
    } else {
      parent_acs_id = null
    }

    let AcademicClassmainSubjectData = {
      agegroup_id: agegroup_id,
      as_id: subject.value,
      acs_name: name,
      acs_code: code,
      acs_coefficient: coefficient,
      acs_url: url,
      acs_desc: description,
      acs_sessions_per_week: sessionsPerWeek,
      acs_credits: acs_credits,
      acs_mandatory: mandatory,
      parent_acs_id: parent_acs_id,
      acs_status: status,
    }

    const fData = new FormData();
    fData.append('center_id', center_id);
    fData.append('center_timezone', center_timezone);
    fData.append('user_id', user_id);
    fData.append('AcademicClassmainSubjectData', JSON.stringify(AcademicClassmainSubjectData));
    fData.append('acs_image', image);

    try {
      const res = await axios.post(apiURL + controller + '/insert', fData, {
        params: {
          auth_key: auth_key,
          appname: process.env.REACT_APP_NAME,
        },
      });
      // "SQLSTATE[23000]: Integrity constraint violation: 1452 Cannot add or update a child row: a foreign key constraint fails (`educores_ieducore`.`lrnaspect`, CONSTRAINT `fk_asp_area` FOREIGN KEY (`learnarea_id`) REFERENCES `learnarea` (`learnarea_id`) ON DELETE NO ACTION ON UPDATE NO ACTION)\nThe SQL being executed was: INSERT INTO `lrnaspect` (`lrnaspect_code`, `ab_id`, `learnarea_id`, `center_id`, `lrnaspect_desc`, `lrnaspect_status`) VALUES ('English 10', 2, 798, 2, '', 1)"

      if (res.status == 200) {
        if (res.data.type) {
          this.handleAddEditFormClose();
          this.getAcademicClassmainSubjects();
          this.setState({ working: false });
          toast[res.data.messageType](t(res.data.message));
        } else {
          if (res.data.data != null) {
            handleFormErrors(res.data.data);
          } else {
            toast[res.data.messageType](t(res.data.message));
          }
          this.setState({ working: false });
        }
      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while adding subject!'));

      this.setState({ working: false });
    }
  };

  /**
   * Update
   */
  update = async () => {
    this.setState({ working: true });
    let { id: acs_id, subject, name, code, coefficient, url, sessionsPerWeek, mandatory, image_deleted, status, image, description, acs_credits, parent_acs_id, parent_acs_id_flag } = this.state;

    const { authData, selectedClass } = this.props;
    const { auth_key, user_id } = authData.loginData;
    const { center_id, center_timezone } = authData.centerData;
    let agegroup_id = (selectedClass) ? selectedClass.class : null;
    if (this.props.agegroup_id) {
      agegroup_id = this.props.agegroup_id
    }
    if (parent_acs_id_flag == 1) {
      parent_acs_id = (parent_acs_id) ? parent_acs_id.value : null
    } else {
      parent_acs_id = null
    }

    let AcademicClassmainSubjectData = {
      agegroup_id: agegroup_id,
      as_id: subject.value,
      acs_name: name,
      acs_code: code,
      acs_coefficient: coefficient,
      acs_url: url,
      acs_desc: description,
      acs_sessions_per_week: sessionsPerWeek,
      acs_credits: acs_credits,
      acs_mandatory: mandatory,
      parent_acs_id: parent_acs_id,
      acs_status: status,
    }
    const fData = new FormData();
    fData.append('center_id', center_id);
    fData.append('center_timezone', center_timezone);
    fData.append('user_id', user_id);
    fData.append('acs_id', acs_id);
    fData.append('AcademicClassmainSubjectData', JSON.stringify(AcademicClassmainSubjectData));
    fData.append('acs_image', image);
    fData.append('acs_image_deleted', image_deleted);

    try {
      const res = await axios.post(apiURL + controller + '/update', fData, {
        params: {
          auth_key: auth_key,
          appname: process.env.REACT_APP_NAME,
        },
      });
      if (res.status == 200) {
        if (res.data.messageType !== 'error') {
          this.handleAddEditFormClose();
          this.getAcademicClassmainSubjects();
          this.setState({ working: false });
        } else {
          handleFormErrors(res.data.data);
          this.setState({ working: false });
        }
      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while updating subject!'));

      this.setState({ working: false });
    }
  };

  /**
   * Delete
   */
  delete = async () => {
    const acs_id = this.state.deleteModal.id;

    try {
      const res = await GET(controller + '/delete', {
        params: {
          acs_id
        }
      });


      if (res.data.type) {
        this.setState({
          deleteModal: {
            ...this.state.deleteModal,
            show: false,
            id: 0
          }
        });
        this.getAcademicClassmainSubjects();
      }

      toast.error(t(res.data.message));
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * Render Component
   * @returns {HTMLElement}
   */
  render() {
    const { showAddEditForm, working, editing, academicClassmainSubjects, name, code, coefficient, url, subject, sessionsPerWeek, mandatory, status, description, image, image_prev, totalCoeff, academicSubjectsList, limit, totalRecords, acs_credits, parent_acs_id, parent_acs_id_flag, deleteModal, academicClassmainSubjectsDDL } = this.state;
    const selectedClass = this.props.selectedClass;
    const defaultTheme = this.props.defaultTheme;

    return (
      <div>
        <div>
          {/* <NavBar /> */}
          {/* id='page-content' */}
          {/* <div> */}
          {/* <Header lite={true} heading={t('Subjects')} /> */}
          {/* have-load-more-btn */}
          {/* grey-section  */}
          <div className={(!this.props.hideHeader) ? 'has-pagination' : 'has-pagination'}>
            {!this.props.hideHeader &&
              <div className='grey-section-header'>
                <div className='d-flex align-items-center'>
                  <span style={{ fontWeight: 600, opacity: 0.8 }}>{t('Subjects')}</span>
                  <Badge className='d-flex align-items-center ms-3' bg='info'>{t('Subjects Total Sessions:')} {this.state.subjectsSessions}</Badge>
                </div>
                <div>
                  <Button onClick={() => this.handleAddEditFormOpen()} className='btn-sm btn-add' variant='success'>
                    {t('Add Subject')}{' '}
                    <FontAwesomeIcon icon={faPlus} color={brand_colors[this.props.defaultTheme.theme_id].color8} style={{ fontSize: 16, }} />
                  </Button>
                </div>
              </div>
            }
            <Row>
              {academicClassmainSubjects ? (
                <>
                  {academicClassmainSubjects.length > 0 ? (
                    <>
                      {academicClassmainSubjects.map((v, i) => {
                        // linkTo, linkToStates, cardImage, name, code, text1, text2, selected, actions, description, extraTexts, status
                        let data = {
                          allData: v,
                          name: v.acs_name,
                          code: v.acs_code,
                          text1: (v.parent_acs_name) ? 'Focus:' : null,
                          text2: v.parent_acs_name,
                          status: (v.acs_status == 1) ? true : false,
                          description: v.acs_desc,
                          // linkTo: '/subject/lessons/' + v.acs_name,
                          // linkToStates: { as_id: v.as_id, acs_id: v.acs_id, ab_id: v.ab_id, },
                          linkTo: '/subject-actions',
                          onClick: () => this.props.setSubject(v),
                          cardImage: (v.acs_image) ? portalURL + this.props.authData.centerData.center_uuid + '/academic_classmain_subject/' + v.acs_image : require('../../assets/images/noimage.png'),
                          actions: [
                            {
                              type: 'edit', name: 'Edit', image: require('../../assets/images/edit.png'), iconName: null, iconColor: brand_colors[this.props.defaultTheme.theme_id].color16, onClick: () => this.handleAddEditFormOpen(true, v)
                            },
                            {
                              type: 'delete', name: 'Delete', image: require('../../assets/images/remove-red.png'), iconName: null, iconColor: brand_colors[this.props.defaultTheme.theme_id].color11, onClick: () => this.setState({ deleteModal: { ...this.state.deleteModal, show: true, id: v.acs_id, action: this.delete } })
                            },
                          ],
                          extraTexts: [
                            { type: 'url', name: 'URL', value: v.acs_url, iconName: null, image: require('../../assets/images/hyperlink.png'), iconColor: brand_colors[this.props.defaultTheme.theme_id].color9, onClick: () => { } },
                            { type: 'text', name: 'Type', value: (v.acs_mandatory == 1) ? 'Mandatory' : 'Elective', icon: null, image: require('../../assets/images/asterisk.png'), iconColor: brand_colors[this.props.defaultTheme.theme_id].color9, onClick: () => { } }
                          ]
                        }
                        return <GeneralCard data={data} key={i} />
                      }
                      )}
                    </>
                  ) : (
                    <Col md={12} className='pt-5 pb-4 pb-0 d-flex align-items-center flex-column'>
                      {t('No Subjects Found')}
                    </Col>
                  )}
                </>
              ) : (
                <Col md={12} className='pt-5 pb-0 d-flex align-items-center justify-content-center flex-column' style={{ minHeight: 245 }}>
                  <Spinner as='span' animation='grow' size='lg' role='status' aria-hidden='true' className='mb-1' />
                  {t('Loading Subjects...')}
                </Col>
              )}
              <Paginate limit={limit} totalRecords={totalRecords} pageChange={this.getAcademicClassmainSubjects} />
            </Row>
          </div>
          {/* </div> */}
        </div>
        <Modal size='lg' show={showAddEditForm} onHide={this.handleAddEditFormClose} backdrop="static"
          keyboard={false}>
          <Modal.Header closeButton>
            <Modal.Title>{editing ? t('Update Subject') : t('New Subject')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='custom-styled-form'>
              <Row>
                <Col md={3}>
                  <Form.Group className='mb-1'>
                    <Form.Label>
                      <img src={imgAddButton} />
                      {t('Subject')}
                    </Form.Label>
                    <Select className='custom-select' options={academicSubjectsList} onChange={this.onChangeAcademicSubject} value={subject} />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className='mb-1'>
                    <Form.Label>
                      <img src={imgAddButton} />
                      {t('Name')}
                    </Form.Label>
                    <Form.Control type='text' value={name} onChange={(event) => setTxtField('name', event.target.value, this)} />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className='mb-1'>
                    <Form.Label>
                      {' '}
                      <img src={imgLabelTag} />
                      {t('Code')}
                    </Form.Label>
                    <Form.Control type='text' value={code} onChange={(event) => setTxtField('code', event.target.value, this)} />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className='mb-1'>
                    <Form.Label>
                      <img src={imgHyperlink} />
                      {t('URL')}
                    </Form.Label>
                    <Form.Control type='text' value={url} onChange={(event) => setTxtField('url', event.target.value, this)} />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={5}>
                  <Form.Group className='mb-1'>
                    <Form.Label>
                      <img src={imgCoefficient} />
                      {t('Subject Total Grade')}
                    </Form.Label>
                    <InputGroup>
                      <Form.Control type='number' min={0} max={'100'} value={coefficient} onChange={this.onChangeCoeff} />
                      <InputGroup.Text id='basic-addon2'>Total: {(coefficient) ? (parseFloat(totalCoeff) + parseFloat(coefficient)) : totalCoeff}/100</InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className='mb-1'>
                    <Form.Label>
                      <img src={imgCalendar} />
                      {t('Sessions Per Week')}
                    </Form.Label>
                    <Form.Control type='number' min={0} value={sessionsPerWeek} onChange={(event) => setTxtField('sessionsPerWeek', event.target.value, this)} />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className='mb-1'>
                    <Form.Label>
                      <img src={imgCoefficient} />
                      {t('Credits')}
                    </Form.Label>
                    <InputGroup>
                      <Form.Control type='number' min={0} step={'0.1'} max={'100'} value={acs_credits} onChange={(event) => setTxtField('acs_credits', event.target.value, this)} />
                      {/* <InputGroup.Text id='basic-addon2'>Total: {(coefficient) ? totalCoeff + coefficient : totalCoeff}/100</InputGroup.Text> */}
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <Form.Group className='mb-1 form-switch-wrap'>
                    <Form.Label>
                      <img src={imgAsterisk} />
                      <span style={{ color: (mandatory == 1) ? brand_colors[defaultTheme.theme_id].color4 : brand_colors[defaultTheme.theme_id].color18 }}>{t('Mandatory')}</span> | <span style={{ color: (mandatory == 0) ? brand_colors[defaultTheme.theme_id].color4 : brand_colors[defaultTheme.theme_id].color18 }}>{t('Elective')}</span>
                    </Form.Label>
                    <Form.Check className='mt-2' type='switch' id='mandatory' value={1} checked={mandatory == 1 ? true : false} onChange={() => setSwitchField('mandatory', mandatory, this)} />
                  </Form.Group>
                </Col>
                <Col md={2}>
                  <Form.Group className='mb-1 form-switch-wrap'>
                    <Form.Label>
                      <img src={imgStatus} />
                      {t('Status')}
                    </Form.Label>
                    <Form.Check className='mt-2' type='switch' id='status' checked={status == 1 ? true : false} onChange={() => setSwitchField('status', status, this)} />
                  </Form.Group>
                </Col>
                <Col md={3} >
                  <Form.Group className='mb-1 form-switch-wrap'>
                    <Form.Label>
                      <span style={{ color: (parent_acs_id_flag == 0) ? brand_colors[defaultTheme.theme_id].color4 : brand_colors[defaultTheme.theme_id].color18 }}>{t('Main')}</span> | <span style={{ color: (parent_acs_id_flag == 1) ? brand_colors[defaultTheme.theme_id].color4 : brand_colors[defaultTheme.theme_id].color18 }}>{t('Focus')}</span> <span>{t('Subject')}</span>
                    </Form.Label>
                    <Form.Check className='mt-2' type='switch' id='status' checked={parent_acs_id_flag == 1 ? true : false} onChange={() => setSwitchField('parent_acs_id_flag', parent_acs_id_flag, this)} />
                  </Form.Group>
                </Col>
                {(parent_acs_id_flag == 1) &&
                  <Col md={4}>
                    <Form.Group className='mb-1'>
                      <Form.Label>
                        <img src={imgAddButton} />
                        {t('Main Subject')}
                      </Form.Label>
                      <Select className='custom-select' options={academicClassmainSubjectsDDL} onChange={(event) => setTxtField('parent_acs_id', event, this)} value={parent_acs_id} />
                    </Form.Group>
                  </Col>
                }
              </Row>
              <Row>
                <Col md={5}>
                  <Form.Group controlId='file3' className='mb-1'>
                    <Form.Label>
                      <img src={imgAttachment} />
                      {t('Image')}
                    </Form.Label>
                    <div
                      style={{
                        flexDirection: 'column',
                      }}
                      className='pic-prev d-flex justify-content-center'
                    >
                      <div
                        style={{
                          borderRadius: 5,
                          borderStyle: 'solid',
                          borderWidth: 1,
                          borderColor: '#ced4da',
                          height: 158,
                          display: 'flex',
                          overflow: 'hidden',
                        }}
                      >
                        {image !== '' && image_prev !== '' ? (
                          <div
                            className='d-flex justify-content-center align-items-center'
                            style={{
                              flex: 1,
                              position: 'relative',
                            }}
                          >
                            <button
                              style={{
                                padding: 3,
                                border: 3,
                                background: 'none',
                                position: 'absolute',
                                top: 0,
                                right: 0,
                              }}
                              onClick={() => {
                                this.setState({
                                  image: '',
                                  image_prev: '',
                                  image_deleted: 0,
                                });
                                this.image.current.value = null;
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faTimesCircle}
                                color='rgb(220, 53, 69)'
                                style={{
                                  fontSize: 20,
                                }}
                              />
                            </button>
                            <img src={image_prev} alt={t('Preview')} width='100' />
                          </div>
                        ) : (
                          <button
                            onClick={() => this.image.current.click()}
                            style={{
                              background: 'none',
                              border: 0,
                              flex: 1,
                            }}
                          >
                            <FontAwesomeIcon
                              className='file-selector'
                              icon={faPlus}
                              style={{
                                fontSize: 30,
                              }}
                            />
                          </button>
                        )}
                      </div>
                      <Form.Control className='d-none' ref={this.image} type='file' onChange={this.previewFile} name='image' accept='.jpg, .jpeg, .png' />
                    </div>
                  </Form.Group>
                </Col>
                <Col md={7}>
                  <Form.Group className='mb-1'>
                    <Form.Label>
                      <img src={imgDescription} />
                      {t('Description')}
                    </Form.Label>
                    <Form.Control as='textarea' rows={6} value={description} onChange={(event) => setTxtField('description', event.target.value, this)} />
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={this.handleAddEditFormClose}>
              {t('Close')}
            </Button>
            <Button variant='primary' disabled={working} onClick={editing ? this.update : this.insert}>
              {editing ? t('Update Subject') : t('Add Subject')} {working ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' /> : ''}
            </Button>
          </Modal.Footer>
        </Modal>
        <SweetAlert
          show={deleteModal.show}
          warning
          showCancel
          confirmBtnText={t('Yes, delete it!')}
          confirmBtnBsStyle='danger'
          title={(deleteModal.title) ? deleteModal.title : t('Are you sure?')}
          onConfirm={deleteModal.action}
          onCancel={() => this.setState({ deleteModal: { ...deleteModal, show: false, id: 0, action: () => { } } })}
          focusCancelBtn
        >
          {(deleteModal.desc) ? deleteModal.desc : t('Confirm Delete!')}
        </SweetAlert>
      </div >
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  selectedClass: state.selectedClass.data,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
});

const mapDispatchToProps = () => ({
  setSubject,
});
export default connect(mapStateToProps, mapDispatchToProps())(Subjects);
