import React, { Component } from 'react';
import {
  Container,
  Card,
  Row,
  Col,
  Button,
  Tab,
  Tabs,
  Table,
  Badge,
  Modal,
  InputGroup,
  Form,
} from 'react-bootstrap';
import 'chart.js/auto';
import Select from 'react-select';
import { Doughnut, Bar, Chart } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { t } from '../../../../helpers/translation_helper';
import { brand_colors } from '../../../../helpers/brand_colors_helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip as Tooltiper } from 'react-tooltip';
import {
  faDashboard,
  faUsers,
  faAddressCard,
  faUserTie,
  faChevronDown,
  faSchool,
  faInfoCircle,
  faEye,
  faXmark,
  faTrash,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import axios from 'axios';
import { toast } from 'react-toastify';
import { LoadingPlaceholder } from '../../../_common/Loading/Loading';
import moment from 'moment';
import { faker } from '@faker-js/faker';
import { GET, POST } from '../../../../api';
import { DateRangePicker } from 'react-date-range';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { handleFormErrors } from '../../../../helpers/form_helpers';
import SchoolAttendanceWidget from '../../../_partials/Widgets/SchoolAttendanceWidget';

const apiURL = process.env.REACT_APP_API_URL;
const portalURL = process.env.REACT_APP_PORTAL_URL;

const barChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
      position: 'bottom',
    },
  },
  scales: {
    x: {
      ticks: {
        callback: function (value) {
          return this.getLabelForValue(value).substr(0, 15) + '...';
        },
      },
    },
  },
};

const doughnutChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  cutout: 90,
  plugins: {
    legend: {
      position: 'bottom',
    },
  },
};

class Main extends Component {
  calendarEntryDefaultFields = {
    cc_id: 0,
    title: '',
    body: '',
    selectedDateRange: {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
    add_type: 'calendar', // holiday, calendar
    class_id: 0,
    weekly_lesson_plan: 0,
  };

  state = {
    terms: [],
    selectedTerm: null,
    showBookedSeatsModal: false,
    registrationsData: null,
    currentPeriodRegistrations: '...',
    reportCapacityData: null,
    reportRevenueData: null,
    reportClassCapacityData: null,
    bookedAvailableSeats: {
      total: 0,
      mon: 0,
      tue: 0,
      wed: 0,
      thu: 0,
      fri: 0,
      sat: 0,
      sun: 0,
    },
    events: null,
    classesDetailData: null,
    calendarEntryModal: {
      ...this.calendarEntryDefaultFields,
      modalShow: false,
      showDateRange: false,
    },
    banner: {
      show: false
    }
  };

  componentDidMount() {
    this.getTerms();
    this.reportRegistrationStatistics();
    this.reportCenterCalendar();
    this.classDetailGraph();
    this.getBanner();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.authData.centerData.center_id !==
      this.props.authData.centerData.center_id
    ) {
      this.getTerms();
      this.reportRegistrationStatistics();
      this.reportCenterCalendar();
      this.classDetailGraph();
    }
  }

  reportRegistrationStatistics = async () => {
    const { authData } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id } = authData.centerData;

    try {
      const res = await GET('admin/report-registration-statistics');

      if (res.status == 200) {
        let registrationsData = {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: [],
            },
          ],
        };

        registrationsData.labels = res.data.all_terms;
        registrationsData.datasets[0].data = res.data.all_count_registrations;
        registrationsData.datasets[0].backgroundColor =
          res.data.all_terms_colors;

        this.setState({ registrationsData });
      }
    } catch (err) {
      console.log('err', err);

      toast.error(t('Something went wrong!'));
    }
  };

  classDetailGraph = async () => {
    const { authData } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id, center_timezone } = authData.centerData;

    try {
      const res = await GET('dashboard/class-detail-graph');

      if (res.status == 200) {
        const labels = ['Class 1', 'Class 2', 'Class 3', 'Class 4', 'Class 5'];

        let data = {
          labels: res.data.classes,
          // labels,
          datasets: [
            {
              type: 'line',
              label: 'Assigned Adults',
              borderColor:
                brand_colors[this.props.defaultTheme.theme_id].color22,
              borderWidth: 3,
              fill: false,
              // data: labels.map(() => faker.number.int({ min: 0, max: 100 })),
              data: res.data.assigned_employees,
            },
            {
              type: 'line',
              label: 'Attended Adults',
              borderColor:
                brand_colors[this.props.defaultTheme.theme_id].color11,
              borderWidth: 3,
              fill: false,
              // data: labels.map(() => faker.number.int({ min: 0, max: 100 })),
              data: res.data.empl_att,
            },
            {
              type: 'bar',
              label: 'Class Capacity',
              backgroundColor:
                brand_colors[this.props.defaultTheme.theme_id].color1,
              borderColor: 'white',
              borderWidth: 2,
              // data: labels.map(() => faker.number.int({ min: 0, max: 100 })),
              data: res.data.class_capacities,
            },
            {
              type: 'bar',
              label: 'Register Students',
              backgroundColor:
                brand_colors[this.props.defaultTheme.theme_id].color13,
              // data: labels.map(() => faker.number.int({ min: 0, max: 100 })),
              data: res.data.registered_students,
            },
            {
              type: 'bar',
              label: 'Attendance',
              backgroundColor:
                brand_colors[this.props.defaultTheme.theme_id].color16,
              // data: labels.map(() => faker.number.int({ min: 0, max: 100 })),
              data: res.data.attendance,
            },
          ],
        };

        // registrationsData.labels = res.data.all_terms;
        // registrationsData.datasets[0].data = res.data.all_count_registrations;
        // registrationsData.datasets[0].backgroundColor = res.data.all_terms_colors;

        this.setState({
          classesDetailData: {
            data,
            table_data: res.data.table_data,
          },
        });
      }
    } catch (err) {
      console.log('err', err);

      toast.error(t('Something went wrong!'));
    }
  };

  getTerms = async () => {
    const { authData } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id } = authData.centerData;

    try {
      const res = await GET('admin/get-terms');

      if (res.status == 200) {
        const terms = res.data.map((term) => {
          let termObj = term;
          termObj.label =
            term.term_name + (term.term_active == 1 ? ' (Active)' : '');
          termObj.value = term.term_id;
          return termObj;
        });

        let selectedTerm = null;
        res.data.map((term) => {
          if (term.term_active == 1) {
            selectedTerm = term;
          }
        });

        if (selectedTerm) {
          this.reportTotalRegistrations(selectedTerm.term_id);
          this.reportCapacity(selectedTerm.term_id);
          this.reportRevenue(selectedTerm.term_id);
          this.reportClassCapacity(selectedTerm.term_id);
        }
        this.setState({
          terms,
          selectedTerm: selectedTerm ? selectedTerm : null,
        });
      }
    } catch (err) {
      console.log('err', err);
      console.log('err', err);
      toast.error(t('Something went wrong!'));
    }
  };

  handleChangeTerm = (obj) => {
    this.setState({ selectedTerm: obj });
    this.reportTotalRegistrations(obj.value);
    this.reportCapacity(obj.value);
    this.reportRevenue(obj.value);
    this.reportClassCapacity(obj.value);
  };

  reportTotalRegistrations = async (term_id) => {
    this.setState({ currentPeriodRegistrations: '...' });

    const { authData } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id } = authData.centerData;

    const request_params = {
      term_id,
    };

    try {
      const res = await GET('admin/report-total-registrations', {
        params: request_params,
      });

      if (res.status == 200) {
        this.setState({
          currentPeriodRegistrations: res.data.total_registrations,
        });
      }
    } catch (err) {
      console.log('err', err);
      toast.error(t('Something went wrong1!'));
    }
  };

  reportCapacity = async (term_id) => {
    this.setState({ reportCapacityData: null });

    const { authData } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id } = authData.centerData;

    const request_params = {
      term_id,
    };

    try {
      const res = await GET('admin/report-capacity', {
        params: request_params,
      });

      if (res.status == 200) {
        let reportCapacityData = {
          labels: [
            t('Registrations'),
            t('Classes Capacity'),
            t('Full Capacity'),
          ],
          datasets: [
            {
              data: [],
              backgroundColor: [
                brand_colors[this.props.defaultTheme.theme_id].color1,
                brand_colors[this.props.defaultTheme.theme_id].color2,
                brand_colors[this.props.defaultTheme.theme_id].color4,
              ],
            },
          ],
        };

        reportCapacityData.datasets[0].data = [
          res.data.total_registrations,
          res.data.total_classes,
          res.data.total_center,
        ];

        this.setState({ reportCapacityData });
      }
    } catch (err) {
      console.log('err', err);

      toast.error(t('Something went wrong!'));
    }
  };

  reportRevenue = async (term_id) => {
    this.setState({ reportRevenueData: null });

    const { authData } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id } = authData.centerData;

    const request_params = {
      term_id,
    };

    try {
      const res = await GET('admin/report-revenue', {
        params: request_params,
      });

      if (res.status == 200) {
        let reportRevenueData = {
          labels: [t('Total Collections'), t('Total Revenues')],
          datasets: [
            {
              data: [],
              backgroundColor: [
                brand_colors[this.props.defaultTheme.theme_id].color2,
                brand_colors[this.props.defaultTheme.theme_id].color4,
              ],
            },
          ],
        };

        reportRevenueData.datasets[0].data = [
          parseFloat(res.data.total_collections),
          parseFloat(res.data.total_revenues),
        ];

        this.setState({ reportRevenueData });
      }
    } catch (err) {
      console.log('err', err);

      toast.error(t('Something went wrong!'));
    }
  };

  reportClassCapacity = async (term_id) => {
    this.setState({ reportClassCapacityData: null });

    const { authData } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id } = authData.centerData;

    const request_params = {
      term_id,
    };

    try {
      const res = await GET('admin/report-class-capacity', {
        params: request_params,
      });

      if (res.status == 200) {
        this.setState({ reportClassCapacityData: res.data.data });
      }
    } catch (err) {
      console.log('err', err);

      toast.error(t('Something went wrong!'));
    }
  };

  reportCenterCalendar = async (arg) => {
    if (arg == undefined) {
    } else {
      this.dateStart = arg.start;
      this.dateEnd = arg.end;
    }
    this.setState({
      events: null,
    });
    const { authData } = this.props;
    const { auth_key, role_id } = authData.loginData;
    const { center_id } = authData.centerData;

    const request_params = {
      startdate: moment(this.dateStart).format('YYYY-MM-DD'),
      enddate: moment(this.dateEnd).format('YYYY-MM-DD'),
      role_id,
      actionType: 'main_tab',
    };

    try {
      const res = await GET('admin/report-center-calendar', {
        params: request_params,
      });

      if (res.status == 200) {
        this.setState({
          events: res.data.data,
        });
      }
    } catch (err) {
      console.log('err', err);

      toast.error(t('Something went wrong!'));
    }
  };

  handleOpenCalendarEntryModal = (info) => {
    let calendarEntryModal;

    if (info.event) {
      calendarEntryModal = {
        cc_id: info.event.extendedProps.cc_id,
        title: info.event.title,
        body: info.event.extendedProps.body,
        add_type: info.event.extendedProps.add_type, // holiday, calendar
        class_id: 0,
        weekly_lesson_plan: 0,
        selectedDateRange: {
          startDate: new Date(info.event.extendedProps.start_datetime),
          endDate: new Date(info.event.extendedProps.end_datetime),
          key: 'selection',
        },
        modalShow: true,
      };
    } else {
      calendarEntryModal = {
        ...this.calendarEntryDefaultFields,
        selectedDateRange: {
          startDate: new Date(info.dateStr),
          endDate: new Date(info.dateStr),
          key: 'selection',
        },
        modalShow: true,
      };
    }

    this.setState({
      calendarEntryModal,
    });
  };

  handleCloseCalendarEntryModal = () => {
    this.setState({
      calendarEntryModal: {
        ...this.calendarEntryDefaultFields,
        modalShow: false,
        showDateRange: false,
      },
    });
  };

  createCalendarEntry = async () => {
    const {
      cc_id,
      title,
      body,
      selectedDateRange,
      add_type,
      class_id,
      weekly_lesson_plan,
    } = this.state.calendarEntryModal;


    const calendarEntryObject = {
      cc_id,
      title,
      body,
      add_type,
      class_id,
      weekly_lesson_plan,
      date: moment(selectedDateRange.startDate).format('YYYY-MM-DD'),
      start_datetime:
        moment(selectedDateRange.startDate).format('YYYY-MM-DD') + ' 00:00:00',
      end_datetime:
        moment(selectedDateRange.endDate).format('YYYY-MM-DD') + ' 23:59:59',
    };

    const fData = new FormData();
    fData.append('CenterCalendarData', JSON.stringify(calendarEntryObject));

    try {
      const res = await POST('center-calendar/save-center-calendar', fData);

      if (res.data.type) {
        this.handleCloseCalendarEntryModal();

        const prevEvents = this.state.events.filter(
          (item) => item.cc_id != res.data.data.cc_id
        );

        this.setState({
          events: [...prevEvents, res.data.data],
        });
      } else {
        // toast.error(t('Something went wrong!'));
        handleFormErrors(res.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  deleteCalendarEntry = async () => {
    const { cc_id } = this.state.calendarEntryModal;

    const fData = new FormData();
    fData.append('cc_id', cc_id);

    try {
      const res = await POST('center-calendar/delete-center-calendar', fData);

      if (res.data.type) {
        this.handleCloseCalendarEntryModal();

        const prevEvents = this.state.events.filter(
          (item) => item.cc_id != cc_id
        );

        this.setState({
          events: prevEvents,
        });
      } else {
        toast.error(t('Something went wrong!'));
        handleFormErrors(res.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  getBanner = async () => {
    const { authData } = this.props;
    const { role_id } = authData.loginData;

    try {
      const res = await GET('admin/get-banner');

      if (res.data) {

        const today = new Date();
        const termFrom = new Date(res.data.term_from);
        const termTo = new Date(res.data.term_to);
        const roles = JSON.parse(res.data.roles);

        if (today >= termFrom && today <= termTo && roles.includes(role_id.toString())) {
          this.setState({
            banner: {
              ...res.data,
              show: true,
            },
          });
        }
      }
    } catch (err) {
      console.log('err', err);
      toast.error(t('Something went wrong!'));
    }
  };

  render() {

    console.log('%cSTATE', 'color: orange; font-size: 20px; font-weight: bold;', this.state);

    return (
      <>
        <Row>
          <Col md={3}>
            <Card
              className='border-0'
              style={{ borderRadius: 20, marginBottom: 20 }}>
              <Card.Body>
                <Select
                  placeholder={t('Term')}
                  value={this.state.selectedTerm}
                  onChange={(obj) => this.handleChangeTerm(obj)}
                  options={this.state.terms}
                />
              </Card.Body>
            </Card>
            <Card
              className='border-0 d-flex align-items-center position-relative'
              style={{ borderRadius: 20, marginBottom: 20 }}>
              <Card.Body>
                <FontAwesomeIcon
                  style={{ position: 'absolute', top: 20, right: 20 }}
                  icon={faInfoCircle}
                  data-tooltip-id='term-change-tip'
                  data-tooltip-content={t(
                    'This widget will update when term is changed'
                  )}
                  data-tooltip-place='right'
                />
                <div
                  style={{
                    margin: '0 auto 20px',
                    width: 100,
                    height: 100,
                    borderRadius: 50,
                    backgroundColor:
                      brand_colors[this.props.defaultTheme.theme_id].color13,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    opacity: 0.7,
                  }}>
                  <FontAwesomeIcon
                    icon={faSchool}
                    style={{
                      fontSize: 40,
                      color:
                        brand_colors[this.props.defaultTheme.theme_id].color8,
                    }}
                  />
                </div>
                <div className='mb-1 text-center'>
                  <strong className='text-muted'>
                    {t('Current Period Registrations')}
                  </strong>
                </div>
                <div className='text-center'>
                  <strong style={{ fontSize: 48 }}>
                    {this.state.currentPeriodRegistrations}
                  </strong>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md={9}>
            <Row>
              <Col md={4}>
                <SchoolAttendanceWidget />
              </Col>
              <Col md={4}>
                <Card
                  className='border-0'
                  style={{ borderRadius: 20, marginBottom: 20 }}>
                  <Card.Body>
                    <Row>
                      <Col>
                        <div className='mb-2'>
                          <strong>{t('Revenue Vs. Collection')}</strong>
                        </div>
                      </Col>
                      <Col md='auto'>
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          data-tooltip-id='term-change-tip'
                          data-tooltip-content={t(
                            'This widget will update when term is changed'
                          )}
                          data-tooltip-place='right'
                        />
                      </Col>
                    </Row>
                    <div>
                      {this.state.reportRevenueData ? (
                        <Doughnut
                          data={this.state.reportRevenueData}
                          options={doughnutChartOptions}
                          height={278}
                        />
                      ) : (
                        <LoadingPlaceholder height={278} />
                      )}
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={4}>
                <Card
                  className='border-0'
                  style={{ borderRadius: 20, marginBottom: 20 }}>
                  <Card.Body>
                    <Row>
                      <Col>
                        <div className='mb-2'>
                          <strong>{t('School Capacity')}</strong>
                        </div>
                      </Col>
                      <Col md='auto'>
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          data-tooltip-id='term-change-tip'
                          data-tooltip-content={t(
                            'This widget will update when term is changed'
                          )}
                          data-tooltip-place='right'
                        />
                      </Col>
                    </Row>
                    <div>
                      {this.state.reportCapacityData ? (
                        <Doughnut
                          data={this.state.reportCapacityData}
                          options={doughnutChartOptions}
                          height={278}
                        />
                      ) : (
                        <LoadingPlaceholder height={278} />
                      )}
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Card
              className='border-0'
              style={{ borderRadius: '20px', marginBottom: 20 }}>
              <Card.Body>
                <div className='mb-1 text-center'>
                  <strong>{t('Registrations')}</strong>
                </div>
                <div>
                  {this.state.registrationsData ? (
                    <Bar
                      options={barChartOptions}
                      data={this.state.registrationsData}
                      height={305}
                    />
                  ) : (
                    <LoadingPlaceholder height={305} />
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card
              className='border-0'
              style={{ borderRadius: 20, marginBottom: 20 }}>
              <Card.Body>
                <Row>
                  <Col className='ps-5'>
                    <div className='text-center mb-2'>
                      <strong>{t('Booked/Available Seats Per Class')}</strong>
                    </div>
                  </Col>
                  <Col md='auto'>
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      data-tooltip-id='term-change-tip'
                      data-tooltip-content={t(
                        'This table will update when term is changed'
                      )}
                      data-tooltip-place='right'
                    />
                  </Col>
                </Row>
                <Table className='mb-0'>
                  <thead>
                    <tr>
                      <th width='30%'>{t('Class')}</th>
                      <th width='20%'>{t('Capacity')}</th>
                      <th width='30%'>{t('Total Registrations')}</th>
                    </tr>
                  </thead>
                </Table>
                <div
                  style={{
                    height: 260,
                    overflowX: 'hidden',
                    overflowY: 'auto',
                  }}>
                  <Table>
                    <tbody>
                      {this.state.reportClassCapacityData ? (
                        <>
                          {this.state.reportClassCapacityData.map(
                            (item, index) => (
                              <tr key={index}>
                                <td width='30%'>{item.class_theme}</td>
                                <td width='20%' style={{ paddingLeft: 10 }}>
                                  <Badge bg='info'>{item.child_count}</Badge>
                                </td>
                                <td width='30%' style={{ paddingLeft: 15 }}>
                                  <Row>
                                    <Col>
                                      <Badge bg='primary'>
                                        {item.total_registered}
                                      </Badge>
                                    </Col>
                                    <Col className='text-end'>
                                      <Button
                                        size='sm'
                                        variant='warning'
                                        onClick={() =>
                                          this.setState({
                                            showBookedSeatsModal: true,
                                            bookedAvailableSeats: {
                                              total: parseInt(item.child_count),
                                              mon: parseInt(
                                                item.total_session_mon
                                              ),
                                              tue: parseInt(
                                                item.total_session_tue
                                              ),
                                              wed: parseInt(
                                                item.total_session_wed
                                              ),
                                              thu: parseInt(
                                                item.total_session_thu
                                              ),
                                              fri: parseInt(
                                                item.total_session_fri
                                              ),
                                              sat: parseInt(
                                                item.total_session_sat
                                              ),
                                              sun: parseInt(
                                                item.total_session_sun
                                              ),
                                            },
                                          })
                                        }
                                        style={{
                                          lineHeight: '10px',
                                          padding: 5,
                                        }}>
                                        <FontAwesomeIcon
                                          icon={faEye}
                                          style={{ fontSize: 10 }}
                                        />
                                      </Button>
                                    </Col>
                                  </Row>
                                </td>
                              </tr>
                            )
                          )}
                        </>
                      ) : (
                        <tr>
                          <td align='center' colSpan={3}>
                            {t('Loading...')}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md={12} style={{ marginBottom: 20 }}>
            <Card className='border-0' style={{ borderRadius: 20 }}>
              <Card.Body>
                <div style={{ height: 600 }}>
                  <FullCalendar
                    headerToolbar={{
                      left: "today prev,next",
                      center: "title",
                      right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
                    }}
                    plugins={[
                      dayGridPlugin,
                      interactionPlugin,
                      timeGridPlugin,
                      listPlugin,
                    ]}
                    initialView='dayGridMonth'
                    datesSet={this.reportCenterCalendar}
                    dateClick={this.handleOpenCalendarEntryModal}
                    events={this.state.events}
                    height={'100%'}
                    eventClick={this.handleOpenCalendarEntryModal}
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>
          {this.props.authData.loginData.role_id != 5 && ( // If Not Accountant
            <>
              <Col md={12} style={{ marginBottom: 20 }}>
                <Card className='border-0' style={{ borderRadius: 20 }}>
                  <Card.Body>
                    <div className='mb-1 text-center'>
                      <strong>{t('EY Class Ratios')}</strong>
                    </div>
                    {this.state.classesDetailData && (
                      <Chart
                        type='bar'
                        height={80}
                        data={this.state.classesDetailData.data}
                      />
                    )}
                  </Card.Body>
                </Card>
              </Col>
              <Col md={6}>
                <Card className='border-0' style={{ borderRadius: 20 }}>
                  <Card.Body>
                    <div className='mb-1 text-center'>
                      <strong>{t('EY Class Ratios Analysis')}</strong>
                    </div>
                    <Table style={{ fontSize: 12, margin: 0 }}>
                      <thead>
                        <tr>
                          <th width='15%'>{t('Class')}</th>
                          <th width='15%' style={{ textAlign: 'center' }}>
                            {t('Registered')}
                          </th>
                          <th width='10%' style={{ textAlign: 'center' }}>
                            {t('Class Capacity')}
                          </th>
                          <th width='10%' style={{ textAlign: 'center' }}>
                            {t('Teacher Ratio')}
                          </th>
                          <th width='10%' style={{ textAlign: 'center' }}>
                            {t('Assigned Adults')}
                          </th>
                          <th width='10%' style={{ textAlign: 'center' }}>
                            {t('Attended')}
                          </th>
                          <th width='10%' style={{ textAlign: 'center' }}>
                            {t('Effective Ratio')}
                          </th>
                          <th width='10%' style={{ textAlign: 'center' }}>
                            {t('Staff Shortage')}
                          </th>
                          <th width='10%' style={{ textAlign: 'center' }}>
                            {t('Staff Attending Shortage')}
                          </th>
                        </tr>
                      </thead>
                    </Table>
                    <div
                      style={{
                        height: 300,
                        overflowX: 'hidden',
                        overflowY: 'auto',
                      }}>
                      <Table style={{ fontSize: 12 }}>
                        <tbody>
                          {this.state.classesDetailData &&
                            this.state.classesDetailData.table_data.map(
                              (item, index) => {
                                var effective_ratio = Math.ceil(
                                  parseInt(item.registered_students) /
                                  parseInt(item.teacher_ratio)
                                );
                                var red_it = '';
                                if (
                                  effective_ratio < parseInt(item.teacher_ratio)
                                ) {
                                  red_it = '#F3435D';
                                }
                                return (
                                  <tr key={index}>
                                    <td width='15%'>{item.class_name}</td>
                                    <td
                                      width='15%'
                                      style={{ textAlign: 'center' }}>
                                      {item.registered_students}
                                    </td>
                                    <td
                                      width='10%'
                                      style={{ textAlign: 'center' }}>
                                      {item.class_capacity}
                                    </td>
                                    <td
                                      width='10%'
                                      style={{ textAlign: 'center' }}>
                                      {item.teacher_ratio}
                                    </td>
                                    <td
                                      width='10%'
                                      style={{ textAlign: 'center' }}>
                                      {item.assigned_employees}
                                    </td>
                                    <td
                                      width='10%'
                                      style={{ textAlign: 'center' }}>
                                      {item.attendance}
                                    </td>
                                    <td
                                      width='10%'
                                      style={{
                                        color: red_it,
                                        textAlign: 'center',
                                      }}>
                                      {isNaN(effective_ratio) ||
                                        effective_ratio === Infinity
                                        ? 0
                                        : effective_ratio}
                                    </td>
                                    <td
                                      width='10%'
                                      style={{ textAlign: 'center' }}>
                                      {isNaN(effective_ratio) ||
                                        effective_ratio === Infinity
                                        ? 0
                                        : effective_ratio -
                                        item.assigned_employees}
                                    </td>
                                    <td
                                      width='10%'
                                      style={{ textAlign: 'center' }}>
                                      {isNaN(effective_ratio) ||
                                        effective_ratio === Infinity
                                        ? 0
                                        : effective_ratio - item.attendance}
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                        </tbody>
                      </Table>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </>
          )}
        </Row>

        {/* Booked Seats Modal */}
        <Modal
          show={this.state.showBookedSeatsModal}
          onHide={() => this.setState({ showBookedSeatsModal: false })}
          size='lg'
          backdrop='static'
          keyboard={false}>
          <Modal.Header closeButton>
            <Modal.Title>
              {t('Booked/Available Seats Per Class')}&nbsp;&nbsp;&nbsp;
              <Badge bg='info' className='mx-1 px-2'>
                {t('Booked')}
              </Badge>{' '}
              <Badge bg='success' className='mx-1 px-2'>
                {t('Available')}
              </Badge>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table bordered>
              <thead>
                <tr>
                  <th className='text-center'>{t('Monday')}</th>
                  <th className='text-center'>{t('Tuesday')}</th>
                  <th className='text-center'>{t('Wednesday')}</th>
                  <th className='text-center'>{t('Thursday')}</th>
                  <th className='text-center'>{t('Friday')}</th>
                  <th className='text-center'>{t('Saturday')}</th>
                  <th className='text-center'>{t('Sunday')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td align='center'>
                    <Badge bg='info' className='mx-1 px-2'>
                      {this.state.bookedAvailableSeats.mon}
                    </Badge>
                    <Badge bg='success' className='mx-1 px-2'>
                      {this.state.bookedAvailableSeats.total -
                        this.state.bookedAvailableSeats.mon}
                    </Badge>
                  </td>
                  <td align='center'>
                    <Badge bg='info' className='mx-1 px-2'>
                      {this.state.bookedAvailableSeats.tue}
                    </Badge>
                    <Badge bg='success' className='mx-1 px-2'>
                      {this.state.bookedAvailableSeats.total -
                        this.state.bookedAvailableSeats.tue}
                    </Badge>
                  </td>
                  <td align='center'>
                    <Badge bg='info' className='mx-1 px-2'>
                      {this.state.bookedAvailableSeats.wed}
                    </Badge>
                    <Badge bg='success' className='mx-1 px-2'>
                      {this.state.bookedAvailableSeats.total -
                        this.state.bookedAvailableSeats.wed}
                    </Badge>
                  </td>
                  <td align='center'>
                    <Badge bg='info' className='mx-1 px-2'>
                      {this.state.bookedAvailableSeats.thu}
                    </Badge>
                    <Badge bg='success' className='mx-1 px-2'>
                      {this.state.bookedAvailableSeats.total -
                        this.state.bookedAvailableSeats.thu}
                    </Badge>
                  </td>
                  <td align='center'>
                    <Badge bg='info' className='mx-1 px-2'>
                      {this.state.bookedAvailableSeats.fri}
                    </Badge>
                    <Badge bg='success' className='mx-1 px-2'>
                      {this.state.bookedAvailableSeats.total -
                        this.state.bookedAvailableSeats.fri}
                    </Badge>
                  </td>
                  <td align='center'>
                    <Badge bg='info' className='mx-1 px-2'>
                      {this.state.bookedAvailableSeats.sat}
                    </Badge>
                    <Badge bg='success' className='mx-1 px-2'>
                      {this.state.bookedAvailableSeats.total -
                        this.state.bookedAvailableSeats.sat}
                    </Badge>
                  </td>
                  <td align='center'>
                    <Badge bg='info' className='mx-1 px-2'>
                      {this.state.bookedAvailableSeats.sun}
                    </Badge>
                    <Badge bg='success' className='mx-1 px-2'>
                      {this.state.bookedAvailableSeats.total -
                        this.state.bookedAvailableSeats.sun}
                    </Badge>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Modal.Body>
        </Modal>
        {/* Calendar Entry Modal */}
        <Modal
          show={this.state.calendarEntryModal.modalShow}
          onHide={this.handleCloseCalendarEntryModal}
          backdrop='static'
          keyboard={false}>
          <Modal.Header closeButton>
            <Modal.Title>{t('Calendar Entry')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={10}>
                <Form.Group className='mb-3'>
                  <Form.Label>{t('Title')}</Form.Label>
                  <Form.Control
                    type='text'
                    defaultValue={this.state.calendarEntryModal.title}
                    onBlur={(e) =>
                      this.setState({
                        calendarEntryModal: {
                          ...this.state.calendarEntryModal,
                          title: e.target.value,
                        },
                      })
                    }
                    onClick={() =>
                      this.setState({
                        calendarEntryModal: {
                          ...this.state.calendarEntryModal,
                          showDateRange: false,
                        },
                      })
                    }
                  />
                </Form.Group>
              </Col>
              <Col md={2}>
                <Form.Group>
                  <Form.Label>{t('Holiday')}</Form.Label>
                  <div className='p-0 pt-2'>
                    <Form.Check
                      type='switch'
                      id='add_type'
                      label={false}
                      onChange={() =>
                        this.setState({
                          calendarEntryModal: {
                            ...this.state.calendarEntryModal,
                            add_type:
                              this.state.calendarEntryModal.add_type ==
                                'holiday'
                                ? 'calendar'
                                : 'holiday',
                          },
                        })
                      }
                      checked={
                        this.state.calendarEntryModal.add_type == 'holiday'
                      }
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col md={12} className='position-relative'>
                <Form.Label>{t('From-To Date')}</Form.Label>
                <InputGroup className='mb-3'>
                  <Form.Control
                    onClick={() =>
                      this.setState({
                        calendarEntryModal: {
                          ...this.state.calendarEntryModal,
                          showDateRange: true,
                        },
                      })
                    }
                    style={{
                      paddingLeft: 20,
                    }}
                    value={
                      moment(
                        this.state.calendarEntryModal.selectedDateRange
                          .startDate
                      ).format('DD-MM-YYYY') +
                      ' -- ' +
                      moment(
                        this.state.calendarEntryModal.selectedDateRange.endDate
                      ).format('DD-MM-YYYY')
                    }
                    readOnly
                  />
                  <Button
                    variant={
                      this.state.calendarEntryModal.showDateRange
                        ? 'danger'
                        : 'primary'
                    }
                    style={{ width: 40 }}
                    onClick={() =>
                      this.setState({
                        calendarEntryModal: {
                          ...this.state.calendarEntryModal,
                          showDateRange:
                            !this.state.calendarEntryModal.showDateRange,
                        },
                      })
                    }
                    id='button-addon2'>
                    <FontAwesomeIcon
                      icon={
                        this.state.calendarEntryModal.showDateRange
                          ? faXmark
                          : faCalendarAlt
                      }
                      style={{
                        fontSize: 16,
                        color:
                          brand_colors[this.props.defaultTheme.theme_id].color8,
                      }}
                    />
                  </Button>
                </InputGroup>
                {this.state.calendarEntryModal.showDateRange && (
                  <div
                    style={{
                      backgroundColor: '#fff',
                      padding: 10,
                      position: 'absolute',
                      border: '#F2F2F2 2px solid',
                      zIndex: 1,
                      top: 68,
                      left: -45,
                      borderRadius: 7,
                    }}>
                    <DateRangePicker
                      ranges={[this.state.calendarEntryModal.selectedDateRange]}
                      onChange={(date) => {
                        this.setState({
                          calendarEntryModal: {
                            ...this.state.calendarEntryModal,
                            selectedDateRange: {
                              startDate: new Date(date.selection.startDate),
                              endDate: new Date(date.selection.endDate),
                              key: 'selection',
                            },
                          },
                        });
                      }}
                      style={{ backgroundColor: '#fff' }}
                    />
                  </div>
                )}
              </Col>
              <Col md={12}>
                <Form.Group className='mb-3'>
                  <Form.Label>{t('Description')}</Form.Label>
                  <Form.Control
                    type='text'
                    defaultValue={this.state.calendarEntryModal.body}
                    onBlur={(e) =>
                      this.setState({
                        calendarEntryModal: {
                          ...this.state.calendarEntryModal,
                          body: e.target.value,
                        },
                      })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Row style={{ flex: 1 }}>
              <Col className='p-0'>
                {(
                  this.state.calendarEntryModal.add_type == 'calendar' &&
                  this.state.calendarEntryModal.cc_id != 0
                ) && (
                    <Button variant='danger' onClick={this.deleteCalendarEntry}>
                      <FontAwesomeIcon icon={faTrashAlt} /> {t('Delete')}
                    </Button>
                  )}
              </Col>
              <Col className='p-0 d-flex justify-content-end'>
                <Button
                  className='me-2'
                  variant='secondary'
                  onClick={this.handleCloseCalendarEntryModal}>
                  {t('Close')}
                </Button>
                <Button variant='primary' onClick={this.createCalendarEntry}>
                  {t('Save')}
                </Button>
              </Col>
            </Row>
          </Modal.Footer>
        </Modal>

        <Modal
          size='xl'
          show={this.state.banner.show}
          onHide={() => this.setState({ banner: { show: false } })}
          backdrop='static'
          centered
          keyboard={false}>
          <Modal.Header closeButton>
            <Modal.Title>{t('Banner')}</Modal.Title>
          </Modal.Header>
          <Modal.Body className='text-center'>
            <div dangerouslySetInnerHTML={{ __html: this.state.banner?.banner_name }} className='mb-4' />
            {/* 1e34d32a-8e04-42b6-8c74-10c031b4634e = > center 2 */}
            <img
              src={portalURL + '1e34d32a-8e04-42b6-8c74-10c031b4634e/educorebanners/' + this.state.banner?.picture}
              alt='Banner'
              fluid
            />
          </Modal.Body>
        </Modal>
        <Tooltiper id='term-change-tip' />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(Main);
