import React, { Component } from 'react';
import { Container, Card, Table, Row, Col, Spinner, Image, Badge, Button, Form, Offcanvas, Dropdown, Modal, } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faAnglesLeft, faAnglesRight, faCalendarAlt, faCheck, faCheckCircle, faCheckDouble, faClock, faEdit, faEllipsisV, faExternalLink, faFileAlt, faFilterCircleXmark, faGear, faGripVertical, faPaperclip, faPlus, faSearch, faTimes, faTrashAlt, faTrashCan, faXmark, } from '@fortawesome/free-solid-svg-icons';
import { Scrollbar } from 'react-scrollbars-custom';
import imgDescription from '../../assets/images/description.png';
import { brand_colors } from '../../helpers/brand_colors_helper';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Link } from 'react-router-dom';
import { faCircle, faThumbsDown, faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import { handleFormErrors, readFile } from '../../helpers/form_helpers';
import { colorPalette } from '../../constants/colorsPalette';
import { capitalizeFirstLetter, getExtension } from '../../helpers/general_helpers';
import HorizontalCard from '../../helpers/HorizontalCard';
import { GET, POST } from '../../api';
import Select from 'react-select';


// API URL
const apiURL = process.env.REACT_APP_API_URL;

// Portal URL
const portalURL = process.env.REACT_APP_PORTAL_URL;

class Progass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deleteModal: { show: false, id: 0, action: () => { }, title: null, desc: null },
            progassData: {
                search: '',
                order: 'progass.progass_id',
                dir: 'DESC',
                limit: 100,
                offset: 0,
                data: [],
                total_count: 0,
                total_count_filtered: 0,
                refreshing: false,
                finished: false,
            },
            modalProgass: {
                show: false,
                toggle: (title = 'Board/Group') => this.setState({ modalProgass: { ...this.state.modalProgass, show: !this.state.modalProgass.show, title } }),
                title: '',
                save: this.saveProgass,
                saving: false,
            },
            progass: {
                progass_id: 0,
                progass_code: '',
                progass_desc: '',
                progass_image: '',
                progass_color: '',
                status: 1,
                progass_assesslog_type: 'general',
            },
            progassAssesslogTypes: [
                { label: 'General', value: 'general' },
                { label: 'Checklist', value: 'checklist' },
            ],
            progass_image: null,
            dragAreaColor: {
                progass_image: brand_colors[this.props.defaultTheme.theme_id].color18,
                toggleColor: (field, value) => this.setState({ dragAreaColor: { ...this.state.dragAreaColor, [field]: value } })
            }
        }
        this.progass_image_ref = React.createRef();
    }

    componentDidMount() {
        this.getProgass()
    }

    getProgass = async () => {
        let progassData = this.state.progassData
        if (progassData.refreshing) {
            return false
        }
        this.setState({
            progassData: {
                ...this.state.progassData,
                refreshing: true
            }
        })

        try {
            const res = await GET('progass/get',
                {
                    params: {
                        search: this.state.progassData.search,
                        order: this.state.progassData.order,
                        dir: this.state.progassData.dir,
                        limit: this.state.progassData.limit,
                        offset: this.state.progassData.offset,
                    },
                }
            );
            this.setState({
                progassData: {
                    ...this.state.progassData,
                    data: res.data.data,
                    total_count: res.data.total_count,
                    total_count_filtered: res.data.total_count_filtered,
                    refreshing: false,
                    finished: true
                }
            });
            if (res.data.data.length > 0) {
                this.props.progass.toggleBoard(res.data.data[0].progass_id)
            }
        } catch (err) {
            console.log('err', err)
            toast.error(t('Something went wrong while fetching data!'));

        }
    };

    saveProgass = async () => {
        this.setState({ modalProgass: { ...this.state.modalProgass, saving: true } });
        const { auth_key, user_id, full_name, role_id } =
            this.props.authData.loginData;
        const { center_id, center_timezone } = this.props.authData.centerData;
        const { progass, progass_image } = this.state;

        const fData = new FormData();
        fData.append('user_id', user_id);
        fData.append('center_id', center_id);
        fData.append('center_timezone', center_timezone);
        fData.append('progass_id', progass.progass_id);
        if (progass_image) {
            fData.append('progass_image', progass_image.data)
        }
        fData.append('ProgassData', JSON.stringify(progass));

        try {
            const res = await POST('progass/save', fData);
            if (!res.data.type) {
                let progassData = this.state.progassData
                if (res.data.data != null) {
                    handleFormErrors(res.data.data);
                } else {
                    toast.error(t('Something went wrong while saving!'));
                }
                this.setState({
                    progassData,
                    modalProgass: {
                        ...this.state.modalProgass,
                        saving: false,
                    }
                }
                );
            } else {
                let progassData = this.state.progassData
                if (progass.progass_id != 0) {
                    progassData.data = progassData.data.map((v, i) => {
                        if (v.progass_id == progass.progass_id) {
                            return { ...v, ...res.data.data }
                        }
                        return v
                    })
                } else {
                    progassData.data = [{ ...res.data.data, status: (res.data.data.status == 1) ? 0 : 1 }, ...progassData.data]
                }

                this.setState({
                    progassData,
                    modalProgass: {
                        ...this.state.modalProgass,
                        show: false,
                        saving: false,
                    },
                    progass: {
                        progass_id: 0,
                        progass_code: '',
                        progass_desc: '',
                        progass_image: '',
                        progass_color: '',
                        status: 1,
                        progass_assesslog_type: 'general'
                    },
                    progass_image: null,
                });
                toast.success(t(res.data.message));
            }
        } catch (err) {
            console.log('err', err)
            toast.error(t('Something went wrong while sending message!'));

        }
    };

    toggleStatusProgass = async (progass_id) => {
        const { auth_key, user_id, full_name, role_id } =
            this.props.authData.loginData;
        const { center_id, center_timezone } = this.props.authData.centerData;
        const fData = new FormData();
        fData.append('center_id', center_id);
        fData.append('progass_id', progass_id);
        try {
            const res = await POST(apiURL + 'progass/toggle-status', fData,);

            if (!res.data.status) {
                toast.error(t('Something went wrong while updating!'));
            } else {
                let progassData = this.state.progassData
                progassData.data = progassData.data.map((v, i) => {
                    if (v.progass_id == progass_id) {
                        return { ...v, status: (v.status == 1) ? 0 : 1 }
                    }
                    return v
                })
                this.setState({ progassData })
                toast.warning(t('Status updated'));
            }
        } catch (err) {
            console.log('err', err)
            toast.error(t('Something went wrong while updating!'));
        }
    };

    setStateProgass = (field, value) => {
        this.setState({ progass: { ...this.state.progass, [field]: value } })
    }

    handleDragFileArea = (e, name) => {
        e.preventDefault();
        e.stopPropagation();

        if (e.type === "dragover") {
            this.state.dragAreaColor.toggleColor(name, brand_colors[this.props.defaultTheme.theme_id].color1)
        } else if (e.type === "dragleave") {
            this.state.dragAreaColor.toggleColor(name, brand_colors[this.props.defaultTheme.theme_id].color18)
        }
    }

    addAttachment = (e) => {
        const selectedFiles = e.target.files;
        let allFiles = [];
        for (let i = 0; i < selectedFiles.length; i++) {
            allFiles.push(readFile(selectedFiles[i]));
        }
        Promise.all(allFiles).then((values) => {
            let attachments = e.target.multiple ? this.state[e.target.name] : null;
            let selectedFiles = values;
            selectedFiles.map((v, i) => {
                if (v.data.type) {
                    if (e.target.multiple) {
                        attachments.push({ url: v.url, data: v.data });
                    } else {
                        attachments = { url: v.url, data: v.data };
                    }
                }
            });
            this.setState({ [e.target.name]: attachments, dragAreaColor: { ...this.state.dragAreaColor, [e.target.name]: brand_colors[this.props.defaultTheme.theme_id].color18 } });
        });
    };

    deleteAttachment = (field, actionFun = null) => {
        let progass = this.state.progass
        if (progass.progass_id != null && progass.progass_id != 0) {
            this.setState({
                deleteModal: {
                    ...this.state.deleteModal, show: true, action: () => {
                        // DELETE FILE CALL 
                        try {
                            GET('progass/delete-progass-image', {
                                params: {
                                    key: progass.progass_id,
                                    field: field,
                                },
                            }).then((res) => {
                                if (res) {
                                    if (actionFun) {
                                        actionFun()
                                    }
                                    progass[field] = null
                                    let progassData = this.state.progassData
                                    progassData.data = progassData.data.map((vv, ii) => {
                                        if (vv.progass_id == this.state.progass.progass_id) {
                                            vv[field] = null;
                                        }
                                        return vv
                                    })
                                    this.setState({
                                        deleteModal: {
                                            ...this.state.deleteModal, show: false
                                        },
                                        progassData,
                                        [field]: null,
                                        progass,
                                    });
                                    toast.warning(t('Attachment deleted'));
                                } else {
                                    toast.error(t('Something went wrong while deleting!'));
                                }
                            })
                        } catch (err) {
                            console.log('err', err)
                            toast.error(t('Something went wrong while deleting!'));

                        }
                    }
                }
            })
        } else {
            progass[field] = null
            this.setState({ [field]: null, progass });
            if (actionFun) {
                actionFun()
            }
        }
    }

    handleOpenColorPicker = () => {
        this.setState({ showColorPicker: true })
    }

    deleteAcademicBoard = (progass_id) => {
        this.setState({
            deleteModal: {
                ...this.state.deleteModal, show: true, action: () => {
                    // DELETE FILE CALL 
                    try {
                        GET('progass/delete', {
                            params: {
                                progass_id,
                            },
                        }).then((res) => {

                            if (res.data.type) {
                                let progassData = this.state.progassData
                                progassData.data = progassData.data.filter((v, i) => {
                                    if (v.progass_id != progass_id) {
                                        return true
                                    }
                                    return false
                                })
                                this.setState({
                                    progassData, deleteModal: {
                                        ...this.state.deleteModal, show: false
                                    }
                                })
                                toast.warning(t(res.data.message));
                            } else {
                                this.setState({
                                    deleteModal: {
                                        ...this.state.deleteModal, show: false
                                    }
                                })
                                toast.error(t(res.data.message));
                            }
                        })
                    } catch (err) {
                        console.log('err', err)
                        toast.error(t('Something went wrong while deleting!'));

                    }
                }
            }
        })
    }

    render() {
        const { defaultTheme } = this.props;
        const { progassData, modalProgass, progass, progass_image } = this.state;

        let selected_progass_id = null
        if (this.props.progass) {
            selected_progass_id = this.props.progass.progass_id
        }

        return (<div className='academic-board'>
            <div className='grey-section-header'>
                <h5>
                    <span className='me-1'>{t('Curriculum')}</span>
                    <span className='mx-1' style={{ opacity: 0.6, fontWeight: 'normal' }}>|</span>
                    <span className='mx-1' style={{ opacity: 0.8, fontSize: '1rem', fontWeight: 'normal' }}>{t('Please select the curriculum below')}</span>
                </h5>
                <div className='d-flex flex-row align-items-center'>
                    <div>
                        <Button
                            onClick={() => this.setState({
                                progass: {
                                    progass_id: 0,
                                    progass_code: '',
                                    progass_desc: '',
                                    progass_image: '',
                                    progass_color: '',
                                    status: 1,
                                    progass_assesslog_type: 'general'
                                },
                                progass_image: null,
                            }, () => modalProgass.toggle())}
                            className='btn-sm btn-add'
                            variant='success'>
                            {t('Add Board/Group')}{' '}
                            <FontAwesomeIcon
                                icon={faPlus}
                                color={brand_colors[defaultTheme.theme_id].color8}
                                style={{
                                    fontSize: 16,
                                }}
                            />
                        </Button>
                    </div>
                </div>
            </div>
            {/* BOARDS */}
            <div style={{ overflowX: 'auto', overflowY: 'hidden' }}>
                <div className='d-flex flex-row' style={{ width: ((progassData.data.length + 1) * 31) + 'vw', }} >
                    {progassData.data.map((v, i) => {
                        let data = {
                            allData: v,
                            name: v.progass_code,
                            // text1: v.progass_desc,
                            cardImage: (v.progass_image) ? portalURL + v.center_id + '/progass/' + v.progass_image : require('../../assets/images/noimage.png'),
                            actions: [
                                { type: 'selection', name: 'Selection', iconName: null, iconColor: brand_colors[this.props.defaultTheme.theme_id].color4, onClick: () => this.props.progass.toggleBoard(v.progass_id), state: selected_progass_id == v.progass_id },
                                { type: 'status', name: (v.status == 1) ? 'Disable' : 'Enable', image: null, iconName: (v.status == 1) ? faThumbsDown : faThumbsUp, iconColor: (v.status == 1) ? brand_colors[this.props.defaultTheme.theme_id].color11 : brand_colors[this.props.defaultTheme.theme_id].color4, onClick: () => this.toggleStatusProgass(v.progass_id), state: (v.status == 1) },
                                {
                                    type: 'edit', name: 'Edit', image: require('../../assets/images/edit.png'), iconName: null, iconColor: brand_colors[this.props.defaultTheme.theme_id].color16, onClick: () => this.setState({
                                        modalProgass: { ...modalProgass, show: true, title: 'Edit Curriculum' },
                                        progass: { ...progass, ...v },
                                        progass_image: (v.progass_image) ? { url: portalURL + v.center_id + '/progass/' + v.progass_image, data: { type: getExtension(v.progass_image) } } : null,
                                    })
                                },
                                { type: 'delete', name: 'Delete', image: require('../../assets/images/remove-red.png'), iconName: null, iconColor: brand_colors[this.props.defaultTheme.theme_id].color11, onClick: () => this.deleteAcademicBoard(v.progass_id) },
                            ]
                        }
                        return <HorizontalCard data={data} key={i} />
                    })}

                    {progassData.refreshing && !progassData.finished &&
                        <div className='d-flex mt-2 align-items-center'>
                            <Spinner animation='grow' variant='dark' />
                        </div>
                    }
                    {/* {!progassData.refreshing && progassData.finished && progassData.data.length > 0 &&
                        <div className='d-flex mt-2 align-items-center'>
                            <div style={{ color: brand_colors[defaultTheme.theme_id].color18 }}>NO MORE DATA...</div>
                        </div>
                    } */}
                    {!progassData.refreshing && progassData.finished && progassData.data.length <= 0 &&
                        <div className='d-flex mt-2 align-items-center'>
                            <div style={{ color: brand_colors[defaultTheme.theme_id].color18 }}>NO DATA FOUND...</div>
                        </div>
                    }
                </div>
            </div>

            <SweetAlert
                show={this.state.deleteModal.show}
                warning
                showCancel
                confirmBtnText={t('Yes, delete it!')}
                confirmBtnBsStyle='danger'
                title={(this.state.deleteModal.title) ? this.state.deleteModal.title : t('Are you sure?')}
                onConfirm={this.state.deleteModal.action}
                onCancel={() => this.setState({ deleteModal: { ...this.state.deleteModal, show: false, id: 0, action: () => { } } })}
                focusCancelBtn
            >
                {(this.state.deleteModal.desc) ? this.state.deleteModal.desc : t('Confirm Delete!')}
            </SweetAlert>

            {/* MODAL NEW/EDIT ACADEMIC BOARD */}
            <Modal show={modalProgass.show} onHide={() => modalProgass.toggle()} size="lg" backdrop="static"
                keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalProgass.title}</Modal.Title>
                </Modal.Header>
                {modalProgass.show &&
                    <Modal.Body className='custom-styled-form'>
                        <Row>
                            <Col md="6">
                                <Row>
                                    <Col md="12">
                                        <Form.Group className='mb-3'>
                                            <Form.Label>
                                                <img src={imgDescription} />
                                                {t('Curriculum')}
                                            </Form.Label>
                                            <Form.Control type='text' defaultValue={progass.progass_code} onBlur={(event) => this.setStateProgass('progass_code', event.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col md="12">
                                        <Form.Group className='mb-3'>
                                            <Form.Label>
                                                <img src={imgDescription} />
                                                {t('Description')}
                                            </Form.Label>
                                            < Form.Control as="textarea" rows={3} defaultValue={progass.progass_desc} onBlur={(event) => this.setStateProgass('progass_desc', event.target.value)} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="4" className='text-center'>
                                        <Form.Group className="mb-3">
                                            <Form.Label className='no-style'>{t('Color')}</Form.Label>
                                            <div className='selected-color' onClick={() => {
                                                this.handleOpenColorPicker();
                                            }}>
                                                <div style={{ backgroundColor: progass.progass_color }}></div>
                                            </div>
                                            {this.state.showColorPicker &&
                                                <div className='color-palette'>
                                                    {colorPalette.map((color, color_i) => (
                                                        <div
                                                            key={color_i}
                                                            onClick={() => {
                                                                this.setState({
                                                                    progass: {
                                                                        ...this.state.progass,
                                                                        progass_color: color.code
                                                                    },
                                                                    showColorPicker: false
                                                                })
                                                            }}
                                                            style={{ backgroundColor: color.code }}
                                                        ></div>
                                                    ))}
                                                </div>
                                            }
                                        </Form.Group>
                                    </Col>
                                    <Col md="8" className='text-center'>
                                        <Form.Label className='no-style'>
                                            {t('Status')}
                                        </Form.Label>
                                        <Form.Check
                                            type="switch"
                                            style={{}}
                                            value={1}
                                            checked={(progass.status == 0) ? false : true}
                                            onChange={(event) => this.setStateProgass('status', (progass.status == 1) ? 0 : 1)}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col md="6">
                                <div style={{ flexDirection: 'column' }} className='pic-prev d-flex justify-content-center mb-3'>
                                    <div style={{ height: 175, padding: 10, overflow: 'hidden', borderStyle: 'dashed', borderColor: this.state.dragAreaColor.progass_image, borderWidth: 2, borderRadius: 20, position: 'relative' }} className='d-flex justify-content-center align-items-center'
                                        onDragEnter={(e) => this.handleDragFileArea(e, 'progass_image')} onDragLeave={(e) => this.handleDragFileArea(e, 'progass_image')} onDragOver={(e) => this.handleDragFileArea(e, 'progass_image')} onDrop={(e) => {

                                            e.preventDefault();
                                            e.stopPropagation();
                                            if (e.dataTransfer.files && e.dataTransfer.files[0]) {
                                                this.addAttachment({ target: { files: e.dataTransfer.files, multiple: false, name: 'progass_image' } })
                                            }
                                        }}>
                                        {progass_image && (!progass_image.data.type.includes('pdf')) && (
                                            <img src={progass_image.url} alt={t('Preview')} style={{ height: '100%' }} />
                                        )}
                                        {progass_image == null &&
                                            <button onClick={() => this.progass_image_ref.current.click()} style={{ background: 'none', border: 0, flex: 1 }}>
                                                {/* <FontAwesomeIcon icon={faPlus} style={{ fontSize: 30, color: color18 }} /> */}
                                                <span style={{ fontSize: 20, fontWeight: 'bold', color: brand_colors[this.props.defaultTheme.theme_id].color18 }}>{t('Image')}</span>
                                            </button>
                                        }

                                        {progass_image && (
                                            <FontAwesomeIcon
                                                icon={faTrashCan}
                                                style={{ fontSize: 14, color: brand_colors[this.props.defaultTheme.theme_id].color11, position: 'absolute', bottom: 6, right: 6, cursor: 'pointer', backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, borderRadius: 100, padding: 3 }}
                                                onClick={() => this.deleteAttachment('progass_image')}
                                            />
                                        )}
                                    </div>
                                    <Form.Control className='d-none' ref={this.progass_image_ref} type='file' onChange={this.addAttachment} name='progass_image' />
                                </div>
                                <div>
                                    <Form.Group className="mb-3">
                                        <Form.Label>{t('Observation Type')}</Form.Label>
                                        <Select
                                            options={this.state.progassAssesslogTypes}
                                            value={{ value: progass.progass_assesslog_type, label: capitalizeFirstLetter(progass.progass_assesslog_type) }}
                                            menuPortalTarget={document.body}
                                            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                            onChange={(obj) => this.setStateProgass('progass_assesslog_type', obj.value)}
                                        />
                                    </Form.Group>
                                </div>
                            </Col>
                        </Row>
                    </Modal.Body>
                }
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => modalProgass.toggle()}>
                        Close
                    </Button>
                    <Button variant="primary" disabled={modalProgass.saving} onClick={modalProgass.save}>
                        Save Changes
                        {modalProgass.saving &&
                            <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />
                        }
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>);
    }
}


const mapStateToProps = (state) => ({
    authData: state.auth.authData,
    defaultLanguage: state.language.defaultLanguage,
    selectedClass: state.selectedClass.data,
    defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(Progass);
