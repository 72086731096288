import moment from 'moment';
import { Component } from 'react';
import { Container, Card, Table, Row, Col, Spinner, Image, Badge, Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCheckDouble, faCrown } from '@fortawesome/free-solid-svg-icons';
import { brand_colors } from '../../../helpers/brand_colors_helper';
import { trimThisDown } from '../../../helpers/general_helpers';

// Portal URL
const portalURL = process.env.REACT_APP_PORTAL_URL;

/**
 * Chat Contact Item Component
 */
class ChatContactItem extends Component {
    // States
    state = {};

    /**
     * Render
     * @returns {HTMLElement}
     */
    render() {
        const { full_name, role, role_id, crole, parentData, chat_datetime_modified, picture, center_id, newmessages, lastChatDetail } = this.props.data;

        // console.log('THISCHAT', this.props.data)
        let uniqueChildren = []
        if (parentData) {
            parentData.map((item, index) => {
                let findChild = uniqueChildren.findIndex((v, i) => v.child_id == item.child_id)
                if (findChild === -1) {
                    uniqueChildren.push(item)
                }
            })
        }

        return (
            <div className='cc-item'>
                {newmessages !== 0 && newmessages !== undefined && (
                    <small className='chat-count'>{newmessages}</small>
                )}
                <div className='cc-thumb'>
                    <div
                        style={{ backgroundImage: (picture !== '' && picture !== null) ? 'url(' + portalURL + this.props.authData.centerData.center_uuid + (role == 'Student' ? '/children/' : '/employees/') + picture + ')' : 'url(https://via.placeholder.com/80?text=No+Thumb)', width: 50, height: 50, backgroundPosition: 'center center', backgroundSize: 'cover', borderRadius: 25, }}></div>
                </div>
                <div style={{ flex: 1 }}>
                    <div className='d-flex'>
                        <div style={{ flex: 1 }}>
                            <strong className='me-2'>{full_name}</strong>
                            <Badge bg={[6, 20].includes(role_id) ? 'success' : 'info'} style={{ borderRadius: 10 }}>
                                {[6, 20].includes(role_id) && (
                                    <>
                                        <FontAwesomeIcon
                                            icon={faCrown}
                                            color={brand_colors[this.props.defaultTheme.theme_id].color8}
                                        />{' '}
                                    </>
                                )}
                                {crole || role}
                            </Badge>
                        </div>
                        {chat_datetime_modified && (
                            <div
                                className='text-muted'
                                style={{ fontSize: 12 }}>
                                {moment(chat_datetime_modified).fromNow()}
                            </div>
                        )}
                    </div>
                    {(lastChatDetail) &&
                        <div className='d-flex flex-row align-items-center' style={{ color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7, fontSize: 12 }}>
                            {(parseInt(lastChatDetail.recipient_id) != parseInt(this.props.authData.loginData.user_id)) && <FontAwesomeIcon className='mx-1' icon={(parseInt(lastChatDetail.chat_detail_read)) ? faCheckDouble : faCheck} style={{ fontSize: 12, color: (parseInt(lastChatDetail.chat_detail_read)) ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color18 }} />
                            }
                            {(parseInt(lastChatDetail.is_chat_detail_deleted))
                                ? <i>{'Message Deleted'}</i>
                                : (lastChatDetail.chat_detail_message) ? trimThisDown(lastChatDetail.chat_detail_message, 30) : 'Attachment'}
                        </div>
                    }
                    {parentData &&
                        uniqueChildren.map((item, index) => {
                            return <Badge
                                key={index}
                                className='me-1'
                                style={{ borderRadius: 10 }}>
                                {item.child_name}
                            </Badge>
                        })}
                </div>
            </div>
        );
    }
}

export default ChatContactItem;
