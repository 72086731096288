import React, { Component } from 'react';
import { Container, Card, Table, Row, Col, Spinner, Image, Badge, Button, Form, Offcanvas, Dropdown, } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faAnglesLeft, faAnglesRight, faCalendarAlt, faCheck, faCheckCircle, faCheckDouble, faClock, faEdit, faEllipsisV, faExternalLink, faFileAlt, faFilterCircleXmark, faGear, faPaperclip, faPlus, faSearch, faTimes, faTrashAlt, faTrashCan, faXmark, } from '@fortawesome/free-solid-svg-icons';
import { Scrollbar } from 'react-scrollbars-custom';
import imgDescription from '../../assets/images/description.png';
import { brand_colors } from '../../helpers/brand_colors_helper';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Link } from 'react-router-dom';
import AcademicBoard from './AcademicBoard';
import LearnArea from './LearnArea';
import LearnAspect from './LearnAspect';
import Curriculum from './Curriculum';
import Progass from './Progass';

// API URL
const apiURL = process.env.REACT_APP_API_URL;

// Portal URL
const portalURL = process.env.REACT_APP_PORTAL_URL;

/**
 * Class CurriculumBuilder Component
 */
class CurriculumBuilder extends Component {
    /**
     * Constructor
     */
    constructor(props) {
        super(props);
        this.state = {
            searchExpanded: false,
            progass: {
                progass_id: null,
                toggleBoard: (progass_id) => {
                    let newState = { progass: { ...this.state.progass, progass_id } }
                    if ((progass_id == this.state.progass.progass_id)) {
                        newState = { progass: { ...this.state.progass, progass_id: null }, learnArea: { ...this.state.learnArea, learnarea_id: null }, learnAspect: { ...this.state.learnAspect, lrnaspect_id: null } }
                    }
                    this.setState(newState)
                }
            },
            learnArea: {
                learnarea_id: null,
                toggle: (learnarea_id) => {
                    let newState = { learnArea: { ...this.state.learnArea, learnarea_id } }
                    if (learnarea_id == this.state.learnArea.learnarea_id) {
                        newState = { learnArea: { ...this.state.learnArea, learnarea_id: null }, learnAspect: { ...this.state.learnAspect, lrnaspect_id: null } }
                    }
                    this.setState(newState)
                }
            },
            learnAspect: {
                lrnaspect_id: null,
                agegroup_id: null,
                toggle: (lrnaspect_id, agegroup_id) => this.setState({ learnAspect: { ...this.state.learnAspect, lrnaspect_id: (lrnaspect_id == this.state.learnAspect.lrnaspect_id) ? null : lrnaspect_id, agegroup_id: (lrnaspect_id == this.state.learnAspect.lrnaspect_id) ? null : agegroup_id } })
            }

        }
    }

    /**
     * Component Did Mount
     */
    componentDidMount() {
    }

    /**
     * Render Component
     * @returns {HTMLElement}
     */
    render() {
        const { defaultTheme } = this.props;
        const { progass, learnArea, learnAspect } = this.state;

        // State

        return (
            <Container fluid>
                <div id='iedu-layout'>
                    <NavBar />
                    <div id='page-content' style={{ width: '100%' }}>
                        <Header lite={true} heading={t('Curriculum Builder')} backBtn={true} showClassSelector={false} showCenterSelector={true} />
                        <div className='grey-section'>

                            {/* ACADEMIC BOARD */}
                            <Progass progass={this.state.progass} />
                            <Row>
                                {/* LEARN AREA */}
                                <Col md="6">
                                    <LearnArea progass={progass} learnArea={learnArea} />
                                </Col>
                                {/* LEARN ASPECT */}
                                <Col md="6">
                                    <LearnAspect progass={progass} learnArea={learnArea} learnAspect={learnAspect} defaultTheme={defaultTheme} />
                                </Col>
                            </Row>

                            {/* CURRICULUM */}
                            <Row>
                                <Col md="12">
                                    <Curriculum progass={progass} learnArea={learnArea} learnAspect={learnAspect} defaultTheme={defaultTheme} />
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    authData: state.auth.authData,
    defaultLanguage: state.language.defaultLanguage,
    selectedClass: state.selectedClass.data,
    defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(CurriculumBuilder);
