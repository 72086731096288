import React, { Component } from 'react';
import { Container, Card, Table, Row, Col, Spinner, Image, Badge, Button, Form, Offcanvas, } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faArrowsLeftRight, faCalendarAlt, faCheck, faCheckDouble, faClock, faEdit, faExternalLink, faFileAlt, faGear, faPaperclip, faTimes, faTrashCan, } from '@fortawesome/free-solid-svg-icons';
import { Scrollbar } from 'react-scrollbars-custom';
import imgDescription from '../../assets/images/description.png';
import { brand_colors } from '../../helpers/brand_colors_helper';
import SweetAlert from 'react-bootstrap-sweetalert';
import { GET } from '../../api';
import { faMessage } from '@fortawesome/free-regular-svg-icons';

// API URL
const apiURL = process.env.REACT_APP_API_URL;

// Portal URL
const portalURL = process.env.REACT_APP_PORTAL_URL;

class ChatMonitor extends Component {
    /**
     * Constructor
     */
    constructor(props) {
        super(props);
        this.state = {
            chatListData: {
                search: '',
                order: '',
                dir: '',
                limit: 10,
                offset: 0,
                data: [],
                total_count: 0,
                total_count_filtered: 0,
                refreshing: false,
            },
            chatDetails: {
                data: [],
                offset: 0,
                limit: 10,
                refreshing: false,
                finished: false,
                total_count: 0,
                total_count_filtered: 0,
            },
            selectedChat: null,
            deleteModal: { show: false, id: 0, action: () => { }, title: null, desc: null },
        }
        this.chatEnd = React.createRef();
        this.cb_image_ref = React.createRef();
    }

    /**
     * Component Did Mount
     */
    componentDidMount() {
        this.getListChatBlogs();
    }

    componentWillUnmount() {
        if (this.getListChatBlogsReq) {
            this.getListChatBlogsReq.abort();
        }
        if (this.getChatDetailsReq) {
            this.getChatDetailsReq.abort();
        }
    }


    /**
     * Get List Chat Blogs
     */
    getListChatBlogs = async () => {
        if (this.getListChatBlogsReq) {
            this.getListChatBlogsReq.abort();
        }
        this.getListChatBlogsReq = new AbortController()
        const { chatListData } = this.state
        const res = await GET(
            'chat-monitor/get',
            {
                params: {
                    search: chatListData.search,
                    order: chatListData.order,
                    dir: chatListData.dir,
                    limit: chatListData.limit,
                    offset: chatListData.offset,
                },
                signal: this.getListChatBlogsReq.signal
            }
        );
        if (res) {
            let resData = res.data
            if (resData.data.length == 0) {
                this.setState({
                    chatListData: { ...chatListData, data: chatListData.data.concat(resData.data), total_count: resData.total_count, total_count_filtered: resData.total_count_filtered, offset: chatListData.offset + chatListData.limit }
                }, () => {
                    if (this.state.chatListData.offset <= this.state.chatListData.total_count_filtered) {
                        this.getListChatBlogs()
                    }
                })
            } else {
                this.setState({
                    chatListData: { ...chatListData, data: chatListData.data.concat(resData.data), total_count: resData.total_count, total_count_filtered: resData.total_count_filtered, offset: chatListData.offset + chatListData.limit }
                });
            }

        }

    };

    /**
     * Render Chat Blog List
     * @returns {HTMLElement}
     */
    renderChatBlogList = () => {
        const { defaultTheme } = this.props;
        const { chatListData } = this.state;
        return chatListData.data.map((item, index) => {
            let getUserData_1 = item.getUserData_1
            let getUserData_2 = item.getUserData_2
            // hasImage = portalURL + this.props.authData.centerData.center_uuid + '/chat_blog/' + item.cb_image
            return (
                <div key={index} className='d-flex flex-column chat-blog-item' style={{ margin: 10 }} onClick={() => this.handleSelectedChat(item)}>
                    <div className='d-flex flex-row align-items-start justify-content-between'>
                        <div className='d-flex flex-column justify-content-between' style={{ flex: 1 }}>
                            <div style={{ opacity: 0.8, fontSize: 14, fontWeight: 600 }}>{getUserData_1.name}</div>
                            <Badge bg="info">{getUserData_1.crole || getUserData_1.role}</Badge>
                        </div>
                        <div className='mx-2'>
                            <FontAwesomeIcon icon={faArrowsLeftRight} />
                        </div>
                        <div className='d-flex flex-column justify-content-between' style={{ flex: 1 }}>
                            <div style={{ opacity: 0.8, fontSize: 14, fontWeight: 600 }}>{getUserData_2.name}</div>
                            <Badge bg="warning">{getUserData_2.crole || getUserData_2.role}</Badge>
                        </div>
                    </div>
                    <div className='d-flex flex-row justify-content-between align-items-center'>
                        <div className='mt-2' style={{ opacity: 0.8, fontSize: 12, fontWeight: 600 }} title={t('Total Messages')}>
                            <FontAwesomeIcon icon={faMessage} className='me-2' />
                            {item.total_messages}
                        </div>
                        <div className='mt-2' style={{ opacity: 0.8, fontSize: 12, fontWeight: 600 }} title={t('Last Message')}>
                            <FontAwesomeIcon icon={faClock} className='me-2' />
                            {moment(item.chat_datetime_modified).format('DD-MM-YYYY - h:mm a')}
                        </div>
                    </div>
                </div>
            );
        });
    };

    /**
     * Handle Selected Blog
     * @param {Object} selectedChat
     */
    handleSelectedChat = (selectedChat) => {
        this.setState({ selectedChat, chatDetails: { ...this.state.chatDetails, data: [], offset: -1, refreshing: false, finished: false } }, () => {
            this.getChatDetails(selectedChat.chat_id)
        });
    };

    /**
     * Get Chat Blog Details
     * @param {Number} chat_id
     */
    getChatDetails = async (chat_id = this.state.selectedChat.chat_id) => {
        let chatDetails = this.state.chatDetails

        let offset = (chatDetails.data.length > 0) ? chatDetails.data[0].chat_detail_id : 10000000000
        console.log('offset', offset);
        if (chatDetails.refreshing || chatDetails.finished) {
            return false
        }

        this.setState({ chatDetails: { ...chatDetails, refreshing: true } }, async () => {
            if (this.getChatDetailsReq) {
                this.getChatDetailsReq.abort();
            }
            this.getChatDetailsReq = new AbortController();

            const res = await GET(
                'chat-monitor/get-more-messages',
                {
                    params: {
                        chat_id: chat_id,
                        limit: chatDetails.limit,
                        chat_detail_id: offset
                    },
                    signal: this.getChatDetailsReq.signal,
                }
            );
            if (res) {
                let resData = res.data
                this.setState({ chatDetails: { ...chatDetails, data: ((resData).reverse()).concat(chatDetails.data), offset: offset, refreshing: false, finished: (chatDetails.offset == offset) ? true : false }, }, () => {
                    if (chatDetails.offset == 0) {
                        this.chatEnd.current.scrollToBottom();
                    } else {
                        // console.log('this.chatEnd.current.scrollHeight', this.chatEnd.current.scrollHeight);
                        this.chatEnd.current.scrollTo(0, 1200);
                    }
                });
            }

        })
    };


    /**
     * Render Chat Message
     * @returns {HTMLElement}
     */
    renderChatMessage = () => {
        const { defaultTheme } = this.props;
        const { chatDetails, selectedChat } = this.state;


        if (selectedChat == null) {
            return null
        }
        return <div>
            <div className='d-flex flex-row justify-content-center align-items-center'>  {chatDetails.refreshing &&
                <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />
            }
                {chatDetails.finished &&
                    <div
                        className='chat-message-message'
                        style={{
                            borderRadius: 15,
                            backgroundColor: brand_colors[defaultTheme.theme_id].color19,
                        }}>
                        {t('Start of the Conversation')}
                    </div>
                }
            </div>
            {chatDetails.data.map((item, index) => {
                let isOther = false
                let display_name = selectedChat.getUserData_1.name
                let chat_detail_read = item.chat_detail_read;
                if (selectedChat.getUserData_1.user_id == item.recipient_id) {
                    isOther = true
                    display_name = selectedChat.getUserData_2.name
                }

                return (
                    <div
                        key={index}
                        className='chat-message'
                        style={{
                            textAlign: isOther ? 'left' : 'right',
                            padding: '0 10px'
                        }}>
                        <div className='chat-message-name text-muted'>
                            {display_name}
                        </div>
                        <div
                            className='chat-message-message'
                            style={{
                                borderTopLeftRadius: isOther ? 0 : 15,
                                borderTopRightRadius: isOther ? 15 : 0,
                                backgroundColor: isOther
                                    ? ''
                                    : 'rgb(226 243 228)',
                            }}>
                            {item.chat_detail_message}
                            {item.chat_detail_attachment
                                && (
                                    <div>
                                        <a
                                            href={
                                                portalURL + this.props.authData.centerData.center_uuid +
                                                '/chat_details/' +
                                                item.chat_detail_attachment

                                            }
                                            style={{
                                                textDecoration: 'none',
                                                color: '#6c757d',
                                                fontSize: 13,
                                            }}
                                            target='_blank'>
                                            <FontAwesomeIcon icon={faPaperclip} />{' '}
                                            {item.chat_detail_attachment
                                            }
                                        </a>
                                    </div>
                                )}
                        </div>
                        <div
                            className='chat-message-timestamp'
                            style={{
                                paddingLeft: isOther ? 10 : 0,
                                paddingRight: isOther ? 0 : 10,
                            }}>
                            {isOther && (chat_detail_read == 0 ? <FontAwesomeIcon icon={faCheck} className='me-1' /> : <FontAwesomeIcon icon={faCheckDouble} color='#60C9DA' className='me-1' />)}
                            <span title={moment(item.chat_detail_datetime).format('DD-MM-YYYY - h:mm a')}>{moment(item.chat_detail_datetime).format('DD-MM-YYYY - h:mm a')}</span>
                        </div>
                    </div>
                );
            })}
        </div>
    };

    resetList = () => {
        let chatListData = this.state.chatListData
        this.setState({ search: this.setState({ chatListData: { ...chatListData, data: [], search: '', limit: 10, offset: 0 } }, () => this.getListChatBlogs()) })
    }

    /**
     * Render Component
     * @returns {HTMLElement}
     */
    render() {
        console.log('this.state', this.state);
        const { defaultTheme } = this.props;
        // State
        const {
            selectedChat,
            msgSending,
            message,
            preferencesModal,
            chatListData
        } = this.state;
        const dir = this.props.defaultLanguage.lang_orientation

        let favColor = null
        if (selectedChat) {
            if (selectedChat.favorite == 1) {
                favColor = brand_colors[defaultTheme.theme_id].color1
            }
            if (selectedChat.favorite == "2") {
                favColor = brand_colors[defaultTheme.theme_id].color13
            }
            if (selectedChat.favorite == "3") {
                favColor = brand_colors[defaultTheme.theme_id].color16
            }
            if (selectedChat.favorite == "4") {
                favColor = brand_colors[defaultTheme.theme_id].color11
            }
        }

        let selectedChatHasImage = null
        if (selectedChat) {
            if (selectedChat.cb_image !== '' && selectedChat.cb_image !== null) {
                selectedChatHasImage = portalURL + selectedChat.center_id + '/chat_blog/' + selectedChat.cb_image
            } else if (selectedChat.asl_attach !== '' && selectedChat.asl_attach !== null) {
                selectedChatHasImage = portalURL + selectedChat.center_id + '/academic_subject_lessons/' + selectedChat.asl_attach
            } else if (selectedChat.acs_image !== '' && selectedChat.acs_image !== null) {
                selectedChatHasImage = portalURL + selectedChat.center_id + '/academic_classmain_subject/' + selectedChat.acs_image
            } else {
                selectedChatHasImage = 'https://via.placeholder.com/80?text=No+Thumb'
            }
        }

        return (
            <Container fluid>
                <div id='iedu-layout'>
                    <NavBar />
                    <div id='page-content'>
                        <Header lite={true} heading={t('Chat Monitor')} backBtn={true} showCenterSelector={true} centerSelectorFunc={[this.resetList]} />
                        <div className='grey-section'>
                            <Row>
                                <Col md={3}>
                                    <div className='d-flex flex-row' style={{
                                        padding: 5, marginBottom: 10, backgroundColor: brand_colors[defaultTheme.theme_id].color8, borderRadius: 5,
                                    }}>
                                        <Form.Control
                                            type='text'
                                            placeholder={t('Search...')}
                                            onChange={(e) => this.setState({ search: this.setState({ chatListData: { ...chatListData, data: [], search: e.target.value, limit: 10, offset: 0 } }, () => this.getListChatBlogs()) })}
                                            style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                        />
                                    </div>
                                    <Scrollbar style={{ height: '65vh', backgroundColor: brand_colors[defaultTheme.theme_id].color8 }} className='border-0 chat-blog-list'>
                                        {chatListData.refreshing && (
                                            <div
                                                className='d-flex align-items-center justify-content-center'
                                                style={{
                                                    flexDirection: 'column',
                                                }}>
                                                <div className='mb-1'>
                                                    <Spinner
                                                        animation='grow'
                                                        variant='dark'
                                                    />
                                                </div>
                                                {t(
                                                    'Fetching Chats list...'
                                                )}
                                            </div>
                                        )}
                                        {!chatListData.refreshing && chatListData.data.length > 0 ? (
                                            this.renderChatBlogList()
                                        ) : (
                                            <div
                                                className='d-flex align-items-center justify-content-center'
                                                style={{
                                                    flexDirection:
                                                        'column',
                                                    height: 400,
                                                }}>
                                                {t(
                                                    'No Chats list found!'
                                                )}
                                            </div>
                                        )}

                                        <div className='d-flex flex-row align-items-center justify-content-center cursor-pointer p-2 m-2' style={{ flexDirection: 'column', fontWeight: 600 }} onClick={() => this.getListChatBlogs()}>
                                            {chatListData.refreshing && <Spinner animation='grow' variant='dark' />}
                                            {/* {((chatListData.search && parseInt(chatListData.total_count_filtered) != chatListData.data.length) || (!chatListData.search && parseInt(chatListData.total_count) != chatListData.data.length))
                                                ? t('Load More')
                                                : t('No More Data')
                                            } */}
                                            {(parseInt(chatListData.offset) < parseInt(chatListData.total_count_filtered))
                                                ? t('Load More')
                                                : t('No More Data')
                                            }
                                        </div>

                                    </Scrollbar>
                                </Col>
                                <Col md={9}>
                                    {selectedChat &&
                                        <Card
                                            className='border-0 card-blog-details'
                                            style={{
                                                borderTopRightRadius: 20,
                                                borderBottomRightRadius: 20,
                                                height: '73vh',
                                            }}>
                                            <Card.Header>
                                                <div className='pt-2'>
                                                    <div className='d-flex pb-1 justify-content-between'>
                                                        <div className='d-flex flex-row align-items-center justify-content-between'>
                                                            <div className='d-flex'>
                                                                <div style={{ opacity: 0.8, fontSize: 14, fontWeight: 600, marginRight: 5 }}>{selectedChat.getUserData_1.name}</div>
                                                                <Badge bg="info">{selectedChat.getUserData_1.crole || selectedChat.getUserData_1.role}</Badge>
                                                            </div>
                                                            <div className='mx-2' style={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                flex: 1
                                                            }}>
                                                                <FontAwesomeIcon icon={faArrowsLeftRight} />
                                                            </div>
                                                            <div className='d-flex'>
                                                                <div style={{ opacity: 0.8, fontSize: 14, fontWeight: 600, marginRight: 5 }}>{selectedChat.getUserData_2.name}</div>
                                                                <Badge bg="warning">{selectedChat.getUserData_2.crole || selectedChat.getUserData_2.role}</Badge>
                                                            </div>
                                                        </div>
                                                        <div className='d-flex flex-row justify-content-between'>
                                                            <div className='mx-2' style={{ opacity: 0.8, fontSize: 12, fontWeight: 600 }} title={t('Total Messages')}>
                                                                <FontAwesomeIcon icon={faMessage} className='me-2' />
                                                                {selectedChat.total_messages}
                                                            </div>
                                                            <div className='mx-2' style={{ opacity: 0.8, fontSize: 12, fontWeight: 600 }} title={t('Last Message')}>
                                                                <FontAwesomeIcon icon={faClock} className='me-2' />
                                                                {moment(selectedChat.chat_datetime_modified).format('DD-MM-YYYY - h:mm a')}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card.Header>
                                            <Card.Body>
                                                <Scrollbar ref={this.chatEnd} className='chat-blog-details' onScroll={(scrollValues, prevScrollValues) => {
                                                    if (this.chatEnd.current.scrollTop < 20) {
                                                        this.getChatDetails()
                                                    }
                                                }}>
                                                    {this.renderChatMessage()}
                                                </Scrollbar>
                                            </Card.Body>
                                        </Card>
                                    }
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
                <SweetAlert
                    show={this.state.deleteModal.show}
                    warning
                    showCancel
                    confirmBtnText={t('Yes, delete it!')}
                    confirmBtnBsStyle='danger'
                    title={(this.state.deleteModal.title) ? this.state.deleteModal.title : t('Are you sure?')}
                    onConfirm={this.state.deleteModal.action}
                    onCancel={() => this.setState({ deleteModal: { ...this.state.deleteModal, show: false, id: 0, action: () => { } } })}
                    focusCancelBtn>
                    {(this.state.deleteModal.desc) ? this.state.deleteModal.desc : t('Confirm Delete!')}
                </SweetAlert>
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    authData: state.auth.authData,
    defaultLanguage: state.language.defaultLanguage,
    selectedClass: state.selectedClass.data,
    children: state.children.list,
    defaultTheme: state.theme.defaultTheme,
    selectedSubject: state.selectedClass.subject,
});

const mapDispatchToProps = () => ({
});
export default connect(mapStateToProps, mapDispatchToProps())(ChatMonitor);
