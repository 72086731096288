import React, { Component } from 'react';
import { Container, Card, Row, Col, Form, Button, ButtonGroup, Spinner, Modal, Offcanvas } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngry, faArrowRight, faAudioDescription, faBus, faCake, faCakeCandles, faCamera, faChevronDown, faChevronUp, faClipboardUser, faClock, faExclamationTriangle, faFileCircleCheck, faGift, faInfo, faInfoCircle, faMedal, faPerson, faPersonHalfDress, faPlus, faSearch, faStarHalfStroke, faThumbsUp, faTrophy, faUserShield, faXmark, faCircleCheck, faEllipsis, faAngleRight, faAngleLeft, faCheckCircle, faImage, faCircle } from '@fortawesome/free-solid-svg-icons';
import { brand_colors } from '../../helpers/brand_colors_helper';
import { Tooltip as Tooltiper } from 'react-tooltip';
import { NavLink } from 'react-router-dom';
import { getStudents, setStudents } from '../../redux/slices/studentListSlice';
import totalPointsIcon from '../../assets/images/student-list/total-points.png'
import axios from 'axios';
import { toast } from 'react-toastify';
import { isTeacher } from '../../helpers/general_helpers';
import { GET } from '../../api';
import ReactPaginate from 'react-paginate';
import Pagination from '../_partials/Pagination';

const portalURL = process.env.REACT_APP_PORTAL_URL;
const apiURL = process.env.REACT_APP_API_URL;

const awardBucketURL = 'https://objectstorage.me-dubai-1.oraclecloud.com/n/axz68zvsutdv/b/Educore-ObjectStorage/o/private/award'

class StudentPastoralCare extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchExpanded: false,
            working: false,
            showModal: false,
            student: null,
            child_name: '',
            totalPoints: 0,
            award_type_text: null,
            awards: [],
            workingIndex: [],
            cdt: {
                rows: [10, 25, 50, 'All'],
            },
            // Form
            attComment: '',
            award_type: '',
            award_id: '',
            antecedent: '',
            consequence: '',
            time_in: '',
            location: '',
            time_out: '',
            points: '',
            behaviour: '',
            award_img: null,
            award_folder: '',
            showAllClasses: [1, 2, 8, 12, 22].includes(props.authData.loginData.role_id) ? true : false,
            awardsImagesModal: {
                show: false,
                imagesList: [],
                open: () => this.getAwardsImages(),
                close: () => this.setState({
                    awardsImagesModal: {
                        ...this.state.awardsImagesModal,
                        show: false,
                        imagesList: []
                    }
                })
            }
        }
        this.searchFieldRef = React.createRef();
    }

    componentDidMount() {
        this.getStudents({
            rows: this.props.studentListRows,
            page: 0,
            search: '',
            status: this.props.studentListStatus,
        });
        this.getAwards();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.selectedClass && prevProps.selectedClass.class.class_id !== this.props.selectedClass.class.class_id) {
            this.getStudents({
                rows: this.props.studentListRows,
                page: 0,
                search: '',
                status: this.props.studentListStatus
            });
            this.setState({ searchExpanded: false });
            this.searchFieldRef.current.value = '';
        }
    }

    getStudents = (params) => {
        this.props.getStudents({
            child_status: params.status,
            search: params.search,
            limit: params.rows,
            page: params.page,
            pastoral_care: true,
            showAllClasses: this.state.showAllClasses,
        });
    }

    getAwards = async () => {

        const { authData } = this.props;
        const { center_id } = authData.centerData;
        const { auth_key } = authData.loginData;

        try {
            const res = await GET('children/get-awards');

            if (res.status === 200) {
                this.setState({
                    awards: res.data
                });
            }

        } catch (err) {
            console.log('err', err)

        }
    }

    getAwardsImages = async () => {

        const { award_type_text } = this.state;
        let folder;

        if (award_type_text == 'Behavior' || award_type_text == 'Warning') {
            folder = 'warning';
        }

        if (award_type_text == 'Awards') {
            folder = 'award';
        }

        if (award_type_text == 'Trophy') {
            folder = 'trophy';
        }

        if (award_type_text == 'Redeem') {
            folder = 'redeem';
        }

        try {
            const res = await GET('children/get-awards-images', {
                params: { folder }
            });

            if (res.status === 200) {
                this.setState({
                    awardsImagesModal: {
                        ...this.state.awardsImagesModal,
                        show: true,
                        imagesList: res.data
                    }
                });
            }

        } catch (err) {
            console.log('err', err)

        }
    }

    renderTrophyDDL = () => {
        let award_type = null;
        const { award_type_text, awards } = this.state;

        if (award_type_text == 'Behavior' || award_type_text == 'Warning') {
            award_type = 3;
        }

        if (award_type_text == 'Awards') {
            award_type = 1;
        }

        if (award_type_text == 'Trophy') {
            award_type = 2;
        }

        if (award_type_text == 'Redeem') {
            award_type = 4;
        }

        const filtered_awards = awards.filter((award) => award.award_type == award_type);

        return filtered_awards;
    }

    saveStdAward = async () => {

        const { authData, studentListData: students, selectedClass } = this.props;
        const { auth_key, user_id } = authData.loginData;
        const { employee_id } = authData.userData;
        const { center_id, center_timezone } = authData.centerData;
        const { student, award_type_text, award_type, award_id, antecedent, consequence, time_in, location, time_out, points, behaviour, award_img } = this.state;

        const request_params = {
            auth_key: auth_key,
            appname: process.env.REACT_APP_NAME,
        };

        const fData = new FormData();
        fData.append('center_id', center_id);
        fData.append('center_timezone', center_timezone);
        fData.append('employee_id', employee_id);
        fData.append('user_id', user_id);
        fData.append('award_type_text', award_type_text);
        fData.append('award_type', award_type);
        fData.append('std_behaviour_pic', award_img);
        fData.append('award_id', award_id);
        fData.append('class_id', selectedClass.class.class_id);
        fData.append('parent_id', student.parent_id);
        fData.append('child_id', student.child_id);
        fData.append('points', points);
        fData.append('behaviour', behaviour);
        fData.append('antecedent', antecedent);
        fData.append('consequence', consequence);
        fData.append('location', location);
        fData.append('time_in', time_in);
        fData.append('time_out', time_out);


        try {
            const res = await axios.post(apiURL + '/children/save-std-award', fData, {
                params: request_params
            });


            if (res.data.type) {
                toast.success(t(res.data.message));
                let updatedStudents = [...students];
                const findIndex = students.findIndex((studentItem) => studentItem.child_id == student.child_id);

                let newV;
                newV = { ...student, totalPoints: res.data.totalPoints, timestamp: new Date().getTime() }

                updatedStudents[findIndex] = newV;

                this.props.setStudents(updatedStudents);
            } else {
                toast.error(t(res.data.message));
            }
            this.handleCloseModal();
        } catch (err) {
            console.log('err', err)
            this.handleCloseModal();
        }

    }

    setTrophy = (e) => {
        var points = e.target.options[e.target.selectedIndex].dataset.points;
        var type = e.target.options[e.target.selectedIndex].dataset.type;
        this.setState({ points, award_type: type, award_id: e.target.value });
    }

    handleOpenModal = (award_type_text, student) => {
        this.setState({
            showModal: true,
            award_type_text,
            student
        });
    }

    handleCloseModal = () => {
        this.setState({
            working: false,
            student: null,
            showModal: false,
            attComment: '',
            award_type_text: null,
            award_type: '',
            award_id: '',
            antecedent: '',
            consequence: '',
            time_in: '',
            location: '',
            time_out: '',
            points: '',
            behaviour: '',
            award_img: null,
        });
    }

    render() {
        let { showAllClasses } = this.state;

        console.log('%cSTATE', 'color: orange; font-weight: bold; text-transform: uppercase; font-size: 25px', this.state);

        return (
            <Card
                className='border-0'
                style={{ borderRadius: '20px' }}>
                <Card.Body>
                    <Row>
                        <Col md={6} className='d-flex flex-column'>
                            <div className='d-flex flex-row align-items-center mb-2'>
                                <strong>{t("Pastoral Care")}</strong>
                                <div className='expandable-search ms-2' style={{ width: this.state.searchExpanded ? 200 : 35 }}>
                                    <input
                                        ref={this.searchFieldRef}
                                        defaultValue={this.props.studentListSearch}
                                        onChange={(e) => this.getStudents({
                                            rows: this.props.studentListRows,
                                            page: 0,
                                            search: e.target.value,
                                            status: this.props.studentListStatus
                                        })}
                                        type='text'
                                        style={{ width: this.state.searchExpanded ? 165 : 0, paddingLeft: this.state.searchExpanded ? 15 : 0, opacity: this.state.searchExpanded ? 1 : 0 }}
                                        placeholder={t('Search Student...')} />
                                    <button
                                        data-tooltip-id="tooltip"
                                        data-tooltip-content={t('Search Student')}
                                        data-tooltip-place="top"
                                        onClick={() => {
                                            this.setState({ searchExpanded: !this.state.searchExpanded });
                                            this.props.studentListSearch != '' && this.state.searchExpanded && this.getStudents({
                                                rows: this.props.studentListRows,
                                                page: 0,
                                                search: '',
                                                status: this.props.studentListStatus
                                            });
                                            this.searchFieldRef.current.value = '';
                                            !this.state.searchExpanded && this.searchFieldRef.current.focus();
                                        }}>
                                        <FontAwesomeIcon
                                            icon={this.state.searchExpanded ? faXmark : faSearch}
                                            color={brand_colors[this.props.defaultTheme.theme_id].color18}
                                        />
                                    </button>
                                </div>
                            </div>
                            <div className='d-flex flex-row align-items-center'>
                                <div>
                                    {t('Show')}
                                    <Form.Select
                                        value={this.props.studentListRows}
                                        size="sm"
                                        onChange={(e) => this.getStudents({
                                            rows: e.target.value,
                                            page: 0,
                                            search: this.props.studentListSearch,
                                            status: this.props.studentListStatus
                                        })}
                                        style={{ width: 70, marginInline: 5, display: 'inline-block' }}>
                                        {this.state.cdt.rows.map((row, row_index) => (<option key={row_index} >{row}</option>))}
                                    </Form.Select>
                                    {t('Records')}
                                </div>
                            </div>
                        </Col>
                        <Col md={6} className='d-flex justify-content-end align-items-start'>
                            {[1, 2, 8, 12, 22].includes(this.props.authData.loginData.role_id) &&
                                <Button onClick={() => this.setState({ showAllClasses: !showAllClasses }, () => {
                                    this.getStudents({
                                        rows: this.props.studentListRows,
                                        status: this.props.studentListStatus,
                                        order: this.props.studentListOrder,
                                        dir: this.props.studentListDir,
                                        page: 0,
                                        search: this.props.studentListSearch
                                    })
                                })} variant={(showAllClasses) ? "success" : "outline-success"} size='sm' className='me-3' title={(showAllClasses) ? t('Show Selected Class only') : t('Show All Classes')}>
                                    {showAllClasses && <FontAwesomeIcon icon={faCheckCircle} color={brand_colors[this.props.defaultTheme.theme_id].color8} className='me-1' />}
                                    {(showAllClasses) ? t('Showing All Classes') : t('Showing Selected Class')}
                                </Button>
                            }
                            <ButtonGroup size='sm'>
                                <Button
                                    onClick={() => this.getStudents({
                                        rows: this.props.studentListRows,
                                        status: true,
                                        page: 0,
                                        search: this.props.studentListSearch
                                    })}
                                    variant={this.props.studentListStatus ? 'success' : 'light'}
                                >{t('Only Active')}</Button>
                                <Button
                                    onClick={() => this.getStudents({
                                        rows: this.props.studentListRows,
                                        status: false,
                                        page: 0,
                                        search: this.props.studentListSearch
                                    })}
                                    variant={this.props.studentListStatus ? 'light' : 'success'}
                                >{t('Show All')}</Button>
                            </ButtonGroup>
                        </Col>
                        {this.props.studentListFetching &&
                            <Col md={12} className='py-5 d-flex flex-column align-items-center'>
                                <Spinner as='span' animation='grow' size='sm' /> {t('Loading Students')}
                            </Col>
                        }
                        {!this.props.studentListFetching &&
                            <Col md={12} className='pt-1 student-attendance-list'>
                                {this.props.studentListData.map((student, student_index) => (
                                    <Row key={student_index}
                                        style={{ opacity: this.state.workingIndex.includes(student_index) ? 0.5 : 1 }}
                                        className='student-attendance-list-item'>
                                        <Col md={3} className='d-flex align-items-center'>
                                            <div style={{
                                                backgroundImage: `url(${(student.picture) ? portalURL + this.props.authData.centerData.center_uuid + '/children/' + student.picture : 'https://via.placeholder.com/150x150?text=' + student.child_name[0].toUpperCase()})`,
                                                width: 60,
                                                height: 60,
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center center',
                                                borderRadius: 30,
                                                marginRight: 15
                                            }}></div>
                                            {student.child_name}
                                            {this.state.workingIndex.includes(student_index) &&
                                                <Spinner animation="border" size='sm' variant="dark" className='ms-3' />
                                            }
                                        </Col>
                                        <Col md={9} className='d-flex'>
                                            <div className='d-flex align-items-center justify-content-center' style={{ padding: 10 }}>
                                                <button className='btn btn-success me-3 py-1' title="Trophy" style={{ borderRadius: 25 }}
                                                    onClick={() => this.handleOpenModal('Trophy', student)}>
                                                    <FontAwesomeIcon icon={faTrophy} color={brand_colors[this.props.defaultTheme.theme_id].color8} style={{ fontSize: 15 }} /> {' ' + t('Trophy')}
                                                </button>
                                                <button className='btn btn-primary me-3 py-1' title="Award" style={{ borderRadius: 25 }}
                                                    onClick={() => this.handleOpenModal('Awards', student)}>
                                                    <FontAwesomeIcon icon={faMedal} color={brand_colors[this.props.defaultTheme.theme_id].color8} style={{ fontSize: 15 }} /> {' ' + t('Awards')}
                                                </button>
                                                <button className='btn btn-danger me-3 py-1' title="Warning" style={{ borderRadius: 25 }}
                                                    onClick={() => this.handleOpenModal('Warning', student)}>
                                                    <FontAwesomeIcon icon={faExclamationTriangle} color={brand_colors[this.props.defaultTheme.theme_id].color8} style={{ fontSize: 15 }} />
                                                    {' ' + t('Warning')}
                                                </button>
                                                <button className='btn btn-warning me-3 py-1' title="Behavior" style={{ borderRadius: 25, color: brand_colors[this.props.defaultTheme.theme_id].color8 }} onClick={() => this.handleOpenModal('Behavior', student)}>
                                                    <FontAwesomeIcon icon={faAngry} color={brand_colors[this.props.defaultTheme.theme_id].color8} style={{ fontSize: 15 }} />
                                                    {' ' + t('Behavior')}
                                                </button>
                                                <button className='btn btn-dark me-3 py-1' title="Redeem" style={{ borderRadius: 25 }}
                                                    onClick={() => this.handleOpenModal('Redeem', student)}>
                                                    <FontAwesomeIcon icon={faGift} color={brand_colors[this.props.defaultTheme.theme_id].color8} style={{ fontSize: 15 }} />
                                                    {' ' + t('Redeem')}
                                                </button>
                                            </div>
                                            <div className='d-flex align-items-center' style={{ padding: 10, flex: 1 }}>
                                                <div className='d-flex' style={{ fontSize: 14, opacity: 0.8, color: '#86b3cb', backgroundColor: '#eef9ff', padding: 10, borderRadius: 10, gap: '10px' }}>
                                                    <div>
                                                        <img src={totalPointsIcon} alt='' width={38} />
                                                    </div>
                                                    <div>
                                                        {t('TOTAL')}<br />
                                                        {t('POINTS')}
                                                    </div>
                                                    <div className='d-flex align-items-center justify-content-center' style={{ width: 38, height: 38, borderRadius: 20, backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, fontSize: 16, fontWeight: 'bold', color: brand_colors[this.props.defaultTheme.theme_id].color1 }}>
                                                        {student.totalPoints}
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                ))}
                            </Col>
                        }
                    </Row>
                    <Pagination
                        pageCount={this.props.studentListLastPage}
                        forcePage={this.props.studentListPage}
                        callbackParams={{
                            rows: this.props.studentListRows,
                            status: this.props.studentListStatus,
                            search: this.props.studentListSearch
                        }}
                        callback={this.getStudents}
                    />
                    <Modal show={this.state.showModal} onHide={this.handleCloseModal} size={'lg'} backdrop="static"
                        keyboard={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>{t(this.state.award_type_text)} ({this.state.student && this.state.student.child_name})</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col md={9}>
                                    <Row>
                                        <Col md={2} className='mb-3'>
                                            <Form.Label>{t('Points:')}</Form.Label>
                                            <div><strong>{this.state.student && this.state.student.totalPoints}</strong></div>
                                        </Col>
                                        <Col md={5} className='mb-3'>
                                            <Form.Label>{t(this.state.award_type_text)}</Form.Label>
                                            <Form.Select defaultValue='Select' onChange={(e) => this.setTrophy(e)}>
                                                <option disabled selected>{t('Select')}</option>
                                                {this.renderTrophyDDL().map((trophy, index) => (
                                                    <option
                                                        key={index}
                                                        value={trophy.award_id}
                                                        data-points={trophy.award_points}
                                                        data-type={trophy.award_type}>
                                                        {trophy.award_name}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </Col>
                                        <Col md={5} className='mb-3'>
                                            <Form.Label>{t('Points')}</Form.Label>
                                            <Form.Control type="number" min={0} value={this.state.points} onChange={(e) => this.setState({ points: e.target.value })} />
                                        </Col>
                                        <Col md={12} className='mb-3'>
                                            <Form.Label>{t('Comment')}</Form.Label>
                                            <Form.Control type="text" default={this.state.behaviour} onBlur={(e) => this.setState({ behaviour: e.target.value })} />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={3}>
                                    Award Image
                                    <div className='award-image' onClick={this.state.awardsImagesModal.open}>
                                        {this.state.award_img ?
                                            <img
                                                src={`${awardBucketURL}/${this.state.award_folder}/${this.state.award_img}`}
                                                alt={this.state.award_img}
                                                style={{ width: 'auto', height: '80%', cursor: 'pointer' }}
                                            />
                                            :
                                            <FontAwesomeIcon icon={faImage} color={'#f2f2f2'} style={{ fontSize: 80 }} />
                                        }
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                {this.state.award_type_text == 'Behavior' &&
                                    <>
                                        <Col md={4} className='mb-3'>
                                            <Form.Label>{t('Antecedent')}</Form.Label>
                                            <Form.Control type="text" default={this.state.antecedent} onBlur={(e) => this.setState({ antecedent: e.target.value })} />
                                        </Col>
                                        <Col md={4} className='mb-3'>
                                            <Form.Label>{t('Consequence')}</Form.Label>
                                            <Form.Control type="text" default={this.state.consequence} onBlur={(e) => this.setState({ consequence: e.target.value })} />
                                        </Col>
                                        <Col md={4} className='mb-3'>
                                            <Form.Label>{t('Location')}</Form.Label>
                                            <Form.Control type="text" default={this.state.location} onBlur={(e) => this.setState({ location: e.target.value })} />
                                        </Col>
                                        <Col md={6} className='mb-3'>
                                            <Form.Label>{t('Time-In')}</Form.Label>
                                            <Form.Control type="time" default={this.state.time_in} onBlur={(e) => this.setState({ time_in: e.target.value })} />
                                        </Col>
                                        <Col md={6} className='mb-3'>
                                            <Form.Label>{t('Time-Out')}</Form.Label>
                                            <Form.Control type="time" default={this.state.time_out} onBlur={(e) => this.setState({ time_out: e.target.value })} />
                                        </Col>
                                    </>
                                }
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleCloseModal} disabled={this.state.working}>
                                {t('Close')}
                            </Button>
                            <Button disabled={this.state.working} variant="primary" onClick={() => this.saveStdAward()}>
                                {t('Add')}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Offcanvas
                        style={{ zIndex: 2000, height: '100%' }}
                        placement={'bottom'} show={this.state.awardsImagesModal.show} onHide={this.state.awardsImagesModal.close}>
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title>{t('Images Gallery')}</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Row>
                                {this.state.awardsImagesModal.imagesList.length === 0 &&
                                    <Col md={12} className="text-center">
                                        {t('No Images Found')}
                                    </Col>
                                }
                                {this.state.awardsImagesModal.imagesList.length > 0 && this.state.awardsImagesModal.imagesList.map((image, index) => (
                                    <Col key={index} md={2} className="mb-4 position-relative">
                                        <div style={{
                                            position: 'absolute',
                                            width: 29,
                                            height: 29,
                                            top: 10,
                                            right: 20,
                                            backgroundColor: '#fff',
                                            borderRadius: '50%',
                                        }}>
                                            {this.state.award_img == image.awi_image ?
                                                <FontAwesomeIcon
                                                    icon={faCircleCheck}
                                                    color={'#b7e543'}
                                                    style={{ fontSize: 30 }}
                                                />
                                                :
                                                <FontAwesomeIcon
                                                    icon={faCircle}
                                                    color={'#fff'}
                                                    style={{ fontSize: 30 }}
                                                />
                                            }
                                        </div>
                                        <img
                                            src={`${awardBucketURL}/${image.awi_folder}/${image.awi_image}`}
                                            alt={image.awi_name}
                                            style={{ width: '100%', height: 'auto', cursor: 'pointer' }}
                                            onClick={() => this.setState({
                                                award_img: image.awi_image,
                                                award_folder: image.awi_folder
                                            })}
                                        />
                                    </Col>
                                ))}
                            </Row>
                        </Offcanvas.Body>
                    </Offcanvas>
                </Card.Body>
            </Card>
        );
    }
}

const mapStateToProps = (state) => ({
    authData: state.auth.authData,
    selectedClass: state.selectedClass.data,
    defaultLanguage: state.language.defaultLanguage,
    defaultTheme: state.theme.defaultTheme,
    studentListFetching: state.studentList.fetching,
    studentListData: state.studentList.data,
    studentListSearch: state.studentList.query,
    studentListRows: state.studentList.rows,
    studentListOffset: state.studentList.offset,
    studentListStatus: state.studentList.status,
    studentListOrder: state.studentList.order,
    studentListDir: state.studentList.dir,
    studentListLastPage: state.studentList.last_page,
    studentListPage: state.studentList.page,
    studentListTotalRecords: state.studentList.total_records,
});

const mapDispatchToProps = () => ({
    getStudents,
    setStudents
});

export default connect(mapStateToProps, mapDispatchToProps())(StudentPastoralCare);
