import { Component } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faBook,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';
import { Modal, ListGroup, Image, Row, Col, Form } from 'react-bootstrap';
import { setSubject } from '../../../redux/slices/selectedClassSlice';
import { t } from '../../../helpers/translation_helper';
import { brand_colors } from '../../../helpers/brand_colors_helper';
import axios from 'axios';
import { toast } from 'react-toastify';

// API URL
const apiURL = process.env.REACT_APP_API_URL;

// Portal URL
const portalURL = process.env.REACT_APP_PORTAL_URL;

class SubjectSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      search: ''
    };
  }

  // componentDidMount() {
  //   const { selectedClass } = this.props;
  // }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedSubject !== this.props.selectedSubject) {
      this.setState({ modal: false, }, () => {
        if (this.props.func?.length > 0) {
          this.props.func.map((func) => {
            return func();
          });
        }
      });
    }
  }

  handleModal = () => {
    const { modal } = this.state;
    this.setState({ modal: !modal });
  };

  subjectsList = () => {
    let list = [];
    if (this.props.selectedClass.academic_classmain_subject && this.props.selectedClass.academic_classmain_subject.length > 0) {
      list = this.props.selectedClass.academic_classmain_subject.map((item, key) => {
        if (!(item.acs_name).toLowerCase().includes(this.state.search.toLowerCase())) {
          return null
        }
        return <ListGroup.Item
          key={key}
          className='child-list-item'
          style={{
            justifyContent: 'space-between'
          }}
          onClick={() => this.handleSubjectChange(item.acs_id)}>
          <div className='d-flex'>
            <div className='child-pic list-child-pic'>
              {item.acs_image ? (
                <Image
                  src={
                    portalURL + this.props.authData.centerData.center_uuid +
                    '/academic_classmain_subject/' +
                    item.acs_image
                  }
                  style={{
                    objectFit: 'cover',
                    width: '100%',
                    height: '100%',
                  }}
                />
              ) : (
                <FontAwesomeIcon icon={faBook} color={brand_colors[this.props.defaultTheme.theme_id].color8} />
              )}
            </div>
            <div className='d-flex align-items-center'>
              <span className='class-name'>
                {item.acs_name}
              </span>
            </div>
          </div>
          <div className='d-flex align-items-center'>
            {this.props.selectedSubject.acs_id == item.acs_id && (
              <FontAwesomeIcon
                icon={faCheckCircle}
                color={brand_colors[this.props.defaultTheme.theme_id].color4}
              />
            )}
          </div>
        </ListGroup.Item>
      });
    }
    return list;
  };

  handleSubjectChange = (subject_id) => {
    const findSubject = this.props.selectedClass.academic_classmain_subject.find((item) => {
      return item.acs_id == subject_id;
    });

    this.props.setSubject(findSubject);

    // this.setState(
    //   {
    //     modal: false,
    //   },
    //   () => {
    //     if (this.props.func?.length > 0) {
    //       this.props.func.map((func) => {
    //         return func();
    //       });
    //     }
    //   }
    // );
  };

  render() {

    const { lang_orientation: dir } = this.props.defaultLanguage;
    const { modal, search } = this.state;
    const { authData } = this.props;
    return (
      <>
        <div className='selector' style={{ padding: '0 10px', cursor: 'pointer' }}>
          <div className='d-flex flex-row justify-content-center align-items-center' style={{ fontSize: 14, fontWeight: 'bold', color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.8 }} onClick={() => this.handleModal()}>
            <FontAwesomeIcon
              icon={faCheckCircle}
              color={brand_colors[this.props.defaultTheme.theme_id].color4}
              className='mx-1'
              style={{ fontSize: 18 }}
            />
            <div className='mx-1'>{this.props.selectedSubject ? this.props.selectedSubject.acs_name : 'No Subjects'}</div>
            <FontAwesomeIcon
              icon={faChevronDown}
              color={brand_colors[this.props.defaultTheme.theme_id].color2}
              className='mx-1'
              style={{ fontSize: 16 }}
            />
          </div>
        </div>
        <Modal show={modal} size='sm' onHide={this.handleModal}>
          <Modal.Header>
            <div className='d-flex flex-column' style={{ width: '100%' }}>
              <div className='d-flex flex-row justify-content-between align-items-center'>
                <Modal.Title>{t('Select Subject')}</Modal.Title>
                <button
                  type='button'
                  className='btn-close'
                  aria-label='Close'
                  onClick={this.handleModal}
                ></button>
              </div>
              <div >
                <Form.Control
                  type='text'
                  placeholder={t('Search...')}
                  onChange={(e) => this.setState({ search: e.target.value })}
                  value={search}
                />
              </div>
            </div>
          </Modal.Header>
          <Modal.Body className='p-0 pb-2'>
            <ListGroup variant='flush'>
              {this.subjectsList()}
            </ListGroup>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  selectedClass: state.selectedClass.data,
  selectedSubject: state.selectedClass.subject,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
});

const mapDispatchToProps = () => ({
  setSubject,
});

export default connect(mapStateToProps, mapDispatchToProps())(SubjectSelector);
