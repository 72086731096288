import React, { Component } from 'react';
import { Container, Card, Table, Row, Col, Spinner, Image, Badge, Button, Form, Offcanvas, Accordion, Modal } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faCalendarAlt, faCheck, faChevronDown, faChevronUp, faClock, faEdit, faExternalLink, faFileAlt, faGear, faPaperclip, faSave, faSearch, faTimes, faTrashCan, } from '@fortawesome/free-solid-svg-icons';
import { Scrollbar } from 'react-scrollbars-custom';
import imgDescription from '../../assets/images/description.png';
import { brand_colors } from '../../helpers/brand_colors_helper';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import { handleFormErrors } from '../../helpers/form_helpers';
import { DateRangePicker } from 'react-date-range';
import { GET } from '../../api';


// API URL
const apiURL = process.env.REACT_APP_API_URL;

// Portal URL
const portalURL = process.env.REACT_APP_PORTAL_URL;

class AssessmentCoverage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dateRangePicker: {
                show: false,
                togglePicker: () => this.setState({ dateRangePicker: { ...this.state.dateRangePicker, show: !this.state.dateRangePicker.show } }),
                value: {
                    startDate: new Date(), endDate: new Date(), key: 'selection'
                }
            },
            reportData: {
                processing: false,
                data: {},
            },
            assess_curr: [],
            reRenderTime: moment(),
            detailsModal: {
                show: false,
                data: false,
                toggleModal: (data = false) => this.setState({ detailsModal: { ...this.state.detailsModal, show: (data) ? true : false, data } })
            },
            progassData: [],
            selectedClass: null,
            selectedProgass: null,
        }
        this.assess_curr = []
    }
    componentDidMount() {
        // this.fetchReport();
        this.getProgass();
    }

    fetchReport = () => {
        let reportData = this.state.reportData;
        const { selectedClass, selectedProgass } = this.state;

        if (selectedClass == null || selectedProgass == null) {
            toast.error(t('Please select class and curriculum!'));
            return;
        }

        this.setState({ reportData: { ...reportData, processing: true, data: {} }, dateRangePicker: { ...this.state.dateRangePicker, show: false } })

        try {
            if (this.fetchReportReq) {
                this.fetchReportReq.abort();
            }
            this.fetchReportReq = new AbortController();
            GET('student-tracker/get-assessment-coverage', {
                params: {
                    class_id: selectedClass.value,
                    progass_id: selectedProgass.value,
                    // startDate: moment(this.state.dateRangePicker.startDate).format('YYYY-MM-DD'),
                    // endDate: moment(this.state.dateRangePicker.endDate).format('YYYY-MM-DD'),
                },
                signal: this.fetchReportReq.signal,
            }).then((res) => {
                if (res) {
                    this.setState({ reportData: { ...reportData, processing: false, data: res.data }, reRenderTime: moment() })
                } else {
                    toast.error(t('Something went wrong while deleting!'));
                    this.setState({ reportData: { ...reportData, processing: false, data: {} } })
                }
            })
        } catch (err) {
            console.log('err', err)
            toast.error(t('Something went wrong while deleting!'));

            this.setState({ reportData: { ...reportData, processing: false, data: {} } })
        }
    }

    getProgass = async () => {
        try {
            const res = await GET('student-tracker/get-progass');

            this.setState({
                progassData: res.data.map(item => {
                    return {
                        label: item.progass_code,
                        value: item.progass_id,
                    };
                })
            });

        } catch (err) {
            console.log('err', err)
            toast.error(t('Something went wrong!'));
        }
    }

    renderDetails = () => {
        let rowdata = this.state.detailsModal.data
        let areadata = rowdata.areadata
        let aspectdata = rowdata.aspectdata
        let agegroupdata = rowdata.agegroupdata
        let curr = Object.entries(rowdata.curr)
        let assessment = rowdata.assessment
        let childdata = rowdata.childdata
        return <div>
            <div style={{ fontSize: 16, fontWeight: 'bold', marginBottom: 10 }}>{childdata.child_name}</div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <td colSpan="3">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb breadcrumb-divider">
                                    <li className="breadcrumb-item" style={{ color: areadata.color }}>{areadata.code}</li>
                                    <li className="breadcrumb-item">{aspectdata.desc}</li>
                                    <li className="breadcrumb-item pr-1" style={{ color: agegroupdata.color }}>{agegroupdata.code}</li>
                                </ol>
                            </nav>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    {
                        curr.map((v, i) => {
                            v = Object.entries(v[1])
                            return v.map((vv, ii) => {
                                vv = vv[1]
                                let assessmentData = assessment.find((vvv, iii) => vv.value == vvv.assmt_id)
                                return <tr key={ii}>
                                    <td style={{ width: '100px' }}>
                                        <div style={{ fontSize: 13 }}>{moment(vv.date).format('DD-MM-YYYY')}</div>
                                    </td>
                                    <td>
                                        <div style={{ fontSize: 13 }}><strong>{vv.ref_num}#</strong> {vv.code}</div>
                                    </td>
                                    <td className="text-center" style={{ fontSize: 13, width: '120px', color: (assessmentData) ? assessmentData.assmt_color : '#fff' }}>{(assessmentData) ? assessmentData.assmt_code : ''}</td>
                                </tr>
                            })
                        })
                    }
                </tbody>
            </Table>
        </div>
    }

    render() {
        const { reportData, reRenderTime } = this.state
        return (
            <Container fluid>
                <div id='iedu-layout'>
                    <NavBar />
                    <div id='page-content'>
                        <Header lite={true} heading={t('Class Tracker')} backBtn={true} />
                        <div className='grey-section pb-0'>
                            <Row className='mb-4'>
                                <Col md={3} className='pe-0'>
                                    <Select
                                        placeholder={t('Select Class')}
                                        options={this.props.authData.classes.map((v, i) => { return { ...v.class, label: v.class.class_theme, value: v.class.class_id } })}
                                        onChange={(event) => this.setState({ selectedClass: event })}
                                        value={this.state.selectedClass}
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    />
                                </Col>
                                <Col md={3} className="pe-0">
                                    <Select
                                        placeholder={t('Select Curriculum')}
                                        options={this.state.progassData}
                                        onChange={(event) => this.setState({ selectedProgass: event })
                                        }
                                        value={this.state.selectedProgass}
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    />
                                </Col>
                                <Col>
                                    <Button variant={'primary'} onClick={() => this.fetchReport()}>
                                        {reportData.processing
                                            ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />
                                            : <FontAwesomeIcon icon={faSearch} style={{ fontSize: 16, color: brand_colors[this.props.defaultTheme.theme_id].color8, }} />
                                        }
                                    </Button>
                                </Col>
                            </Row>
                            <div className=' d-flex justify-content-center align-items-center'>
                                {reportData.processing
                                    ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />
                                    : <RenderCurriculum reportData={reportData} defaultTheme={this.props.defaultTheme} reRenderTime={reRenderTime} detailsModal={this.state.detailsModal} />
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <Modal size="lg" show={this.state.detailsModal.show} onHide={() => this.state.detailsModal.toggleModal()} backdrop="static"
                    keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t('Details')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.detailsModal.show &&
                            this.renderDetails()
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.state.detailsModal.toggleModal()}>
                            {t('Close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    authData: state.auth.authData,
    defaultLanguage: state.language.defaultLanguage,
    selectedClass: state.selectedClass.data,
    children: state.children.list,
    defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(AssessmentCoverage);


class RenderCurriculum extends Component {
    constructor(props) {
        super(props);
    }
    state = {}

    shouldComponentUpdate(nextProps) {
        if (moment(nextProps.reRenderTime).isSame(moment(this.props.reRenderTime))) {
            return false
        }
        return true
    }

    render() {
        let reportData = this.props.reportData
        if (reportData.data.children == undefined) {
            return null
        }
        let children = reportData.data.children
        let report_data = reportData.data.report_data
        let structure = Object.entries(reportData.data.structure.area)
        let assessment = reportData.data.assessment
        return (<Scrollbar style={{ height: '74vh' }}>
            <Table striped bordered hover >
                <thead>
                    <tr>
                        <th style={{ minWidth: '100px', }} rowSpan="2">{t('Students')}</th>
                        {structure.map((v, i) => {
                            v = v[1]
                            let aspectLen = (Object.entries(v.aspect)).length
                            return <th key={i} style={{ minWidth: '100px', color: v.color }} colSpan={aspectLen} title={v.desc}>{v.code}</th>
                        })}
                    </tr>
                    <tr>
                        {structure.map((v, i) => {
                            v = v[1]
                            let aspect = (Object.entries(v.aspect))
                            return aspect.map((vv, ii) => {
                                vv = vv[1]
                                return <th key={ii} style={{ minWidth: '100px', color: brand_colors[this.props.defaultTheme.theme_id].color9 }} title={vv.desc}>{vv.code}</th>
                            })
                        })}
                    </tr>
                </thead>
                <tbody>
                    {children.map((child, childKey) => {
                        return <tr key={childKey}>
                            <td>{child.child_name}</td>
                            {structure.map((v, i) => {
                                v = v[1]
                                let aspect = (Object.entries(v.aspect))
                                return aspect.map((vv, ii) => {
                                    vv = vv[1]

                                    if (report_data['child'] && report_data['child'][child.child_id]) {
                                        if (report_data['child'][child.child_id]['larea'][v.id]) {
                                            if (report_data['child'][child.child_id]['larea'][v.id]['lasp'][vv.id]) {
                                                var report_child_data = Object.entries(report_data['child'][child.child_id]['larea'][v.id]['lasp'][vv.id]['agrp'])
                                                return <td key={ii}>
                                                    {report_child_data.map((vvv, iii) => {
                                                        vvv = vvv[1]
                                                        if (vvv.code) {
                                                            let modalData = {
                                                                childdata: { child_id: child.child_id, child_name: child.child_name },
                                                                areadata: { id: v.id, desc: v.desc, code: v.code, color: vv.color },
                                                                aspectdata: { id: vv.id, desc: vv.desc, code: vv.code },
                                                                agegroupdata: { id: vvv.id, desc: vvv.desc, code: vvv.code, color: vvv.color },
                                                                curr: vvv.curr,
                                                                assessment: assessment,
                                                            }
                                                            return <Button key={iii} variant="outline-primary" className="mb-2" style={{ color: vvv.color }} onClick={() => {
                                                                this.props.detailsModal.toggleModal(modalData)
                                                            }}>
                                                                {vvv.code} ({vvv.curr_count_percnt})
                                                            </Button>
                                                        }
                                                        return null
                                                    })}
                                                </td>
                                            } else {
                                                return <td key={ii}></td>
                                            }
                                        } else {
                                            return <td key={ii}></td>
                                        }
                                    } else {
                                        return <td key={ii}></td>
                                    }
                                })
                            })}
                        </tr>
                    })}
                </tbody>
            </Table>
        </Scrollbar >
        );
    }
}
