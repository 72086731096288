import React, { Component, createRef } from 'react'
import { Container, Card, Row, Col, Badge, Button, Modal, Form, Spinner, Alert, ButtonGroup, DropdownButton, Dropdown } from 'react-bootstrap'
import { t } from '../../helpers/translation_helper'
import NavBar from '../_partials/NavBar/_NavBar'
import Header from '../_partials/Header/_Header'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Select from 'react-select'
import { faEdit, faPlus, faThumbsDown, faThumbsUp, faSearch, faTimesCircle, faTrashAlt, faXmark, faCalendar, faSave, faLink, faCopy, faBarsStaggered, faEye, } from '@fortawesome/free-solid-svg-icons'
import { brand_colors } from '../../helpers/brand_colors_helper'
import { handleFormErrors, readFile, setSwitchField, setTxtField, } from '../../helpers/form_helpers'
import SweetAlert from 'react-bootstrap-sweetalert'
// Images
import imgAddButton from '../../assets/images/add-button-small.png'
import imgHyperlink from '../../assets/images/hyperlink-small.png'
import imgAttachment from '../../assets/images/attachment-small.png'
import imgLabelTag from '../../assets/images/label-tag.png'
import imgCalendar from '../../assets/images/calendar.png'
import imgDescription from '../../assets/images/description.png'
import { withParams } from '../../helpers/params_helper'
import axios from 'axios'
import Paginate from '../_partials/Paginate/_Paginate'
import { GET, POST } from '../../api'
import GeneralCard from '../../helpers/GeneralCard'
import { faCalendarAlt, faComments, faFolder, faTrashCan } from '@fortawesome/free-regular-svg-icons'
import { sendCloudMessage } from '../../helpers/cloud_message'
import { changeColorOpacity, getExtension } from '../../helpers/general_helpers'
import { Chance } from 'chance';
import { setSubLessonModal } from '../../redux/slices/subLessonModalSlice';
import AddEditSubLesson from '../_common/AddEditSubLesson/_AddEditSubLesson';
const chance = new Chance();

// Vars
const apiURL = process.env.REACT_APP_API_URL
const portalURL = process.env.REACT_APP_PORTAL_URL
const controller = 'academic-subject-lessons'
const imgDir = 'academic_subject_lessons'

/**
 * Lessons Component
 */
class Lessons extends Component {
  constructor(props) {
    super(props)
    //  States
    this.state = {
      asl_id_copy: null,
      deleteModal: { show: false, id: 0, action: () => { }, title: null, desc: null },
      showAddEditForm: false,
      working: false,
      editing: false,
      termsDefault: [],
      // Form fields
      id: 0,
      name: '',
      code: '',
      url: '',
      period: '',
      chatBlog: 0,
      theme: '',
      senRequired: '',
      aslf_id: '',
      ictUsage: '',
      afl: '',
      type1: { value: 'Interdisciplinary', label: 'Interdisciplinary' },
      // type1Selected: '',
      type2: { value: 'Standalone', label: 'Standalone' },
      // type2Selected: '',
      note1: '',
      note2: '',
      note3: '',
      image: '',
      image_prev: '',
      image_deleted: 0,
      type1Opts: [
        { value: 'Interdisciplinary', label: 'Interdisciplinary' },
        { value: 'Subject', label: 'Subject' },
      ],
      type2Opts: [
        { value: 'Standalone', label: 'Standalone' },
        { value: 'Inquiry', label: 'Inquiry' },
      ],
      term_d_id: null,
      // Paginate
      academicSubjectLessons: {
        // lessons: null,
        active_lessons: false,
        openSearch: false,
        search: '',
        limit: 12,
        offset: 0,
        data: [],
        total_count: 0,
        total_count_filtered: 0,
        refreshing: false,
        finished: false,
        orderName: 'Name ASC',
        order: 'academic_subject_lessons.asl_name',
        dir: 'ASC',
        changeOrder: (orderName = 'Name ASC', order = 'academic_subject_lessons.asl_name', dir = 'DESC') => this.setState({ academicSubjectLessons: { ...this.state.academicSubjectLessons, orderName, order, dir } }, () => this.getLessons()),
      },
      academicSubjectLessonFoldersData: {
        openSearch: false,
        search: '',
        orderName: 'Name ASC',
        order: 'academic_subject_lesson_folders.aslf_id',
        dir: 'DESC',
        changeOrder: (orderName = 'Name ASC', order = 'academic_subject_lesson_folders.aslf_name', dir = 'DESC') => this.setState({ academicSubjectLessonFoldersData: { ...this.state.academicSubjectLessonFoldersData, orderName, order, dir } }, () => this.getAcademicSubjectLessonFolders()),
        limit: 100,
        offset: 0,
        data: [],
        total_count: 0,
        total_count_filtered: 0,
        refreshing: false,
        finished: false,
      },
      InterdisciplinaryFoldersData: {
        openSearch: false,
        search: '',
        orderName: 'Name ASC',
        order: 'academic_subject_lesson_folders.aslf_id',
        dir: 'DESC',
        changeOrder: (orderName = 'Name ASC', order = 'academic_subject_lesson_folders.aslf_name', dir = 'DESC') => this.setState({ InterdisciplinaryFoldersData: { ...this.state.InterdisciplinaryFoldersData, orderName, order, dir } }, () => this.getAcademicSubjectLessonFolders()),
        limit: 100,
        offset: 0,
        data: [],
        total_count: 0,
        total_count_filtered: 0,
        refreshing: false,
        finished: false,
      },
      modalActivateLesson: {
        show: false,
        toggle: (asl = null, assignedSubjectsArray = []) => this.setState({ modalActivateLesson: { ...this.state.modalActivateLesson, show: !this.state.modalActivateLesson.show, assignedSubjectsArray, asl } }),
        assignedSubjectsArray: [],
        asl: null,
      },
      modalLessonCopy: {
        show: false,
        toggle: () => this.setState({ modalLessonCopy: { ...this.state.modalLessonCopy, show: !this.state.modalLessonCopy.show } }),
      },
      modalReflections: {
        show: false,
        title: '',
        toggle: (title, asl) => this.setState({ modalReflections: { ...this.state.modalReflections, show: !this.state.modalReflections.show, title, asl } }, () => {
          if (this.state.modalReflections.show) {
            this.getLessonResources(this.state.modalReflections.asl.asl_id)
          }
        }),
        asl: null,
        data: [],
        setData: (field, value) => this.setState({ modalReflections: { ...this.state.modalReflections, editData: { ...this.state.modalReflections.editData, [field]: value }, edit: true } }),
        setAllData: (editData = {}) => this.setState({ modalReflections: { ...this.state.modalReflections, editData: { ...this.state.modalReflections.editData, ...editData }, edit: true } }),
        edit: null,
        editData: {
          aslr_id: 0,
          aslr_type: 'user_reflection',
          aslr_title: '',
          aslr_url_type: '',
          aslr_url: '',
          aslr_desc: '',
          aslr_type_id: null,
          ab_id: null,
          as_id: null,
          acs_id: null,
          asl_id: null,
        },
        edit: false,
        save: this.saveLessonResource
      },
      viewType: {
        selected: 'folder',
        toggleView: (selected = 'folder', aslf_id = '', aslf_name = '') => this.setState({ viewType: { ...this.state.viewType, selected, aslf_id, aslf_name } }, () => {
          if (selected == 'lesson') {
            this.getLessons()
          }
        }),
        aslf_id: '',
        aslf_name: '',
      },
      modalFolder: {
        show: false,
        toggle: (title = 'New Folder') => this.setState({ modalFolder: { ...this.state.modalFolder, show: !this.state.modalFolder.show, title } }),
        title: '',
        save: this.saveASLF,
        saving: false,
        setData: (field, value) => this.setState({ modalFolder: { ...this.state.modalFolder, data: { ...this.state.modalFolder.data, [field]: value } } }),
        data: {
          aslf_id: 0,
          aslf_name: '',
          aslf_image: '',
          acs_id: '',
          agegroup_id: '',
          aa_id: null
        }
      },
      aslf_image: null,
      dragAreaColor: {
        aslf_image: brand_colors[this.props.defaultTheme.theme_id].color18,
        toggleColor: (field, value) => this.setState({ dragAreaColor: { ...this.state.dragAreaColor, [field]: value } })
      },
      asl_type1_filter: {
        data: [
          { name: 'All', value: 'All' },
          { name: 'Interdisciplinary', value: 'Interdisciplinary' },
          { name: 'Subject', value: 'Subject' },
        ],
        selected: 'All',
        toggleView: (selected = 'All') => this.setState({ asl_type1_filter: { ...this.state.asl_type1_filter, selected } }, () => this.getLessons())
      },
      term_d_id_filter: { label: 'All', value: 'All' },
    }
    this.aslf_image_ref = React.createRef()
    this.image = createRef()
  }

  /**
   * Component Did Mount
   */
  componentDidMount() {
    this.getLessons()
    this.getAcademicSubjectLessonFolders()
    // this.getAcademicSubjects()
    this.getInterdisciplinaryFolders()
    this.getTermsDefault()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.viewType.selected != this.state.viewType.selected && this.state.viewType.selected == 'folder') {
      this.getAcademicSubjectLessonFolders()
      this.getInterdisciplinaryFolders()
    }
  }

  getTermsDefault = async () => {
    if (this.getTermsDefaultReq) {
      this.getTermsDefaultReq.abort();
    }
    this.getTermsDefaultReq = new AbortController();

    try {
      const res = await GET(controller + '/get-terms-default',
        {
          signal: this.getTermsDefaultReq.signal,
        }
      );
      this.setState({
        termsDefault: res.data.map((v, i) => { return { ...v, label: v.term_d_name, value: v.term_d_id } })
      });
    } catch (err) {
      console.log('err', err)
      if (axios.isCancel(err)) {

      } else {
        toast.error(t('Something went wrong while fetching data!'));

      }
    }
  };


  /**
   * Get Lessons
   */
  getLessons = async (limit = this.state.academicSubjectLessons.limit, offset = 0) => {

    if (this.getLessonsReq) {
      this.getLessonsReq.abort()
    }
    this.getLessonsReq = new AbortController()
    let academicSubjectLessons = this.state.academicSubjectLessons
    this.setState({
      academicSubjectLessonFoldersData: {
        ...this.state.academicSubjectLessonFoldersData,
        refreshing: true,
        finished: false
      }
    })

    const { authData, selectedClass } = this.props
    const { auth_key } = authData.loginData
    const { center_id } = authData.centerData
    const acs_id = (this.props.selectedSubject) ? this.props.selectedSubject.acs_id : null
    let { viewType, term_d_id_filter } = this.state
    const fData = new FormData()
    fData.append('center_id', center_id)
    fData.append('acs_id', acs_id)
    fData.append('limit', limit)
    fData.append('offset', offset)
    fData.append('order', academicSubjectLessons.order)
    fData.append('dir', academicSubjectLessons.dir)
    if (viewType.aslf_id) {
      fData.append('aslf_id', viewType.aslf_id)
    }
    fData.append('class_id', (selectedClass) ? selectedClass.class.class_id : null)
    if (academicSubjectLessons.active_lessons) {
      fData.append('is_activated', 1)
    }
    if (academicSubjectLessons.search) {
      fData.append('search', (academicSubjectLessons.search).toLowerCase())
    }
    if (this.state.asl_type1_filter.selected != 'All') {
      fData.append('asl_type1', this.state.asl_type1_filter.selected)
    }

    if (term_d_id_filter.value != 'All') {
      fData.append('term_d_id_filter', term_d_id_filter.value)
    }

    try {
      const res = await POST(controller + '/get', fData, {
        signal: this.getLessonsReq.signal,
      })
      if (res) {
        this.setState({
          academicSubjectLessons: {
            ...this.state.academicSubjectLessons,
            data: res.data.data.map((v, i) => { return { ...v, asl_aa_ids: JSON.parse(v.asl_aa_ids), asl_acs_ids: JSON.parse(v.asl_acs_ids) } }),
            total_count: res.data.total_count,
            total_count_filtered: res.data.total_count_filtered,
            refreshing: false,
            finished: (res.data.data.length <= this.state.academicSubjectLessons.limit) ? true : false
          }
        })
      }
    } catch (err) {
      console.log('err', err)
      if (axios.isCancel(err)) {

      } else {
        toast.error(t('Something went wrong while fetching lessons!'))
      }
    }
  }
  getAcademicSubjectLessonFolders = async () => {
    let academicSubjectLessonFoldersData = this.state.academicSubjectLessonFoldersData
    if (academicSubjectLessonFoldersData.refreshing) {
      return false
    }
    this.setState({
      academicSubjectLessonFoldersData: {
        ...this.state.academicSubjectLessonFoldersData,
        refreshing: true
      }
    })

    if (this.getAcademicSubjectLessonFoldersReq) {
      this.getAcademicSubjectLessonFoldersReq.abort()
    }
    this.getAcademicSubjectLessonFoldersReq = new AbortController

    const res = await GET('academic-subject-lesson-folders/get',
      {
        params: {
          search: this.state.academicSubjectLessonFoldersData.search,
          order: this.state.academicSubjectLessonFoldersData.order,
          dir: this.state.academicSubjectLessonFoldersData.dir,
          limit: this.state.academicSubjectLessonFoldersData.limit,
          offset: this.state.academicSubjectLessonFoldersData.offset,
          acs_id: this.props.selectedSubject.acs_id,
          agegroup_id: this.props.selectedClass.class.agegroup_id,
        },
        signal: this.getAcademicSubjectLessonFoldersReq.signal
      }
    )
    if (res) {
      this.setState({
        academicSubjectLessonFoldersData: {
          ...this.state.academicSubjectLessonFoldersData,
          data: res.data.data,
          total_count: res.data.total_count,
          total_count_filtered: res.data.total_count_filtered,
          refreshing: false,
          finished: true
        }
      })
    }
  }

  getInterdisciplinaryFolders = async () => {
    let InterdisciplinaryFoldersData = this.state.InterdisciplinaryFoldersData
    if (InterdisciplinaryFoldersData.refreshing) {
      return false
    }
    this.setState({
      InterdisciplinaryFoldersData: {
        ...this.state.InterdisciplinaryFoldersData,
        refreshing: true
      }
    })

    if (this.getInterdisciplinaryFoldersReq) {
      this.getInterdisciplinaryFoldersReq.abort()
    }
    this.getInterdisciplinaryFoldersReq = new AbortController

    const res = await GET('academic-subject-lesson-folders/get-interdisciplinary-folders',
      {
        params: {
          search: this.state.InterdisciplinaryFoldersData.search,
          order: this.state.InterdisciplinaryFoldersData.order,
          dir: this.state.InterdisciplinaryFoldersData.dir,
          limit: this.state.InterdisciplinaryFoldersData.limit,
          offset: this.state.InterdisciplinaryFoldersData.offset,
          acs_id: this.props.selectedSubject.acs_id,
          agegroup_id: this.props.selectedClass.class.agegroup_id,
        },
        signal: this.getInterdisciplinaryFoldersReq.signal
      }
    )
    if (res) {
      this.setState({
        InterdisciplinaryFoldersData: {
          ...this.state.InterdisciplinaryFoldersData,
          data: res.data.data,
          total_count: res.data.total_count,
          total_count_filtered: res.data.total_count_filtered,
          refreshing: false,
          finished: true
        }
      })
    }
  }

  getLessonResources = async (asl_id, aslr_type = 'user_reflection') => {
    const res = await GET(controller + '/get-lesson-resources',
      {
        params: {
          asl_id: asl_id,
          aslr_type: aslr_type,
          users_info: true,
        },
      }
    );
    if (res) {
      let resData = res.data
      let modalReflections = this.state.modalReflections
      this.setState({ modalReflections: { ...modalReflections, data: resData } })
    }
  };

  deleteLessonResource = async (aslr_id) => {
    this.setState({
      deleteModal: {
        ...this.state.deleteModal, show: true, action: async () => {
          if (this.deleteLessonResourceReq) {
            this.deleteLessonResourceReq.abort()
          }
          this.deleteLessonResourceReq = new AbortController
          const res = await GET(controller + '/delete-lesson-resource',
            {
              params: {
                aslr_id: aslr_id,
              },
              signal: this.deleteLessonResourceReq.signal
            }
          );
          if (res) {
            let modalReflections = this.state.modalReflections
            let resData = res.data
            let findaslr = modalReflections.data.findIndex((v, i) => v.aslr_id == aslr_id)
            if (findaslr !== -1) {
              modalReflections.data.splice(findaslr, 1)
            }
            modalReflections.edit = false
            this.setState({ modalReflections, deleteModal: { ...this.state.deleteModal, show: false, } })
          } else {
            toast[res.data.messageType](t(res.data.message));
            this.setState({ deleteModal: { ...this.state.deleteModal, show: false } })
          }
        }
      }
    })
  }

  saveLessonResource = async () => {
    const { authData, selectedClass } = this.props;
    const { center_id, center_timezone } = authData.centerData;
    const { auth_key, user_id } = authData.loginData;
    const fData = new FormData();
    fData.append('center_id', center_id);
    fData.append('center_timezone', center_timezone);
    fData.append('user_id', user_id);
    fData.append('AcademicSubjectLessonResources', JSON.stringify(this.state.modalReflections.editData));
    if (this.saveLessonResourceReq) {
      this.saveLessonResourceReq.abort()
    }
    this.saveLessonResourceReq = new AbortController
    const res = await POST(controller + '/save-lesson-resource', fData, {
      signal: this.saveLessonResourceReq.signal
    }
    );
    if (res) {
      if (res.data.type) {
        toast.success(t('Updated successfully!'));
        let modalReflections = this.state.modalReflections
        let findaslr = modalReflections.data.findIndex((v, i) => v.aslr_id == res.data.data.aslr_id)
        if (findaslr === -1) {
          modalReflections.data.push(res.data.data)
        } else {
          modalReflections.data[findaslr] = res.data.data
        }
        modalReflections.edit = false
        this.setState({ modalReflections })

        res.data.sendCloudMessages.map((v, i) => {
          if (v.user_id) {
            sendCloudMessage(v.user_id, v.title, v.body, v.data)
          }
        })

      } else {
        handleFormErrors(res.data.data);
        // this.setState({ working: false });
      }
    }
  };

  saveASLF = async () => {
    this.setState({ modalFolder: { ...this.state.modalFolder, saving: true } })
    const { aslf_image, modalFolder } = this.state

    const fData = new FormData()
    fData.append('aslf_id', modalFolder.data.aslf_id)
    if (aslf_image) {
      fData.append('aslf_image', aslf_image.data)
    }

    modalFolder.data.acs_id = this.props.selectedSubject.acs_id
    modalFolder.data.agegroup_id = this.props.selectedClass.class.agegroup_id
    modalFolder.data.createActivity = ['Primary Year Programme', 'Middle Year Programme', 'Diploma Programme'].includes(this.props.selectedSubject.agegroup_type) ? 1 : 0

    fData.append('AcademicSubjectLessonFoldersData', JSON.stringify(modalFolder.data))

    const res = await POST(
      'academic-subject-lesson-folders/save',
      fData,
    )
    if (res) {
      if (!res.data.type) {
        if (res.data.data != null) {
          handleFormErrors(res.data.data)
        } else {
          toast.error(t('Something went wrong while saving!'))
        }
      } else {
        let academicSubjectLessonFoldersData = this.state.academicSubjectLessonFoldersData
        if (modalFolder.data.aslf_id != 0) {
          academicSubjectLessonFoldersData.data = academicSubjectLessonFoldersData.data.map((v, i) => {
            if (v.aslf_id == modalFolder.data.aslf_id) {
              return { ...v, ...res.data.data }
            }
            return v
          })
        } else {
          academicSubjectLessonFoldersData.data = [{ ...res.data.data }, ...academicSubjectLessonFoldersData.data]
        }

        this.setState({
          academicSubjectLessonFoldersData,
          modalFolder: {
            ...this.state.modalFolder,
            show: false,
            saving: false,
            data: {
              aslf_id: 0,
              aslf_name: '',
              aslf_image: '',
              acs_id: '',
              agegroup_id: '',
              aa_id: null
            }
          },
          aslf_image: null,
        })
        toast.success(t(res.data.message))
      }
    }
  }

  /**
   * Handle Add / Edit form
   */
  handleAddEditFormOpen = (isEditing = false, editingData = {}) => {
    // this.resetForm()

    // If Editing
    let editingState = {}
    if (isEditing) {
      const { type1Opts, type2Opts, termsDefault } = this.state

      let type1Obj = type1Opts.find((item) => {
        return item.value == editingData.asl_type1
      })

      if (!type1Obj) {
        type1Obj = { value: 'Interdisciplinary', label: 'Interdisciplinary' }
      }

      let type2Obj = type2Opts.find((item) => {
        return item.value == editingData.asl_type2
      })
      if (!type2Obj) {
        type2Obj = { value: 'Standalone', label: 'Standalone' }
      }

      let aslf_id = editingData.aslf_id
      if (aslf_id) {
        let find_aslf_id = this.state.academicSubjectLessonFoldersData.data.find((v, i) => v.aslf_id == aslf_id)
        if (find_aslf_id) {
          aslf_id = { value: find_aslf_id.aslf_id, label: find_aslf_id.aslf_name }
        }
      }

      editingState = {
        id: editingData.asl_id,
        name: editingData.asl_name,
        code: editingData.asl_code,
        url: editingData.asl_link,
        period: editingData.asl_period,
        type1: type1Obj,
        type2: type2Obj,
        term_d_id: editingData.term_d_id,
        theme: editingData.asl_theme,
        chatBlog: parseInt(editingData.asl_chat_blog),
        note1: editingData.asl_notes1 || '',
        image: editingData.asl_attach,
        image_prev: portalURL + this.props.authData.centerData.center_uuid + '/' + imgDir + '/' + editingData.asl_attach,
        aslf_id: aslf_id
      }
    } else {
      let viewType = this.state.viewType
      let aslf_id = null
      if (viewType.aslf_id) {
        aslf_id = { value: viewType.aslf_id, label: viewType.aslf_name }
      }

      editingState = {
        name: '',
        code: '',
        url: '',
        period: '',
        aslf_id: '',
        chatBlog: 0,
        theme: '',
        senRequired: '',
        ictUsage: '',
        afl: '',
        type1: { value: 'Interdisciplinary', label: 'Interdisciplinary' },
        type2: { value: 'Standalone', label: 'Standalone' },
        note1: '',
        note2: '',
        note3: '',
        image: '',
        image_prev: '',
        aslf_id: aslf_id,
      }
    }

    this.setState({
      ...editingState,
      showAddEditForm: true,
      editing: isEditing,
    })
  }
  handleAddEditFormClose = () => {
    this.setState({
      showAddEditForm: false,
      editing: false,
    })
  }

  /**
   * Preview Upload File
   * @returns
   */
  previewFile = (e) => {
    const reader = new FileReader()

    const selectedFile = e.target.files[0]

    if (selectedFile) {
      reader.readAsDataURL(selectedFile)
    }

    if (!selectedFile.type.includes('image')) {
      toast.error(t('Only image file is allowed!'))
      return
    }

    reader.onload = (readerEvent) => {
      this.setState({
        [e.target.name + '_deleted']: 0,
        [e.target.name + '_prev']: readerEvent.target.result,
        [e.target.name]: e.target.files[0],
      })
    }
  }

  /**
   * Reset Form
   */
  resetForm = () => {
    this.setState({
      name: '',
      code: '',
      url: '',
      period: '',
      aslf_id: '',
      chatBlog: 0,
      theme: '',
      senRequired: '',
      ictUsage: '',
      afl: '',
      type1: { value: 'Interdisciplinary', label: 'Interdisciplinary' },
      type2: { value: 'Standalone', label: 'Standalone' },
      note1: '',
      note2: '',
      note3: '',
      image: '',
      image_prev: '',
    })
  }

  /**
   * Insert
   */
  insert = async () => {

    // return
    this.setState({ working: true })

    const { name, code, theme, url, type1, type2, period, aslf_id, chatBlog, image, note1, term_d_id, asl_id_copy } = this.state
    const { authData, selectedClass } = this.props
    const { auth_key, user_id } = authData.loginData
    const { center_id, center_timezone } = authData.centerData
    // const { classmain_id } = selectedClass.class
    let as_id, acs_id, ab_id = null
    if (this.props.selectedSubject) {
      as_id = this.props.selectedSubject.as_id
      acs_id = this.props.selectedSubject.acs_id
      ab_id = this.props.selectedSubject.ab_id
    }

    const fData = new FormData()
    if (asl_id_copy) {
      fData.append('asl_id_copy', asl_id_copy.asl_id)
    }
    fData.append('center_id', center_id)
    fData.append('center_timezone', center_timezone)
    fData.append('user_id', user_id)
    // fData.append('AcademicSubjectLessons[classmain_id]', classmain_id)
    fData.append('AcademicSubjectLessons[as_id]', as_id)
    fData.append('AcademicSubjectLessons[aslf_id]', (aslf_id) ? aslf_id.value : null)
    fData.append('AcademicSubjectLessons[acs_id]', acs_id)
    fData.append('AcademicSubjectLessons[ab_id]', ab_id)
    fData.append('AcademicSubjectLessons[asl_name]', name)
    fData.append('AcademicSubjectLessons[asl_code]', code)
    fData.append('AcademicSubjectLessons[asl_theme]', theme)
    fData.append('AcademicSubjectLessons[asl_link]', url)
    fData.append('AcademicSubjectLessons[asl_notes1]', note1)
    fData.append('AcademicSubjectLessons[asl_period]', period)
    fData.append('AcademicSubjectLessons[asl_chat_blog]', chatBlog)
    if (term_d_id) {
      fData.append('AcademicSubjectLessons[term_d_id]', term_d_id.value)
    }
    fData.append('AcademicSubjectLessons[asl_type1]', type1.value)
    fData.append('AcademicSubjectLessons[asl_type2]', type2.value)
    fData.append('asl_attach', image)

    try {
      const res = await axios.post(
        apiURL + controller + '/insert',
        fData,
        {
          params: {
            auth_key: auth_key,
            appname: process.env.REACT_APP_NAME,
          },
        }
      )
      if (res) {
        if (res.data.type) {
          this.handleAddEditFormClose()
          this.getLessons()
          this.setState({ working: false, asl_id_copy: null })
          toast[res.data.messageType](t(res.data.message))
        } else {
          if (res.data.data) {
            handleFormErrors(res.data.data)
          } else {
            toast[res.data.messageType](t(res.data.message))
          }
          this.setState({ working: false })
        }
      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while adding lesson!'))

      this.setState({ working: false })
    }
  }

  /**
   * Update
   */
  update = async () => {
    this.setState({ working: true })

    const { id: asl_id, name, code, theme, url, type1, type2, period, chatBlog, image, image_deleted, note1, aslf_id, term_d_id,
    } = this.state
    const { authData, selectedClass } = this.props
    const { auth_key, user_id } = authData.loginData
    const { center_id, center_timezone } = authData.centerData
    // const { classmain_id } = selectedClass.class
    let as_id, acs_id, ab_id = null
    if (this.props.selectedSubject) {
      as_id = this.props.selectedSubject.as_id
      acs_id = this.props.selectedSubject.acs_id
      ab_id = this.props.selectedSubject.ab_id
    }

    const fData = new FormData()
    fData.append('center_id', center_id)
    fData.append('center_timezone', center_timezone)
    fData.append('user_id', user_id)
    fData.append('asl_id', asl_id)
    // fData.append('AcademicSubjectLessons[classmain_id]', classmain_id)
    fData.append('AcademicSubjectLessons[aslf_id]', (aslf_id) ? aslf_id.value : null)
    fData.append('AcademicSubjectLessons[as_id]', as_id)
    fData.append('AcademicSubjectLessons[acs_id]', acs_id)
    fData.append('AcademicSubjectLessons[ab_id]', ab_id)
    fData.append('AcademicSubjectLessons[asl_name]', name)
    fData.append('AcademicSubjectLessons[asl_code]', code)
    fData.append('AcademicSubjectLessons[asl_theme]', theme)
    fData.append('AcademicSubjectLessons[asl_link]', url)
    fData.append('AcademicSubjectLessons[asl_notes1]', note1)
    fData.append('AcademicSubjectLessons[asl_period]', period)
    fData.append('AcademicSubjectLessons[asl_chat_blog]', chatBlog)
    fData.append('AcademicSubjectLessons[asl_type1]', type1.value)
    fData.append('AcademicSubjectLessons[asl_type2]', type2.value)
    if (term_d_id) {
      fData.append('AcademicSubjectLessons[term_d_id]', term_d_id.value)
    }
    fData.append('asl_attach', image)
    fData.append('asl_attach_deleted', image_deleted)

    try {
      const res = await axios.post(
        apiURL + controller + '/update',
        fData,
        {
          params: {
            auth_key: auth_key,
            appname: process.env.REACT_APP_NAME,
          },
        }
      )
      if (res.status == 200) {
        if (res.data.messageType !== 'error') {
          this.handleAddEditFormClose()
          this.getLessons()
          this.setState({ working: false })
          toast[res.data.messageType](t(res.data.message))
        } else {
          if (res.data.data) {
            handleFormErrors(res.data.data)
          } else {
            toast[res.data.messageType](t(res.data.message))
          }
          this.setState({ working: false })
        }
      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while update lesson!'))

      this.setState({ working: false })
    }
  }

  /**
   * Handle Delete
   */
  delete = (asl_id) => {
    this.setState({
      deleteModal: {
        ...this.state.deleteModal, show: true, action: async () => {
          const { authData } = this.props
          const { center_id } = authData.centerData
          const { auth_key } = authData.loginData
          try {
            const res = await GET(controller + '/delete-lesson', {
              params: {
                asl_id,
              },
            })
            if (res.status == 200) {
              toast.success(t('Lesson deleted!'))
              this.setState({
                deleteModal: {
                  ...this.state.deleteModal, show: false
                }
              }, () => this.getLessons())
            }
          } catch (err) {
            console.log('err', err)
            toast.error(t('Something went wrong while deleting lessons!'))

          }
        }
      }
    })

  }

  // updateLessonStatus = async (item, index) => {
  //   let lessons = this.state.lessons
  //   const { authData, selectedClass } = this.props
  //   const { user_id } = authData.loginData
  //   const { center_id, center_timezone } = authData.centerData

  //   const fData = new FormData()
  //   fData.append('center_id', center_id)
  //   fData.append('user_id', user_id)
  //   fData.append('center_timezone', center_timezone)
  //   fData.append('class_id', selectedClass.class.class_id)
  //   fData.append('acs_id', item.acs_id)
  //   fData.append('asl_id', item.asl_id)
  //   fData.append('is_activated', (item.is_activated == 0 || item.is_activated == null) ? 1 : 0)

  //   try {
  //     const res = await POST('timetable/update-lesson-status', fData)
  //     console.log(res)

  //     if (res.data.type) {
  //       toast.success(t(res.data.message))
  //       if (this.state.active_lessons) {
  //         lessons.splice(index, 1)
  //       } else {
  //         lessons[index].is_activated = (item.is_activated == 0 || item.is_activated == null) ? 1 : 0
  //       }
  //       this.setState({ lessons })
  //     } else {
  //       toast.error(t(res.data.message))
  //     }
  //   } catch (err) {
  //     console.log('err', err)
  //     toast.error(t('Something went wrong while updating lesson status!'))
  //   }

  // }


  updateLessonStatusMultiple = async () => {
    let { academicSubjectLessons, modalActivateLesson } = this.state
    let lessons = academicSubjectLessons.data
    const fData = new FormData()
    fData.append('asl_id', parseInt(modalActivateLesson.asl.asl_id))
    fData.append('is_activated', (parseInt(modalActivateLesson.asl.is_activated) == 0 || modalActivateLesson.asl.is_activated == null) ? 1 : 0)
    fData.append('assignedSubjectsArray', JSON.stringify(modalActivateLesson.assignedSubjectsArray))
    const res = await POST('timetable/update-lesson-status-multiple', fData)

    if (res.data.type) {
      toast.success(t(res.data.message))
      let thisClass = modalActivateLesson.assignedSubjectsArray.find((v) => v.class.class_id == this.props.selectedClass.class.class_id)
      if (thisClass) {
        if (academicSubjectLessons.active_lessons) {
          // WHEN DEACTIVATED
          if (parseInt(modalActivateLesson.asl.is_activated) == 0 || modalActivateLesson.asl.is_activated == null) {
            // lessons = lessons.map((v, i) => (v.asl_id == modalActivateLesson.asl.asl_id) ? { ...v, is_activated: (thisClass.selected) ? 1 : 0 } : v)
            lessons = lessons.map((v, i) => (v.asl_id == modalActivateLesson.asl.asl_id) ? { ...v, is_activated: (thisClass.selected) ? 1 : 0 } : v)
          } else {
            lessons = lessons.filter((v, i) => v.asl_id != modalActivateLesson.asl.asl_id)
          }
        } else {
          lessons = lessons.map((v, i) => (v.asl_id == modalActivateLesson.asl.asl_id) ? { ...v, is_activated: (thisClass.selected) ? 1 : 0 } : v)
        }
      }
      this.setState({ academicSubjectLessons: { ...this.state.academicSubjectLessons, data: lessons }, modalActivateLesson: { ...modalActivateLesson, show: false, assignedSubjectsArray: [], asl: null } })
      // MB:Ahmad 16092024
      // res.data.data.map((v, i) => {
      //   if (v.user_id) {
      //     sendCloudMessage(v.user_id, v.title, v.body, v.data)
      //   }
      // })
      // SEND NOTIFICATION TO RELATED USERS
      // res.data.sendCloudMessages.map((v, i) => {
      //   if (v.user_id) {
      //     sendCloudMessage(v.user_id, v.title, v.body, v.data)
      //   }
      // })

    } else {
      toast.error(t(res.data.message))
    }
  }

  modalActivateLesson = (data) => {
    if (!this.props.authData.userData.employee_lesson_approve || this.props.authData.userData.employee_lesson_approve == 0) {
      toast.error(t('Action not allowed, Contact Admin'))
      return
    }
    let assignedSubjectsArray = []
    this.props.authData.classes.map((v, i) => {
      v.academic_classmain_subject.map((vv, ii) => {
        if (this.props.selectedSubject.agegroup_id == vv.agegroup_id && this.props.selectedSubject.acs_id == vv.acs_id) {
          assignedSubjectsArray.push({ class: v.class, academic_classmain_subject: vv, selected: (this.props.selectedClass.class.class_id == v.class.class_id) ? true : false })
        }
      })
    })
    this.state.modalActivateLesson.toggle(data, assignedSubjectsArray)
  }


  handleDragFileArea = (e, name) => {
    e.preventDefault()
    e.stopPropagation()

    if (e.type === "dragover") {
      this.state.dragAreaColor.toggleColor(name, brand_colors[this.props.defaultTheme.theme_id].color1)
    } else if (e.type === "dragleave") {
      this.state.dragAreaColor.toggleColor(name, brand_colors[this.props.defaultTheme.theme_id].color18)
    }
  }
  addAttachment = (e) => {
    const selectedFiles = e.target.files
    let allFiles = []
    for (let i = 0; i < selectedFiles.length; i++) {
      allFiles.push(readFile(selectedFiles[i]))
    }
    Promise.all(allFiles).then((values) => {
      let attachments = e.target.multiple ? this.state[e.target.name] : null
      let selectedFiles = values
      selectedFiles.map((v, i) => {
        if (v.data.type) {
          if (e.target.multiple) {
            attachments.push({ url: v.url, data: v.data })
          } else {
            attachments = { url: v.url, data: v.data }
          }
        }
      })
      this.setState({ [e.target.name]: attachments, dragAreaColor: { ...this.state.dragAreaColor, [e.target.name]: brand_colors[this.props.defaultTheme.theme_id].color18 } })
    })
  }
  deleteFolder = (aslf_id) => {
    this.setState({
      deleteModal: {
        ...this.state.deleteModal, show: true, action: () => {
          // DELETE FILE CALL 
          GET('academic-subject-lesson-folders/delete', { params: { aslf_id: aslf_id } }).then((res) => {

            if (res.data.type) {
              let academicSubjectLessonFoldersData = this.state.academicSubjectLessonFoldersData
              academicSubjectLessonFoldersData.data = academicSubjectLessonFoldersData.data.filter((v, i) => {
                if (v.aslf_id != aslf_id) {
                  return true
                }
                return false
              })
              this.setState({
                academicSubjectLessonFoldersData, deleteModal: {
                  ...this.state.deleteModal, show: false
                }
              })
              toast.warning(t(res.data.message));
            } else {
              this.setState({
                deleteModal: {
                  ...this.state.deleteModal, show: false
                }
              })
              toast.error(t(res.data.message));
            }
          })
        }
      }
    })
  }
  deleteFolderAttachment = (field, actionFun = null) => {
    let modalFolder = this.state.modalFolder
    if (modalFolder.data.aslf_id != null && modalFolder.data.aslf_id != 0) {
      this.setState({
        deleteModal: {
          ...this.state.deleteModal, show: true, action: () => {
            // DELETE FILE CALL 
            try {
              GET('academic-subject-lesson-folders/delete-academic-subject-lesson-folders-attachment', {
                params: {
                  key: modalFolder.data.aslf_id,
                  field: field,
                },
              }).then((res) => {
                if (res) {
                  if (actionFun) {
                    actionFun()
                  }
                  modalFolder['data'][field] = null
                  let academicSubjectLessonFoldersData = this.state.academicSubjectLessonFoldersData
                  academicSubjectLessonFoldersData.data = academicSubjectLessonFoldersData.data.map((vv, ii) => {
                    if (vv.aslf_id == modalFolder.data.aslf_id) {
                      vv[field] = null
                    }
                    return vv
                  })
                  this.setState({
                    deleteModal: {
                      ...this.state.deleteModal, show: false
                    },
                    academicSubjectLessonFoldersData,
                    modalFolder,
                    [field]: null,
                  })
                  toast.warning(t('Attachment deleted'))
                } else {
                  toast.error(t('Something went wrong while deleting!'))
                }
              })
            } catch (err) {
              console.log('err', err)
              toast.error(t('Something went wrong while deleting!'))

            }
          }
        }
      })
    } else {
      modalFolder['data'][field] = null
      this.setState({ [field]: null, modalFolder })
      if (actionFun) {
        actionFun()
      }
    }
  }

  makeLessonCopy = () => {
    let { asl_id_copy, modalLessonCopy } = this.state
    if (!asl_id_copy) {
      toast.error(t('Please select a lesson!'));
      return
    }
    let editingData = asl_id_copy
    const { type1Opts, type2Opts, termsDefault } = this.state

    let type1Obj = type1Opts.find((item) => {
      return item.value == editingData.asl_type1
    })

    if (!type1Obj) {
      type1Obj = { value: 'Interdisciplinary', label: 'Interdisciplinary' }
    }

    let type2Obj = type2Opts.find((item) => {
      return item.value == editingData.asl_type2
    })
    if (!type2Obj) {
      type2Obj = { value: 'Standalone', label: 'Standalone' }
    }

    let aslf_id = editingData.aslf_id
    if (aslf_id) {
      let find_aslf_id = this.state.academicSubjectLessonFoldersData.data.find((v, i) => v.aslf_id == aslf_id)
      if (find_aslf_id) {
        aslf_id = { value: find_aslf_id.aslf_id, label: find_aslf_id.aslf_name }
      }
    }

    let editingState = {
      id: null,
      name: editingData.asl_name,
      code: editingData.asl_code,
      url: editingData.asl_link,
      period: editingData.asl_period,
      type1: type1Obj,
      type2: type2Obj,
      term_d_id: editingData.term_d_id,
      theme: editingData.asl_theme,
      chatBlog: parseInt(editingData.asl_chat_blog),
      note1: editingData.asl_notes1 || '',
      image: editingData.asl_attach,
      image_prev: portalURL + this.props.authData.centerData.center_uuid + '/' + imgDir + '/' + editingData.asl_attach,
      aslf_id: aslf_id
    }
    this.setState({
      ...editingState,
      showAddEditForm: true,
      editing: false,
      modalLessonCopy: { ...modalLessonCopy, show: false }
    })

  }


  /**
   * Render Component
   * @returns {HTMLElement}
   */
  render() {
    const { showAddEditForm, working, editing, name, code, url, period, chatBlog, theme, senRequired, ictUsage, afl, type1, type2, note1, note2, note3, image, image_prev, type1Opts, type2Opts, modalActivateLesson, viewType, aslf_id, term_d_id, modalFolder, aslf_image, academicSubjectLessonFoldersData, InterdisciplinaryFoldersData, modalReflections, academicSubjectLessons, termsDefault, modalLessonCopy } = this.state
    const selectedClass = this.props.selectedClass
    const defaultTheme = this.props.defaultTheme
    const selectedSubject = this.props.selectedSubject
    const authData = this.props.authData

    console.log('%cSTATE', 'font-size:20px;font-weight:bold;color:orange');
    console.log(this.state);

    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content'>
            <Header
              lite={true}
              heading={t('Lesson / Units')}
              backBtn={true}
              classSelectorFunc={[this.getLessons, this.getAcademicSubjectLessonFolders, this.getInterdisciplinaryFolders]} subjectSelectorFunc={[this.getLessons, this.getAcademicSubjectLessonFolders, this.getInterdisciplinaryFolders]} showClassSelector={true} showSubjectSelector={true}
            />
            <div className='d-flex justify-content-between px-2'>
              <div className='d-flex align-items-center'>
                <ButtonGroup>
                  {viewType.selected == 'lesson'
                    ? <div className='cb-expandable-search' style={{ width: (academicSubjectLessons.openSearch) ? 178 : 32, borderRadius: 0 }}>
                      <input ref={this.searchFieldRef} type='text' placeholder={t('Search Lesson')}
                        onChange={(e) => this.setState({ academicSubjectLessons: { ...academicSubjectLessons, search: e.target.value } }, () => this.getLessons())}
                        value={academicSubjectLessons.search}
                        style={{ width: academicSubjectLessons.openSearch ? 150 : 0, paddingLeft: academicSubjectLessons.openSearch ? 15 : 0, opacity: academicSubjectLessons.openSearch ? 1 : 0, }} />
                      <Button
                        data-tooltip-id="tooltip"
                        data-tooltip-content={t('Search')}
                        data-tooltip-place="top"
                        title={t('Search')}
                        variant={(academicSubjectLessons.search) ? 'danger' : 'primary'}
                        onClick={() => this.setState({ academicSubjectLessons: { ...academicSubjectLessons, search: '', openSearch: !academicSubjectLessons.openSearch } }, () => this.getLessons())}>
                        <FontAwesomeIcon
                          icon={academicSubjectLessons.openSearch ? faXmark : faSearch}
                          color={brand_colors[defaultTheme.theme_id].color8}
                        />
                      </Button>
                    </div>
                    : <div className='cb-expandable-search' style={{ width: (academicSubjectLessonFoldersData.openSearch) ? 178 : 32, borderRadius: 0 }}>
                      <input ref={this.searchFieldRef} type='text' placeholder={t('Search Folder')}
                        onChange={(e) => this.setState({ academicSubjectLessonFoldersData: { ...academicSubjectLessonFoldersData, search: e.target.value } })}
                        value={academicSubjectLessonFoldersData.search}
                        style={{ width: academicSubjectLessonFoldersData.openSearch ? 150 : 0, paddingLeft: academicSubjectLessonFoldersData.openSearch ? 15 : 0, opacity: academicSubjectLessonFoldersData.openSearch ? 1 : 0, }} />
                      <Button
                        data-tooltip-id="tooltip"
                        data-tooltip-content={t('Search')}
                        data-tooltip-place="top"
                        title={t('Search')}
                        variant={(academicSubjectLessonFoldersData.search) ? 'danger' : 'primary'}
                        onClick={() => this.setState({ academicSubjectLessonFoldersData: { ...academicSubjectLessonFoldersData, search: '', openSearch: !academicSubjectLessonFoldersData.openSearch } })}>
                        <FontAwesomeIcon
                          icon={academicSubjectLessonFoldersData.openSearch ? faXmark : faSearch}
                          color={brand_colors[defaultTheme.theme_id].color8}
                        />
                      </Button>
                    </div>
                  }
                  {(viewType.selected == 'lesson')
                    ? <DropdownButton size='sm' as={ButtonGroup} title={'ORDER BY: ' + academicSubjectLessons.orderName} id="bg-nested-dropdown">
                      <Dropdown.Item onClick={() => academicSubjectLessons.changeOrder('Name ASC', 'academic_subject_lessons.asl_name', 'ASC')}>Name ASC</Dropdown.Item>
                      <Dropdown.Item onClick={() => academicSubjectLessons.changeOrder('Name DESC', 'academic_subject_lessons.asl_name', 'DESC')}>Name DESC</Dropdown.Item>
                      <Dropdown.Item onClick={() => academicSubjectLessons.changeOrder('Date ASC', 'academic_subject_lessons.created_on', 'ASC')}>Date ASC</Dropdown.Item>
                      <Dropdown.Item onClick={() => academicSubjectLessons.changeOrder('Date DESC', 'academic_subject_lessons.created_on', 'DESC')}>Date DESC</Dropdown.Item>
                      <Dropdown.Item onClick={() => academicSubjectLessons.changeOrder('Modified Date ASC', 'academic_subject_lessons.updated_on', 'ASC')}>Modified Date ASC</Dropdown.Item>
                      <Dropdown.Item onClick={() => academicSubjectLessons.changeOrder('Modified Date DESC', 'academic_subject_lessons.updated_on', 'DESC')}>Modified Date DESC</Dropdown.Item>
                    </DropdownButton>
                    : <DropdownButton size='sm' as={ButtonGroup} title={'ORDER BY: ' + academicSubjectLessonFoldersData.orderName} id="bg-nested-dropdown">
                      <Dropdown.Item onClick={() => academicSubjectLessonFoldersData.changeOrder('Name ASC', 'academic_subject_lesson_folders.aslf_name', 'ASC')}>Name ASC</Dropdown.Item>
                      <Dropdown.Item onClick={() => academicSubjectLessonFoldersData.changeOrder('Name DESC', 'academic_subject_lesson_folders.aslf_name', 'DESC')}>Name DESC</Dropdown.Item>
                    </DropdownButton>
                  }
                </ButtonGroup>

                <div className='d-flex flex-row ms-3'>
                  <div style={{ fontWeight: 600, color: (viewType.selected == 'folder') ? brand_colors[defaultTheme.theme_id].color16 : brand_colors[defaultTheme.theme_id].color9 }}>{t('Folder View')}</div>
                  <Form.Check
                    className='mx-2'
                    type="switch"
                    checked={(viewType.selected == 'lesson') ? true : false}
                    onChange={() => viewType.toggleView((viewType.selected == 'lesson') ? 'folder' : 'lesson')}
                  />
                  <div style={{ fontWeight: 600, color: (viewType.selected == 'lesson') ? brand_colors[defaultTheme.theme_id].color4 : brand_colors[defaultTheme.theme_id].color9 }}>{t('Lesson View')}</div>
                </div>

                {viewType.aslf_id &&
                  <div className='ms-3'>
                    <Badge bg="warning" className='cursor-pointer' onClick={() => viewType.toggleView('folder')}>
                      <FontAwesomeIcon icon={faFolder} />
                      <span className='mx-2'>{viewType.aslf_name.toUpperCase()}</span>
                      <FontAwesomeIcon icon={faTimesCircle} />
                    </Badge>
                  </div>
                }
              </div>

              {viewType.selected == 'folder'
                ? <div className='d-flex align-items-center'>
                  <div>
                    {/* {this.props.selectedSubject && ['General', 'UAE MOE'].includes(this.props.selectedSubject.agegroup_type) && */}
                    <Button
                      onClick={() => modalFolder.toggle()}
                      className='btn-sm btn-add'
                      variant='success'>
                      {t('Add Folder')}{' '}
                      <FontAwesomeIcon
                        icon={faPlus}
                        className='ms-1'
                        style={{
                          fontSize: 16,
                        }}
                      />
                    </Button>
                    {/* } */}
                    {/* // MB:AHMAD COMMENTED 16092024 */}
                    {/* {this.props.selectedSubject && ['Primary Year Programme', 'Middle Year Programme', 'Diploma Programme'].includes(this.props.selectedSubject.agegroup_type) &&
                      <Button
                        onClick={() => modalFolder.toggle()}
                        className='btn-sm btn-add'
                        variant='success'>
                        {t('Add Folder with Activity')}{' '}
                        <FontAwesomeIcon
                          icon={faPlus}
                          className='ms-1'
                          style={{
                            fontSize: 16,
                          }}
                        />
                      </Button>
                    } */}
                  </div>
                </div>
                : <div className='d-flex align-items-center'>
                  <div className='custom-styled-form me-3' style={{ width: '150px' }}>
                    <Form.Group >
                      <Form.Label>{t('Term')}</Form.Label>
                      <Select
                        placeholder={t('Default Term')}
                        classNamePrefix='custom-styled-select'
                        value={this.state.term_d_id_filter}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (
                            base
                          ) => ({
                            ...base,
                            zIndex: 9999,
                          }),
                        }}
                        options={[{ label: 'All', value: 'All' }].concat(termsDefault)}
                        onChange={(obj) => {
                          this.setState({ term_d_id_filter: obj }, () => this.getLessons())
                        }}
                      />
                    </Form.Group>
                  </div>

                  <ButtonGroup size='sm' className='me-2'>
                    {this.state.asl_type1_filter.data.map((v, i) => {
                      return <Button variant={(v.value == this.state.asl_type1_filter.selected) ? "success" : "light"} onClick={() => this.state.asl_type1_filter.toggleView(v.value)}>{v.name}</Button>
                    })}
                  </ButtonGroup>



                  {selectedClass &&
                    <Form.Check
                      className='me-3'
                      type="switch"
                      label={t('Active Lessons')}
                      onChange={() => this.setState({ academicSubjectLessons: { ...academicSubjectLessons, active_lessons: !academicSubjectLessons.active_lessons } }, () => this.getLessons())}
                    />
                  }
                  <div>
                    {this.props.selectedSubject &&
                      <Button
                        onClick={() =>
                          this.handleAddEditFormOpen()
                        }
                        className='btn-sm btn-add'
                        variant='success'>
                        {t('Add Lesson')}{' '}
                        <FontAwesomeIcon
                          icon={faPlus}
                          className='ms-1'
                          style={{
                            fontSize: 16,
                          }}
                        />
                      </Button>
                    }
                    {this.props.selectedSubject &&
                      <Button
                        onClick={() =>
                          modalLessonCopy.toggle()
                        }
                        className='ms-1 btn-sm btn-add'
                        variant='info'>
                        {t('Make Lesson Copy')}{' '}
                        <FontAwesomeIcon
                          icon={faCopy}
                          className='ms-1'
                          style={{
                            fontSize: 16,
                          }}
                        />
                      </Button>
                    }
                  </div>
                </div>
              }

            </div>
            {viewType.selected == 'lesson' &&
              <Row className='pb-3'>
                {academicSubjectLessons.refreshing && !academicSubjectLessons.finished &&
                  <Col
                    md={12}
                    className='pt-5 pb-0 d-flex align-items-center flex-column'>
                    <Spinner
                      as='span'
                      animation='grow'
                      size='lg'
                      role='status'
                      aria-hidden='true'
                      className='mb-1'
                    />
                    {t('Loading Lessons...')}
                  </Col>
                }
                {!academicSubjectLessons.refreshing && academicSubjectLessons.finished && academicSubjectLessons.data.length <= 0 &&
                  <Col
                    md={12}
                    className='pt-5 pb-4 pb-0 d-flex align-items-center flex-column'>
                    {t('No Lessons Found')}
                  </Col>

                }

                {academicSubjectLessons.data.map((v, i) => {

                  if (v.term_d_id != null && !v.term_d_id.value) {
                    v.term_d_id = termsDefault.find((item) => {
                      return item.value == v.term_d_id
                    })
                  }

                  let extraTexts = []
                  if (v.activation_date) {
                    extraTexts.push({ type: 'activation', name: 'Activation', value: v.activation_date, icon: faCalendarAlt, image: null, iconColor: changeColorOpacity(brand_colors[this.props.defaultTheme.theme_id].color4, 0.5), onClick: () => { } })
                  }
                  extraTexts.push({ type: 'url', name: 'URL', value: v.asl_link, icon: null, image: require('../../assets/images/hyperlink.png'), iconColor: brand_colors[this.props.defaultTheme.theme_id].color9, onClick: () => { } })
                  extraTexts.push({
                    type: 'text', name: 'Type 1', value: (v.asl_type1 == 'Interdisciplinary')
                      ? <span>{v.asl_type1}<div className='badge badge-light-success ms-1'>{v.acs_name}</div></span>
                      : v.asl_type1,
                    icon: null, image: require('../../assets/images/asterisk.png'), iconColor: brand_colors[this.props.defaultTheme.theme_id].color9, onClick: () => { }
                  })
                  if (v.asl_acs_ids_data.length > 0) {
                    extraTexts.push({
                      type: 'text', name: 'Interdisciplinary Subjects', value: v.asl_acs_ids_data.map((vv, ii) => {
                        if (vv.acs_id != v.acs_id) {
                          return <div className='badge badge-light-warning me-1 mb-1'>{vv.acs_name}</div>
                        }
                      }), icon: null, image: require('../../assets/images/asterisk.png'), iconColor: brand_colors[this.props.defaultTheme.theme_id].color9, onClick: () => { }
                    })
                  }
                  extraTexts.push({ type: 'text', name: 'Type 2', value: v.asl_type2, icon: null, image: require('../../assets/images/asterisk.png'), iconColor: brand_colors[this.props.defaultTheme.theme_id].color9, onClick: () => { } })
                  extraTexts.push({ type: 'text', name: 'Chat Blog', value: (v.asl_chat_blog == 1) ? 'Yes' : 'No', icon: faComments, image: null, iconColor: brand_colors[this.props.defaultTheme.theme_id].color9, onClick: () => { } })

                  let data = {
                    allData: v,
                    name: v.asl_name,
                    text2: v.asl_code,
                    status: (v.is_activated == 1) ? { name: 'Active', bg: 'success' } : { name: 'Not Active', bg: 'danger' },
                    description: (v.asl_notes1 != null && v.asl_notes1 != 'null' && v.asl_notes1 != '') ? v.asl_notes1 : null,
                    linkTo: '/subject/lesson/planner',
                    linkToStates: { lessonData: v },
                    cardImage: (v.asl_attach) ? portalURL + this.props.authData.centerData.center_uuid + '/academic_subject_lessons/' + v.asl_attach : require('../../assets/images/noimage.png'),
                    actions: [
                      {
                        type: 'edit', name: 'Edit', image: require('../../assets/images/edit.png'), iconName: null, iconColor: brand_colors[this.props.defaultTheme.theme_id].color16, onClick: () => this.handleAddEditFormOpen(true, v)
                      },
                      {
                        type: 'delete', name: 'Delete', image: require('../../assets/images/remove-red.png'), iconName: null, iconColor: brand_colors[this.props.defaultTheme.theme_id].color11, onClick: () => this.delete(v.asl_id)
                      },
                      {
                        type: 'activate', name: (v.is_activated == 0 || v.is_activated == null) ? 'Activate' : 'De-Activate', image: null, iconName: v.is_activated == 0 || v.is_activated == null ? faThumbsUp : faThumbsDown, iconColor: v.is_activated == 0 || v.is_activated == null ? brand_colors[this.props.defaultTheme.theme_id].color13 : brand_colors[this.props.defaultTheme.theme_id].color11, onClick: () => this.modalActivateLesson(v)//this.updateLessonStatus(v, i)
                      },
                      {
                        type: 'user_reflection', name: 'Reflections', image: null, iconName: faPlus, iconColor: brand_colors[this.props.defaultTheme.theme_id].color16, onClick: () => modalReflections.toggle(v.asl_name, v)
                      },
                      {
                        type: 'sub_lessons', name: 'Sub-Lessons', image: null, iconName: faBarsStaggered, iconColor: brand_colors[this.props.defaultTheme.theme_id].color1, onClick: () => {
                          this.props.setSubLessonModal({ showModal: true, asl_id: v.asl_id })
                        }
                      },
                      {
                        type: 'lesson_preview', name: 'Preview', image: null, iconName: faEye, iconColor: brand_colors[this.props.defaultTheme.theme_id].color16, onClick: () => null
                      },
                    ],
                    extraTexts: extraTexts
                  }
                  return <GeneralCard data={data} key={i} />
                })}
                <Paginate limit={academicSubjectLessons.limit} totalRecords={academicSubjectLessons.total_count_filtered} pageChange={this.getLessons} />
              </Row>
            }


            {viewType.selected == 'folder' &&
              <div>
                {/* SUBJECT FOLDERS */}
                <Row className='pb-3'>
                  {academicSubjectLessonFoldersData.data.length <= 0 &&
                    <Alert variant='warning' className='mt-2'>
                      {t('No folder has been created, Please create the folder.')}
                    </Alert>
                  }
                  {academicSubjectLessonFoldersData.data.map((v, i) => {
                    if (!((v.aslf_name.toLowerCase()).includes(academicSubjectLessonFoldersData.search.toLowerCase()))) {
                      return null
                    }

                    let aslf_actions = [{
                      type: 'edit', name: 'Edit', image: require('../../assets/images/edit.png'), iconName: null, iconColor: brand_colors[this.props.defaultTheme.theme_id].color16, onClick: () => this.setState({
                        modalFolder: { ...modalFolder, show: true, title: 'Edit Folder', data: { ...v } },
                        aslf_image: (v.aslf_image) ? { url: portalURL + this.props.authData.centerData.center_uuid + '/academic_subject_lesson_folders/' + v.aslf_image, data: { type: getExtension(v.aslf_image) } } : null,
                      })
                    },
                    { type: 'delete', name: 'Delete', image: require('../../assets/images/remove-red.png'), iconName: null, iconColor: brand_colors[this.props.defaultTheme.theme_id].color11, onClick: () => this.deleteFolder(v.aslf_id) }]

                    // MB:AHMAD COMMENTED 16092024
                    // if (v.aa_id) {
                    //   aslf_actions.push({ type: 'redirect', name: 'Edit Activity', image: null, iconName: faLink, iconColor: brand_colors[this.props.defaultTheme.theme_id].color11, onClick: () => window.open(window.location.origin + "/activities?aa_id=" + v.aa_id) })
                    // }

                    let data = {
                      allData: v,
                      name: v.aslf_name,
                      cardImage: (v.aslf_image) ? portalURL + this.props.authData.centerData.center_uuid + '/academic_subject_lesson_folders/' + v.aslf_image : (selectedSubject.acs_image) ? portalURL + this.props.authData.centerData.center_uuid + '/academic_classmain_subject/' + selectedSubject.acs_image : require('../../assets/images/folder.png'),
                      onClick: () => viewType.toggleView('lesson', v.aslf_id, v.aslf_name),
                      actions: aslf_actions
                    }
                    return <GeneralCard data={data} key={i} />
                  })}
                </Row>
                {/* INTERDISCIPLINARY FOLDERS */}
                <Row className='pb-3 border-top'>
                  <div className='my-2' style={{ fontSize: 16, fontWeight: 'bold' }}>Interdisciplinary Folders</div>
                  {InterdisciplinaryFoldersData.data.length <= 0 &&
                    <Alert variant='warning' className='mt-2'>
                      {t('No interdisciplinary lesson found with a folder.')}
                    </Alert>
                  }
                  {InterdisciplinaryFoldersData.data.map((v, i) => {
                    if (!((v.aslf_name.toLowerCase()).includes(InterdisciplinaryFoldersData.search.toLowerCase()))) {
                      return null
                    }

                    let aslf_actions = [{
                      type: 'edit', name: 'Edit', image: require('../../assets/images/edit.png'), iconName: null, iconColor: brand_colors[this.props.defaultTheme.theme_id].color16, onClick: () => this.setState({
                        modalFolder: { ...modalFolder, show: true, title: 'Edit Folder', data: { ...v } },
                        aslf_image: (v.aslf_image) ? { url: portalURL + this.props.authData.centerData.center_uuid + '/academic_subject_lesson_folders/' + v.aslf_image, data: { type: getExtension(v.aslf_image) } } : null,
                      })
                    },
                    { type: 'delete', name: 'Delete', image: require('../../assets/images/remove-red.png'), iconName: null, iconColor: brand_colors[this.props.defaultTheme.theme_id].color11, onClick: () => this.deleteFolder(v.aslf_id) }]

                    let data = {
                      allData: v,
                      name: v.aslf_name,
                      cardImage: (v.aslf_image) ? portalURL + this.props.authData.centerData.center_uuid + '/academic_subject_lesson_folders/' + v.aslf_image : (selectedSubject.acs_image) ? portalURL + this.props.authData.centerData.center_uuid + '/academic_classmain_subject/' + selectedSubject.acs_image : require('../../assets/images/folder.png'),
                      onClick: () => viewType.toggleView('lesson', v.aslf_id, v.aslf_name),
                      actions: aslf_actions
                    }
                    return <GeneralCard data={data} key={i} />
                  })}
                </Row>

              </div>
            }

          </div>
        </div>

        <Modal
          backdrop="static"
          keyboard={false}
          size='lg'
          show={showAddEditForm}
          onHide={this.handleAddEditFormClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              {editing ? t('Update Lesson') : t('New Lesson')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='custom-styled-form'>
              <Row>
                <Col md={4}>
                  <Form.Group className='mb-3'>
                    <Form.Label>
                      <img src={imgAddButton} />
                      {t('Name')}
                    </Form.Label>
                    <Form.Control
                      type='text'
                      defaultValue={name}
                      onBlur={(event) =>
                        setTxtField(
                          'name',
                          event.target.value,
                          this
                        )
                      }
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className='mb-3'>
                    <Form.Label>
                      <img src={imgLabelTag} />
                      {t('Code')}
                    </Form.Label>
                    <Form.Control
                      type='text'
                      onBlur={(event) =>
                        setTxtField(
                          'code',
                          event.target.value,
                          this
                        )
                      }
                      defaultValue={code}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className='mb-3'>
                    <Form.Label>
                      <img src={imgHyperlink} />
                      {t('URL')}
                    </Form.Label>
                    <Form.Control
                      type='text'
                      onBlur={(event) =>
                        setTxtField(
                          'url',
                          event.target.value,
                          this
                        )
                      }
                      defaultValue={url}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className='mb-3'>
                    <Form.Label>
                      <img src={imgCalendar} />
                      {t('Period')}
                    </Form.Label>
                    <Form.Control
                      type='number'
                      onBlur={(event) =>
                        setTxtField(
                          'period',
                          event.target.value,
                          this
                        )
                      }
                      defaultValue={period}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className='mb-3'>
                    <Form.Label>{t('Theme')}</Form.Label>
                    <Form.Control
                      type='text'
                      onBlur={(event) =>
                        setTxtField(
                          'theme',
                          event.target.value,
                          this
                        )
                      }
                      defaultValue={theme}
                    />
                  </Form.Group>
                </Col>
                <Col md={2} className='d-none'>
                  <Form.Group className='mb-3 form-switch-wrap'>
                    <Form.Label>
                      {t('Chat Blog')}
                    </Form.Label>
                    <Form.Check
                      className='mt-2'
                      type='switch'
                      id='chatBlog'
                      value={1}
                      checked={
                        chatBlog == 1 ? true : false
                      }
                      onChange={() =>
                        setSwitchField(
                          'chatBlog',
                          chatBlog,
                          this
                        )
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Form.Group className='mb-3'>
                    <Form.Label>{t('Folder')}</Form.Label>
                    <Select isClearable
                      placeholder={t('Select folder')}
                      value={aslf_id}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (
                          base
                        ) => ({
                          ...base,
                          zIndex: 9999,
                        }),
                      }}
                      options={academicSubjectLessonFoldersData.data.map((v, i) => { return { label: v.aslf_name, value: v.aslf_id } })}
                      onChange={(obj) => {
                        setTxtField(
                          'aslf_id',
                          obj,
                          this
                        )
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group className='mb-3'>
                    <Form.Label>{t('Default Term')}</Form.Label>
                    <Select isClearable
                      placeholder={t('Select Default Term')}
                      value={term_d_id}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (
                          base
                        ) => ({
                          ...base,
                          zIndex: 9999,
                        }),
                      }}
                      options={termsDefault}
                      onChange={(obj) => {
                        setTxtField(
                          'term_d_id',
                          obj,
                          this
                        )
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                {/* <Col md={4}>
                                    <Form.Group className='mb-3'>
                                        <Form.Label>
                                            {t('SEN Required')}
                                        </Form.Label>
                                        <Form.Control
                                            type='text'
                                            onChange={(event) =>
                                                setTxtField(
                                                    'senRequired',
                                                    event.target.value,
                                                    this
                                                )
                                            }
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group className='mb-3'>
                                        <Form.Label>
                                            {t('ICT Usage')}
                                        </Form.Label>
                                        <Form.Control
                                            type='text'
                                            onChange={(event) =>
                                                setTxtField(
                                                    'ictUsage',
                                                    event.target.value,
                                                    this
                                                )
                                            }
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group className='mb-3'>
                                        <Form.Label>{t('AFL')}</Form.Label>
                                        <Form.Control
                                            type='text'
                                            onChange={(event) =>
                                                setTxtField(
                                                    'ictUsage',
                                                    event.target.value,
                                                    this
                                                )
                                            }
                                        />
                                    </Form.Group>
                                </Col> */}
                <Col md={6} className="mb-3 d-none">
                  <label className='no-style'>{t('Type 1')}</label>
                  <div key="radio_type1">
                    {type1Opts.map((v, i) => {
                      return <Form.Check type={'radio'} id={`radio_type1_${v.value}`} inline key={i} >
                        <Form.Check.Input type={'radio'} value={v.value} name="radio_type1" onChange={(e) => setTxtField(
                          'type1',
                          e.target,
                          this
                        )} checked={(type1.value == v.value) ? true : false} />
                        <Form.Check.Label className='no-style'>{t(v.label)}</Form.Check.Label>
                        {/* <Form.Control.Feedback type="valid">
                                                    You did it!
                                                </Form.Control.Feedback> */}
                      </Form.Check>
                    })}
                  </div>
                </Col>
                <Col md={6} className="mb-3 d-none">
                  <label className='no-style'>{t('Type 2')}</label>
                  <div key="radio_type2">
                    {type2Opts.map((v, i) => {
                      return <Form.Check type={'radio'} id={`radio_type2_${v.value}`} key={i} inline>
                        <Form.Check.Input type={'radio'} value={v.value} name="radio_type2" onChange={(e) => setTxtField(
                          'type2',
                          e.target,
                          this
                        )} checked={(type2.value == v.value) ? true : false} />
                        <Form.Check.Label className='no-style'>{t(v.label)}</Form.Check.Label>
                        {/* <Form.Control.Feedback type="valid">
                                                    You did it!
                                                </Form.Control.Feedback> */}
                      </Form.Check>
                    })}
                  </div>
                </Col>


                {/* <Col md={6}>
                                    <Form.Group className='mb-3'>
                                        <Form.Label>{t('Type 1')}</Form.Label>
                                        <Select
                                            className='custom-select'
                                            options={type1Opts}
                                            onChange={(type1Obj) =>
                                                setTxtField(
                                                    'type1',
                                                    type1Obj,
                                                    this
                                                )
                                            }
                                            value={type1}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className='mb-3'>
                                        <Form.Label>{t('Type 2')}</Form.Label>
                                        <Select
                                            className='custom-select'
                                            options={type2Opts}
                                            onChange={(type2Obj) =>
                                                setTxtField(
                                                    'type2',
                                                    type2Obj,
                                                    this
                                                )
                                            }
                                            value={type2}
                                        />
                                    </Form.Group>
                                </Col> */}

                <Col md={6}>
                  <Form.Group controlId='file3' className='mb-3'>
                    <Form.Label>
                      <img src={imgAttachment} />
                      {t('Image')}
                    </Form.Label>
                    <div style={{ flexDirection: 'column', }} className='pic-prev d-flex justify-content-center'>
                      <div style={{ borderRadius: 5, borderStyle: 'solid', borderWidth: 1, borderColor: '#ced4da', height: 158, display: 'flex', overflow: 'hidden', }}>
                        {(image !== '' && image_prev !== '') ? (
                          <div className='d-flex justify-content-center align-items-center' style={{ flex: 1, position: 'relative', }}>
                            <button style={{ padding: 3, border: 3, background: 'none', position: 'absolute', top: 0, right: 0, }}
                              onClick={() => {
                                this.setState({
                                  image: '',
                                  image_prev:
                                    '',
                                  image_deleted: 1,
                                })
                                this.image.current.value =
                                  null
                              }}>
                              <FontAwesomeIcon
                                icon={
                                  faTimesCircle
                                }
                                color='rgb(220, 53, 69)'
                                style={{
                                  fontSize: 20,
                                }}
                              />
                            </button>
                            <img
                              src={image_prev}
                              alt={t('Preview')}
                              width='100'
                            />
                          </div>
                        ) : (
                          <button
                            onClick={() =>
                              this.image.current.click()
                            }
                            style={{
                              background: 'none',
                              border: 0,
                              flex: 1,
                            }}>
                            <FontAwesomeIcon
                              className='file-selector'
                              icon={faPlus}
                              style={{
                                fontSize: 30,
                              }}
                            />
                          </button>
                        )}
                      </div>
                      <Form.Control
                        className='d-none'
                        ref={this.image}
                        type='file'
                        onChange={this.previewFile}
                        name='image'
                        accept='.jpg, .jpeg, .png'
                      />
                    </div>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className='mb-3'>
                    <Form.Label>
                      <img src={imgDescription} />
                      {t('Central Idea')}
                    </Form.Label>
                    <Form.Control
                      as='textarea'
                      rows={6}
                      onBlur={(event) =>
                        setTxtField(
                          'note1',
                          event.target.value,
                          this
                        )
                      }
                      defaultValue={note1}
                    />
                  </Form.Group>
                </Col>
                {/* <Col md={6}>
                                    <Form.Group className='mb-3'>
                                        <Form.Label>
                                            <img src={imgDescription} />
                                            {t('Note 2')}
                                        </Form.Label>
                                        <Form.Control
                                            as='textarea'
                                            rows={6}
                                            onChange={(event) =>
                                                setTxtField(
                                                    'note2',
                                                    event.target.value,
                                                    this
                                                )
                                            }
                                            value={note2}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className='mb-3'>
                                        <Form.Label>
                                            <img src={imgDescription} />
                                            {t('Note 3')}
                                        </Form.Label>
                                        <Form.Control
                                            as='textarea'
                                            rows={6}
                                            onChange={(event) =>
                                                setTxtField(
                                                    'note3',
                                                    event.target.value,
                                                    this
                                                )
                                            }
                                            value={note3}
                                        />
                                    </Form.Group>
                                </Col> */}
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='secondary'
              onClick={this.handleAddEditFormClose}>
              {t('Close')}
            </Button>
            <Button
              disabled={working}
              variant='primary'
              onClick={editing ? this.update : this.insert}>
              {editing ? t('Update Lesson') : t('Add Lesson')}{' '}
              {(working) && (
                <Spinner
                  as='span'
                  animation='grow'
                  size='sm'
                  role='status'
                  aria-hidden='true'
                />
              )}
            </Button>
          </Modal.Footer>
        </Modal>

        {/* ACTIVATE LESSON POPUP */}
        <Modal size='lg' show={modalActivateLesson.show} onHide={modalActivateLesson.toggle} backdrop="static"
          keyboard={false}>
          <Modal.Header closeButton>
            <Modal.Title>
              <div className='d-flex flex-row align-items-center'>
                {modalActivateLesson.asl &&
                  <div className='ms-2' style={{ opacity: 0.8 }}>
                    {modalActivateLesson.asl.asl_name}
                  </div>
                }
              </div>
            </Modal.Title>
          </Modal.Header>
          {modalActivateLesson.show &&
            <Modal.Body>
              <div className='d-flex lfex-row justify-content-between'>
                <div className='mb-3' style={{ fontSize: 20, fontWeight: '600', opacity: 0.8 }}>{(modalActivateLesson.asl.is_activated == 0 || modalActivateLesson.asl.is_activated == null) ? t('Activate Lesson for') : t('De-Activate Lesson for')}</div>
              </div>

              <div className='d-flex flex-column'>
                {modalActivateLesson.assignedSubjectsArray.map((v, i) => {
                  return <div className='p-1'>
                    <Form.Check type="switch" label={(v.selected) ? v.class.class_theme + ' | ' + t('Activate') : v.class.class_theme + ' | ' + t('De-Activate')} checked={v.selected} onChange={() => {
                      modalActivateLesson.assignedSubjectsArray[i].selected = !modalActivateLesson.assignedSubjectsArray[i].selected
                      this.setState({ modalActivateLesson })
                    }} />
                  </div>
                })}
              </div>
            </Modal.Body>
          }
          {modalActivateLesson.show &&
            <Modal.Footer>
              <Button variant='success' onClick={() => this.updateLessonStatusMultiple()}>{t('Update')}</Button>
            </Modal.Footer>
          }
        </Modal>


        {/* modalLessonCopy */}
        <Modal size='lg' show={modalLessonCopy.show} onHide={modalLessonCopy.toggle} backdrop="static"
          keyboard={false}>
          <Modal.Header closeButton>
            <Modal.Title>
              <div className='d-flex flex-row align-items-center'>
                <div className='ms-2' style={{ opacity: 0.8 }}>
                  {t('Copy Lesson')}
                </div>
              </div>
            </Modal.Title>
          </Modal.Header>
          {modalLessonCopy.show &&
            <Modal.Body className='custom-styled-form'>

              <div>
                <Form.Group >
                  <Form.Label>{t('Choose Lesson to Start Copy')}</Form.Label>
                  <Select
                    placeholder={t('Choose Lesson')}
                    classNamePrefix='custom-styled-select'
                    value={this.state.asl_id_copy}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (
                        base
                      ) => ({
                        ...base,
                        zIndex: 9999,
                      }),
                    }}
                    options={academicSubjectLessons.data.map((v, i) => { return { ...v, label: v.asl_name, value: v.asl_id } })}
                    onChange={(obj) => {
                      this.setState({ asl_id_copy: obj })
                    }}
                  />
                </Form.Group>
              </div>
              <Button variant="primary" className='btn-sm btn-add mt-2' onClick={() => this.makeLessonCopy()}>
                {t('Start Copy')}{' '}
                <FontAwesomeIcon
                  icon={faCopy}
                  color={brand_colors[defaultTheme.theme_id].color8}
                  style={{
                    fontSize: 16,
                  }}
                />
              </Button>
            </Modal.Body>
          }
        </Modal>
        {/* USER REFLECTIONS POPUP */}
        <Modal size='lg' show={modalReflections.show} onHide={modalReflections.toggle} backdrop="static"
          keyboard={false}>
          <Modal.Header closeButton>
            <Modal.Title>
              <div className='d-flex flex-row align-items-center'>
                <div className='ms-2' style={{ opacity: 0.8 }}>
                  {modalReflections.title}
                </div>
              </div>
            </Modal.Title>
          </Modal.Header>
          {modalReflections.show &&
            <Modal.Body className='custom-styled-form'>
              <Alert variant='info'>{t('Reflections can be viewed/added by any users assigned for the same subject.')}</Alert>
              {/* ADD EDIT */}
              {modalReflections.edit &&
                <div className='custom-styled-form my-2' style={{ borderBottom: '#b7b7b7 1px solid' }}>
                  <div className='d-flex flex-row align-items-center'>
                  </div>
                  <Row>
                    <Col md="8">
                      <Row>
                        <Col md="12">
                          <Form.Group className='mb-3'>
                            <Form.Label>
                              <img src={imgDescription} />
                              {t('Title')}
                            </Form.Label>
                            <Form.Control type='text' defaultValue={modalReflections.editData.aslr_title} onBlur={(event) => modalReflections.setData('aslr_title', event.target.value)} />
                          </Form.Group>
                        </Col>
                        <Col md="12">
                          <Form.Group className='mb-3'>
                            <Form.Label>
                              <img src={imgDescription} />
                              {t('Description')} (Max: 500 characters)
                            </Form.Label>
                            < Form.Control as="textarea" rows={3} defaultValue={modalReflections.editData.aslr_desc} onBlur={(event) => modalReflections.setData('aslr_desc', event.target.value)} />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              }
              <div className='grey-section-header mt-2 flex-row align-items-center justify-content-between'>
                <div className='d-flex flex-row align-items-center'>
                  {!modalReflections.edit &&
                    <Button
                      onClick={() => modalReflections.setAllData({
                        aslr_id: 0,
                        aslr_title: '',
                        aslr_desc: '',
                        ab_id: modalReflections.asl.ab_id,
                        as_id: modalReflections.asl.as_id,
                        acs_id: modalReflections.asl.acs_id,
                        asl_id: modalReflections.asl.asl_id
                      })}
                      className='btn-sm btn-add'
                      variant='success'>
                      {t('Add Reflection')}{' '}
                      <FontAwesomeIcon
                        icon={faPlus}
                        color={brand_colors[defaultTheme.theme_id].color8}
                        style={{
                          fontSize: 16,
                        }}
                      />
                    </Button>
                  }
                  {modalReflections.edit &&
                    <Button variant="primary" className='btn-sm btn-add me-1' onClick={modalReflections.save} disabled={modalReflections.saving}>
                      {t('Save Reflection')}{' '}
                      <FontAwesomeIcon
                        icon={faSave}
                        color={brand_colors[defaultTheme.theme_id].color8}
                        style={{
                          fontSize: 16,
                        }}
                      />
                    </Button>
                  }
                  {modalReflections.edit &&
                    <Button variant="warning" className='btn-sm btn-add' onClick={() => this.setState({ modalReflections: { ...modalReflections, edit: false, editData: {} } })}>
                      {t('Cancel')}
                    </Button>
                  }
                </div>
              </div>
              <div>
                {/* LIST */}
                {modalReflections.data.map((v, i) => {
                  return <div key={i} className='scroll-list-card' style={{ backgroundColor: brand_colors[defaultTheme.theme_id].color7 }}>
                    <div className='d-flex flex-row justify-content-between align-items-center p-0 m-0' style={{ flex: 1 }}>
                      {v.created_by == authData.loginData.user_id &&
                        <div className='me-3'>
                          <div className='d-flex flex-row align-items-center'>
                            {/* EDIT */}
                            <FontAwesomeIcon icon={faEdit} color={brand_colors[defaultTheme.theme_id].color16} className='cursor-pointer'
                              onClick={() => this.setState({ modalReflections: { ...modalReflections, edit: false, editData: {} } }, () => modalReflections.setAllData(v))} title={t('Edit')} />
                            {/* DELETE */}
                            <FontAwesomeIcon icon={faTrashCan} color={brand_colors[defaultTheme.theme_id].color11} className='cursor-pointer ms-3' onClick={() => this.deleteLessonResource(v.aslr_id)} title={t('Delete')} />
                          </div>
                        </div>
                      }
                      <div className='d-flex flex-row align-items-center' style={{ flex: 1, }}>
                        <div style={{ flex: 1, fontWeight: '600' }} className='mx-1'>{v.aslr_title}</div>
                        <div style={{ flex: 2 }}>{v.aslr_desc}</div>
                        <div className='mx-1 d-flex flex-column'>
                          <Badge bg="info">{v.full_name_1}</Badge>
                          <Badge bg="info">{v.created_on}</Badge>
                        </div>
                        <div className=' d-flex flex-column'>
                          <Badge bg="warning">{v.full_name_2}</Badge>
                          <Badge bg="warning">{v.updated_on}</Badge>
                        </div>
                      </div>
                    </div>
                  </div>
                })}
                <div>
                  {modalReflections.data.length <= 0 &&
                    <div className='d-flex mt-2 align-items-center justify-content-center'>
                      <div style={{ color: brand_colors[defaultTheme.theme_id].color18 }}>{t('NO DATA FOUND')}...</div>
                    </div>
                  }
                </div>
              </div>
            </Modal.Body>
          }
        </Modal>

        {/* MODAL NEW/EDIT FOLDER */}
        <Modal show={modalFolder.show} onHide={() => modalFolder.toggle()} size="lg" backdrop="static"
          keyboard={false}>
          <Modal.Header closeButton>
            <Modal.Title>{modalFolder.title}</Modal.Title>
          </Modal.Header>
          {modalFolder.show &&
            <Modal.Body className='custom-styled-form'>
              <Row>
                <Col md="6">
                  <Row>
                    <Col md="12">
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          <img src={imgDescription} />
                          {t('Name')}
                        </Form.Label>
                        <Form.Control type='text' defaultValue={modalFolder.data.aslf_name} onBlur={(event) => modalFolder.setData('aslf_name', event.target.value)} />
                      </Form.Group>
                    </Col>
                  </Row>

                  {this.props.selectedSubject && ['Primary Year Programme', 'Middle Year Programme', 'Diploma Programme'].includes(this.props.selectedSubject.agegroup_type) &&
                    <Row>
                      <Col md="12">
                        <Alert>
                          {t('It will automatically create and link an Activity with this folder')}
                        </Alert>
                      </Col>
                    </Row>
                  }



                </Col>
                <Col md="6">
                  <div style={{ flexDirection: 'column' }} className='pic-prev d-flex justify-content-center mb-3'>
                    <div style={{ height: 175, padding: 10, overflow: 'hidden', borderStyle: 'dashed', borderColor: this.state.dragAreaColor.aslf_image, borderWidth: 2, borderRadius: 20, position: 'relative' }} className='d-flex justify-content-center align-items-center'
                      onDragEnter={(e) => this.handleDragFileArea(e, 'aslf_image')} onDragLeave={(e) => this.handleDragFileArea(e, 'aslf_image')} onDragOver={(e) => this.handleDragFileArea(e, 'aslf_image')} onDrop={(e) => {

                        e.preventDefault()
                        e.stopPropagation()
                        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
                          this.addAttachment({ target: { files: e.dataTransfer.files, multiple: false, name: 'aslf_image' } })
                        }
                      }}>
                      {aslf_image && (!aslf_image.data.type.includes('pdf')) && (
                        <img src={aslf_image.url} alt={t('Preview')} style={{ height: '100%' }} />
                      )}
                      {aslf_image == null &&
                        <button onClick={() => this.aslf_image_ref.current.click()} style={{ background: 'none', border: 0, flex: 1 }}>
                          {/* <FontAwesomeIcon icon={faPlus} style={{ fontSize: 30, color: color18 }} /> */}
                          <span style={{ fontSize: 20, fontWeight: 'bold', color: brand_colors[this.props.defaultTheme.theme_id].color18 }}>{t('Image')}</span>
                        </button>
                      }

                      {aslf_image && (
                        <FontAwesomeIcon
                          icon={faTrashCan}
                          style={{ fontSize: 14, color: brand_colors[this.props.defaultTheme.theme_id].color11, position: 'absolute', bottom: 6, right: 6, cursor: 'pointer', backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, borderRadius: 100, padding: 3 }}
                          onClick={() => this.deleteFolderAttachment('aslf_image')}
                        />
                      )}
                    </div>
                    <Form.Control className='d-none' ref={this.aslf_image_ref} type='file' onChange={this.addAttachment} name='aslf_image' />
                  </div>
                </Col>
              </Row>
            </Modal.Body>
          }
          <Modal.Footer>
            <Button variant="secondary" onClick={() => modalFolder.toggle()}>
              Close
            </Button>
            <Button variant="primary" onClick={modalFolder.save}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>

        <AddEditSubLesson />

        <SweetAlert
          show={this.state.deleteModal.show}
          warning
          showCancel
          confirmBtnText={t('Yes, delete it!')}
          confirmBtnBsStyle='danger'
          title={(this.state.deleteModal.title) ? this.state.deleteModal.title : t('Are you sure?')}
          onConfirm={this.state.deleteModal.action}
          onCancel={() => this.setState({ deleteModal: { ...this.state.deleteModal, show: false, id: 0, action: () => { } } })}
          focusCancelBtn
        >
          {(this.state.deleteModal.desc) ? this.state.deleteModal.desc : t('Confirm Delete!')}
        </SweetAlert>
      </Container >
    )
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  selectedClass: state.selectedClass.data,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
  selectedSubject: state.selectedClass.subject,
  subLessonModal: state.subLessonModal,
});

const mapDispatchToProps = () => ({
  setSubLessonModal,
});

export default connect(mapStateToProps, mapDispatchToProps())(withParams(Lessons))
