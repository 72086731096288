import React, { Component } from 'react';
import { Container, Card, Table, Row, Col, Spinner, Image, Badge, Button, Form, Offcanvas, Dropdown, Modal, ButtonGroup, DropdownButton, } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faAnglesLeft, faAnglesRight, faCalendarAlt, faCheck, faCheckCircle, faCheckDouble, faClock, faEdit, faEllipsisV, faExternalLink, faFileAlt, faFilterCircleXmark, faGear, faGripVertical, faPaperclip, faPlus, faSearch, faTimes, faTrashAlt, faTrashCan, faXmark, } from '@fortawesome/free-solid-svg-icons';
import { Scrollbar } from 'react-scrollbars-custom';
import imgDescription from '../../assets/images/description.png';
import { brand_colors } from '../../helpers/brand_colors_helper';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Link } from 'react-router-dom';
import { faCircle, faThumbsDown, faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import { handleFormErrors, readFile } from '../../helpers/form_helpers';
import { getExtension } from '../../helpers/general_helpers';
import { colorPalette } from '../../constants/colorsPalette';
import { GET } from '../../api';
import Select from 'react-select';

// API URL
const apiURL = process.env.REACT_APP_API_URL;

// Portal URL
const portalURL = process.env.REACT_APP_PORTAL_URL;

class LearnAspect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      deleteModal: { show: false, id: 0, action: () => { }, title: null, desc: null },
      learnAspectData: {
        openSearch: false,
        toggleSearch: () => this.setState({ learnAspectData: { ...this.state.learnAspectData, openSearch: !this.state.learnAspectData.openSearch, search: '' } }, () => {
          if (!this.state.learnAspectData.openSearch) {
            this.getLearnAspect()
          }
        }),
        changeSearch: (search = '') => this.setState({ learnAspectData: { ...this.state.learnAspectData, search } }, () => this.getLearnAspect()),
        search: '',
        order: 'lrnaspect.lrnaspect_id',
        dir: 'DESC',
        orderName: 'Newest First',
        changeOrder: (orderName = 'Newest First', order = 'lrnaspect.lrnaspect_id', dir = 'DESC') => this.setState({ learnAspectData: { ...this.state.learnAspectData, orderName, order, dir } }, () => this.getLearnAspect()),
        limit: 100,
        offset: 0,
        data: [],
        total_count: 0,
        total_count_filtered: 0,
        refreshing: false,
        finished: false,
      },
      modalLearnAspect: {
        show: false,
        toggle: (title = 'New Sub Domain') => this.setState({ modalLearnAspect: { ...this.state.modalLearnAspect, show: !this.state.modalLearnAspect.show, title } }),
        title: '',
        save: this.saveLearnAspect,
        saving: false,
      },
      lrnaspect: {
        lrnaspect_id: 0,
        lrnaspect_code: '',
        lrnaspect_desc: '',
        lrnaspect_desc2: '',
        lrnaspect_status: 1,
        progass_id: props.progass.progass_id,
        learnarea_id: props.learnArea.learnarea_id,
        new_learnarea_id: null
      },
      learnAreas: []
    }
  }

  componentDidMount() {
    if (this.props.progass.progass_id) {
      this.getLearnAspect();
      this.getLearnArea();
    }
  }

  componentDidUpdate(prevProps, prevState) {

    if (this.props.progass.progass_id != prevProps.progass.progass_id || this.props.learnArea.learnarea_id != prevProps.learnArea.learnarea_id) {
      this.setState({
        learnAspectData: {
          ...this.state.learnAspectData,
          data: [],
          total_count: 0,
          total_count_filtered: 0,
          refreshing: false,
          finished: false
        },
        lrnaspect: {
          lrnaspect_id: 0,
          lrnaspect_code: '',
          lrnaspect_desc: '',
          lrnaspect_desc2: '',
          lrnaspect_status: 1,
          progass_id: this.props.progass.progass_id,
          learnarea_id: this.props.learnArea.learnarea_id,
        },
      }, () => {
        this.getLearnAspect();
        this.getLearnArea();
      });
    }

  }

  getLearnAspect = async () => {
    if (!this.props.progass.progass_id) {
      return
    }

    if (this.getLearnAspectReq) {
      this.getLearnAspectReq.abort();
    }
    this.getLearnAspectReq = new AbortController();

    this.setState({
      learnAspectData: {
        ...this.state.learnAspectData,
        refreshing: true
      }
    })

    try {
      const res = await GET('learn-aspect/get',
        {
          params: {
            progass_id: this.props.progass.progass_id,
            learnarea_id: this.props.learnArea.learnarea_id,
            search: this.state.learnAspectData.search,
            order: this.state.learnAspectData.order,
            dir: this.state.learnAspectData.dir,
            limit: this.state.learnAspectData.limit,
            offset: this.state.learnAspectData.offset,
          },
          signal: this.getLearnAspectReq.signal,
        }
      );
      this.setState({
        learnAspectData: {
          ...this.state.learnAspectData,
          data: res.data.data,
          total_count: res.data.total_count,
          total_count_filtered: res.data.total_count_filtered,
          refreshing: false,
          finished: true
        }
      });
    } catch (err) {
      console.log('err', err)
      if (axios.isCancel(err)) {

      } else {
        toast.error(t('Something went wrong while fetching data!'));

      }
    }
  };

  getLearnArea = async () => {
    if (!this.props.progass.progass_id) {
      return
    }

    if (this.getLearnAreaReq) {
      this.getLearnAreaReq.abort();
    }
    this.getLearnAreaReq = new AbortController();

    try {
      const res = await GET('learn-aspect/get-learn-areas',
        {
          params: {
            progass_id: this.props.progass.progass_id,
          },
          signal: this.getLearnAreaReq.signal,
        }
      );

      console.log('learn-aspect/get-learn-areas', res);

      this.setState({
        learnAreas: res.data.map(item => {
          return {
            ...item,
            label: item.learnarea_code,
            value: item.learnarea_id,
          }
        })
      });

    } catch (err) {
      console.log('err', err)
      if (axios.isCancel(err)) {

      } else {
        toast.error(t('Something went wrong while fetching data!'));

      }
    }
  };

  saveLearnAspect = async () => {
    this.setState({ modalLearnAspect: { ...this.state.modalLearnAspect, saving: true } });
    const { auth_key, user_id, full_name, role_id } =
      this.props.authData.loginData;
    const { center_id, center_timezone } = this.props.authData.centerData;
    const { lrnaspect } = this.state;

    const fData = new FormData();
    fData.append('user_id', user_id);
    fData.append('center_id', center_id);
    fData.append('center_timezone', center_timezone);
    fData.append('lrnaspect_id', lrnaspect.lrnaspect_id);
    fData.append('LearnAspectData', JSON.stringify(lrnaspect));

    try {
      const res = await axios.post(
        apiURL + 'learn-aspect/save',
        fData,
        {
          params: {
            auth_key: auth_key,
            appname: process.env.REACT_APP_NAME,
          },
        }
      );


      if (!res.data.type) {
        if (res.data.data != null) {
          handleFormErrors(res.data.data);
        } else {
          toast.error(t('Something went wrong while saving!'));
        }
        this.setState({ modalLearnAspect: { ...this.state.modalLearnAspect, saving: false } });
      } else {
        let learnAspectData = this.state.learnAspectData
        if (lrnaspect.lrnaspect_id != 0) {
          learnAspectData.data = learnAspectData.data.map((v, i) => {
            if (v.lrnaspect_id == lrnaspect.lrnaspect_id) {
              return { ...v, ...res.data.data }
            }
            return v
          })
        } else {
          learnAspectData.data = [res.data.data, ...learnAspectData.data]
        }

        this.setState({
          isEditing: false,
          learnAspectData,
          modalLearnAspect: {
            ...this.state.modalLearnAspect,
            show: false,
            saving: false,
          },
          lrnaspect: {
            lrnaspect_id: 0,
            lrnaspect_code: '',
            lrnaspect_desc: '',
            lrnaspect_desc2: '',
            lrnaspect_status: 1,
            progass_id: this.props.progass.progass_id,
            learnarea_id: this.props.learnArea.learnarea_id,
            new_learnarea_id: null
          },
        });
        toast.success(t(res.data.message));

      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while sending message!'));
      this.setState({ modalLearnAspect: { ...this.state.modalLearnAspect, saving: false } });
    }
  };
  toggleStatusLearnAspect = async (lrnaspect_id) => {
    const { auth_key, user_id, full_name, role_id } =
      this.props.authData.loginData;
    const { center_id, center_timezone } = this.props.authData.centerData;
    const fData = new FormData();
    fData.append('center_id', center_id);
    fData.append('lrnaspect_id', lrnaspect_id);
    try {
      const res = await axios.post(
        apiURL + 'learn-aspect/toggle-status',
        fData,
        {
          params: {
            auth_key: auth_key,
            appname: process.env.REACT_APP_NAME,
          },
        }
      );

      if (!res.data.status) {
        toast.error(t('Something went wrong while updating!'));
      } else {
        let learnAspectData = this.state.learnAspectData
        learnAspectData.data = learnAspectData.data.map((v, i) => {
          if (v.lrnaspect_id == lrnaspect_id) {
            return { ...v, lrnaspect_status: (v.lrnaspect_status == 1) ? 0 : 1 }
          }
          return v
        })
        this.setState({ learnAspectData })
        toast.warning(t('Status updated'));
      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while updating!'));

      this.setState({ msgSending: false });
    }
  };
  setStateLearnAspect = (field, value) => {
    this.setState({ lrnaspect: { ...this.state.lrnaspect, [field]: value } })
  }
  deleteLearnAspect = (lrnaspect_id) => {
    this.setState({
      deleteModal: {
        ...this.state.deleteModal, show: true, action: () => {
          // DELETE FILE CALL 
          try {
            GET('learn-aspect/delete', {
              params: {
                lrnaspect_id: lrnaspect_id,
              },
            }).then((res) => {

              if (res.data.type) {
                let learnAspectData = this.state.learnAspectData
                learnAspectData.data = learnAspectData.data.filter((v, i) => {
                  if (v.lrnaspect_id != lrnaspect_id) {
                    return true
                  }
                  return false
                })
                this.setState({
                  learnAspectData, deleteModal: {
                    ...this.state.deleteModal, show: false
                  }
                })
                toast.warning(t(res.data.message));
              } else {
                this.setState({
                  deleteModal: {
                    ...this.state.deleteModal, show: false
                  }
                })
                toast.error(t(res.data.message));
              }
            })
          } catch (err) {
            console.log('err', err)
            toast.error(t('Something went wrong while deleting!'));

          }
        }
      }
    })
  }


  render() {
    const { defaultTheme, progass, learnArea, learnAspect } = this.props;
    const { learnAspectData, modalLearnAspect, lrnaspect } = this.state;


    return (<div>
      <div className='grey-section-header mt-2 flex-row align-items-center'>
        <h5 className='mb-0'>
          <span>
            {t('Sub Domain')}
          </span>
        </h5>
        <div className='d-flex flex-row align-items-center'>
          {/* REMOVE SELECTED */}
          {learnAspect.lrnaspect_id &&
            <div className='cb-expandable-search mx-1' >
              <Button
                variant='warning'
                data-tooltip-id="tooltip"
                data-tooltip-content={t('Remove Filter')}
                data-tooltip-place="top"
                title={t('Remove Filter')}
                onClick={() => learnAspect.toggle(null)}
              >
                <FontAwesomeIcon
                  icon={faFilterCircleXmark}
                  color={brand_colors[defaultTheme.theme_id].color8}
                />
              </Button>
            </div>
          }

          <ButtonGroup className='mx-1'>
            <div className='cb-expandable-search' style={{ width: learnAspectData.openSearch ? 178 : 32, borderRadius: 0 }}>
              <input
                ref={this.searchFieldRef}
                onChange={(e) => learnAspectData.changeSearch(e.target.value)}
                value={learnAspectData.search}
                type='text'
                style={{ width: learnAspectData.openSearch ? 150 : 0, paddingLeft: learnAspectData.openSearch ? 15 : 0, opacity: learnAspectData.openSearch ? 1 : 0, backgroundColor: brand_colors[defaultTheme.theme_id].color8, fontSize: 14 }}
                placeholder={t('Search')} />
              <Button
                style={{ border: 'inherit', }}
                size='sm'
                title={t('Search')}
                variant={(learnAspectData.search) ? 'danger' : 'primary'}
                onClick={() => learnAspectData.toggleSearch()}>
                <FontAwesomeIcon
                  icon={learnAspectData.openSearch ? faXmark : faSearch}
                  color={brand_colors[defaultTheme.theme_id].color8}
                />
              </Button>
            </div>
            <DropdownButton size='sm' as={ButtonGroup} title={'ORDER BY: ' + learnAspectData.orderName} id="bg-nested-dropdown">
              <Dropdown.Item onClick={() => learnAspectData.changeOrder('Newest First', 'lrnaspect.lrnaspect_id', 'DESC')}>Newest First</Dropdown.Item>
              <Dropdown.Item onClick={() => learnAspectData.changeOrder('Oldest First', 'lrnaspect.lrnaspect_id', 'ASC')}>Oldest First</Dropdown.Item>
              <Dropdown.Item onClick={() => learnAspectData.changeOrder('Code ASC', 'lrnaspect.lrnaspect_code', 'ASC')}>Code ASC</Dropdown.Item>
              <Dropdown.Item onClick={() => learnAspectData.changeOrder('Code DESC', 'lrnaspect.lrnaspect_code', 'DESC')}>Code DESC</Dropdown.Item>
              <Dropdown.Item onClick={() => learnAspectData.changeOrder('Desc ASC', 'lrnaspect.lrnaspect_desc', 'ASC')}>Desc ASC</Dropdown.Item>
              <Dropdown.Item onClick={() => learnAspectData.changeOrder('Desc DESC', 'lrnaspect.lrnaspect_desc', 'DESC')}>Desc DESC</Dropdown.Item>
            </DropdownButton>
          </ButtonGroup>
          <div>
            <Button
              onClick={() => (learnArea.learnarea_id)
                ? this.setState({
                  lrnaspect: {
                    lrnaspect_id: 0,
                    lrnaspect_code: '',
                    lrnaspect_desc: '',
                    lrnaspect_desc2: '',
                    lrnaspect_status: 1,
                    progass_id: this.props.progass.progass_id,
                    learnarea_id: this.props.learnArea.learnarea_id,
                  },
                }, () => modalLearnAspect.toggle())
                : toast.warning(t('Please select a Main Domain first!'))}
              className='btn-sm btn-add'
              variant='success'>
              {t('Add Sub Domain')}{' '}
              <FontAwesomeIcon
                icon={faPlus}
                color={brand_colors[defaultTheme.theme_id].color8}
                style={{
                  fontSize: 16,
                }}
              />
            </Button>
          </div>
        </div>
      </div>
      <div style={{ height: '30vh' }}>

        {!progass.progass_id
          ? <div className='d-flex mt-2 align-items-center'>
            <div style={{ color: brand_colors[defaultTheme.theme_id].color18 }}>{t('Please select a Curriculum')}</div>
          </div>
          : <div>
            {learnAspectData.refreshing && !learnAspectData.finished &&
              <div className='d-flex mt-2 align-items-center'>
                <Spinner animation='grow' variant='dark' />
              </div>
            }
            {/* {!learnAspectData.refreshing && learnAspectData.finished && learnAspectData.data.length > 0 &&
                            <div className='d-flex mt-2 align-items-center'>
                                <div style={{ color: brand_colors[defaultTheme.theme_id].color18 }}>NO MORE DATA...</div>
                            </div>
                        } */}
            {!learnAspectData.refreshing && learnAspectData.finished && learnAspectData.data.length <= 0 &&
              <div className='d-flex mt-2 align-items-center'>
                <div style={{ color: brand_colors[defaultTheme.theme_id].color18 }}>NO DATA FOUND...</div>
              </div>
            }
          </div>
        }

        {progass.progass_id &&
          <Scrollbar style={{ width: '100%', height: '100%' }} >
            {learnAspectData.data.map((v, i) => {

              return <div key={i} className='scroll-list-card'>
                <div className='d-flex flex-row justify-content-between align-items-center p-0 m-0'>
                  <div className='cd-div-actionbtn' style={{ width: this.state.searchExpanded ? 108 : 14 }}>

                    <FontAwesomeIcon icon={faGripVertical} color={brand_colors[defaultTheme.theme_id].color18} className='cursor-pointer' onClick={() => this.setState({ searchExpanded: !this.state.searchExpanded })} />

                    <div className='d-flex flex-row align-items-center all-btns' style={{ opacity: this.state.searchExpanded ? 1 : 0 }} >
                      {/* EDIT */}
                      <FontAwesomeIcon icon={faEdit} color={brand_colors[defaultTheme.theme_id].color16} className='cursor-pointer ms-3'
                        onClick={() => this.setState({
                          isEditing: true,
                          modalLearnAspect: { ...modalLearnAspect, show: true, title: 'Edit Sub Domain' },
                          lrnaspect: { ...lrnaspect, ...v },
                        })} />
                      {/* DELETE */}
                      <FontAwesomeIcon icon={faTrashCan} color={brand_colors[defaultTheme.theme_id].color11} className='cursor-pointer ms-3' onClick={() => this.deleteLearnAspect(v.lrnaspect_id)} />
                      {/* STATUS */}
                      <FontAwesomeIcon icon={(v.lrnaspect_status == 1) ? faThumbsDown : faThumbsUp} color={(v.lrnaspect_status == 1) ? brand_colors[defaultTheme.theme_id].color12 : brand_colors[defaultTheme.theme_id].color11} className='cursor-pointer ms-3' title={(v.lrnaspect_status == 1) ? t('DeActivate') : t('Activate')}
                        onClick={() => this.toggleStatusLearnAspect(v.lrnaspect_id)} />
                    </div>
                  </div>
                  <div className='me-2'>{v.lrnaspect_code}</div>
                  <div>{v.lrnaspect_desc}</div>
                  {v.agegroup_id && <div style={{ color: brand_colors[defaultTheme.theme_id].color8, backgroundColor: v.agegroup_color, borderRadius: '1rem', padding: '0.05rem 0.3rem', fontSize: 12, margin: '0 1rem' }}>{v.agegroup_code}</div>}
                </div>
                <div>
                  {/* TOGGLE CHECK*/}
                  <div className='d-flex flex-row align-items-center me-2 cursor-pointer' style={{ backgroundColor: (learnAspect.lrnaspect_id == v.lrnaspect_id) ? brand_colors[this.props.defaultTheme.theme_id].color4_light : brand_colors[this.props.defaultTheme.theme_id].color7, borderRadius: '0.8rem', padding: '0.2rem 0.8rem' }} onClick={() => learnAspect.toggle(v.lrnaspect_id, v.agegroup_id)}>
                    <div className='d-flex align-items-center justify-content-center me-2' style={{ backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, borderRadius: '100%', height: '0.9rem', width: '0.9rem' }}>
                      {(learnAspect.lrnaspect_id == v.lrnaspect_id) && <FontAwesomeIcon icon={faCheckCircle} color={brand_colors[this.props.defaultTheme.theme_id].color4} style={{ fontSize: '0.8rem', }} />}
                    </div>
                    <div style={{ color: (learnAspect.lrnaspect_id == v.lrnaspect_id) ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color18, fontWeight: '600', fontSize: '0.8rem' }}>
                      {v.state ? t('Preview') : t('Preview')}
                    </div>
                  </div>
                </div>
              </div>
            })}
          </Scrollbar>}
      </div>

      <SweetAlert
        show={this.state.deleteModal.show}
        warning
        showCancel
        confirmBtnText={t('Yes, delete it!')}
        confirmBtnBsStyle='danger'
        title={(this.state.deleteModal.title) ? this.state.deleteModal.title : t('Are you sure?')}
        onConfirm={this.state.deleteModal.action}
        onCancel={() => this.setState({ deleteModal: { ...this.state.deleteModal, show: false, id: 0, action: () => { } } })}
        focusCancelBtn>
        {(this.state.deleteModal.desc) ? this.state.deleteModal.desc : t('Confirm Delete!')}
      </SweetAlert>

      {/* MODAL NEW/EDIT ACADEMIC BOARD */}
      <Modal show={modalLearnAspect.show} onHide={() => modalLearnAspect.toggle()} size="lg" backdrop="static"
        keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>{modalLearnAspect.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='custom-styled-form'>
          <Row>
            <Col md="8">
              <Row>
                <Col md="12">
                  <Form.Group className='mb-3'>
                    <Form.Label>
                      <img src={imgDescription} />
                      {t('Code')}
                    </Form.Label>
                    <Form.Control type='text' defaultValue={lrnaspect.lrnaspect_code} onBlur={(event) => this.setStateLearnAspect('lrnaspect_code', event.target.value)} />
                  </Form.Group>
                </Col>
                <Col md="12">
                  <Form.Group className='mb-3'>
                    <Form.Label>
                      <img src={imgDescription} />
                      {t('Description')}
                    </Form.Label>
                    < Form.Control as="textarea" rows={3} defaultValue={lrnaspect.lrnaspect_desc} onBlur={(event) => this.setStateLearnAspect('lrnaspect_desc', event.target.value)} />
                  </Form.Group>
                </Col>
                <Col md="12">
                  <Form.Group className='mb-3'>
                    <Form.Label>
                      <img src={imgDescription} />
                      {t('Description2')}
                    </Form.Label>
                    < Form.Control as="textarea" rows={3} defaultValue={lrnaspect.lrnaspect_desc2} onBlur={(event) => this.setStateLearnAspect('lrnaspect_desc2', event.target.value)} />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md="4" className='text-center'>
                  <Form.Label className='no-style'>
                    {t('Status')}
                  </Form.Label>
                  <Form.Check
                    type="switch"
                    style={{}}
                    value={1}
                    checked={(lrnaspect.lrnaspect_status == 0) ? false : true}
                    onChange={(event) => this.setStateLearnAspect('lrnaspect_status', (lrnaspect.lrnaspect_status == 1) ? 0 : 1)}
                  />
                </Col>
              </Row>
            </Col>
            {this.state.isEditing &&
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('Change Main Domain')}</Form.Label>
                  <Select
                    value={this.state.lrnaspect.new_learnarea_id}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (
                        base
                      ) => ({
                        ...base,
                        zIndex: 9999,
                      }),
                    }}
                    options={this.state.learnAreas}
                    onChange={(obj) => this.setState({
                      lrnaspect: {
                        ...this.state.lrnaspect,
                        new_learnarea_id: obj
                      }
                    })}
                  />
                </Form.Group>
              </Col>
            }
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => modalLearnAspect.toggle()}>
            Close
          </Button>
          <Button variant="primary" disabled={modalLearnAspect.saving} onClick={modalLearnAspect.save}>
            Save Changes
            {modalLearnAspect.saving &&
              <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />
            }
          </Button>
        </Modal.Footer>
      </Modal>
    </div>);
  }
}


const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  selectedClass: state.selectedClass.data,
  defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(LearnAspect);
