import React, { Component } from 'react';
import { Container, Card, Row, Col, ButtonGroup, Button, DropdownButton, Dropdown, Table, Form, Spinner, Modal, Tabs, Tab, Badge, Offcanvas, ToggleButton } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCheckCircle, faSearch, faUser, faXmark, faLayerGroup, faHandsHoldingChild, faPeopleGroup, faListCheck, faPrint, faBus, faInfoCircle, faUserShield, faChartArea, faChartPie, faEllipsis, faAngleRight, faAngleLeft, faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import { changeColorOpacity, getUrlParam, ifNavAllowed, isTeacher } from '../../helpers/general_helpers';
import { getStudents } from '../../redux/slices/studentListSlice';
import ReactPaginate from 'react-paginate';
import Pagination from '../_partials/Pagination';
import { brand_colors } from '../../helpers/brand_colors_helper';
import moment from 'moment';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { GET, POST } from '../../api';
import { toast } from 'react-toastify';
import { DateRangePicker } from 'react-date-range';
import Flatpickr from 'react-flatpickr';
import { Fragment } from 'react';
import { faEdit, faTrashCan } from '@fortawesome/free-regular-svg-icons';
import SweetAlert from 'react-bootstrap-sweetalert';
import { toggleRegistrationSimpleModal } from '../../redux/slices/addEditRegistrationSimpleModalSlice';
import AddEditRegistrationSimple from '../_common/AddEditRegistrationSimple/_AddEditRegistrationSimple';

const portalURL = process.env.REACT_APP_PORTAL_URL;

class StudentRegistrationSimple extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstLoad: true,
      deleteModal: { show: false, id: 0, action: () => { } },
      searchExpanded: false,
      cdt: {
        order: { column: 'child_id', type: 'DESC' },
        rows: [10, 25, 50, 'All'],
      },
      showRegistration: [],
      showAllClasses: [1, 2, 8, 12, 22].includes(props.authData.loginData.role_id) ? true : false,
    }
    this.searchFieldRef = React.createRef();
  }


  componentDidMount() {
    this.getStudents({
      rows: this.props.studentListRows,
      page: this.props.studentListPage,
      search: this.props.studentListSearch,
      status: true,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if ((prevProps.authData.centerData.center_id !== this.props.authData.centerData.center_id) || (this.props.selectedClass && prevProps.selectedClass.class.class_id !== this.props.selectedClass.class.class_id)) {
      this.getStudents({
        rows: this.props.studentListRows,
        page: 0,
        search: '',
        status: true
      });
      this.setState({ searchExpanded: false });
      this.searchFieldRef.current.value = '';
    }

    if (prevProps.studentListFetching != this.props.studentListFetching && !this.props.studentListFetching && this.state.firstLoad) {
      this.setState({ firstLoad: false }, () => {
        this.addRegistrationFromURL()
      })
    }
  }

  getStudents = (params) => {
    this.props.getStudents({
      child_status: params.status,
      search: params.search,
      limit: params.rows,
      page: params.page,
      registrations: true,
      showAllClasses: this.state.showAllClasses,
    });
  }

  addRegistrationFromURL = () => {
    let getUrlParamData = getUrlParam()
    console.log('getUrlParamData.id', getUrlParamData.id);
    if (getUrlParamData.id) {
      let student = this.props.studentListData.find((v) => v.child_id == getUrlParamData.id)
      console.log('student.id', this.props.studentListData, student);
      if (student) {
        let weekend_days = this.props.authData.centerData.weekend_days
        if (weekend_days) {
          weekend_days = weekend_days.split(',')
        }
        let newRegistration = [{
          reg_id: 0,
          center_id: this.props.authData.loginData.center_id,
          child: { label: student.child_name, value: student.child_id, ...student },
          class_id: 0,
          session_id: 0,
          sessioncat_id: 0,
          term_id: 0,
          centerlic_id: 0,
          reg_year: 0,
          session_mon: weekend_days.includes('monday') ? false : true,
          session_tue: weekend_days.includes('tuesday') ? false : true,
          session_wed: weekend_days.includes('wednesday') ? false : true,
          session_thu: weekend_days.includes('thursday') ? false : true,
          session_fri: weekend_days.includes('friday') ? false : true,
          session_sat: weekend_days.includes('saturday') ? false : true,
          session_sun: weekend_days.includes('sunday') ? false : true,
          reg_date: moment().format('YYYY-MM-DD'),
          reg_num: 0,
          date_from: moment().format('YYYY-MM-DD'),
          date_to: moment().format('YYYY-MM-DD'),
        }]
        this.props.toggleRegistrationSimpleModal({ title: 'New Registration', child: student, registrations: newRegistration, reg_date: moment().format('YYYY-MM-DD') })
      }
    }
  }

  loadStudentOptions = async (search, callback) => {
    console.log('search, callback', search, callback);
    if (this.loadStudentOptionsReq) {
      this.loadStudentOptionsReq.abort();
    }
    this.loadStudentOptionsReq = new AbortController();
    const { user_id, role_id } = this.props.authData.loginData;
    const { employee_id } = this.props.authData.userData;
    let class_ids = false;
    if ([3, 4, 15].includes(role_id)) {
      class_ids = this.props.selectedClass.class.class_id
    }

    const res = await GET('admin/children-list-paginate', {
      signal: this.loadStudentOptionsReq.signal,
      params: {
        search,
        limit: 20,
        offset: 0,
        user_id,
        employee_id,
        role_id,
        class_ids,
        registrations: true
      },
    });

    if (res) {
      let resData = res.data
      callback(resData.data.map((v, i) => {
        return { label: v.child_name, value: v.child_id, ...v }
      }))
    }
  }

  deleteRegistration = (reg_id) => {
    this.setState({
      deleteModal: {
        ...this.state.deleteModal, show: true, id: reg_id, action: async () => {
          if (this.deleteRegistrationReq) {
            this.deleteRegistrationReq.abort()
          }

          this.deleteRegistrationReq = new AbortController();
          let fData = new FormData()
          fData.append('reg_id', reg_id)
          let res = await POST('registration-simple/delete-registration', fData, {
            signal: this.deleteRegistrationReq.signal
          })
          console.log('res', res);
          if (res) {
            let resData = res.data
            if (resData.type) {
              this.setState({
                deleteModal: {
                  ...this.state.deleteModal, show: false,
                }
              }, () => {
                this.getStudents({
                  rows: this.props.studentListRows,
                  page: this.props.studentListPage,
                  search: this.props.studentListSearch,
                  status: true,
                })
              })
            }
            toast[resData.messageType](resData.message)
          }
        }
      }
    })
  }

  render() {
    let { showRegistration, showAllClasses } = this.state
    const dir = this.props.defaultLanguage.lang_orientation
    let weekend_days = this.props.authData.centerData.weekend_days
    let defaultTheme = this.props.defaultTheme
    if (weekend_days) {
      weekend_days = weekend_days.split(',')
    }
    console.log('this.state', this.state, this.props);
    return (
      <>
        <Card
          className='border-0'
          style={{ borderRadius: '20px' }}>
          <Card.Body>
            <Row>
              <Col md={6} className='d-flex flex-column'>
                <div className='d-flex flex-row align-items-center mb-2'>
                  <strong>{t("Registrations")}</strong>
                  <div className='expandable-search ms-2' style={{ width: this.state.searchExpanded ? 200 : 35 }}>
                    <input
                      ref={this.searchFieldRef}
                      defaultValue={this.props.studentListSearch}
                      onChange={(e) => this.getStudents({
                        rows: this.props.studentListRows,
                        page: 0,
                        search: e.target.value,
                        status: true,
                      })}
                      type='text'
                      style={{ width: this.state.searchExpanded ? 165 : 0, paddingLeft: this.state.searchExpanded ? 15 : 0, opacity: this.state.searchExpanded ? 1 : 0 }}
                      placeholder={t('Search Student...')} />
                    <button
                      data-tooltip-id="tooltip"
                      data-tooltip-content={t('Search Student')}
                      data-tooltip-place="top"
                      onClick={() => {
                        this.setState({ searchExpanded: !this.state.searchExpanded });
                        this.props.studentListSearch != '' && this.state.searchExpanded && this.getStudents({
                          rows: this.props.studentListRows,
                          page: 0,
                          search: '',
                          status: true,
                        });
                        this.searchFieldRef.current.value = '';
                        !this.state.searchExpanded && this.searchFieldRef.current.focus();
                      }}>
                      <FontAwesomeIcon
                        icon={this.state.searchExpanded ? faXmark : faSearch}
                        color={brand_colors[defaultTheme.theme_id].color18}
                      />
                    </button>
                  </div>
                </div>
                <div className='d-flex flex-row align-items-center'>
                  <div>
                    {t('Show')}
                    <Form.Select
                      value={this.props.studentListRows}
                      size="sm"
                      onChange={(e) => this.getStudents({
                        rows: e.target.value,
                        page: 0,
                        search: this.props.studentListSearch,
                        status: true,
                      })}
                      style={{ width: 70, marginInline: 5, display: 'inline-block' }}>
                      {this.state.cdt.rows.map((row, row_index) => (<option key={row_index} >{row}</option>))}
                    </Form.Select>
                    {t('Records')}
                  </div>
                </div>
              </Col>
              <Col md={6} className='d-flex justify-content-end align-items-start'>
                {[1, 2, 8, 12, 22].includes(this.props.authData.loginData.role_id) &&
                  <Button onClick={() => this.setState({ showAllClasses: !showAllClasses }, () => {
                    this.getStudents({
                      rows: this.props.studentListRows,
                      status: this.props.studentListStatus,
                      order: this.props.studentListOrder,
                      dir: this.props.studentListDir,
                      page: 0,
                      search: this.props.studentListSearch
                    })
                  })} variant={(showAllClasses) ? "success" : "outline-success"} size='sm' className='me-3' title={(showAllClasses) ? t('Show Selected Class only') : t('Show All Classes')}>
                    {showAllClasses && <FontAwesomeIcon icon={faCheckCircle} color={brand_colors[this.props.defaultTheme.theme_id].color8} className='me-1' />}
                    {(showAllClasses) ? t('Showing All Classes') : t('Showing Selected Class')}
                  </Button>
                }
                <Button variant='warning' size='sm' onClick={() => this.props.toggleRegistrationSimpleModal()}>
                  <FontAwesomeIcon icon={faPlus} color={brand_colors[defaultTheme.theme_id].color8} />
                </Button>
              </Col>
            </Row>
            <Row>
              {this.props.studentListFetching &&
                <Col md={12} className='py-5 d-flex flex-column align-items-center'>
                  <Spinner as='span' animation='grow' size='sm' /> {t('Loading Students')}
                </Col>
              }
              {!this.props.studentListFetching &&
                <Col md={12} className='pt-1 student-attendance-list'>
                  {this.props.studentListData.map((student, student_index) => {
                    if (!student) {
                      return null
                    }
                    let showRegistrationFind = showRegistration.findIndex((v) => v == student.child_id)
                    return <Row key={student_index}
                      className='student-attendance-list-item'>
                      <Col md={3} className='d-flex align-items-start'>
                        <div className='d-flex align-items-center'>
                          <div style={{
                            backgroundImage: `url(${(student.picture) ? portalURL + this.props.authData.centerData.center_uuid + '/children/' + student.picture : 'https://via.placeholder.com/150x150?text=' + student.child_name[0].toUpperCase()})`,
                            width: 50,
                            height: 50,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center center',
                            borderRadius: 10,
                            marginRight: 15
                          }}></div>
                          {student.child_name}
                        </div>
                      </Col>
                      <Col>
                        <div >
                          <Card className='p-0' border="light">
                            <Card.Header style={{ backgroundColor: changeColorOpacity(brand_colors[defaultTheme.theme_id].color4, 0.1), borderBottom: 0 }}>
                              <div className='d-flex flex-row justify-content-between align-items-center'>

                                <div className='d-flex flex-row align-items-center'>
                                  <strong>{t('Total Registrations')}:</strong>
                                  <div className='ms-1'>{student.registrations && student.registrations.length}</div>
                                </div>
                                <div className='d-flex flex-row align-items-center'>
                                  <div className='d-flex flex-row align-items-center me-2 cursor-pointer' style={{ backgroundColor: (showRegistrationFind !== -1) ? changeColorOpacity(brand_colors[defaultTheme.theme_id].color4, 0.1) : changeColorOpacity(brand_colors[defaultTheme.theme_id].color4, 0.1), borderRadius: '0.8rem', padding: '0.2rem 0.8rem' }} onClick={() => {
                                    if (showRegistrationFind !== -1) {
                                      showRegistration.splice(showRegistrationFind, 1)
                                    } else {
                                      showRegistration.push(student.child_id)
                                    }
                                    this.setState({ showRegistration })
                                  }}>
                                    <div className='d-flex align-items-center justify-content-center me-2' style={{ backgroundColor: brand_colors[defaultTheme.theme_id].color8, borderRadius: '100%', height: '0.9rem', width: '0.9rem' }}>
                                      {(showRegistrationFind !== -1) && <FontAwesomeIcon icon={faCheckCircle} color={brand_colors[defaultTheme.theme_id].color4} style={{ fontSize: '0.8rem', }} />}
                                    </div>
                                    <div style={{ color: (showRegistrationFind !== -1) ? brand_colors[defaultTheme.theme_id].color4 : brand_colors[defaultTheme.theme_id].color18, fontWeight: '600', fontSize: '0.8rem' }}>
                                      {t('Preview')}
                                    </div>
                                  </div>
                                  <Button variant='success' size='sm' onClick={() => {
                                    let newRegistration = [{
                                      reg_id: 0,
                                      center_id: this.props.authData.loginData.center_id,
                                      child: { label: student.child_name, value: student.child_id, ...student },
                                      class_id: 0,
                                      session_id: 0,
                                      sessioncat_id: 0,
                                      term_id: 0,
                                      centerlic_id: 0,
                                      reg_year: 0,
                                      session_mon: weekend_days.includes('monday') ? false : true,
                                      session_tue: weekend_days.includes('tuesday') ? false : true,
                                      session_wed: weekend_days.includes('wednesday') ? false : true,
                                      session_thu: weekend_days.includes('thursday') ? false : true,
                                      session_fri: weekend_days.includes('friday') ? false : true,
                                      session_sat: weekend_days.includes('saturday') ? false : true,
                                      session_sun: weekend_days.includes('sunday') ? false : true,
                                      reg_date: moment().format('YYYY-MM-DD'),
                                      reg_num: 0,
                                      date_from: moment().format('YYYY-MM-DD'),
                                      date_to: moment().format('YYYY-MM-DD'),
                                    }]
                                    this.props.toggleRegistrationSimpleModal({ title: 'New Registration', child: student, registrations: newRegistration, reg_date: moment().format('YYYY-MM-DD') })
                                  }
                                  }>
                                    <FontAwesomeIcon icon={faPlus} color={brand_colors[defaultTheme.theme_id].color8} />
                                  </Button>
                                </div>
                              </div>
                            </Card.Header>
                            {showRegistrationFind !== -1 &&
                              <Card.Body className='p-1'>
                                <Table striped hover responsive size='sm'>
                                  <tbody>
                                    {student.registrations.map((v, i) => {
                                      return <tr style={{ fontSize: 13, opacity: 0.9 }}>
                                        <td>
                                          <FontAwesomeIcon icon={faEdit} color={brand_colors[defaultTheme.theme_id].color16} className='cursor-pointer ms-3'
                                            onClick={() => this.props.toggleRegistrationSimpleModal({
                                              title: 'Update Registration', child: student, registrations: [{
                                                ...v,
                                                child: { label: student.child_name, value: student.child_id, ...student },
                                                class_id: { label: v.class_theme, value: v.class_id },
                                                session_id: { label: v.session_name, value: v.session_id, session_day_number: v.session_day_number },
                                                sessioncat_id: { label: v.sessioncat_name, value: v.sessioncat_id },
                                                term_id: { label: v.term_name, value: v.term_id, term_active: v.term_active },
                                              }], reg_date: v.reg_date
                                            })
                                            }
                                          />
                                          {/* DELETE */}
                                          <FontAwesomeIcon icon={faTrashCan} color={brand_colors[defaultTheme.theme_id].color11} className='cursor-pointer ms-3' onClick={() => this.deleteRegistration(v.reg_id)} />
                                        </td>
                                        <td>
                                          <strong>{v.class_theme}</strong>
                                        </td>
                                        <td>
                                          {moment(v.reg_date, 'YYYY-MM-DD').format('YYYY-MM-DD')}
                                        </td>
                                        <td>
                                          {v.reg_year} - {v.term_name}
                                        </td>
                                        <td>
                                          {v.sessioncat_name}
                                        </td>
                                        <td>
                                          {v.session_name}
                                        </td>
                                        <td>
                                          <Badge bg="success">{moment(v.date_from).format('YYYY-MM-DD')}</Badge>
                                          <Badge className='ms-1' bg="warning">{moment(v.date_to).format('YYYY-MM-DD')}</Badge>
                                        </td>
                                      </tr>
                                    })}
                                  </tbody>
                                </Table>
                              </Card.Body>
                            }
                          </Card>
                        </div>
                      </Col>
                    </Row>
                  })}
                </Col>
              }
            </Row>
            <Pagination
              pageCount={this.props.studentListLastPage}
              forcePage={this.props.studentListPage}
              callbackParams={{
                rows: this.props.studentListRows,
                search: this.props.studentListSearch,
                status: true
              }}
              callback={this.getStudents}
            />
          </Card.Body>
        </Card >


        <AddEditRegistrationSimple callbackFunction={() => this.getStudents({
          rows: this.props.studentListRows,
          page: this.props.studentListPage,
          search: this.props.studentListSearch,
          status: true,
        })} />


        <SweetAlert show={this.state.deleteModal.show} warning showCancel confirmBtnText={t('Yes, delete it!')} confirmBtnBsStyle='danger' title={t('Are you sure?')} onConfirm={this.state.deleteModal.action} onCancel={() => this.setState({ deleteModal: { ...this.state.deleteModal, show: false, id: 0, action: () => { } } })} focusCancelBtn>
          {t('Confirm Delete!')}
        </SweetAlert>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  selectedClass: state.selectedClass.data,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
  studentListFetching: state.studentList.fetching,
  studentListData: state.studentList.data,
  studentListSearch: state.studentList.query,
  studentListRows: state.studentList.rows,
  studentListOffset: state.studentList.offset,
  studentListStatus: state.studentList.status,
  studentListOrder: state.studentList.order,
  studentListDir: state.studentList.dir,
  studentListLastPage: state.studentList.last_page,
  studentListPage: state.studentList.page,
  studentListTotalRecords: state.studentList.total_records,
});

const mapDispatchToProps = () => ({
  getStudents,
  toggleRegistrationSimpleModal
});

export default connect(mapStateToProps, mapDispatchToProps())(StudentRegistrationSimple);
