import { Component } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faChevronDown,
  faSchool,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';
import { Modal, ListGroup, Image, Form } from 'react-bootstrap';
import { setSelectedClass, setSubject } from '../../../redux/slices/selectedClassSlice';
import { t } from '../../../helpers/translation_helper';
import { brand_colors } from '../../../helpers/brand_colors_helper';
import axios from 'axios';
import { toast } from 'react-toastify';
import { setChildren } from '../../../redux/slices/childrenSlice';
import { GET } from '../../../api';

// API URL
const apiURL = process.env.REACT_APP_API_URL;

// Portal URL
const portalURL = process.env.REACT_APP_PORTAL_URL;

/**
 * Class Selector Partial Component
 */
class ClassSelector extends Component {
  /**
   * @constructor
   * @param {any} props
   */
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      search: ''
    };
  }

  /**
   * Component Did Mount
   */
  componentDidMount() {
    const { selectedClass } = this.props;
    //!AHMAD  COMMENTED BECAUSE WE ARE NOT USING THIS SLICE ANYMORE
    // this.setChildrenList(selectedClass);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.selectedClass?.class.class_id !== prevProps.selectedClass?.class.class_id && this.props.selectedClass.class) {
      // if ((prevProps.selectedClass.class !== this.props.selectedClass.class)) {
      this.setState({ modal: false, }, () => {
        if (this.props.func?.length > 0) {
          this.props.func.map((func) => {
            return func();
          });
        }
      });
    }
  }

  /**
   * Handle Modal
   */
  handleModal = () => {
    const { modal } = this.state;
    this.setState({ modal: !modal });
  };

  /**
   * Classes List
   * @returns {HTMLElement} Outputs the classes list.
   */
  classesList = () => {
    let list = [];
    if (this.props.authData.classes && this.props.authData.classes.length > 0) {
      list = this.props.authData.classes.map((item, key) => {
        if (!(item.class.class_theme).toLowerCase().includes(this.state.search.toLowerCase())) {
          return null
        }
        return <ListGroup.Item
          key={key}
          className='child-list-item'
          style={{
            justifyContent: 'space-between'
          }}
          onClick={() => this.handleClassChange(item.class.class_id)}>
          <div className='d-flex'>
            <div className='child-pic list-child-pic'>
              {item.class.class_logo ? (
                <Image
                  src={portalURL + this.props.authData.centerData.center_uuid + '/classes/' + item.class.class_logo}
                  style={{
                    objectFit: 'cover',
                    width: '100%',
                    height: '100%',
                  }}
                />
              ) : (
                <FontAwesomeIcon icon={faSchool} color={brand_colors[this.props.defaultTheme.theme_id].color8} />
              )}
            </div>
            <div className='d-flex flex-column'>
              <span className='class-name'>
                {item.class.class_theme}
              </span>
              <div style={{ fontSize: 11, color: item.class.agegroup_color }}>{item.class.agegroup_code}</div>
            </div>
          </div>
          <div className='d-flex align-items-center'>
            {this.props.selectedClass.class.class_id == item.class.class_id && (
              <FontAwesomeIcon
                icon={faCheckCircle}
                color={brand_colors[this.props.defaultTheme.theme_id].color4}
              />
            )}
          </div>
        </ListGroup.Item>
      });
    }
    return list;
  };

  /**
   * Handle Class Change
   * @param {number} class_id ID of the class
   */
  handleClassChange = (class_id) => {
    const findClass = this.props.authData.classes.find((item) => {
      return item.class.class_id == class_id;
    });

    this.props.setSelectedClass(findClass);

    if (this.props.selectedSubject) {

      const findSubjectIndex = findClass.academic_classmain_subject.findIndex(item => item.acs_id == this.props.selectedSubject.acs_id);

      if (findSubjectIndex !== -1) {
        this.props.setSubject(findClass.academic_classmain_subject?.[findSubjectIndex]);
      } else {
        this.props.setSubject(findClass.academic_classmain_subject?.[0]);
      }

    } else {
      this.props.setSubject(findClass.academic_classmain_subject?.[0]);
    }

    //!AHMAD  COMMENTED BECAUSE WE ARE NOT USING THIS SLICE ANYMORE
    // this.setChildrenList(findClass);

    // this.setState(
    //   {
    //     modal: false,
    //   },
    //   () => {
    //     if (this.props.func?.length > 0) {
    //       this.props.func.map((func) => {
    //         return func();
    //       });
    //     }
    //   }
    // );

  };

  /**
   * Set Children List
   * @param {Object} cls
   */
  setChildrenList = async (cls) => {
    const { authData } = this.props;
    const { center_id, center_timezone } = authData.centerData;
    const { auth_key } = authData.loginData;

    const params = {
      class_id: cls.class.class_id,
    };

    try {
      const res = await GET('children/index', {
        params,
      });
      const timestamp = new Date().getTime();
      for (var i = 0; i < res.data.length; i++) {
        res.data[i].timestamp = timestamp;
      }

      this.props.setChildren(res.data);
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while fetching children!'));

    }
  };

  /**
   * Render Component
   * @returns {HTMLElement}
   */
  render() {

    const { lang_orientation: dir } = this.props.defaultLanguage;
    const { modal, search } = this.state;
    const { authData } = this.props;
    return (
      <>
        {(this.props.minimal)
          ? <div className='selector' style={{ padding: '0 10px', cursor: 'pointer' }}>
            <div className='d-flex flex-row justify-content-center align-items-center' style={{ fontSize: 14, fontWeight: 'bold', color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.8 }} onClick={() => this.handleModal()}>
              <FontAwesomeIcon
                icon={faCheckCircle}
                color={brand_colors[this.props.defaultTheme.theme_id].color4}
                className='mx-1'
                style={{ fontSize: 18 }}
              />
              <div className='mx-1 d-flex flex-column justify-content-center'
                style={{
                  lineHeight: '13px'
                }}>
                <div>{(this.props.selectedClass) ? this.props.selectedClass.class.class_theme : 'No Class'}</div>
                <div style={{ fontSize: 11, color: (this.props.selectedClass) ? this.props.selectedClass.class.agegroup_color : brand_colors[this.props.defaultTheme.theme_id].color9 }}>{(this.props.selectedClass) ? this.props.selectedClass.class.agegroup_code : ''}</div>
              </div>
              <FontAwesomeIcon
                icon={faChevronDown}
                color={brand_colors[this.props.defaultTheme.theme_id].color2}
                className='mx-1'
                style={{ fontSize: 16 }}
              />
            </div>
          </div>
          : <div className='selector'>
            <div className='child-pic'>
              {authData.userData.employee_picture ? (<Image src={portalURL + this.props.authData.centerData.center_uuid + '/employees/' + authData.userData.employee_picture}
                style={{ objectFit: 'cover', width: '100%', height: '100%', }}
              />
              ) : (
                <FontAwesomeIcon icon={faUser} color={brand_colors[this.props.defaultTheme.theme_id].color8} />
              )}
            </div>
            <div>
              <h5 className='child-name'>
                <span onClick={() => this.handleModal()}>
                  {dir == 0 && t('Hi')}{' '}
                  {authData.loginData.full_name}{' '}
                  {dir == 1 && t('Hi')}
                </span>
              </h5>
              <h6 className='class-name'>
                <span onClick={() => this.handleModal()}>
                  {(this.props.selectedClass) ? this.props.selectedClass.class.class_theme : 'No Class'}{' '}
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    color={brand_colors[this.props.defaultTheme.theme_id].color2}
                  />
                </span>
              </h6>
            </div>
          </div>
        }

        <Modal show={modal} size='sm' onHide={this.handleModal}>
          <Modal.Header>
            <div className='d-flex flex-column' style={{ width: '100%' }}>
              <div className='d-flex flex-row justify-content-between align-items-center'>
                <Modal.Title>{t('Select Class')}</Modal.Title>
                <button
                  type='button'
                  className='btn-close'
                  aria-label='Close'
                  onClick={this.handleModal}
                ></button>
              </div>
              <div >
                <Form.Control
                  type='text'
                  placeholder={t('Search...')}
                  onChange={(e) => this.setState({ search: e.target.value })}
                  value={search}
                />
              </div>
            </div>
          </Modal.Header>
          <Modal.Body className='p-0 pb-2'>
            <ListGroup variant='flush'>
              {this.classesList()}
            </ListGroup>
          </Modal.Body>
        </Modal >
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  selectedClass: state.selectedClass.data,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
  selectedSubject: state.selectedClass.subject,
});

const mapDispatchToProps = () => ({
  setSelectedClass,
  setSubject,
  setChildren,
});

export default connect(mapStateToProps, mapDispatchToProps())(ClassSelector);
