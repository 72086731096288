import React, { Component } from 'react';
import { Container, Card, Table, Row, Col, Spinner, Image, Badge, Button, Form, Offcanvas, Dropdown, Modal, } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faAnglesLeft, faAnglesRight, faCalendarAlt, faCheck, faCheckCircle, faCheckDouble, faClock, faEdit, faEllipsisV, faExternalLink, faFileAlt, faFilterCircleXmark, faGear, faGripVertical, faPaperclip, faPlus, faSearch, faTimes, faTrashAlt, faTrashCan, faXmark, } from '@fortawesome/free-solid-svg-icons';
import { Scrollbar } from 'react-scrollbars-custom';
import imgDescription from '../../assets/images/description.png';
import { brand_colors } from '../../helpers/brand_colors_helper';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Link } from 'react-router-dom';
import { faCircle, faThumbsDown, faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import { handleFormErrors, readFile } from '../../helpers/form_helpers';
import { colorPalette } from '../../constants/colorsPalette';
import { capitalizeFirstLetter, getExtension } from '../../helpers/general_helpers';
import HorizontalCard from '../../helpers/HorizontalCard';
import { GET } from '../../api';
import Select from 'react-select';


// API URL
const apiURL = process.env.REACT_APP_API_URL;

// Portal URL
const portalURL = process.env.REACT_APP_PORTAL_URL;

class AcademicBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteModal: { show: false, id: 0, action: () => { }, title: null, desc: null },
      academicBoardsData: {
        search: '',
        order: 'academic_boards.ab_id',
        dir: 'DESC',
        limit: 100,
        offset: 0,
        data: [],
        total_count: 0,
        total_count_filtered: 0,
        refreshing: false,
        finished: false,
      },
      modalAcademicBoard: {
        show: false,
        toggle: (title = 'New Curriculum') => this.setState({ modalAcademicBoard: { ...this.state.modalAcademicBoard, show: !this.state.modalAcademicBoard.show, title } }),
        title: '',
        save: this.saveBoard,
        saving: false,
      },
      academic_board: {
        ab_id: 0,
        ab_name: '',
        ab_desc: '',
        ab_image: '',
        ab_color: '',
        ab_status: 1,
        ab_assesslog_type: 'general',
      },
      abAssesslogTypes: [
        { label: 'General', value: 'general' },
        { label: 'Checklist', value: 'checklist' },
      ],
      ab_image: null,
      dragAreaColor: {
        ab_image: brand_colors[this.props.defaultTheme.theme_id].color18,
        toggleColor: (field, value) => this.setState({ dragAreaColor: { ...this.state.dragAreaColor, [field]: value } })
      }
    }
    this.ab_image_ref = React.createRef();
  }

  componentDidMount() {
    this.getBoards()
  }

  getBoards = async () => {
    let academicBoardsData = this.state.academicBoardsData
    if (academicBoardsData.refreshing) {
      return false
    }
    this.setState({
      academicBoardsData: {
        ...this.state.academicBoardsData,
        refreshing: true
      }
    })

    try {
      const res = await GET('academic-boards/get',
        {
          params: {
            search: this.state.academicBoardsData.search,
            order: this.state.academicBoardsData.order,
            dir: this.state.academicBoardsData.dir,
            limit: this.state.academicBoardsData.limit,
            offset: this.state.academicBoardsData.offset,
          },
        }
      );
      this.setState({
        academicBoardsData: {
          ...this.state.academicBoardsData,
          data: res.data.data,
          total_count: res.data.total_count,
          total_count_filtered: res.data.total_count_filtered,
          refreshing: false,
          finished: true
        }
      });
      if (res.data.data.length > 0) {
        this.props.academicBoard.toggleBoard(res.data.data[0].ab_id)
      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while fetching data!'));

    }
  };

  saveBoard = async () => {
    this.setState({ modalAcademicBoard: { ...this.state.modalAcademicBoard, saving: true } });
    const { auth_key, user_id, full_name, role_id } =
      this.props.authData.loginData;
    const { center_id, center_timezone } = this.props.authData.centerData;
    const { academic_board, ab_image } = this.state;

    const fData = new FormData();
    fData.append('user_id', user_id);
    fData.append('center_id', center_id);
    fData.append('center_timezone', center_timezone);
    fData.append('ab_id', academic_board.ab_id);
    if (ab_image) {
      fData.append('ab_image', ab_image.data)
    }
    fData.append('AcademicBoardData', JSON.stringify(academic_board));

    try {
      const res = await axios.post(
        apiURL + 'academic-boards/save',
        fData,
        {
          params: {
            auth_key: auth_key,
            appname: process.env.REACT_APP_NAME,
          },
        }
      );


      if (!res.data.type) {
        let academicBoardsData = this.state.academicBoardsData
        if (res.data.data != null) {
          handleFormErrors(res.data.data);
        } else {
          toast.error(t('Something went wrong while saving!'));
        }
        this.setState({
          academicBoardsData,
          modalAcademicBoard: {
            ...this.state.modalAcademicBoard,
            saving: false,
          }
        }
        );
      } else {
        let academicBoardsData = this.state.academicBoardsData
        if (academic_board.ab_id != 0) {
          academicBoardsData.data = academicBoardsData.data.map((v, i) => {
            if (v.ab_id == academic_board.ab_id) {
              return { ...v, ...res.data.data }
            }
            return v
          })
        } else {
          academicBoardsData.data = [{ ...res.data.data, ab_status: (res.data.data.ab_status == 1) ? 0 : 1 }, ...academicBoardsData.data]
        }

        this.setState({
          academicBoardsData,
          modalAcademicBoard: {
            ...this.state.modalAcademicBoard,
            show: false,
            saving: false,
          },
          academic_board: {
            ab_id: 0,
            ab_name: '',
            ab_desc: '',
            ab_image: '',
            ab_color: '',
            ab_status: 1,
            ab_assesslog_type: 'general'
          },
          ab_image: null,
        });
        toast.success(t(res.data.message));
      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while sending message!'));

    }
  };

  toggleStatusBoard = async (ab_id) => {
    const { auth_key, user_id, full_name, role_id } =
      this.props.authData.loginData;
    const { center_id, center_timezone } = this.props.authData.centerData;
    const fData = new FormData();
    fData.append('center_id', center_id);
    fData.append('ab_id', ab_id);
    try {
      const res = await axios.post(
        apiURL + 'academic-boards/toggle-status',
        fData,
        {
          params: {
            auth_key: auth_key,
            appname: process.env.REACT_APP_NAME,
          },
        }
      );

      if (!res.data.status) {
        toast.error(t('Something went wrong while updating!'));
      } else {
        let academicBoardsData = this.state.academicBoardsData
        academicBoardsData.data = academicBoardsData.data.map((v, i) => {
          if (v.ab_id == ab_id) {
            return { ...v, ab_status: (v.ab_status == 1) ? 0 : 1 }
          }
          return v
        })
        this.setState({ academicBoardsData })
        toast.warning(t('Status updated'));
      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while updating!'));

      this.setState({ msgSending: false });
    }
  };

  setStateAcademicBoard = (field, value) => {
    this.setState({ academic_board: { ...this.state.academic_board, [field]: value } })
  }

  handleDragFileArea = (e, name) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === "dragover") {
      this.state.dragAreaColor.toggleColor(name, brand_colors[this.props.defaultTheme.theme_id].color1)
    } else if (e.type === "dragleave") {
      this.state.dragAreaColor.toggleColor(name, brand_colors[this.props.defaultTheme.theme_id].color18)
    }
  }
  addAttachment = (e) => {
    const selectedFiles = e.target.files;
    let allFiles = [];
    for (let i = 0; i < selectedFiles.length; i++) {
      allFiles.push(readFile(selectedFiles[i]));
    }
    Promise.all(allFiles).then((values) => {
      let attachments = e.target.multiple ? this.state[e.target.name] : null;
      let selectedFiles = values;
      selectedFiles.map((v, i) => {
        if (v.data.type) {
          if (e.target.multiple) {
            attachments.push({ url: v.url, data: v.data });
          } else {
            attachments = { url: v.url, data: v.data };
          }
        }
      });
      this.setState({ [e.target.name]: attachments, dragAreaColor: { ...this.state.dragAreaColor, [e.target.name]: brand_colors[this.props.defaultTheme.theme_id].color18 } });
    });
  };

  deleteAttachment = (field, actionFun = null) => {
    let academic_board = this.state.academic_board
    if (academic_board.ab_id != null && academic_board.ab_id != 0) {
      this.setState({
        deleteModal: {
          ...this.state.deleteModal, show: true, action: () => {
            // DELETE FILE CALL 
            try {
              GET('academic-boards/delete-academic-board-image', {
                params: {
                  key: academic_board.ab_id,
                  field: field,
                },
              }).then((res) => {
                if (res) {
                  if (actionFun) {
                    actionFun()
                  }
                  academic_board[field] = null
                  let academicBoardsData = this.state.academicBoardsData
                  academicBoardsData.data = academicBoardsData.data.map((vv, ii) => {
                    if (vv.ab_id == this.state.academic_board.ab_id) {
                      vv[field] = null;
                    }
                    return vv
                  })
                  this.setState({
                    deleteModal: {
                      ...this.state.deleteModal, show: false
                    },
                    academicBoardsData,
                    [field]: null,
                    academic_board,
                  });
                  toast.warning(t('Attachment deleted'));
                } else {
                  toast.error(t('Something went wrong while deleting!'));
                }
              })
            } catch (err) {
              console.log('err', err)
              toast.error(t('Something went wrong while deleting!'));

            }
          }
        }
      })
    } else {
      academic_board[field] = null
      this.setState({ [field]: null, academic_board });
      if (actionFun) {
        actionFun()
      }
    }
  }

  handleOpenColorPicker = () => {
    this.setState({ showColorPicker: true })
  }

  deleteAcademicBoard = (ab_id) => {
    this.setState({
      deleteModal: {
        ...this.state.deleteModal, show: true, action: () => {
          // DELETE FILE CALL 
          try {
            GET('academic-boards/delete', {
              params: {
                auth_key: this.props.authData.loginData.auth_key,
                center_id: this.props.authData.centerData.center_id,
                appname: process.env.REACT_APP_NAME,
                ab_id,
              },
            }).then((res) => {

              if (res.data.type) {
                let academicBoardsData = this.state.academicBoardsData
                academicBoardsData.data = academicBoardsData.data.filter((v, i) => {
                  if (v.ab_id != ab_id) {
                    return true
                  }
                  return false
                })
                this.setState({
                  academicBoardsData, deleteModal: {
                    ...this.state.deleteModal, show: false
                  }
                })
                toast.warning(t(res.data.message));
              } else {
                this.setState({
                  deleteModal: {
                    ...this.state.deleteModal, show: false
                  }
                })
                toast.error(t(res.data.message));
              }
            })
          } catch (err) {
            console.log('err', err)
            toast.error(t('Something went wrong while deleting!'));

          }
        }
      }
    })
  }

  render() {
    const { defaultTheme } = this.props;
    const { academicBoardsData, modalAcademicBoard, academic_board, ab_image } = this.state;

    let selected_ab_id = null
    if (this.props.academicBoard) {
      selected_ab_id = this.props.academicBoard.ab_id
    }



    return (<div className='academic-board'>
      <div className='grey-section-header'>
        <h5>
          <span className='me-1'>{t('Board/Group')}</span>
          <span className='mx-1' style={{ opacity: 0.6, fontWeight: 'normal' }}>|</span>
          <span className='mx-1' style={{ opacity: 0.8, fontSize: '1rem', fontWeight: 'normal' }}>{t('Please select the board/group below')}</span>
        </h5>
        <div className='d-flex flex-row align-items-center'>
          <div>
            <Button
              onClick={() => this.setState({
                academic_board: {
                  ab_id: 0,
                  ab_name: '',
                  ab_desc: '',
                  ab_image: '',
                  ab_color: '',
                  ab_status: 1,
                  ab_assesslog_type: 'general'
                },
                ab_image: null,
              }, () => modalAcademicBoard.toggle())}
              className='btn-sm btn-add'
              variant='success'>
              {t('Add Curriculum')}{' '}
              <FontAwesomeIcon
                icon={faPlus}
                color={brand_colors[defaultTheme.theme_id].color8}
                style={{
                  fontSize: 16,
                }}
              />
            </Button>
          </div>
        </div>
      </div>
      {/* BOARDS */}
      <div style={{ overflowX: 'auto', overflowY: 'hidden' }}>
        <div className='d-flex flex-row' style={{ width: ((academicBoardsData.data.length + 1) * 31) + 'vw', }} >
          {academicBoardsData.data.map((v, i) => {
            let data = {
              allData: v,
              name: v.ab_name,
              // text1: v.ab_desc,
              cardImage: (v.ab_image) ? portalURL + v.center_id + '/academic_boards/' + v.ab_image : require('../../assets/images/noimage.png'),
              actions: [
                { type: 'selection', name: 'Selection', iconName: null, iconColor: brand_colors[this.props.defaultTheme.theme_id].color4, onClick: () => this.props.academicBoard.toggleBoard(v.ab_id), state: selected_ab_id == v.ab_id },
                { type: 'status', name: (v.ab_status == 1) ? 'Disable' : 'Enable', image: null, iconName: (v.ab_status == 1) ? faThumbsDown : faThumbsUp, iconColor: (v.ab_status == 1) ? brand_colors[this.props.defaultTheme.theme_id].color11 : brand_colors[this.props.defaultTheme.theme_id].color4, onClick: () => this.toggleStatusBoard(v.ab_id), state: (v.ab_status == 1) },
                {
                  type: 'edit', name: 'Edit', image: require('../../assets/images/edit.png'), iconName: null, iconColor: brand_colors[this.props.defaultTheme.theme_id].color16, onClick: () => this.setState({
                    modalAcademicBoard: { ...modalAcademicBoard, show: true, title: 'Edit Curriculum' },
                    academic_board: { ...academic_board, ...v },
                    ab_image: (v.ab_image) ? { url: portalURL + v.center_id + '/academic_boards/' + v.ab_image, data: { type: getExtension(v.ab_image) } } : null,
                  })
                },
                { type: 'delete', name: 'Delete', image: require('../../assets/images/remove-red.png'), iconName: null, iconColor: brand_colors[this.props.defaultTheme.theme_id].color11, onClick: () => this.deleteAcademicBoard(v.ab_id) },
              ]
            }
            return <HorizontalCard data={data} key={i} />
          })}

          {academicBoardsData.refreshing && !academicBoardsData.finished &&
            <div className='d-flex mt-2 align-items-center'>
              <Spinner animation='grow' variant='dark' />
            </div>
          }
          {/* {!academicBoardsData.refreshing && academicBoardsData.finished && academicBoardsData.data.length > 0 &&
                        <div className='d-flex mt-2 align-items-center'>
                            <div style={{ color: brand_colors[defaultTheme.theme_id].color18 }}>NO MORE DATA...</div>
                        </div>
                    } */}
          {!academicBoardsData.refreshing && academicBoardsData.finished && academicBoardsData.data.length <= 0 &&
            <div className='d-flex mt-2 align-items-center'>
              <div style={{ color: brand_colors[defaultTheme.theme_id].color18 }}>NO DATA FOUND...</div>
            </div>
          }
        </div>
      </div>

      <SweetAlert
        show={this.state.deleteModal.show}
        warning
        showCancel
        confirmBtnText={t('Yes, delete it!')}
        confirmBtnBsStyle='danger'
        title={(this.state.deleteModal.title) ? this.state.deleteModal.title : t('Are you sure?')}
        onConfirm={this.state.deleteModal.action}
        onCancel={() => this.setState({ deleteModal: { ...this.state.deleteModal, show: false, id: 0, action: () => { } } })}
        focusCancelBtn
      >
        {(this.state.deleteModal.desc) ? this.state.deleteModal.desc : t('Confirm Delete!')}
      </SweetAlert>

      {/* MODAL NEW/EDIT ACADEMIC BOARD */}
      <Modal show={modalAcademicBoard.show} onHide={() => modalAcademicBoard.toggle()} size="lg" backdrop="static"
        keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>{modalAcademicBoard.title}</Modal.Title>
        </Modal.Header>
        {modalAcademicBoard.show &&
          <Modal.Body className='custom-styled-form'>
            <Row>
              <Col md="6">
                <Row>
                  <Col md="12">
                    <Form.Group className='mb-3'>
                      <Form.Label>
                        <img src={imgDescription} />
                        {t('Curriculum')}
                      </Form.Label>
                      <Form.Control type='text' defaultValue={academic_board.ab_name} onBlur={(event) => this.setStateAcademicBoard('ab_name', event.target.value)} />
                    </Form.Group>
                  </Col>
                  <Col md="12">
                    <Form.Group className='mb-3'>
                      <Form.Label>
                        <img src={imgDescription} />
                        {t('Description')}
                      </Form.Label>
                      < Form.Control as="textarea" rows={3} defaultValue={academic_board.ab_desc} onBlur={(event) => this.setStateAcademicBoard('ab_desc', event.target.value)} />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="4" className='text-center'>
                    <Form.Group className="mb-3">
                      <Form.Label className='no-style'>{t('Color')}</Form.Label>
                      <div className='selected-color' onClick={() => {
                        this.handleOpenColorPicker();
                      }}>
                        <div style={{ backgroundColor: academic_board.ab_color }}></div>
                      </div>
                      {this.state.showColorPicker &&
                        <div className='color-palette'>
                          {colorPalette.map((color, color_i) => (
                            <div
                              key={color_i}
                              onClick={() => {
                                this.setState({
                                  academic_board: {
                                    ...this.state.academic_board,
                                    ab_color: color.code
                                  },
                                  showColorPicker: false
                                })
                              }}
                              style={{ backgroundColor: color.code }}
                            ></div>
                          ))}
                        </div>
                      }
                    </Form.Group>
                  </Col>
                  <Col md="8" className='text-center'>
                    <Form.Label className='no-style'>
                      {t('Status')}
                    </Form.Label>
                    <Form.Check
                      type="switch"
                      style={{}}
                      value={1}
                      checked={(academic_board.ab_status == 0) ? false : true}
                      onChange={(event) => this.setStateAcademicBoard('ab_status', (academic_board.ab_status == 1) ? 0 : 1)}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md="6">
                <div style={{ flexDirection: 'column' }} className='pic-prev d-flex justify-content-center mb-3'>
                  <div style={{ height: 175, padding: 10, overflow: 'hidden', borderStyle: 'dashed', borderColor: this.state.dragAreaColor.ab_image, borderWidth: 2, borderRadius: 20, position: 'relative' }} className='d-flex justify-content-center align-items-center'
                    onDragEnter={(e) => this.handleDragFileArea(e, 'ab_image')} onDragLeave={(e) => this.handleDragFileArea(e, 'ab_image')} onDragOver={(e) => this.handleDragFileArea(e, 'ab_image')} onDrop={(e) => {

                      e.preventDefault();
                      e.stopPropagation();
                      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
                        this.addAttachment({ target: { files: e.dataTransfer.files, multiple: false, name: 'ab_image' } })
                      }
                    }}>
                    {ab_image && (!ab_image.data.type.includes('pdf')) && (
                      <img src={ab_image.url} alt={t('Preview')} style={{ height: '100%' }} />
                    )}
                    {ab_image == null &&
                      <button onClick={() => this.ab_image_ref.current.click()} style={{ background: 'none', border: 0, flex: 1 }}>
                        {/* <FontAwesomeIcon icon={faPlus} style={{ fontSize: 30, color: color18 }} /> */}
                        <span style={{ fontSize: 20, fontWeight: 'bold', color: brand_colors[this.props.defaultTheme.theme_id].color18 }}>{t('Image')}</span>
                      </button>
                    }

                    {ab_image && (
                      <FontAwesomeIcon
                        icon={faTrashCan}
                        style={{ fontSize: 14, color: brand_colors[this.props.defaultTheme.theme_id].color11, position: 'absolute', bottom: 6, right: 6, cursor: 'pointer', backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, borderRadius: 100, padding: 3 }}
                        onClick={() => this.deleteAttachment('ab_image')}
                      />
                    )}
                  </div>
                  <Form.Control className='d-none' ref={this.ab_image_ref} type='file' onChange={this.addAttachment} name='ab_image' />
                </div>
                <div>
                  <Form.Group className="mb-3">
                    <Form.Label>{t('Observation Type')}</Form.Label>
                    <Select
                      options={this.state.abAssesslogTypes}
                      value={{ value: academic_board.ab_assesslog_type, label: capitalizeFirstLetter(academic_board.ab_assesslog_type) }}
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                      onChange={(obj) => this.setStateAcademicBoard('ab_assesslog_type', obj.value)}
                    />
                  </Form.Group>
                </div>
              </Col>
            </Row>
          </Modal.Body>
        }
        <Modal.Footer>
          <Button variant="secondary" onClick={() => modalAcademicBoard.toggle()}>
            Close
          </Button>
          <Button variant="primary" disabled={modalAcademicBoard.saving} onClick={modalAcademicBoard.save}>
            Save Changes
            {modalAcademicBoard.saving &&
              <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />
            }
          </Button>
        </Modal.Footer>
      </Modal>

    </div>);
  }
}


const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  selectedClass: state.selectedClass.data,
  defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(AcademicBoard);
