import React, { Component } from 'react';
import { Container, Card, Row, Col, ButtonGroup, Button, DropdownButton, Dropdown, Table, Form, Spinner, Modal, Tabs, Tab, Alert } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCheckCircle, faSearch, faUser, faXmark, faLayerGroup, faHandsHoldingChild, faPeopleGroup, faListCheck, faPrint, faBus, faInfoCircle, faUserShield, faChartArea, faChartPie, faEllipsis, faAngleRight, faAngleLeft, faBook } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import { ifNavAllowed, isTeacher } from '../../helpers/general_helpers';
import { getStudents } from '../../redux/slices/studentListSlice';
import ReactPaginate from 'react-paginate';
import Pagination from '../_partials/Pagination';
import { brand_colors } from '../../helpers/brand_colors_helper';
const portalURL = process.env.REACT_APP_PORTAL_URL;

class StudentReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      student: null,
      searchExpanded: false,
      cdt: {
        order: { column: 'child_id', type: 'DESC' },
        rows: [10, 25, 50, 'All'],
      },
    }
    this.searchFieldRef = React.createRef();
  }


  componentDidMount() {
    this.getStudents({
      rows: this.props.studentListRows,
      page: 0,
      search: '',
      status: this.props.studentListStatus
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.selectedClass && prevProps.selectedClass.class.class_id !== this.props.selectedClass.class.class_id) {
      this.getStudents({
        rows: this.props.studentListRows,
        page: 0,
        search: '',
        status: this.props.studentListStatus
      });
      this.setState({ searchExpanded: false });
      this.searchFieldRef.current.value = '';
    }
  }

  getStudents = (params) => {
    this.props.getStudents({
      child_status: params.status,
      search: params.search,
      limit: params.rows,
      page: params.page,
      showAllClasses: false
    });
  }

  render() {
    console.log('this.stateREPORT', this.state, this.props);
    return (
      <>
        <Card
          className='border-0'
          style={{ borderRadius: '20px' }}>
          <Card.Body>
            <Row>
              <Col md={12} className='d-flex flex-column'>
                <div className='d-flex flex-row align-items-center mb-2'>
                  <strong>{t("Reports")}</strong>
                  <div className='expandable-search ms-2' style={{ width: this.state.searchExpanded ? 200 : 35 }}>
                    <input
                      ref={this.searchFieldRef}
                      defaultValue={this.props.studentListSearch}
                      onChange={(e) => this.getStudents({
                        rows: this.props.studentListRows,
                        page: 0,
                        search: e.target.value,
                        status: this.props.studentListStatus
                      })}
                      type='text'
                      style={{ width: this.state.searchExpanded ? 165 : 0, paddingLeft: this.state.searchExpanded ? 15 : 0, opacity: this.state.searchExpanded ? 1 : 0 }}
                      placeholder={t('Search Student...')} />
                    <button
                      data-tooltip-id="tooltip"
                      data-tooltip-content={t('Search Student')}
                      data-tooltip-place="top"
                      onClick={() => {
                        this.setState({ searchExpanded: !this.state.searchExpanded });
                        this.props.studentListSearch != '' && this.state.searchExpanded && this.getStudents({
                          rows: this.props.studentListRows,
                          page: 0,
                          search: '',
                          status: this.props.studentListStatus
                        });
                        this.searchFieldRef.current.value = '';
                        !this.state.searchExpanded && this.searchFieldRef.current.focus();
                      }}>
                      <FontAwesomeIcon
                        icon={this.state.searchExpanded ? faXmark : faSearch}
                        color={brand_colors[this.props.defaultTheme.theme_id].color18}
                      />
                    </button>
                  </div>
                </div>
                <div className='d-flex flex-row align-items-center'>
                  <div>
                    {t('Show')}
                    <Form.Select
                      value={this.props.studentListRows}
                      size="sm"
                      onChange={(e) => this.getStudents({
                        rows: e.target.value,
                        page: 0,
                        search: this.props.studentListSearch,
                        status: this.props.studentListStatus
                      })}
                      style={{ width: 70, marginInline: 5, display: 'inline-block' }}>
                      {this.state.cdt.rows.map((row, row_index) => (<option key={row_index} >{row}</option>))}
                    </Form.Select>
                    {t('Records')}
                  </div>
                </div>
              </Col>
              {this.props.studentListFetching &&
                <Col md={12} className='py-5 d-flex flex-column align-items-center'>
                  <Spinner as='span' animation='grow' size='sm' /> {t('Loading Students')}
                </Col>
              }
              {!this.props.studentListFetching &&
                <Col md={12} className='pt-1 student-attendance-list'>
                  {this.props.studentListData.length <= 0 &&
                    <div className='d-flex flex-row justify-content-center'>
                      <Alert variant="danger">{t('No Students found in this Class')}</Alert>
                    </div>
                  }
                  {this.props.studentListData.map((student, student_index) => (
                    <Row key={student_index}
                      className='student-attendance-list-item'>
                      <Col md={3} className='d-flex align-items-center'>
                        <div style={{
                          backgroundImage: `url(${(student.picture) ? portalURL + this.props.authData.centerData.center_uuid + '/children/' + student.picture : 'https://via.placeholder.com/150x150?text=' + student.child_name[0].toUpperCase()})`,
                          width: 60,
                          height: 60,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center center',
                          borderRadius: 30,
                          marginRight: 15
                        }}></div>
                        {student.child_name}
                      </Col>
                      <Col>
                        <div className='d-flex align-items-center' style={{ padding: 10, flex: 1 }}>
                          {ifNavAllowed('report-student-integrity') &&
                            <NavLink className='me-1' to={`/report-student-integrity?id=` + student.child_id}>
                              <Button variant="outline-success" >
                                <FontAwesomeIcon icon={faChartPie} />
                                <br />
                                {t('General Performance')}
                              </Button>
                            </NavLink>
                          }
                          {ifNavAllowed('report-card') &&
                            <NavLink className='me-1' to={`/report-card?id=` + student.child_id}>
                              <Button variant="outline-success" >
                                <FontAwesomeIcon icon={faBook} />
                                <br />
                                {t('Report Card')}
                              </Button>
                            </NavLink>
                          }
                        </div>
                      </Col>
                    </Row>
                  ))}
                </Col>
              }
            </Row>
            <Pagination
              pageCount={this.props.studentListLastPage}
              forcePage={this.props.studentListPage}
              callbackParams={{
                rows: this.props.studentListRows,
                status: this.props.studentListStatus,
                search: this.props.studentListSearch
              }}
              callback={this.getStudents}
            />
          </Card.Body>
        </Card >
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  selectedClass: state.selectedClass.data,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
  studentListFetching: state.studentList.fetching,
  studentListData: state.studentList.data,
  studentListSearch: state.studentList.query,
  studentListRows: state.studentList.rows,
  studentListOffset: state.studentList.offset,
  studentListStatus: state.studentList.status,
  studentListOrder: state.studentList.order,
  studentListDir: state.studentList.dir,
  studentListLastPage: state.studentList.last_page,
  studentListPage: state.studentList.page,
  studentListTotalRecords: state.studentList.total_records,
});

const mapDispatchToProps = () => ({
  getStudents
});

export default connect(mapStateToProps, mapDispatchToProps())(StudentReports);
