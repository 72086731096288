import React, { Component } from 'react';
import { Container, Card, Row, Col, Button, Spinner, Modal, Form, Offcanvas, Badge, FormGroup, FormLabel, ButtonGroup, InputGroup } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import { GET, POST } from '../../api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faShapes, faCircle, faCircleCheck, faBorderNone, faChevronUp, faChevronDown, faSearch, faSubscript, faArrowsTurnRight, faCircleArrowRight, faXmark } from '@fortawesome/free-solid-svg-icons';
import GeneralCard from '../../helpers/GeneralCard';
import moment from 'moment';
import Pagination from '../_partials/Pagination';
import { brand_colors } from '../../helpers/brand_colors_helper';
import Select from 'react-select';
import { toast } from 'react-toastify';
import SweetAlert from "react-bootstrap-sweetalert";
import { DateRangePicker } from "react-date-range";
import { logger } from "redux-logger/src";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import { uniqueByAttribute2 } from "../../helpers/general_helpers";
const portalURL = process.env.REACT_APP_PORTAL_URL;
const portalURL2 = process.env.REACT_APP_API_URL;

class Evaluations extends Component {
  state = {
    deleteModal: { show: false, action: () => { } },
    evaluations: null,
    fetching: false,
    working: false,
    limit: 8,
    offset: 0,
    total_count: 0,
    page: 0,
    lastPage: 0,
    employees: [],
    groups: [],
    categories: [],
    topics: [],
    subTopics: [],
    attainments: [],
    // Evaluation Modal
    showModal: false,
    showModalInfo: false,
    isEditing: false,
    employee: null,
    appraisalgrp: 0,
    appraisalcat: null,
    appraisaltopic: null,
    appraisaltopicsub: null,
    appraisalempldetails: [],
    evaluation: null,
    evaluationDetails: null,
    appraisalemplData: {
      appraisalempl_date: null,
      employee_create: null,
      appraisalempl_id: null,
    },
    sumGrade: 0,
    filter: {
      show: false,
      toggleFilter: (show) => this.setState({
        filter: {
          ...this.state.filter,
          show: (this.state.filter.show == show) ? false : show
        }
      }),
      setData: (key, value) => {
        this.setState({ filter: { ...this.state.filter, [key]: value } }, () => {
          if (key == 'selectedClasses') {
            this.getClassesChildren(this.state.filter.selectedClasses, 'filter')
          }
        })
      },
      selectedDateRange: { startDate: new Date(moment().subtract(1, 'years')), endDate: new Date(), key: 'selection' },
      selectedEmployee: null,
      selectedGroup: null,
      selectedCategory: null,
      keyword: '',
    },

    termData: [],
    employeeClassData: [],
    classSubjectsData: [],
    selectedClass: null,
    selectedTerm: null,
    selectedSubject: null,
    employeeRoleId: null,
    areasOfStrengthValue: '',
    areasToBeImprovedValue: '',
  }

  componentDidMount() {
    this.getEmployees();
    this.getGroups();
    this.getAttainments();
    this.closeDropDownMenu()
    this.getEvaluations({
      limit: this.state.limit,
      page: 0,
    });
    this.handleGetAllTerm();

  }
  closeDropDownMenu() {
    this.state.filter.setData('show', false)
  }
  getEmployees = async () => {
    try {
      const res = await GET('appraisal/get-employees');
      console.log('getEmployees', res);
      if (res.status == 200) {
        this.setState({
          employees: res.data.map(item => {
            return {
              ...item,
              value: item.employee_id,
              label: item.employee_name
            }
          })
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  getGroups = async () => {
    try {
      const res = await GET('appraisal/get-appraisalgrp');
      console.log('getGroups', res);
      if (res.status == 200) {
        this.setState({
          groups: res.data.map(item => {
            return {
              ...item,
              value: item.appraisalgrp_id,
              label: item.appraisalgrp_code
            }
          })
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  getCategories = async (appraisalgrp_id) => {
    try {
      const res = await GET('appraisal/get-appraisalcat', {
        params: {
          appraisalgrp_id
        }
      });
      console.log('getCategories', res);
      if (res.status == 200) {
        this.setState({
          categories: res.data.map(item => {
            return {
              ...item,
              value: item.appraisalcat_id,
              label: item.appraisalcat_code
            }
          })
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  getTopicsSubTopics = async (appraisalcat_id) => {
    try {
      const res = await GET('appraisal/get-topics-sub-topics', {
        params: {
          appraisalcat_id
        }
      });
      console.log('getTopicsSubTopics', res);
      if (res.status == 200) {
        this.setState({
          topics: res.data.topics,
          subTopics: res.data.sub_topics,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  getAttainments = async () => {
    try {
      const res = await GET('appraisal/get-appraisalassessment');
      console.log('getAttainments', res);
      if (res.status == 200) {
        this.setState({
          attainments: res.data
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  getEvaluations = async (params) => {
    console.log("param", params)
    this.setState({
      fetching: true,

    });

    try {
      let employee_id = this.state.filter.selectedEmployee ? this.state.filter.selectedEmployee.employee_id : 0;
      let appraisalgrp_id = this.state.filter.selectedGroup ? this.state.filter.selectedGroup.appraisalgrp_id : 0;
      let appraisalcat_id = this.state.filter.selectedCategory ? this.state.filter.selectedCategory.appraisalcat_id : 0;
      let startDate = (this.state.filter.selectedDateRange) ? moment(this.state.filter.selectedDateRange.startDate).format('YYYY-MM-DD') + ' 00:00:00' : null
      let endDate = (this.state.filter.selectedDateRange) ? moment(this.state.filter.selectedDateRange.endDate).format('YYYY-MM-DD') + ' 00:00:00' : null
      console.log('employee_id', employee_id)

      const { center_id } = this.props.authData.centerData;
      const fData = new FormData();
      fData.append('created_by', this.props.authData.userData.employee_id);
      fData.append('center_id', center_id);
      fData.append('limit', params.limit);
      fData.append('offset', parseInt(params.page) * parseInt(params.limit));
      fData.append('employee_id', employee_id);
      fData.append('appraisalgrp_id', appraisalgrp_id);
      fData.append('appraisalcat_id', appraisalcat_id);
      fData.append('date_from', startDate)
      fData.append('date_to', endDate)

      const res = await POST('appraisal/get-appraisal-list', fData);
      console.log("resEvaluation", res)
      if (res.status == 200) {
        this.setState({
          evaluations: res.data.data,
          total_count: res.data.total_count_filtered,
          fetching: false,
          lastPage: Math.ceil(parseInt(res.data.total_count_filtered) / parseInt(params.limit)),
          page: params.page,
          selectedClass: null,
          selectedTerm: null,
          selectedSubject: null,
          employeeClassData: [],
          classSubjectsData: [],
        });
      }

      console.log('res', res);
    } catch (err) {
      console.log(err);
    }

  }

  saveEvaluation = async () => {
    console.log("saveEvaluation", this.state);
    this.setState({
      working: true,
      filter: { ...this.state.filter, show: false }
    });

    const {
      authData
    } = this.props;

    const {
      evaluation,
      employee,
      appraisalgrp,
      appraisalcat,
      isEditing,
      appraisalempldetails
    } = this.state;

    const appraisalempldetails_filtered = appraisalempldetails.filter(item => item.appraisalempl_comments ||
      item.appraisalempl_grade ||
      item.apprassmt_id);
    let class_id = this.state.selectedClass ? this.state.selectedClass.value : 0
    let term_id = this.state.selectedTerm ? this.state.selectedTerm.value : 0
    let acs_id = this.state.selectedSubject ? this.state.selectedSubject.value : 0
    let areas_of_strength = this.state.areasOfStrengthValue
    let areas_to_be_improved = this.state.areasToBeImprovedValue

    try {
      const fData = new FormData();
      fData.append('center_id', authData.centerData.center_id);
      fData.append('appraisalgrp_id', appraisalgrp.value);
      fData.append('appraisalcat_id', appraisalcat.value);
      fData.append('appraisalempl_emloyee_id', employee.value);
      fData.append('class_id', class_id)
      fData.append('term_id', term_id)
      fData.append('acs_id', acs_id)
      fData.append('areas_of_strength', areas_of_strength)
      fData.append('areas_to_be_improved', areas_to_be_improved)
      if (evaluation) {
        fData.append('appraisalempl_id', evaluation.appraisalempl_id);
      } else {
        fData.append('appraisalempl_id', 0);
      }

      if (authData.userData) {
        fData.append('employee_id', authData.userData.employee_id);
      } else {
        fData.append('employee_id', 0);
      }
      fData.append('appraisalempldetails', JSON.stringify(appraisalempldetails_filtered));
      if (this.saveEvaluationReq) {
        this.saveEvaluationReq.abort()
      }
      this.saveEvaluationReq = new AbortController()
      const res = await POST('appraisal/save-appraisal', fData, {
        signal: this.saveEvaluationReq.signal
      });

      if (res.data.type) {
        toast.success(t(res.data.message));
        this.state.showModal = false
        this.setState({
          filter: {
            ...this.state.filter,
            selectedEmployee: null,
            selectedGroup: null,
            selectedCategory: null,
            keyword: '',
          },
          areasToBeImprovedValue: '',
          areasOfStrengthValue: '',
          working: false
        }, () => {
          this.getEvaluations({
            limit: this.state.limit,
            page: this.state.page,
          });
        })
      } else {
        toast.error(t(res.data.message));
        this.setState({
          working: false
        });
      }
      console.log('saveEvaluation', res);
    } catch (err) {
      this.setState({
        working: false
      });
      console.log(err);
    }
  }

  startEvaluation = async () => {

    const { selectedEmployee, selectedGroup, selectedCategory } = this.state.filter;

    if (!selectedEmployee || !selectedGroup || !selectedCategory) {
      toast.error(t('Please select Employee, Group, and Category to start the Evaluation!'));
      return;
    }
    this.setState({
      selectedClass: null,
      selectedSubject: null,
      selectedTerm: null,
      isEditing: true, filter: { ...this.state.filter, show: false }
    }, () => {
      this.handleGetAllEmployeeClass();
    });

  }

  topicCheck = (item) => {
    return this.state.appraisaltopic?.appraisaltopic_id == item?.appraisaltopic_id;
  }

  subTopicCheck = (item) => {
    return this.state.appraisaltopicsub?.appraisaltopicsub_id == item?.appraisaltopicsub_id;
  }

  saveEvaluationDetails = (field, value, sub, topic) => {
    const { appraisaltopicsub, appraisalempldetails } = this.state;
    const findEvaluationIndex = appraisalempldetails.findIndex(item => item.appraisaltopic_id == topic && item.appraisaltopicsub_id == sub);
    if (findEvaluationIndex !== -1) {
      appraisalempldetails[findEvaluationIndex][field] = value;
    } else {
      appraisalempldetails.push({
        appraisaltopic_id: topic,
        appraisaltopicsub_id: sub,
        appraisalempldetails_id: 0,
        apprassmt_id: null,
        appraisalempl_grade: 0,
        appraisalempl_comments: '',
        areas_of_strength: '',
        areas_to_be_improved: '',
        [field]: value,
      })
    }
    this.setState({
      appraisalempldetails,
    });

  }

  handleOpenModal = async (data = null, isEditing = false) => {
    if (isEditing) {
      try {
        const res = await GET('appraisal/edit-appraisalempl', {
          params: {
            appraisalempl_id: data.appraisalempl_id,
          }
        });

        console.log('handleOpenModal Async Data', res.data);

        const {
          appraisalempl_emloyee_id,
          appraisalempl_employee_role_id,
          employee_name,
          appraisalgrp_id,
          appraisalgrp_code,
          appraisalassessment,
          appraisalcat_id,
          appraisalcat_code,
          class_id,
          acs_id,
          term_id,
          class_theme,
          acs_name,
          term_name,
          term_active,
          appraisalempl_areas_of_strength,
          appraisalempl_areas_to_be_improved,

        } = res.data.appraisalempl;

        const classObject = { value: class_id, label: class_theme };
        this.setState({
          topics: res.data.topics,
          subTopics: res.data.sub_topics,
          employee: {
            employee_id: appraisalempl_emloyee_id,
            employee_name: employee_name,
            value: appraisalempl_emloyee_id,
            label: employee_name,
            role_id: appraisalempl_employee_role_id,
          },
          appraisalgrp: {
            value: appraisalgrp_id,
            label: appraisalgrp_code,
            appraisalassessment,
          },
          appraisalcat: {
            value: appraisalcat_id,
            label: appraisalcat_code,
          },
          appraisalempldetails: res.data.appraisalempldetails,

          selectedClass: (class_id) ? classObject : null,
          selectedSubject: (acs_id) ? { value: acs_id, label: acs_name } : null,
          selectedTerm: (term_id) ? { value: term_id, label: term_name, status: term_active } : null,
          areasOfStrengthValue: appraisalempl_areas_of_strength,
          areasToBeImprovedValue: appraisalempl_areas_to_be_improved,
          evaluation: data,
          showModal: true,
          isEditing,
          filter: { ...this.state.filter, show: false }
        }, () => {
          this.handleGetAllEmployeeClass()
          if (class_id) {
            this.handleGetAllClassSubject(classObject)
          }
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      this.setState({
        evaluation: data,
        showModal: true,
        isEditing,
        filter: { ...this.state.filter, show: false }
      })
    }
  }
  handleDeleteAppraisal = (v) => {
    console.log("delete", v);
    console.log("evaluations", this.state.evaluations)
    try {
      this.setState({
        deleteModal: {
          ...this.state.deleteModal, show: true, action: async () => {
            if (this.deleteAppraisalReq) {
              this.deleteAppraisalReq.abort()
            }
            this.deleteAppraisalReq = new AbortController();
            let fData = new FormData();
            fData.append('appraisalempl_id', v.appraisalempl_id)
            const res = await POST('appraisal/delete-appraisal', fData, {
              signal: this.deleteAppraisalReq.signal
            })
            if (res) {
              let new_evaluation = this.state.evaluations.filter(item => item.appraisalempl_id !== v.appraisalempl_id);
              this.setState({
                evaluations: new_evaluation,
                deleteModal: {
                  ...this.state.deleteModal, show: false,
                },
              })
            }
          }
        }
      });

    }
    catch (e) {
      console.log(e)
    }
  }

  handleOpenModalInfo = async (data = null, isEditing = false) => {
    console.log('handleOpenModalInfo Data', data);

    this.setState({
      evaluation: data,
      showModalInfo: true,
      isEditing
    });

    if (isEditing) {
      try {
        const res = await GET('appraisal/edit-appraisalempl', {
          params: {
            appraisalempl_id: data.appraisalempl_id
          }
        });

        console.log('handleOpenModalInfo Async Data', res.data);

        const {
          appraisalempl_emloyee_id,
          employee_name,
          appraisalgrp_id,
          appraisalgrp_code,
          appraisalassessment,
          appraisalcat_id,
          appraisalcat_code,
          appraisalempl_date,
          employee_create,
          appraisalempl_id,
          class_theme,
          acs_name,
          term_name,
          appraisalempl_areas_of_strength,
          appraisalempl_areas_to_be_improved,
        } = res.data.appraisalempl;

        this.setState({
          topics: res.data.topics,
          subTopics: res.data.sub_topics,
          appraisalempldetails: res.data.appraisalempldetails,
          appraisalemplData: {
            appraisalempl_date,
            employee_create,
            appraisalempl_id,
            class_theme,
            acs_name,
            term_name,
            appraisalempl_areas_of_strength,
            appraisalempl_areas_to_be_improved,
          },
          employee: {
            value: appraisalempl_emloyee_id,
            label: employee_name,
            //role_id:
          },
          appraisalgrp: {
            value: appraisalgrp_id,
            label: appraisalgrp_code,
            appraisalassessment,
          },
          appraisalcat: {
            value: appraisalcat_id,
            label: appraisalcat_code,
          },

        });

      } catch (err) {
        console.log(err);
      }
    }

  }
  handleCloseModal = () => {
    this.setState({
      showModal: false,
      showModalInfo: false,
      isEditing: false,
      employee: null,
      appraisalgrp: null,
      appraisalcat: null,
      appraisaltopic: null,
      appraisaltopicsub: null,
      appraisalempldetails: [],
      evaluation: null,
      evaluationDetails: null,
    });
  }
  handleGetAllTerm = async () => {
    try {
      const res = await GET('appraisal/get-terms-centerlic')
      console.log("TermceternLic", res.data)
      if (res) {
        if (this.state.isEditing) {
          this.setState({
            termData: res.data.map(term => {
              return {
                label: term.term_name,
                value: term.term_id,
                status: term.term_active
              }
            }),
          });
        }
        else {
          let defaultTerm = res.data ? res.data.find(term => term.term_active == 1) : null
          const selectedTerm = {
            label: defaultTerm.term_name,
            value: defaultTerm.term_id,
            statue: defaultTerm.term_active
          }
          console.log("defaultTerm", defaultTerm)
          this.setState({
            termData: res.data.map(term => {
              return {
                label: term.term_name,
                value: term.term_id,
                status: term.term_active
              }
            }),
            selectedTerm: selectedTerm
          });
        }
      }
    }
    catch (e) {
      console.log(e)
    }
  }
  handleGetRoleId = (employee_id) => {
    let emp = this.state.employees.find(item => item.employee_id == employee_id)
    this.setState({ employeeRoleId: emp })
    return emp.role_id;
  }
  handleGetAllEmployeeClass = async () => {
    // let employeeId;
    // if (this.state.isEditing) {
    //   employeeId = employee_id
    // }
    // else {
    //   employeeId = this.state.employee ? this.state.employee.employee_id : 0
    // }


    try {
      const res = await GET('appraisal/get-assigned-classes', {
        params: {
          employee_id: this.state.employee.employee_id,
        }
      })
      this.setState({
        employeeClassData: res.data.map(item => {
          return {
            label: item.class_theme,
            value: item.class_id
          }
        })
      })
      console.log("employeeClass", res.data)
    }
    catch (e) {
      console.log(e)
    }
  }
  handleGetAllClassSubject = async (obj) => {
    this.setState({ selectedClass: obj })
    try {
      const res = await GET('appraisal/get-assigned-subjects', {
        params: {
          class_id: obj.value,
          employee_id: this.state.employee.employee_id,
          role_id: this.state.employee.role_id
        }
      })
      if (res) {
        let uniqueSubject = uniqueByAttribute2(res.data, ['acs_id']);
        this.setState({
          classSubjectsData: uniqueSubject.map(subject => {
            return {
              label: subject.acs_name,
              value: subject.acs_id
            }
          })
        })
      }
    }
    catch (e) {
      console.log(e)
    }
  }
  handleSumGrade = () => {

    let employeeGrade = 0;
    let maxGrade = 0;
    let results = 0;
    this.state.topics.map((item_topic, index) => {
      this.state.subTopics.map((sub_item, index_sub) => {
        let subTopicLength = this.state.subTopics.length;
        if (sub_item.appraisaltopic_id === item_topic.appraisaltopic_id) {
          this.state.appraisalempldetails.map((details, index_details) => {
            if (details.appraisaltopicsub_id == sub_item.appraisaltopicsub_id) {
              employeeGrade += parseInt(details.appraisalempl_grade);
              maxGrade += parseInt(sub_item.max_grade);

            }

          })
        }
      })
    })
    results = !isNaN((employeeGrade / maxGrade) * 100) ? (employeeGrade / maxGrade) * 100 : 0;
    return results.toFixed(2);
  }


  openPDF = (lang) => {
    let appraisalEmployee = {
      appraisalempl_id: this.state.appraisalemplData.appraisalempl_id,
      topics: this.state.topics,
      auth_key: this.props.authData.loginData.auth_key,
      center_id: this.props.authData.centerData.center_id,
      sum_grades: this.handleSumGrade(),
      lang: lang
    };
    const qURL = portalURL2 + 'appraisal/evaluation-employee-export?' + new URLSearchParams(appraisalEmployee).toString()
    window.open(qURL, "PDF" + "_EmployeeEvaluation_")
  }

  render() {

    return (
      <Container fluid>
        {/* FILTER BAR */}
        <div style={{ backgroundColor: brand_colors[this.props.defaultTheme.theme_id].header_bg }} className="filter-bar">
          <div className="filter">
            <strong className="filter-title">{t('Keyword')}</strong>
            <input type="text"
              className="paginate_search"
              placeholder="Enter Keyword..."
              defaultValue={this.state.filter.keyword}
              onClick={() => this.state.filter.toggleFilter(false)}
              onBlur={(e) => this.state.filter.setData('keyword', e.target.value)}
              style={{ backgroundColor: brand_colors[this.props.defaultTheme.theme_id].header_bg }}
            />
          </div>
          {/* DATE */}
          <div className="filter filter-date" >
            <div className="filter-trigger" onClick={() => this.state.filter.toggleFilter('date_range_picker')}>
              <FontAwesomeIcon
                icon={(this.state.filter.show == 'date_range_picker') ? faChevronUp : faChevronDown}
                color={brand_colors[this.props.defaultTheme.theme_id].color18}
                className="filter-arrow"
              />
              <strong className="filter-title">{t('Date Range')}</strong>
              <span className="selected-types">{moment(this.state.filter.selectedDateRange.startDate).format('DD-MM-YYYY')} -- {moment(this.state.filter.selectedDateRange.endDate).format('DD-MM-YYYY')}</span>
            </div>
            {(this.state.filter.show == 'date_range_picker')
              && <div className='filter-box' style={{ display: (this.state.filter.show == 'date_range_picker') ? 'initial' : 'none' }}>
                <DateRangePicker
                  ranges={[this.state.filter.selectedDateRange]}
                  onChange={(date) => {
                    this.state.filter.setData('selectedDateRange', { startDate: new Date(date.selection.startDate), endDate: new Date(date.selection.endDate), key: 'selection' })
                  }}
                  // className='filter-date-picker'
                  style={{ backgroundColor: '#fff' }}
                />
              </div>
            }
          </div>
          {/* Employee */}
          <div className="filter filter-students">
            <div className="filter-trigger" onClick={() => this.state.filter.toggleFilter('employee')}>
              <FontAwesomeIcon
                icon={(this.state.filter.show == 'employee') ? faChevronUp : faChevronDown}
                color={brand_colors[this.props.defaultTheme.theme_id].color18}
                className="filter-arrow"
              />
              <strong className="filter-title">{t('Employee')}</strong>
              <span className="selected-types"><span>{(this.state.filter.selectedEmployee && this.state.employee) ? this.state.filter.selectedEmployee.employee_name : 'Select'}</span></span>
            </div>
            <div className="filter-box" style={{ display: (this.state.filter.show == 'employee') ? 'initial' : 'none' }}>
              <Select
                isClearable
                value={this.state.employee}
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999, }), }}
                options={this.state.employees}
                onChange={(obj) => { this.setState({ employee: obj, }); this.state.filter.setData('selectedEmployee', obj) }}

              />
            </div>
          </div>

          {/* Group */}
          <div className="filter filter-students">
            <div className="filter-trigger" onClick={() => this.state.filter.toggleFilter('groups')}>
              <FontAwesomeIcon
                icon={(this.state.filter.show == 'groups') ? faChevronUp : faChevronDown}
                color={brand_colors[this.props.defaultTheme.theme_id].color18}
                className="filter-arrow"
              />
              <strong className="filter-title">{t('Group')}</strong>
              <span className="selected-types"><span>{(this.state.filter.selectedGroup && this.state.appraisalgrp) ? this.state.filter.selectedGroup.appraisalgrp_code : 'Select'}</span></span>
            </div>


            <div className="filter-box" style={{ display: (this.state.filter.show == 'groups') ? 'initial' : 'none' }}>
              <Select
                value={this.state.appraisalgrp}
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999, }), }}
                options={this.state.groups}
                onChange={(obj) => { this.setState({ appraisalgrp: obj }, () => this.getCategories(obj.value)); this.state.filter.setData('selectedGroup', obj) }}
                formatOptionLabel={({ label, appraisalassessment }) => (
                  <div>{label}
                    <Badge className='ms-1' pill bg={appraisalassessment == 1 ? "success" : "dark"}>
                      {appraisalassessment == 1 ? t('Attainment') : t('Grade')}
                    </Badge>
                  </div>
                )}
              />
            </div>
          </div>

          {/* Category */}
          <div className="filter filter-students">
            <div className="filter-trigger" onClick={() => this.state.filter.toggleFilter('category')}>
              <FontAwesomeIcon
                icon={(this.state.filter.show == 'category') ? faChevronUp : faChevronDown}
                color={brand_colors[this.props.defaultTheme.theme_id].color18}
                className="filter-arrow"
              />
              <strong className="filter-title">{t('Category')}</strong>
              <span className="selected-types"><span>{(this.state.filter.selectedCategory && this.state.appraisalcat) ? this.state.filter.selectedCategory.appraisalcat_code : 'Select'}</span></span>
            </div>

            <div className="filter-box" style={{ display: (this.state.filter.show == 'category') ? 'initial' : 'none' }}>
              <Select

                value={this.state.appraisalcat}
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999, }), }}
                options={this.state.categories}
                onChange={(obj) => { this.setState({ appraisalcat: obj }, () => this.getTopicsSubTopics(obj.value)); this.state.filter.setData('selectedCategory', obj) }}
              />
            </div>
          </div>
          <div className="filter-btn d-flex align-items-center" style={{ marginLeft: 10 }}>
            <button type="button"
              className="btn btn-primary" id="btn_filter_modal_apply"
              style={{ width: 50, height: 50, textAlign: 'center' }}
              onClick={() => {
                this.setState({
                  filter: {
                    ...this.state.filter,
                    show: ''
                  }
                });
                this.getEvaluations({
                  limit: this.state.limit,
                  page: this.state.page,
                });
              }}
            >
              <FontAwesomeIcon
                icon={faSearch}
                color={brand_colors[this.props.defaultTheme.theme_id].color8}
                style={{ fontSize: '24px', }}
              />
            </button>
          </div>


          <div className="filter-btn d-flex align-items-center" style={{ marginLeft: 10 }}>
            <Button variant="success" size="sm" title={t('Start Appraisal')}
              onClick={() => this.handleOpenModal()} style={{ width: 50, height: 50, textAlign: 'center' }}>
              <FontAwesomeIcon icon={faPlus}
                style={{ fontSize: '24px', }}
              />
            </Button>
          </div>
        </div>

        {this.state.evaluations == null &&
          <div className='text-center p-4'
            style={{ flex: 1 }}>
            <Spinner animation="grow" size="sm" className='me-2' />{t('Loading')}
          </div>
        }
        {this.state.evaluations && this.state.evaluations.length == 0 &&
          <div className='text-center p-4'
            style={{ flex: 1 }}>
            {t('No Data Found')}
          </div>
        }
        {this.state.evaluations &&
          <div className='d-flex flex-row flex-wrap'>
            {this.state.evaluations.map((item, index) => (
              <GeneralCard key={index} data={{
                cardImage: item.employee_picture ? portalURL + this.props.authData.centerData.center_uuid + '/employees/' + item.employee_picture : require('../../assets/images/user.jpg'),
                name: item.employee_name,
                code: <><br />{moment(item.appraisalempl_date).format('DD-MM-YYYY')}</>,
                text1: <Badge bg='info'>{item.crole_name}</Badge>,
                onClick: () => this.handleOpenModal(item, true),
                actions: [
                  {
                    type: 'edit',
                    name: 'Edit',
                    image: require('../../assets/images/edit.png'),
                    onClick: () => this.handleOpenModal(item, true)
                  },
                  {
                    type: 'report',
                    name: 'Report',
                    image: require('../../assets/images/info-slim-yellow.png'),
                    onClick: () => this.handleOpenModalInfo(item, true)
                  },
                  {
                    type: 'delete',
                    name: 'Delete',
                    image: require('../../assets/images/remove-red.png'),
                    onClick: () => this.handleDeleteAppraisal(item)
                  }
                ],
                extraTexts: [
                  {
                    type: 'text',
                    name: 'Group',
                    value: ' ' + item.appraisalgrp_code,
                    icon: faBorderNone,
                    iconColor: brand_colors[this.props.defaultTheme.theme_id].color1,
                  },
                  {
                    type: 'text',
                    name: 'Category',
                    value: item.appraisalcat_code,
                    icon: faShapes,
                    iconColor: brand_colors[this.props.defaultTheme.theme_id].color1,
                  },
                  {
                    type: 'text',
                    name: 'Category',
                    value: <strong>{'Term' + ' : ' + (item.term_name ?? '---')}</strong>,
                    iconColor: brand_colors[this.props.defaultTheme.theme_id].color1,
                  },
                  {
                    type: 'text',
                    name: 'Category',
                    value: <strong>{'Class' + ' : ' + (item.class_theme ?? '---')}</strong>,
                    // icon: faShapes,
                    iconColor: brand_colors[this.props.defaultTheme.theme_id].color1,
                  },
                  {
                    type: 'text',
                    name: 'Category',
                    value: <strong>{'Subject' + ' : ' + (item.acs_name ?? '---')}</strong>,
                    //icon: faSubscript,
                    iconColor: brand_colors[this.props.defaultTheme.theme_id].color1,
                  },
                ]
              }} />
            ))}
          </div>
        }
        <Pagination
          pageCount={this.state.lastPage || 1}
          forcePage={this.state.page}
          callbackParams={{
            limit: this.state.limit,
          }}
          callback={this.getEvaluations}
        />

        {/* Add Madel Start*/}
        <Offcanvas show={this.state.showModal} onHide={this.handleCloseModal} placement="bottom" style={{ height: '100%', width: '60%' }}>
          <Offcanvas.Header closeButton className='pb-0'>
            <Offcanvas.Title>{t('Evaluation')}</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className='d-flex flex-column' style={{ height: '100%' }}>
              <div className='custom-styled-form' style={{ flex: 1 }}>
                {/* Add Mode Start*/}
                {!this.state.isEditing &&
                  <div style={{ backgroundColor: brand_colors[this.props.defaultTheme.theme_id].header_bg }} className="filter-bar">
                    {/* Employee */}
                    <div className="filter filter-students">
                      <div className="filter-trigger" onClick={() => this.state.filter.toggleFilter('employee')}>
                        <FontAwesomeIcon
                          icon={(this.state.filter.show == 'employee') ? faChevronUp : faChevronDown}
                          color={brand_colors[this.props.defaultTheme.theme_id].color18}
                          className="filter-arrow"
                        />
                        <strong className="filter-title">{t('Employee')}</strong>
                        <span className="selected-types"><span>{(this.state.filter.selectedEmployee && this.state.employee) ? this.state.filter.selectedEmployee.employee_name : 'Select'}</span></span>
                      </div>
                      <div className="filter-box" style={{ display: (this.state.filter.show == 'employee') ? 'initial' : 'none' }}>
                        <Select
                          isClearable
                          value={this.state.employee}
                          menuPortalTarget={document.body}
                          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999, }), }}
                          options={this.state.employees}
                          onChange={(obj) => { this.setState({ employee: obj }); this.state.filter.setData('selectedEmployee', obj) }}
                        />
                      </div>
                    </div>
                    {/* Group */}
                    <div className="filter filter-students">
                      <div className="filter-trigger" onClick={() => this.state.filter.toggleFilter('groups')}>
                        <FontAwesomeIcon
                          icon={(this.state.filter.show == 'groups') ? faChevronUp : faChevronDown}
                          color={brand_colors[this.props.defaultTheme.theme_id].color18}
                          className="filter-arrow"
                        />
                        <strong className="filter-title">{t('Group')}</strong>
                        <span className="selected-types"><span>{(this.state.filter.selectedGroup && this.state.appraisalgrp) ? this.state.filter.selectedGroup.appraisalgrp_code : 'Select'}</span></span>
                      </div>


                      <div className="filter-box" style={{ display: (this.state.filter.show == 'groups') ? 'initial' : 'none' }}>
                        <Select
                          value={this.state.appraisalgrp}
                          menuPortalTarget={document.body}
                          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999, }), }}
                          options={this.state.groups}
                          onChange={(obj) => { this.setState({ appraisalgrp: obj }, () => this.getCategories(obj.value)); this.state.filter.setData('selectedGroup', obj) }}
                          formatOptionLabel={({ label, appraisalassessment }) => (
                            <div>{label}
                              <Badge className='ms-1' pill bg={appraisalassessment == 1 ? "success" : "dark"}>
                                {appraisalassessment == 1 ? t('Attainment') : t('Grade')}
                              </Badge>
                            </div>
                          )}
                        />
                      </div>
                    </div>

                    {/* Category */}
                    <div className="filter filter-students">
                      <div className="filter-trigger" onClick={() => this.state.filter.toggleFilter('category')}>
                        <FontAwesomeIcon
                          icon={(this.state.filter.show == 'category') ? faChevronUp : faChevronDown}
                          color={brand_colors[this.props.defaultTheme.theme_id].color18}
                          className="filter-arrow"
                        />
                        <strong className="filter-title">{t('Category')}</strong>
                        <span className="selected-types"><span>{(this.state.filter.selectedCategory && this.state.appraisalcat) ? this.state.filter.selectedCategory.appraisalcat_code : 'Select'}</span></span>
                      </div>


                      <div className="filter-box" style={{ display: (this.state.filter.show == 'category') ? 'initial' : 'none' }}>
                        <Select
                          value={this.state.appraisalcat}
                          menuPortalTarget={document.body}
                          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999, }), }}
                          options={this.state.categories}
                          onChange={(obj) => { this.setState({ appraisalcat: obj }, () => this.getTopicsSubTopics(obj.value)); this.state.filter.setData('selectedCategory', obj) }}
                        />
                      </div>
                    </div>
                    <div className="filter-btn d-flex align-items-center" style={{ marginLeft: 10 }}>
                      <Button variant="success" size="sm" title={t('Start Appraisal')}
                        onClick={() => this.startEvaluation()} style={{ width: 50, height: 50, textAlign: 'center' }}>
                        <FontAwesomeIcon icon={faCircleArrowRight}
                          style={{ fontSize: '24px', }}
                        />
                      </Button>
                    </div>
                  </div>
                }

                {this.state.isEditing &&
                  <>
                    <div className='text-center mb-3'>
                      <h5 className='mb-0'>{this.state.employee?.label}</h5>
                      {this.state.appraisalgrp?.label}<br />
                      {this.state.appraisalcat?.label}
                    </div>
                    {/* New Design Start */}
                    {this.state.topics &&
                      <>
                        <div className='eval-topics-sub-topics-heading1'>
                        </div>
                        <div className='grey-section'>
                          <Row>
                            <Col md={12}>
                              <Row>
                                {/*DATE */}
                                <Col md={4} className='position-relative pe-0'>
                                  <Form.Label>{t('Term')}</Form.Label>
                                  <Select
                                    placeholder={t('Select Term')}
                                    options={this.state.termData}
                                    onChange={(event) => this.setState({ selectedTerm: event })}
                                    value={this.state.selectedTerm}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    formatOptionLabel={option => (
                                      <div className='d-flex align-items-center'>
                                        <span style={{ fontSize: 16 }} className='me-1'>{option.label}</span>
                                        {option.status == 1 &&
                                          <Badge pill bg="success">{t('Active')}</Badge>}
                                      </div>
                                    )}
                                  />
                                </Col>
                                <Col md={4} className='pe-0 custom-styled-form'>
                                  <Form.Label>{t('Class')}</Form.Label>
                                  <Select
                                    isClearable
                                    placeholder={t('Select Class')}
                                    options={this.state.employeeClassData}
                                    onChange={this.handleGetAllClassSubject}
                                    value={this.state.selectedClass}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    formatOptionLabel={option => (
                                      <div className='d-flex align-items-center'>
                                        <span style={{ fontSize: 16 }} className='me-1'>{option.label}</span>
                                        {option.reg_status == 1 &&
                                          <Badge pill bg="success">{t('Active')}</Badge>}
                                      </div>
                                    )}
                                  />
                                </Col>
                                <Col md={4} className='pe-0 custom-styled-form'>
                                  <Form.Label>{t('Subject')}</Form.Label>
                                  <Select
                                    isClearable
                                    placeholder={t('Select Subject')}
                                    options={this.state.classSubjectsData}
                                    onChange={(obj) => {
                                      this.setState({ selectedSubject: obj })
                                    }}
                                    value={this.state.selectedSubject}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    formatOptionLabel={option => (
                                      <div className='d-flex align-items-center'>
                                        <span style={{ fontSize: 16 }} className='me-1'>{option.label}</span>
                                        {option.term_active == 1 &&
                                          <Badge pill bg="success">{t('Active')}</Badge>}
                                      </div>
                                    )}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </div>

                        <div className='eval-topics-sub-topics1 mb-3'>
                          {this.state.topics.map((item_topic, index) => {
                            return <>
                              <Row style={{ marginBottom: 20 }}>
                                <Col key={index} md={12}>
                                  <span key={index} style={{

                                    backgroundColor: '#F2F2F2',
                                    borderColor: '#D1F6DA',
                                    padding: 10,
                                    borderRadius: 10,
                                    fontWeight: "bold",
                                    fontSize: 20
                                  }}
                                  >
                                    {item_topic.appraisaltopic_code}
                                  </span>
                                  {
                                    this.state.subTopics.map((sub_item, index_sub) => {
                                      let subTopicLength = this.state.subTopics.length;
                                      if (sub_item.appraisaltopic_id === item_topic.appraisaltopic_id) {

                                        let detailsTemp = this.state.appraisalempldetails.find((details, index_details) => (
                                          (details.appraisaltopicsub_id === sub_item.appraisaltopicsub_id) &&
                                          (details.appraisaltopic_id === sub_item.appraisaltopic_id)))

                                        return <>
                                          <Row key={index_sub}>
                                            <Col md={12} style={{ marginLeft: 20, marginTop: 24 }}>
                                              <span key={index}
                                                style={{
                                                  backgroundColor: '#F2F2F2',
                                                  borderColor: '#F2F2F2',
                                                  borderRadius: 10,
                                                  marginBottom: 10,
                                                  padding: 10
                                                }}
                                              >
                                                {sub_item.appraisaltopicsub_code}
                                              </span>
                                            </Col>
                                          </Row>
                                          <Row className='mb-2' style={{ marginLeft: 20, marginTop: 24 }}>
                                            <Col md={12} >
                                              <div style={{
                                                backgroundColor: '#F2F2F2',
                                                borderRadius: 10,
                                                padding: 20,
                                                minHeight: 114
                                              }}>
                                                {sub_item.appraisaltopicsub_desc &&
                                                  <p>{sub_item.appraisaltopicsub_desc}</p>
                                                }
                                                {sub_item.appraisaltopicsub_desc2 &&
                                                  <p className="mb-0">{sub_item.appraisaltopicsub_desc2}</p>
                                                }
                                              </div>
                                              <div>
                                                <Form.Group className="mb-3">
                                                  <Form.Label>{t('Comment')}</Form.Label>
                                                  <Form.Control as="textarea" style={{
                                                    backgroundColor: '#F2F2F2',
                                                    borderColor: '#D1F6DA',
                                                  }} rows={4}
                                                    value={detailsTemp ? detailsTemp.appraisalempl_comments : ''}
                                                    onChange={(e) => this.saveEvaluationDetails('appraisalempl_comments', e.target.value, sub_item.appraisaltopicsub_id, sub_item.appraisaltopic_id)} />
                                                </Form.Group>
                                              </div>
                                            </Col>
                                            <Col md={12}></Col>
                                          </Row>
                                          <Row style={{ marginLeft: 20, marginTop: 24 }}>
                                            <Col>
                                              {this.state.appraisalgrp &&
                                                this.state.appraisalgrp.appraisalassessment == 1 ?
                                                <div>
                                                  {this.state.attainments
                                                    .filter(item => item.appraisalgrp_id == this.state.appraisalgrp.appraisalgrp_id)
                                                    .map((item, index) => {
                                                      let bg = 'transparent';
                                                      let color = item.apprassmt_color;
                                                      const matchingDetail = this.state.appraisalempldetails.find(details => details.apprassmt_id === item.apprassmt_id && details.appraisaltopicsub_id == sub_item.appraisaltopicsub_id &&
                                                        details.appraisaltopic_id == sub_item.appraisaltopic_id)

                                                      if (matchingDetail) {
                                                        bg = item.apprassmt_color;
                                                        color = '#fff';
                                                      }
                                                      return (
                                                        <button key={index}
                                                          onClick={() => this.saveEvaluationDetails('apprassmt_id', item.apprassmt_id, sub_item.appraisaltopicsub_id, sub_item.appraisaltopic_id)}
                                                          style={{
                                                            borderColor: item.apprassmt_color,
                                                            borderWidth: 2,
                                                            borderStyle: 'solid',
                                                            color: color,
                                                            height: 35,
                                                            borderRadius: 10,
                                                            fontSize: 15,
                                                            marginRight: 10,
                                                            backgroundColor: bg,
                                                            paddingInline: 15
                                                          }}>{item.apprassmt_code}</button>
                                                      );
                                                    })}
                                                </div>
                                                :
                                                <div>
                                                  {Array.from({ length: (parseInt(sub_item.max_grade) + 1) }).map((_, index) => {
                                                    let bg = '#F2F2F2';
                                                    let color = '#7B7B7B';
                                                    let grade = index.toString();
                                                    let grade2 = detailsTemp ? detailsTemp.appraisalempl_grade : ''
                                                    if (grade2 == grade) {
                                                      bg = brand_colors[this.props.defaultTheme.theme_id].color1;
                                                      color = '#fff';
                                                    }
                                                    return (
                                                      <button key={index}
                                                        onClick={() => this.saveEvaluationDetails('appraisalempl_grade', grade, sub_item.appraisaltopicsub_id, sub_item.appraisaltopic_id)}
                                                        style={{
                                                          backgroundColor: bg,
                                                          color: color,
                                                          border: 0,
                                                          width: 40,
                                                          height: 40,
                                                          borderRadius: 10,
                                                          fontSize: 17,
                                                          marginRight: 25
                                                        }}>{index}</button>
                                                    )
                                                  })}
                                                </div>
                                              }
                                            </Col>
                                          </Row>
                                          <hr className="hr-text" data-content={"Sub Topic"} />
                                        </>
                                        //))
                                      }
                                    })
                                  }
                                </Col>
                              </Row>
                              <hr className="hr-text" data-content={"Main Topic" + '-' + parseInt(index + 1)} />
                            </>
                          })
                          }
                        </div>
                      </>
                    }
                    <Row>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Areas of strength')}</Form.Label>
                          <Form.Control as="textarea" style={{
                            backgroundColor: '#F2F2F2',
                            borderColor: '#D1F6DA',
                          }} rows={2}
                            value={this.state.areasOfStrengthValue}
                            onChange={(e) => this.setState({ areasOfStrengthValue: e.target.value })} />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Areas to be improved')}</Form.Label>
                          <Form.Control as="textarea" style={{
                            backgroundColor: '#F2F2F2',
                            borderColor: '#D1F6DA',
                          }} rows={2}
                            value={this.state.areasToBeImprovedValue}
                            onChange={(e) => this.setState({ areasToBeImprovedValue: e.target.value })} />
                        </Form.Group>
                      </Col>
                    </Row>
                  </>
                }
              </div>
              <div className='text-end' style={{ paddingTop: 102, paddingBottom: 35 }}>
                <Button disabled={this.state.working} variant={this.state.isEditing ? "warning" : "success"} onClick={this.state.isEditing ? this.saveEvaluation : ''}>
                  {this.state.isEditing ? t('Update') : t('Save')}
                  {this.state.working &&
                    <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />
                  }
                </Button>
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
        {/* Add Mode End*/}


        {/* Info Mode Start */}
        <Offcanvas show={this.state.showModalInfo} onHide={this.handleCloseModal} placement="bottom" style={{ height: '100%' }}>
          <Offcanvas.Header closeButton className='pb-0'>
            <Offcanvas.Title style={{ textAlign: "center" }}>{t('Evaluation Info')}</Offcanvas.Title>
            <div className="d-flex justify-content-center">
              <Button className='me-1' onClick={() => this.openPDF(0)}>{t('Export En')}</Button>
              <Button onClick={() => this.openPDF(1)}>{t('Export AR')}</Button>
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className='d-flex flex-column' style={{ height: '100%' }}>
              <div className='custom-styled-form' style={{ flex: 1 }}>
                <>
                  <div>
                    <Row>
                      <Col md={6}>
                        <img style={{ width: 135 }} src={this.props.authData.centerData.logo}></img>
                      </Col>
                      <Col md={6}>
                        <h1 style={{ fontSize: 22, paddingLeft: 263 }}>{this.props.authData.centerData.center_name}</h1>
                      </Col>
                    </Row>
                    <Row style={{ textAlign: "center" }}>
                      <Col md={3} className="ml-10">
                        <h3 className='mb-0'>{this.state.employee?.label}</h3>
                        {this.state.appraisalgrp?.label}<br />
                        {this.state.appraisalcat?.label}
                      </Col>
                      <Col md={3}>
                        {this.state.appraisalemplData.employee_create ? this.state.appraisalemplData.employee_create : '----'}
                      </Col>
                      <Col md={3}>
                        {this.state.appraisalemplData.appraisalempl_date}
                      </Col>
                      <Col md={3}>
                        {this.handleSumGrade() + '%'}
                      </Col>
                    </Row>
                  </div>


                  <div className='text-center mb-3'>
                  </div>

                  {this.state.topics &&

                    <>
                      <div className='eval-topics-sub-topics-heading'>
                      </div>
                      <Row>
                        <Col md={3}>
                          <Form.Group className="mb-3">
                            <Form.Label>{t('Term')}</Form.Label>
                            <Form.Control type="text"


                              disabled={true}
                              Value={this.state.appraisalemplData.term_name ?? '---'}

                            />
                          </Form.Group>
                        </Col>
                        <Col md={3}>
                          <Form.Group className="mb-3">
                            <Form.Label>{t('Class')}</Form.Label>
                            <Form.Control type="text"
                              disabled={true}
                              Value={this.state.appraisalemplData.class_theme ?? '---'}

                            />
                          </Form.Group>
                        </Col>
                        <Col md={3}>
                          <Form.Group className="mb-3">
                            <Form.Label>{t('Subject')}</Form.Label>
                            <Form.Control type="text"
                              disabled={true}
                              Value={this.state.appraisalemplData.acs_name ?? '---'}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <div>
                        {this.state.topics.map((topic_item, index) => (
                          <Row>
                            <Col key={index} style={{ borderRadius: '10px' }}>
                              {
                                this.state.subTopics.map((subtopic_item, index) => {
                                  if (subtopic_item.appraisaltopic_id === topic_item.appraisaltopic_id) {
                                    return this.state.appraisalempldetails.map((details, index) => {
                                      if (details.appraisaltopicsub_id === subtopic_item.appraisaltopicsub_id) {
                                        console.log("details----", details)
                                        return (
                                          <div key={index} className="mb-2">
                                            <span className="badge badge-light-primary"
                                              style={{
                                                fontSize: '17px',
                                                marginBottom: '14px',
                                                marginTop: '12px'
                                              }}>
                                              {
                                                topic_item.appraisaltopic_code
                                              }
                                            </span>
                                            <Row>
                                              <Col md={12}>
                                                <div style={{ display: 'flex', alignItems: 'center', paddingLeft: 18 }}>
                                                  <div className="badge badge-light-success">
                                                    {subtopic_item.appraisaltopicsub_code}
                                                  </div>
                                                  {this.state.appraisalgrp.appraisalassessment === 1 ? (
                                                    <div style={{ padding: 10 }}>
                                                      {/*<span className="" style={{ marginLeft: 7, fontSize: 14,padding:10 ,border:'2px solid '+details.apprassmt_color  }}>*/}
                                                      {/*    {details.apprassmt_desc}*/}
                                                      {/*</span>*/}
                                                      <span style={{
                                                        borderColor: details.apprassmt_color,
                                                        borderWidth: 2,
                                                        borderStyle: 'solid',
                                                        color: '#fff',
                                                        height: 35,
                                                        borderRadius: 10,
                                                        fontSize: 15,
                                                        marginRight: 10,
                                                        backgroundColor: details.apprassmt_color,
                                                        paddingInline: 15,
                                                        padding: 10
                                                      }}>
                                                        {details.apprassmt_desc}
                                                      </span>
                                                    </div>

                                                  ) : (
                                                    <div style={{ padding: 10 }}>
                                                      <span className="badge badge-outline-success" style={{ marginLeft: 7, fontSize: 14 }}>
                                                        {details.appraisalempl_grade}
                                                      </span>
                                                    </div>
                                                  )}
                                                </div>

                                                <div style={{ paddingLeft: 68 }}>
                                                  <div>
                                                    <p>{subtopic_item.appraisaltopicsub_desc}</p>
                                                    <br />
                                                    <p>{subtopic_item.appraisaltopicsub_desc2}</p>
                                                  </div>
                                                  <br />
                                                  <div>
                                                    <p>
                                                      {/* <strong>Comment :</strong>  */}
                                                      {details.appraisalempl_comments}
                                                    </p>
                                                  </div>
                                                </div>

                                              </Col>
                                            </Row>
                                            <hr className="hr-text" data-content={"Sub Topic"} />
                                          </div>

                                        );

                                      }
                                      // {this.setState('sumGrade',{sumResult}) }
                                      return null;
                                    });
                                  }

                                  return null; // If the condition is not met
                                })
                              }

                            </Col>
                          </Row>
                        ))
                        }
                        <Row>
                          <Col md={3}>
                            <Form.Group className="mb-3">
                              {/* <Form.Label>{t('Area Of Strength')}</Form.Label> */}
                              <Form.Control type="text"


                                disabled={true}
                                Value={this.state.appraisalemplData.appraisalempl_areas_of_strength ?? '---'}

                              />
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group className="mb-3">
                              {/* <Form.Label>{t('Areas To Be Improved')}</Form.Label> */}
                              <Form.Control type="text"
                                disabled={true}
                                Value={this.state.appraisalemplData.appraisalempl_areas_to_be_improved ?? '---'}

                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>
                    </>
                  }
                  {this.state.subTopics && this.state.appraisaltopic &&
                    <>
                      <div className='eval-topics-sub-topics-heading'>
                        <span>{t('Sub-Topics')}</span>
                      </div>
                      <div className='eval-topics-sub-topics mb-3'>
                        {this.state.subTopics
                          .filter(item => item.appraisaltopic_id == this.state.appraisaltopic.appraisaltopic_id)
                          .map((item, index) => (
                            <button key={index} style={{
                              color: this.subTopicCheck(item) ? '#48CF48' : '#7B7B7B',
                              backgroundColor: this.subTopicCheck(item) ? '#D1F6DA' : '#F2F2F2',
                              borderColor: this.subTopicCheck(item) ? '#D1F6DA' : '#F2F2F2',
                            }}
                            >
                              <FontAwesomeIcon icon={this.subTopicCheck(item
                              ) ? faCircleCheck : faCircle} color={this.subTopicCheck(item
                              ) ? '#48CF48' : '#fff'} className='me-1' /> {item.appraisaltopicsub_code}</button>
                          ))}
                      </div>
                    </>
                  }
                  {this.state.appraisaltopicsub &&
                    <>
                      <div className='eval-topics-sub-topics-heading mb-3'>
                        <span>{t('Details')}</span>
                      </div>
                      <Row className='mb-2'>
                        <Col md={6}>
                          <div style={{
                            backgroundColor: '#F2F2F2',
                            borderRadius: 10,
                            padding: 20,
                            minHeight: 114
                          }}>
                            {this.state.appraisaltopicsub.appraisaltopicsub_desc &&
                              <p>{this.state.appraisaltopicsub.appraisaltopicsub_desc}</p>
                            }
                            {this.state.appraisaltopicsub.appraisaltopicsub_desc2 &&
                              <p className="mb-0">{this.state.appraisaltopicsub.appraisaltopicsub_desc2}</p>
                            }
                          </div>
                        </Col>
                        <Col md={6} style={{ marginTop: -22 }}>
                          <Form.Group className="mb-3">
                            <Form.Label>{t('Comment')}</Form.Label>
                            <Form.Control as="textarea" rows={4}
                              value={this.state.evaluationDetails ? this.state.evaluationDetails.appraisalempl_comments : ''}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={12}></Col>
                      </Row>
                      <div className='eval-topics-sub-topics-heading mb-2'>
                        <span>
                          {this.state.appraisalgrp.appraisalassessment == 1 ? t('Attainments') : t('Grades')}
                        </span>
                      </div>
                      {this.state.appraisalgrp.appraisalassessment == 1 ?
                        <div>
                          {this.state.attainments.map((item, index) => {

                            let bg = 'transparent';
                            let color = item.apprassmt_color;

                            if (
                              this.state.evaluationDetails &&
                              this.state.evaluationDetails.apprassmt_id == item.apprassmt_id
                            ) {
                              bg = item.apprassmt_color;
                              color = '#fff';
                            }

                            return (
                              <button key={index}
                                style={{
                                  borderColor: item.apprassmt_color,
                                  borderWidth: 2,
                                  borderStyle: 'solid',
                                  color: color,
                                  height: 35,
                                  borderRadius: 10,
                                  fontSize: 15,
                                  marginRight: 10,
                                  backgroundColor: bg,
                                  paddingInline: 15
                                }}>{item.apprassmt_code}</button>
                            );
                          })}
                        </div>
                        :
                        <div>
                          {Array.from({ length: (parseInt(this.state.appraisaltopicsub.max_grade) + 1) }).map((_, index) => {

                            let bg = '#F2F2F2';
                            let color = '#7B7B7B';

                            let grade = index.toString();

                            if (this.state.evaluationDetails && this.state.evaluationDetails.appraisalempl_grade == grade) {
                              bg = brand_colors[this.props.defaultTheme.theme_id].color1;
                              color = '#fff';
                            }

                            return (
                              <button key={index}
                                style={{
                                  backgroundColor: bg,
                                  color: color,
                                  border: 0,
                                  width: 40,
                                  height: 40,
                                  borderRadius: 10,
                                  fontSize: 17,
                                  marginRight: 10
                                }}>{index}</button>
                            )
                          })}
                        </div>
                      }
                    </>
                  }
                </>

              </div>
            </div>
          </Offcanvas.Body>

        </Offcanvas>
        {/* Info Mode End*/}
        <SweetAlert show={this.state.deleteModal.show} warning showCancel confirmBtnText={t('Yes, delete it!')} confirmBtnBsStyle='danger' title={t('Are you sure?')} onConfirm={this.state.deleteModal.action} onCancel={() => this.setState({ deleteModal: { ...this.state.deleteModal, show: false, action: () => { } } })} focusCancelBtn>
          {t('Confirm Delete!')}
        </SweetAlert>
      </Container >
    );
  }
}


const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(Evaluations);
