import React, { Component, Fragment } from 'react';
import { Container, Card, Table, Row, Col, Spinner, Image, Badge, Button, Form, Offcanvas, Accordion, Modal, Alert } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faCalendarAlt, faCheck, faClock, faEdit, faExternalLink, faFileAlt, faGear, faPaperclip, faSave, faSearch, faTimes, faTrashCan, } from '@fortawesome/free-solid-svg-icons';
import { Scrollbar } from 'react-scrollbars-custom';
import imgDescription from '../../assets/images/description.png';
import { brand_colors } from '../../helpers/brand_colors_helper';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import { handleFormErrors } from '../../helpers/form_helpers';
import { GET } from '../../api';
import { getUrlParam } from '../../helpers/general_helpers';
import { Chart, Radar } from 'react-chartjs-2';
var Chance = require('chance');
var chance = new Chance();

// API URL
const apiURL = process.env.REACT_APP_API_URL;

// Portal URL
const portalURL = process.env.REACT_APP_PORTAL_URL;

class ReportStudentIntegrity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            centerlicData: { data: [], selected: [] },
            reRenderTime: moment(),
            reportData: {
                processing: false,
                data: {},
                finished: false
            },
            selectedStudent: null
        }
    }

    componentDidMount() {
        this.getCenterlic()
        let getUrlParamData = getUrlParam()
        if (getUrlParamData.id) {
            let findStudent = this.props.studentListData.find((v) => v.child_id == getUrlParamData.id)

            if (findStudent) {
                this.setState({ selectedStudent: findStudent })
            }
        }
    }

    getCenterlic = () => {
        try {
            if (this.getCenterlicReq) {
                this.getCenterlicReq.abort();
            }
            this.getCenterlicReq = new AbortController();
            GET('report-exams/get-centerlic', {
                signal: this.getCenterlicReq.signal,
            }).then((res) => {

                if (res) {
                    let selected = res.data.find((v) => v.reg_status == 1)
                    this.setState({ centerlicData: { ...this.state.centerlicData, data: res.data, selected: [{ value: selected.centerlic_id, label: selected.reg_year, reg_status: selected.reg_status }] } })
                }
            })
        } catch (err) {
            console.log('err', err)
            toast.error(t('Something went wrong while deleting!'));

        }
    }

    fetchReport = () => {
        let reportData = this.state.reportData
        let centerlicData = this.state.centerlicData
        let selectedStudent = this.state.selectedStudent
        if (!this.props.selectedClass) {
            toast.error(t('No Class Selected'));
            return
        }
        if (centerlicData.selected.length <= 0) {
            toast.error(t('Please select a Year'));
            return
        }
        if (!selectedStudent) {
            toast.error(t('Please select a Student'));
            return
        }

        this.setState({ reportData: { ...reportData, processing: true, data: {} } })

        try {
            if (this.fetchReportReq) {
                this.fetchReportReq.abort();
            }
            this.fetchReportReq = new AbortController();
            GET('children/get-report-student-integrity', {
                params: {
                    class_id: this.props.selectedClass.class.class_id,
                    centerlic_ids: JSON.stringify(centerlicData.selected.map((v, i) => v.value)),
                    child_id: selectedStudent.child_id
                },
                signal: this.fetchReportReq.signal,
            }).then((res) => {

                if (res) {
                    this.setState({ reportData: { ...reportData, processing: false, data: res.data, finished: true }, reRenderTime: moment() })
                } else {
                    toast.error(t('Something went wrong while deleting!'));
                    this.setState({ reportData: { ...reportData, processing: false, data: {} } })
                }
            })
        } catch (err) {
            console.log('err', err)
            toast.error(t('Something went wrong while deleting!'));

            this.setState({ reportData: { ...reportData, processing: false, data: {} } })
        }
    }

    render() {

        const { reportData, reRenderTime, centerlicData, selectedStudent } = this.state
        return (
            <Container fluid>
                <div id='iedu-layout'>
                    <NavBar />
                    <div id='page-content'>
                        <Header lite={true} heading={t('General Performance')} backBtn={true} />
                        <div className='grey-section'>
                            <Row className='justify-content-between align-items-center'>
                                <Col>
                                    <Row className='justify-content-between  align-items-center'>
                                        {selectedStudent
                                            ? <Col className='d-flex align-items-center'>
                                                <div style={{
                                                    backgroundImage: `url(${(selectedStudent.picture) ? portalURL + this.props.authData.centerData.center_uuid + '/children/' + selectedStudent.picture : 'https://via.placeholder.com/150x150?text=' + selectedStudent.child_name.toUpperCase()})`,
                                                    width: 50,
                                                    height: 50,
                                                    backgroundSize: 'cover',
                                                    backgroundPosition: 'center center',
                                                    borderRadius: 30,
                                                    marginRight: 15
                                                }}></div>
                                                <h4>{selectedStudent.child_name}</h4>
                                            </Col>
                                            : <Col className='d-flex align-items-center'>
                                                <h4>{t('No Student Selected')}</h4>
                                            </Col>
                                        }
                                        <Col>
                                            <Select
                                                closeMenuOnSelect={false}
                                                isMulti
                                                placeholder={t('Select Year')}
                                                options={centerlicData.data.map((v, i) => { return { label: v.reg_year, value: v.centerlic_id, reg_status: v.reg_status } })}
                                                onChange={(event) => this.setState({ centerlicData: { ...centerlicData, selected: event } })
                                                }
                                                value={centerlicData.selected}
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                                formatOptionLabel={option => (
                                                    <div className='d-flex align-items-center'>
                                                        <span style={{ fontSize: 16 }} className='me-1'>{option.label}</span>
                                                        {option.reg_status == 1 && <Badge pill bg="success">{t('Active')}</Badge>}
                                                    </div>
                                                )}
                                            />
                                        </Col>
                                        <Col md="auto">
                                            <Button variant={'success'} onClick={() => this.fetchReport()}>
                                                {reportData.processing
                                                    ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />
                                                    : <FontAwesomeIcon icon={faSearch} style={{ fontSize: 16, color: brand_colors[this.props.defaultTheme.theme_id].color8, }} />
                                                }
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md="auto">

                                </Col>
                            </Row>
                        </div>
                        <div className='grey-section'>
                            {reportData.processing
                                ? <div className='text-center'>
                                    <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />
                                </div>
                                : <RenderReport reportData={reportData} defaultTheme={this.props.defaultTheme} reRenderTime={reRenderTime} />
                            }
                        </div>
                    </div>
                </div>

            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    authData: state.auth.authData,
    defaultLanguage: state.language.defaultLanguage,
    selectedClass: state.selectedClass.data,
    selectedSubject: state.selectedClass.subject,
    studentListData: state.studentList.data,
    defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(ReportStudentIntegrity);


class RenderReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }


    shouldComponentUpdate(nextProps) {
        if (moment(nextProps.reRenderTime).isSame(moment(this.props.reRenderTime))) {

            return false
        }

        return true
    }

    render() {
        let reportData = this.props.reportData
        if (!reportData.finished) {
            return null
        }

        return (
            <div className='d-flex flex-row flex-wrap'>
                {Object.values(reportData.data.unique_centerlic_data).map((v, i) => {
                    if (v.exams_data.length <= 0) {
                        return null
                    }
                    let uniqueAcademicSubjects = []
                    v.exams_data.map((v) => {
                        let find_uniqueAcademicSubjects = uniqueAcademicSubjects.find((vv, ii) => v.as_id == vv.as_id)
                        if (!find_uniqueAcademicSubjects) {
                            uniqueAcademicSubjects.push({ as_id: v.as_id, as_name: v.as_name })
                        }
                    })

                    const data = {
                        labels: uniqueAcademicSubjects.map((vv) => vv.as_name),
                        datasets: [
                            {
                                label: 'Year ' + v.reg_year,
                                data: uniqueAcademicSubjects.map((vv) => {
                                    let calculatedAverages = 0
                                    let count = 0
                                    v.exams_data.map((vvv) => {
                                        if (vvv.as_id == vv.as_id) {
                                            calculatedAverages += (parseFloat(vvv.examsgrade_grade) / parseFloat(vvv.exams_grade)) * 100
                                            count++
                                        }
                                    })
                                    return parseFloat(calculatedAverages / count).toFixed(2)
                                }),
                                backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color4_light,
                                borderColor: brand_colors[this.props.defaultTheme.theme_id].color4,
                                borderWidth: 1,
                            },
                        ],
                    };

                    console.log('data', data);
                    return <Card className='mb-2' style={{ width: '100%' }}>
                        <Card.Header>{'Year ' + v.reg_year}</Card.Header>
                        <Card.Body className='d-flex flex-row align-items-center'>
                            <div style={{ width: '30%' }}>
                                <Radar data={data} />
                            </div>
                            <div style={{ width: '70%' }}>
                                <Card>
                                    {uniqueAcademicSubjects.map((vv) => {
                                        let labels2 = []
                                        v.exams_data.map((vvv, iii) => {
                                            if (vvv.as_id == vv.as_id) {
                                                labels2.push(vvv)
                                            }
                                        })

                                        let data2 = {
                                            labels: labels2.map((vvv) => vvv.exams_name),
                                            datasets: [
                                                {
                                                    type: 'line',
                                                    label: 'Class Average',
                                                    borderColor: brand_colors[this.props.defaultTheme.theme_id].color16,
                                                    borderWidth: 2,
                                                    fill: false,
                                                    data: labels2.map((vvv) => vvv.exam_average),
                                                },
                                                {
                                                    type: 'bar',
                                                    label: 'Student Grade',
                                                    backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color4_light,
                                                    data: labels2.map((vvv) => vvv.examsgrade_grade),
                                                    borderColor: brand_colors[this.props.defaultTheme.theme_id].color4,
                                                    borderWidth: 2,
                                                },
                                            ],
                                        }
                                        let options2 = {
                                            responsive: true,
                                            plugins: {
                                                legend: {
                                                    position: 'top',
                                                },
                                                title: {
                                                    display: true,
                                                    text: vv.as_name,
                                                },
                                            },
                                        }


                                        return <Card.Body>
                                            <Chart height={60} type='bar' options={options2} data={data2} />
                                        </Card.Body>
                                    })}
                                </Card>

                            </div>
                        </Card.Body>
                    </Card>
                })
                }
            </div >
        )
    }
}
