import React, { Component } from 'react';
import { Container, Card, Row, Col, ButtonGroup, Button, DropdownButton, Dropdown, Table, Form, Spinner, Modal, Tabs, Tab, Badge, Offcanvas, ToggleButton, Image, Alert } from 'react-bootstrap';
import { t } from '../../../helpers/translation_helper';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCheckCircle, faSearch, faUser, faXmark, faLayerGroup, faHandsHoldingChild, faPeopleGroup, faListCheck, faPrint, faBus, faInfoCircle, faUserShield, faChartArea, faChartPie, faEllipsis, faAngleRight, faAngleLeft, faChevronUp, faChevronDown, } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import {
  changeColorOpacity,
  getUrlParam,
  ifNavAllowed,
  isTeacher,
} from '../../../helpers/general_helpers';
import { getStudents } from '../../../redux/slices/studentListSlice';
import { brand_colors } from '../../../helpers/brand_colors_helper';
import moment from 'moment';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { GET, POST } from '../../../api';
import { toast } from 'react-toastify';
import { DateRangePicker } from 'react-date-range';
import Flatpickr from 'react-flatpickr';
import { Fragment } from 'react';
import { faEdit, faTrashCan } from '@fortawesome/free-regular-svg-icons';
import SweetAlert from 'react-bootstrap-sweetalert';
import {
  toggleRegistrationAdvanceModal,
  updateChild,
} from '../../../redux/slices/addEditRegistrationAdvanceModalSlice';
import { handleFormErrors } from '../../../helpers/form_helpers';
import { getExtension } from '../../../helpers/general_helpers';
var Chance = require('chance');
var chance = new Chance();

const apiURL = process.env.REACT_APP_API_URL;
const portalURL = process.env.REACT_APP_PORTAL_URL;

class AddEditRegistrationAdvance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteModal: { show: false, id: 0, action: () => { } },
      getTermsData: [],
      getDiscountsData: { data: [] },
      getClassesData: { data: [], selected: null },
      getSessionsData: { data: [], loading: false, selected: null },
      getSessioncatData: { data: [], selected: null },
      getServicesData: { data: [] },
      getCenterLocationsData: { data: [] },
      days: [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ],
      todayDate: moment().format('YYYY-MM-DD'),
      registrations: [],
      addservices: [],
      addRegCount: 1,
      editMode: null,
      convertMode: false,
      saving: false,
      students: [],
      fee_type: 1,
    };
  }

  componentDidMount() {
    this.getTerms();
    this.loadStudentOptions();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.addEditRegistrationAdvanceModal.showModal !=
      this.props.addEditRegistrationAdvanceModal.showModal &&
      this.props.addEditRegistrationAdvanceModal.showModal
    ) {
      this.setState(
        {
          registrations: JSON.parse(
            JSON.stringify(
              this.props.addEditRegistrationAdvanceModal.registrations
            )
          ),
          todayDate: this.props.addEditRegistrationAdvanceModal.todayDate,
          addRegCount: this.props.addEditRegistrationAdvanceModal.addRegCount,
          addservices: JSON.parse(
            JSON.stringify(
              this.props.addEditRegistrationAdvanceModal.addservices
            )
          ),
          editMode: this.props.addEditRegistrationAdvanceModal.editMode,
          convertMode: this.props.addEditRegistrationAdvanceModal.convertMode,
          getClassesData: {
            data: [],
            selected: this.props.addEditRegistrationAdvanceModal.selectedClass,
          },
          getSessionsData: {
            data: [],
            loading: false,
            selected:
              this.props.addEditRegistrationAdvanceModal.selectedSession,
          },
          getSessioncatData: {
            data: [],
            selected:
              this.props.addEditRegistrationAdvanceModal.selectedSessionCat,
          },
          fee_type: this.props.addEditRegistrationAdvanceModal.fee_type,
        },
        () => {
          this.getTerms();
          this.getSessioncat();
          this.getClasses();
          this.getDiscounts();
          this.getServices();
          this.getCenterLocations();
          if (!this.state.editMode && !this.state.convertMode) {
            this.addRegCount();
          }
        }
      );
    }
  }

  setStateRegistration = (i, field, value) => {
    let registrations = this.state.registrations;
    registrations[i][field] = value;
    this.setState({ registrations });
  };

  // loadStudentOptions = async (search, callback) => {
  //   console.log('search, callback', search, callback);
  //   if (this.loadStudentOptionsReq) {
  //     this.loadStudentOptionsReq.abort();
  //   }
  //   this.loadStudentOptionsReq = new AbortController();
  //   const { user_id, role_id } = this.props.authData.loginData;
  //   const { employee_id } = this.props.authData.userData;
  //   let class_ids = false;
  //   if ([3, 4, 15].includes(role_id)) {
  //     class_ids = this.props.selectedClass.class.class_id
  //   }

  //   const res = await GET('admin/children-list-paginate', {
  //     signal: this.loadStudentOptionsReq.signal,
  //     params: {
  //       search,
  //       limit: 20,
  //       offset: 0,
  //       user_id,
  //       employee_id,
  //       role_id,
  //       class_ids,
  //       registrations: true
  //     },
  //   });

  //   if (res) {
  //     let resData = res.data
  //     callback(resData.data.map((v, i) => {
  //       return { label: v.child_name, value: v.child_id, ...v }
  //     }))
  //   }
  // }

  loadStudentOptions = async (search, callback) => {
    if (this.loadStudentOptionsReq) {
      this.loadStudentOptionsReq.abort();
    }
    this.loadStudentOptionsReq = new AbortController();
    const { user_id, role_id } = this.props.authData.loginData;
    const { employee_id } = this.props.authData.userData;
    let class_ids = false;
    if ([3, 4, 15].includes(role_id)) {
      class_ids = this.props.selectedClass.class.class_id;
    }

    const res = await GET('admin/children-list-paginate', {
      signal: this.loadStudentOptionsReq.signal,
      params: {
        search,
        limit: -1,
        offset: 0,
        user_id,
        employee_id,
        role_id,
        class_ids,
        registrations: true,
        child_status: true,
      },
    });

    if (res) {
      let resData = res.data;
      // callback()
      this.setState({
        students: resData.data.map((v, i) => {
          return { label: v.child_name, value: v.child_id, ...v };
        }),
      });
    }
  };

  getCatFee = async (regIndex, term_id) => {
    if (this.state.registrations[regIndex].reg_id == 0) {
      if (
        this.state.getSessionsData.selected == null ||
        this.state.getClassesData.selected == null ||
        term_id == undefined
      ) {
        let registrations = this.state.registrations;
        registrations[regIndex].session_fee = '';
        registrations[regIndex].term_amount = '';
        registrations[regIndex].term_amount_modified = false;
        this.setState({ registrations });
        return false;
      }
      // if (this.getCatFeeReqReq) {
      //     this.getCatFeeReqReq.abort();
      // }
      this.getCatFeeReqReq = new AbortController();
      const res = await GET('registration-advance/get-cat-fee', {
        params: {
          session_id: this.state.getSessionsData.selected.value,
          classmain_id: this.state.getClassesData.selected.classmain_id,
          term_id: term_id,
        },
        signal: this.getCatFeeReqReq.signal,
      });
      if (res) {
        let resData = res.data;
        // console.log('get-cat-fee', resData);
        if (resData) {
          let registrations = this.state.registrations;
          registrations[regIndex].session_fee = resData.catfees_fee;
          registrations[regIndex].term_amount = resData.catfees_fee;
          registrations[regIndex].term_amount_modified = false;
          this.setState({ registrations }, () => {
            this.lineTotalRegistration(regIndex);
          });
        }
      }
    }
  };

  getDiscounts = async () => {
    if (this.getDiscountsReq) {
      this.getDiscountsReq.abort();
    }
    this.getDiscountsReq = new AbortController();
    const res = await GET('registration-advance/get-discounts', {
      signal: this.getDiscountsReq.signal,
    });

    if (res) {
      let resData = res.data;
      this.setState({ getDiscountsData: { data: resData } });
    }
  };

  getServices = async () => {
    if (this.getServicesReq) {
      this.getServicesReq.abort();
    }
    this.getServicesReq = new AbortController();
    const res = await GET('registration-advance/get-services', {
      signal: this.getServicesReq.signal,
    });

    if (res) {
      let resData = res.data;
      this.setState({ getServicesData: { data: resData } });
    }
  };

  getCenterLocations = async () => {
    if (this.getCenterLocationsReq) {
      this.getCenterLocationsReq.abort();
    }
    this.getCenterLocationsReq = new AbortController();
    const res = await GET('registration-advance/get-center-locations', {
      signal: this.getCenterLocationsReq.signal,
    });

    if (res) {
      let resData = res.data;
      this.setState({ getCenterLocationsData: { data: resData } });
    }
  };

  getSessioncat = async () => {
    if (this.getSessioncatReq) {
      this.getSessioncatReq.abort();
    }
    this.getSessioncatReq = new AbortController();
    const res = await GET('registration-advance/get-sessioncat', {
      signal: this.getSessioncatReq.signal,
    });

    if (res) {
      let resData = res.data;
      this.setState({
        getSessioncatData: { ...this.state.getSessioncatData, data: resData },
        getSessionsData: { ...this.state.getSessionsData, data: [] },
      });
    }
  };

  getTerms = async () => {
    if (this.getTermsReq) {
      this.getTermsReq.abort();
    }
    this.getTermsReq = new AbortController();
    const res = await GET('registration-advance/get-terms', {
      signal: this.getTermsReq.signal,
    });

    if (res) {
      let resData = res.data;
      this.setState({ getTermsData: resData });
    }
  };

  getClasses = async () => {
    if (this.getClassesReq) {
      this.getClassesReq.abort();
    }
    this.getClassesReq = new AbortController();
    const res = await GET('registration-advance/get-classes', {
      params: {},
      signal: this.getClassesReq.signal,
    });

    if (res) {
      let resData = res.data;
      this.setState({
        getClassesData: { ...this.state.getClassesData, data: resData },
      });
    }
  };

  getSessions = async (sessioncat_id) => {
    this.setState({
      getSessionsData: {
        ...this.state.getSessionsData,
        data: [],
        loading: true,
      },
    });
    if (sessioncat_id == null) {
      toast['warning']('Please select Category First.');
      return false;
    }
    if (this.getSessionsReq) {
      this.getSessionsReq.abort();
    }
    this.getSessionsReq = new AbortController();
    const res = await GET('registration-advance/get-sessions', {
      params: {
        sessioncat_id: sessioncat_id.value,
      },
      signal: this.getSessionsReq.signal,
    });

    if (res) {
      let resData = res.data;
      this.setState({
        getSessionsData: {
          ...this.state.getSessionsData,
          data: resData,
          loading: false,
        },
      });
    }
  };

  addRegCount = async (wholeYear = false) => {
    let { addRegCount, getTermsData } = this.state;
    let termsToReg = [];
    if (wholeYear) {
      let terms_default = await GET('registration-advance/get-terms-default');
      // console.log('terms_default', terms_default);
      terms_default = terms_default.data;
      terms_default.map((v, i) => {
        let findTerm = getTermsData.find(
          (vv, ii) => vv.term_d_id == v.term_d_id
        );
        if (findTerm) {
          termsToReg.push(findTerm);
        }
      });
    } else {
      let activeTermToDate = null;
      for (let index = 0; index < addRegCount; index++) {
        if (activeTermToDate == null) {
          let findTerm = getTermsData.find((vv, ii) => vv.term_active == 1);
          if (findTerm) {
            activeTermToDate = findTerm.term_to;
            termsToReg.push(findTerm);
          }
        } else {
          if (activeTermToDate != null) {
            let findTerm = getTermsData.find((vv, ii) =>
              moment(vv.term_from).isAfter(moment(activeTermToDate))
            );
            if (findTerm) {
              activeTermToDate = findTerm.term_to;
              termsToReg.push(findTerm);
            }
          }
        }
      }
    }

    let weekend_days = this.props.authData.centerData.weekend_days;
    if (weekend_days) {
      weekend_days = weekend_days.split(',');
    }

    let registrations = [...this.state.registrations];
    termsToReg.map((v, i) => {
      registrations.push({
        reg_id: 0,
        center_id: this.props.authData.loginData.center_id,
        child_id: this.props.addEditRegistrationAdvanceModal.child
          ? this.props.addEditRegistrationAdvanceModal.child.child_id
          : null,
        child: this.props.addEditRegistrationAdvanceModal.child
          ? this.props.addEditRegistrationAdvanceModal.child
          : null,
        class_id: this.state.getClassesData.selected
          ? this.state.getClassesData.selected.class_id
          : null,
        session_id: this.state.getSessionsData.selected
          ? this.state.getSessionsData.selected.session_id
          : null,
        sessioncat_id: this.state.getSessioncatData.selected
          ? this.state.getSessioncatData.selected.sessioncat_id
          : null,
        term_id: { label: v.term_name, value: v.term_id, ...v },
        discount_id: null,
        discount_rate: 0.0,
        discount_amount: 0.0,
        term_amount: 0.0,
        term_amount_modified: false,
        session_fee: 0.0,
        reg_vat: 0.0,
        vat_amount: 0.0,
        total: 0.0,
        reg_type: 0,
        is_earned: v.term_active == 1 ? 1 : 0,
        centerlic_id: 0,
        reg_year: 0,
        acc_num: null,
        reg_notes: '',
        term_fee_type: null,
        term_fee_value: null,
        session_mon: weekend_days.includes('monday') ? 0 : 1,
        session_tue: weekend_days.includes('tuesday') ? 0 : 1,
        session_wed: weekend_days.includes('wednesday') ? 0 : 1,
        session_thu: weekend_days.includes('thursday') ? 0 : 1,
        session_fri: weekend_days.includes('friday') ? 0 : 1,
        session_sat: weekend_days.includes('saturday') ? 0 : 1,
        session_sun: weekend_days.includes('sunday') ? 0 : 1,
        reg_num: 0,
        date_from: v.term_from,
        date_to: v.term_to,
      });
    });

    // ! WRONG HERE IN CASE OF EDIT EDIT MODE SHOULD NOT BE NULL
    this.setState({ registrations }, () => {//, editMode: null
      this.state.registrations.map((v, i) => {
        this.getCatFee(i, v.term_id?.term_id);
      });
    });
  };

  setStateRegistration = (i, field, value) => {
    let registrations = this.state.registrations;
    if (field == 'term_amount') {
      registrations[i]['term_amount_modified'] = true;
    }
    registrations[i][field] = value;
    this.setState({ registrations }, () => this.lineTotalRegistration(i));
  };

  setStateService = (i, field, value) => {
    let addservices = this.state.addservices;
    if (field == 'addservices_fee') {
      addservices[i]['addservices_fee_modified'] = true;
    } else if (field == 'services_id') {
      addservices[i]['addservices_fee_modified'] = false;
    }
    addservices[i][field] = value;
    this.setState({ addservices }, () => this.lineTotalAddservice(i));
  };

  saveRegistration = async () => {
    let registrations = JSON.parse(JSON.stringify(this.state.registrations));
    let addservices = JSON.parse(JSON.stringify(this.state.addservices));
    // if (registrations.length <= 0) {
    //   toast['warning']('Please create at-least one registration.');
    //   return false;
    // }
    let todayDate = this.state.todayDate;
    let editMode = this.state.editMode;
    let modalRegistration = this.state.modalRegistration;

    let weekend_days = this.props.authData.centerData.weekend_days;
    if (weekend_days) {
      weekend_days = weekend_days.split(',');
    }

    let checkError = false;
    registrations = registrations.map((v, i) => {
      if (
        v.term_id == null ||
        v.child == null ||
        v.sessioncat_id == null ||
        v.class_id == null ||
        v.session_id == null ||
        v.term_amount === ''
      ) {
        toast['warning']('Record ' + (i + 1) + ': All fields are required.');
        checkError = true;
        return;
      }
      // FOR VERIFYING THE NUMBER OF DAYS
      // let session_day_number = v.session_id.session_day_number
      // let verify_session_day_number = 0;
      // this.state.days.map((vv) => {
      //     let sessionDayName = 'session_' + vv.slice(0, 3).toLowerCase()
      //     if (v[sessionDayName] == 1 || v[sessionDayName] == true) {
      //         v[sessionDayName] = 1
      //         verify_session_day_number++
      //     } else {
      //         v[sessionDayName] = 0
      //     }
      // })
      // if (parseInt(v.session_id.session_day_number) != verify_session_day_number) {
      //     toast['warning']('Record ' + (i + 1) + ': ' + session_day_number + ' Day(s) must be selected.')
      //     checkError = true
      //     return
      // }
      if (!v.date_from || !v.date_to) {
        toast['warning']('Record ' + (i + 1) + ': Date Range must be selected');
        checkError = true;
        return;
      }

      v.discount_rate = v.discount_id ? v.discount_id.discount_rate : null;
      v.discount_id = v.discount_id ? v.discount_id.discount_id : null;
      v.term_fee_type = v.term_id ? v.term_id.term_fee_type : null;
      v.term_id = v.term_id ? v.term_id.term_id : null;
      v.reg_date = todayDate;

      return v;
    });
    addservices = addservices.map((v, i) => {
      let services_type = v.services_id ? v.services_id.services_type : null;

      if (
        v.services_id == null ||
        ([1, 2].includes(services_type) && v.cloc_id == null)
      ) {
        toast['warning']('Service ' + (i + 1) + ': All fields are required.');
        checkError = true;
        return;
      }

      v.services_cost = v.services_id ? v.services_id.services_cost : null;
      v.service_acc_num = v.services_id ? v.services_id.acc_num : null;
      v.service_chart_id = v.services_id ? v.services_id.chart_id : null;
      v.service_chapt_id = v.services_id ? v.services_id.chapt_id : null;
      v.service_name = v.services_id ? v.services_id.services_name : null;
      v.services_type = v.services_id ? v.services_id.services_type : null;
      v.cloc_id = v.cloc_id ? v.cloc_id.cloc_id : null;
      v.addservices_date = todayDate;
      v.services_id = v.services_id ? v.services_id.services_id : null;

      return v;
    });
    if (checkError) {
      return;
    }

    this.setState({
      saving: true,
    });
    if (this.saveRegistrationReq) {
      this.saveRegistrationReq.abort();
    }

    this.saveRegistrationReq = new AbortController();
    let fData = new FormData();
    fData.append('registrationData', JSON.stringify(registrations));
    fData.append('addservicesData', JSON.stringify(addservices));
    fData.append(
      'child_id',
      this.props.addEditRegistrationAdvanceModal.child?.child_id
    );
    fData.append('weekend_days', JSON.stringify(weekend_days));
    fData.append('reg_date', todayDate);
    if (editMode) {
      fData.append('fee_type', editMode.fee_type);
      fData.append('reg_num', editMode.reg_num);
      fData.append('addservicesheader_id', editMode.addservicesheader_id);
    } else {
      fData.append('fee_type', this.state.fee_type);
    }
    let res = await POST('registration-advance/save-registration', fData, {
      signal: this.saveRegistrationReq.signal,
    });
    // console.log('res', res);
    if (res) {
      let resData = res.data;
      if (resData.type) {
        this.setState({
          saving: false,
          getClassesData: { ...this.state.getClassesData, selected: null },
          getSessionsData: {
            ...this.state.getSessionsData,
            loading: false,
            selected: null,
          },
          getSessioncatData: {
            ...this.state.getSessioncatData,
            selected: null,
          },
        });

        if (this.state.convertMode) {
          // console.log('resData', resData.data);

          this.lockProformaInvoice(
            this.state.convertMode.reg_num,
            this.state.convertMode.fee_type,
            resData.data.addservicesheader_id
          );
        }

        if (this.props.callbackFunction) {
          this.props.callbackFunction();
        }
        this.props.toggleRegistrationAdvanceModal();
      } else {
        this.setState({
          saving: false,
        });
      }
      toast[resData.messageType](resData.message);
    }
  };

  lockProformaInvoice = async (reg_num, fee_type, new_addservicesheader_id) => {
    if (this.lockProformaInvoiceReq) {
      this.lockProformaInvoiceReq.abort();
    }
    this.lockProformaInvoiceReq = new AbortController();
    let res = await GET('registration-advance/lock-proforma-invoice', {
      params: {
        reg_num: reg_num,
        fee_type: fee_type,
        new_addservicesheader_id: new_addservicesheader_id,
      },
      signal: this.lockProformaInvoiceReq.signal,
    });
    if (res) {
    }
  };

  deleteRegistration = (registration, index = null) => {
    this.setState({
      deleteModal: {
        ...this.state.deleteModal,
        show: true,
        id: registration,
        action: async () => {
          if (this.deleteRegistrationReq) {
            this.deleteRegistrationReq.abort();
          }

          this.deleteRegistrationReq = new AbortController();
          let fData = new FormData();
          fData.append('reg_id', registration.reg_id);
          fData.append('child_id', registration.child_id);
          fData.append('reg_num', registration.reg_num);
          let res = await POST(
            'registration-advance/delete-registration',
            fData,
            {
              signal: this.deleteRegistrationReq.signal,
            }
          );
          // console.log('res', res);
          if (res) {
            let resData = res.data;
            if (resData.type) {
              let registrations = this.state.registrations;
              if (index != null) {
                registrations.splice(index, 1);
              }
              this.setState(
                {
                  registrations,
                  deleteModal: {
                    ...this.state.deleteModal,
                    show: false,
                    id: null,
                  },
                },
                () => {
                  if (this.props.callbackFunction) {
                    this.props.callbackFunction();
                  }
                }
              );
            }
            toast[resData.messageType](resData.message);
          }
        },
      },
    });
  };

  deleteRegistrationIndex = (index) => {
    let registrations = this.state.registrations;
    registrations.splice(index, 1);
    this.setState({ registrations });
  };

  lineTotalAddservice = (addserviceIndex) => {
    if (!this.props.addEditRegistrationAdvanceModal.child) {

      return
    }
    let addservices = this.state.addservices;
    if (addservices[addserviceIndex].services_id == null) {
      return;
    }

    addservices[addserviceIndex].addservices_vat = parseFloat(
      addservices[addserviceIndex].services_id.services_vat
    );
    let centerCountry = this.props.authData.centerData.country_id;
    let child_is_local =
      this.props.addEditRegistrationAdvanceModal.child.country_id ==
      centerCountry;
    // console.log(
    //   'child_is_local',
    //   child_is_local,
    //   this.props.addEditRegistrationAdvanceModal.child,
    //   addservices[addserviceIndex].services_id
    // );

    if (addservices[addserviceIndex].services_id.expats_only == 1) {
      if (child_is_local == true) {
        addservices[addserviceIndex].addservices_vat = 0.0;
      }
    }

    if (addservices[addserviceIndex].addservices_fee_modified == false) {
      addservices[addserviceIndex].addservices_fee = parseFloat(
        addservices[addserviceIndex].services_id.services_fee
      );
    }

    let addservices_fee = parseFloat(
      addservices[addserviceIndex].addservices_fee
    );
    if (!Number.isFinite(addservices_fee)) {
      addservices_fee = 0;
      addservices[addserviceIndex].addservices_fee = addservices_fee;
    }

    let addservices_quantity = parseFloat(
      addservices[addserviceIndex].addservices_quantity
    );
    if (!Number.isFinite(addservices_quantity)) {
      addservices_quantity = 0;
      addservices[addserviceIndex].addservices_quantity = addservices_quantity;
    }

    let line_total = addservices_fee * addservices_quantity;

    let addservices_discount = parseFloat(
      addservices[addserviceIndex].addservices_discount
    );

    if (Number.isFinite(addservices_discount)) {
      addservices_discount = parseFloat(
        addservices[addserviceIndex].addservices_discount
      );
      if (addservices_discount > 100) {
        addservices_discount = 100;
        addservices[addserviceIndex].addservices_discount =
          addservices_discount;
      } else if (addservices_discount <= 0) {
        addservices_discount = 0;
        addservices[addserviceIndex].addservices_discount =
          addservices_discount;
      }
    } else {
      addservices_discount = 0;
      addservices[addserviceIndex].addservices_discount = addservices_discount;
    }

    addservices[addserviceIndex].addservices_discount_amount =
      (line_total * addservices_discount) / 100;
    addservices[addserviceIndex].addservices_vat_amount =
      ((line_total - addservices[addserviceIndex].addservices_discount_amount) *
        addservices[addserviceIndex].addservices_vat) /
      100;
    addservices[addserviceIndex].total =
      line_total -
      addservices[addserviceIndex].addservices_discount_amount +
      addservices[addserviceIndex].addservices_vat_amount;
    this.setState({ addservices });
  };

  lineTotalRegistration = (regIndex) => {
    let registrations = this.state.registrations;
    if (
      registrations[regIndex].session_id == null ||
      registrations[regIndex].class_id == null ||
      registrations[regIndex].sessioncat_id == null ||
      registrations[regIndex].child == null ||
      registrations[regIndex].term_id == null
    ) {
      return;
    }
    let centerCountry = this.props.authData.centerData.country_id;
    let child_is_local =
      registrations[regIndex].child.country_id == centerCountry;

    let session_fee = registrations[regIndex].session_fee;
    if (session_fee == '' || session_fee == undefined || session_fee == null) {
      session_fee = 0.0;
    }
    session_fee = parseFloat(session_fee);

    let date_from_selected = moment(
      registrations[regIndex].date_from,
      'YYYY-MM-DD'
    );
    let date_to_selected = moment(
      registrations[regIndex].date_to,
      'YYYY-MM-DD'
    );
    let total_selected_days =
      date_to_selected.diff(date_from_selected, 'days') + 1;

    let date_from = moment(registrations[regIndex].term_id.term_from);
    let date_to = moment(registrations[regIndex].term_id.term_to);
    let total_term_days = date_to.diff(date_from, 'days') + 1;
    let per_day_catfee = session_fee / parseFloat(total_term_days);
    registrations[regIndex].term_amount_text =
      'Prorated @ ' + per_day_catfee + ' per day';

    let late_days = date_from_selected.diff(date_from, 'days');
    let reg_vat = this.state.getSessioncatData.selected.vat_rate;
    if (reg_vat == '' || reg_vat == undefined || reg_vat == null) {
      reg_vat = 0.0;
    }
    let expats_only = this.state.getSessioncatData.selected.expats_only;
    // if (expats_only == "" || expats_only == undefined || expats_only == null) {
    //   expats_only = 1;
    // }
    if (expats_only == 1) {
      if (child_is_local == true) {
        reg_vat = 0.0;
      }
    }
    registrations[regIndex].reg_vat = reg_vat;

    let discount_id = registrations[regIndex].discount_id;
    let discount_rate = 0.0;
    if (discount_id) {
      discount_rate = discount_id.discount_rate;
      if (
        discount_rate == '' ||
        discount_rate == undefined ||
        discount_rate == null
      ) {
        discount_rate = 0.0;
      }
    }
    let term_fee_type = registrations[regIndex].term_id.term_fee_type;
    // TERM AMOUNT ACCORDING TO THE TERM FEE TYPE
    let input_line_term_amount = 0.0;
    let tlp_discount = 0;
    if (term_fee_type == 'none') {
      input_line_term_amount = parseFloat(session_fee);
    } else if (term_fee_type == 'prorated') {
      input_line_term_amount =
        parseFloat(per_day_catfee) * parseFloat(total_selected_days);
    } else if (term_fee_type == 'policy') {
      let term_linked_policy =
        registrations[regIndex].term_id.term_linked_policy;
      // let nondeductible_days = term_linked_policy[term_linked_policy.length - 1].tlp_days_start;
      if (total_selected_days == total_term_days) {
        term_fee_type = 'none';
        input_line_term_amount = parseFloat(session_fee);
      } else {
        let found_policy = false;
        term_linked_policy.map((v, i) => {
          if (late_days >= parseInt(v.tlp_days_start) && !found_policy) {
            tlp_discount = v.tlp_discount;
            found_policy = true;
          }
        });
        if (!found_policy) {
          term_fee_type = 'prorated';
          input_line_term_amount =
            parseFloat(per_day_catfee) * parseFloat(total_selected_days);
        } else {
          term_fee_type = 'policy';
          input_line_term_amount =
            parseFloat(session_fee) -
            (parseFloat(session_fee) * parseFloat(tlp_discount)) / 100;
        }
      }
    }

    if (term_fee_type == 'none') {
      registrations[regIndex].term_amount_text = 'None';
    } else if (term_fee_type == 'prorated') {
      registrations[regIndex].term_amount_text =
        'Prorated @ ' + per_day_catfee.toFixed(2) + ' per day';
      registrations[regIndex].term_fee_value = per_day_catfee.toFixed(2);
    } else if (term_fee_type == 'policy') {
      registrations[regIndex].term_amount_text =
        'Policy discount @ ' + tlp_discount + '%';
      registrations[regIndex].term_fee_value = tlp_discount;
    }

    // $('.input_line_term_amount[uuid="' + uuid + '"]').attr("modified", "false");
    // $('.select_line_discount_info[uuid="' + uuid + '"]').text("0% : 0.00");
    //APPLY DISCOUNT IF VALUE IS NOT MODIFIED
    if (registrations[regIndex].term_amount_modified == false) {
      if (discount_rate != '0.00') {
        let discount_amount =
          (parseFloat(input_line_term_amount) * parseFloat(discount_rate)) /
          100;
        registrations[regIndex].discount_amount = discount_amount.toFixed(2);
        input_line_term_amount =
          parseFloat(input_line_term_amount) - parseFloat(discount_amount);
      }
      registrations[regIndex].term_amount = input_line_term_amount.toFixed(2);
    } else {
      registrations[regIndex].discount_amount = 0.0;
    }

    let input_line_vat = parseFloat(registrations[regIndex].reg_vat);

    let input_line_vat_amount =
      (parseFloat(registrations[regIndex].term_amount) *
        parseFloat(input_line_vat)) /
      100;
    //APPLY VAT
    registrations[regIndex].vat_amount = input_line_vat_amount.toFixed(2);
    registrations[regIndex].total = (
      parseFloat(registrations[regIndex].term_amount) +
      parseFloat(input_line_vat_amount)
    ).toFixed(2);
    //LINE TOTAL
    this.setState({ registrations });
  };

  deleteAddService = (index) => {
    let addservices = this.state.addservices;
    addservices.splice(index, 1);
    this.setState({ addservices });
  };

  render() {
    let addEditRegistrationAdvanceModal =
      this.props.addEditRegistrationAdvanceModal;
    if (!addEditRegistrationAdvanceModal.showModal) {
      return null;
    }

    let {
      registrations,
      getTermsData,
      getClassesData,
      getSessioncatData,
      getSessionsData,
      days,
      todayDate,
      getDiscountsData,
      addservices,
      addRegCount,
      getServicesData,
      getCenterLocationsData,
      editMode,
      saving,
      fee_type,
    } = this.state;
    const dir = this.props.defaultLanguage.lang_orientation;
    let weekend_days = this.props.authData.centerData.weekend_days;
    let defaultTheme = this.props.defaultTheme;
    if (weekend_days) {
      weekend_days = weekend_days.split(',');
    }
    console.log('ADVANCE ADDEDIT', this.state, this.props);

    return (
      <Offcanvas
        show={addEditRegistrationAdvanceModal.showModal}
        style={{ height: '100%', zIndex: 9990 }}
        placement='bottom'
        onHide={() => this.props.toggleRegistrationAdvanceModal()}>
        <Offcanvas.Header
          id='iedu-header'
          style={{
            marginTop: '1rem',
            marginRight: '1rem',
            marginLeft: '1rem',
            marginBottom: 0,
          }}>
          <div className='d-flex flex-row  align-items-center justify-content-center'>
            <span
              className='d-flex align-items-center justify-content-center btn-back-white'
              onClick={() => this.props.toggleRegistrationAdvanceModal()}>
              <FontAwesomeIcon
                icon={dir == 1 ? faAngleRight : faAngleLeft}
                color={brand_colors[defaultTheme.theme_id].color9}
              />
            </span>
            <Offcanvas.Title>
              <div className='d-flex flex-row align-items-center justify-content-center title-back-color8'>
                {/* STUDENT */}
                <div className='ms-3' style={{ width: '500px' }}>
                  {/* <AsyncSelect
                  placeholder={t('Type to Search Student')}
                  cacheOptions
                  defaultOptions={[]}
                  loadOptions={this.loadStudentOptions}
                  onChange={(event) => {
                    this.props.updateChild(event)
                    let newRegistrations = this.state.registrations
                    newRegistrations = newRegistrations.map((v) => { return { ...v, child_id: event.child_id, child: event } })
                    this.setState({ registrations: newRegistrations })
                  }}
                  value={addEditRegistrationAdvanceModal.child}
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  formatOptionLabel={option => (
                    <div>
                      {option.picture ? <img src={portalURL + this.props.authData.centerData.center_uuid + '/children/' + option.picture} style={{ width: 30, height: 30, objectFit: 'cover', marginRight: 5 }} /> : ''}
                      <span>{option.label}</span>
                      <Badge bg='info' className='ms-1'>{option.parent_name}</Badge>
                    </div>
                  )}
                /> */}
                  <Select
                    onChange={(event) => {
                      this.props.updateChild(event);
                      let newRegistrations = this.state.registrations;
                      newRegistrations = newRegistrations.map((v) => {
                        return { ...v, child_id: event.child_id, child: event };
                      });
                      this.setState({ registrations: newRegistrations });
                    }}
                    value={addEditRegistrationAdvanceModal.child}
                    placeholder={t('Select Student')}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 9999,
                      }),
                    }}
                    options={this.state.students}
                    formatOptionLabel={(option) => (
                      <div>
                        {/* {option.picture ? <img src={portalURL + this.props.authData.centerData.center_uuid + '/children/' + option.picture} style={{ width: 30, height: 30, objectFit: 'cover', marginRight: 5 }} /> : ''} */}
                        <span>{option.label}</span>
                        <Badge bg='info' className='ms-1'>
                          {option.parent_name}
                        </Badge>
                      </div>
                    )}
                  />
                </div>
              </div>
            </Offcanvas.Title>
          </div>
          <div className='custom-styled-form'>
            <div className='d-flex flex-row justify-content-between align-items-end'>
              <div
                className='me-5 d-flex flex-row align-items-center'
                style={{
                  fontWeight: '600',
                  fontSize: '20px',
                  backgroundColor: 'white',
                  borderRadius: 20,
                  padding: '5px 20px',
                }}>
                <div className='me-2'>{t('Grand Total')}:</div>
                <div>
                  {(
                    addservices.reduce(
                      (previousValue, currentValue) =>
                        previousValue + parseFloat(currentValue.total),
                      0
                    ) +
                    registrations.reduce(
                      (previousValue, currentValue) =>
                        previousValue + parseFloat(currentValue.total),
                      0
                    )
                  ).toFixed(2)}
                </div>
              </div>
              <div>
                {editMode ? (
                  <Button
                    disabled={saving}
                    variant={'warning'}
                    onClick={() =>
                      setTimeout(() => {
                        this.saveRegistration();
                      }, 300)
                    }>
                    {t('Update')}{' '}
                    {saving ? (
                      <Spinner as='span' animation='grow' size='sm' />
                    ) : (
                      ''
                    )}
                  </Button>
                ) : (
                  <Button
                    disabled={saving}
                    variant={'success'}
                    onClick={() =>
                      setTimeout(() => {
                        this.saveRegistration();
                      }, 300)
                    }>
                    {t('Save')}{' '}
                    {saving ? (
                      <Spinner as='span' animation='grow' size='sm' />
                    ) : (
                      ''
                    )}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div
            className={'d-flex flex-column grey-section'}
            style={{
              overflow: 'initial',
              backgroundImage:
                'url(https://academic.educore.io/static/media/pegboard-bg.9b84e4936acef29bbcd9.png)',
              backgroundRepeat: 'repeat',
              backgroundPosition: '10px 12px',
              overflow: 'hidden',
            }}>
            <div
              className='custom-styled-form hide-scrollbar'
              style={{
                backgroundColor: brand_colors[defaultTheme.theme_id].color8,
                marginLeft: 0,
                marginRight: 10,
                borderRadius: '20px',
                padding: '1rem',
                overflow: 'auto',
                height: '74vh',
                width: '100%',
              }}>
              {editMode && fee_type != 32 && (
                <Alert>
                  {t(
                    'Updating the Data may cause to reverse and rewrite Journal Vouchers'
                  )}
                </Alert>
              )}
              <div
                className='d-flex flex-row mb-3 align-items-end'
                style={{
                  border: '#DEE2E6 1px solid',
                  padding: '0 20px 20px',
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                }}>
                <div>
                  <Form.Label>{t('Date')}</Form.Label>
                  <Flatpickr
                    className='form-control'
                    value={todayDate}
                    onChange={([date]) =>
                      this.setState({
                        todayDate: moment(date).format('YYYY-MM-DD'),
                      })
                    }
                    options={{ static: true }}
                  />
                </div>

                <div className='ms-3' style={{ flex: 1 }}>
                  <Form.Label>{t('Class')}</Form.Label>
                  <Select
                    classNamePrefix='custom-styled-select'
                    placeholder={t('Select Class')}
                    options={getClassesData.data.map((vv) => {
                      return {
                        label: vv.class_theme,
                        value: vv.class_id,
                        ...vv,
                      };
                    })}
                    onChange={(event) => {
                      let registrations = this.state.registrations;
                      registrations = registrations.map((v, i) => {
                        return { ...v, class_id: event.class_id };
                      });
                      this.setState(
                        {
                          getClassesData: {
                            ...getClassesData,
                            selected: event,
                          },
                          registrations,
                        },
                        () => {
                          registrations.map((v, i) => {
                            this.getCatFee(i, v.term_id?.term_id);
                          });
                        }
                      );
                    }}
                    value={getClassesData.selected}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    formatOptionLabel={(option) => (
                      <div>
                        {option.class_theme}
                        <small
                          style={{
                            border: '1px solid ' + option.agegroup_color,
                            fontWeight: 'bold',
                            color: option.agegroup_color,
                            display: 'inline-block',
                            padding: '3px 7px',
                            borderRadius: 7,
                            marginLeft: 5,
                            fontSize: 11,
                          }}>
                          {option.agegroup_code}
                        </small>
                      </div>
                    )}
                  />
                </div>
                <div className='ms-3' style={{ flex: 1 }}>
                  <Form.Label>{t('Category')}</Form.Label>
                  <Select
                    classNamePrefix='custom-styled-select'
                    placeholder={t('Select Category')}
                    options={getSessioncatData.data.map((vv) => {
                      return {
                        label: vv.sessioncat_name,
                        value: vv.sessioncat_id,
                        ...vv,
                      };
                    })}
                    onChange={(event) => {
                      let registrations = this.state.registrations;
                      registrations = registrations.map((v, i) => {
                        return {
                          ...v,
                          sessioncat_id: event.sessioncat_id,
                          session_id: null,
                        };
                      });
                      this.setState({
                        getSessioncatData: {
                          ...getSessioncatData,
                          selected: event,
                        },
                        getSessionsData: { ...getSessionsData, selected: null },
                        registrations,
                      });
                    }}
                    value={getSessioncatData.selected}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    formatOptionLabel={(option) => (
                      <div>
                        <span style={{ fontSize: 14 }}>{option.label}</span>
                        {option.vat_rate && <br />}
                        {option.vat_rate && (
                          <Badge bg='success'>VAT={option.vat_rate}</Badge>
                        )}
                      </div>
                    )}
                  />
                </div>
                <div className='ms-3' style={{ flex: 1 }}>
                  <Form.Label>{t('Session')}</Form.Label>
                  <Select
                    classNamePrefix='custom-styled-select'
                    placeholder={t('Select Session')}
                    options={getSessionsData.data.map((vv) => {
                      return {
                        label: vv.session_name,
                        value: vv.session_id,
                        ...vv,
                      };
                    })}
                    onChange={(event) => {
                      let registrations = this.state.registrations;
                      registrations = registrations.map((v, i) => {
                        return { ...v, session_id: event.session_id };
                      });
                      this.setState(
                        {
                          getSessionsData: {
                            ...getSessionsData,
                            selected: event,
                          },
                          registrations,
                        },
                        () => {
                          registrations.map((v, i) => {
                            this.getCatFee(i, v.term_id?.term_id);
                          });
                        }
                      );
                    }}
                    value={getSessionsData.selected}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    formatOptionLabel={(option) => (
                      <div>
                        <span style={{ fontSize: 14 }}>{option.label}</span>
                        {option.session_day_number && <br />}
                        {option.session_day_number && (
                          <Badge bg='warning'>
                            {'Days'}: {option.session_day_number}
                          </Badge>
                        )}
                      </div>
                    )}
                    autoload={false}
                    isLoading={getSessionsData.loading}
                    onFocus={() => this.getSessions(getSessioncatData.selected)}
                  />
                </div>
              </div>
              <div>
                <Table bordered size='sm'>
                  <thead>
                    <tr>
                      <th colSpan='5' className='table-success'>
                        <div className='d-flex flex-row align-items-center'>
                          <div style={{ fontSize: 20, opacity: 0.8 }}>
                            {t('Registrations')}
                          </div>
                          {(!editMode || fee_type == 32) && (
                            <div className='mx-2 pt-2'>
                              <Form.Control
                                type='number'
                                value={addRegCount}
                                size='sm'
                                style={{ width: '70px' }}
                                onChange={(e) =>
                                  this.setState({
                                    addRegCount: e.target.value,
                                  })
                                }
                              />
                            </div>
                          )}
                          {(!editMode || fee_type == 32) && (
                            <div>
                              <Button
                                variant='success'
                                size='sm'
                                onClick={() => this.addRegCount()}>
                                <FontAwesomeIcon
                                  className='me-1'
                                  icon={faPlus}
                                  color={
                                    brand_colors[defaultTheme.theme_id].color8
                                  }
                                />
                                {t('Period Registration')}
                              </Button>
                            </div>
                          )}
                          {(!editMode || fee_type == 32) && (
                            <div className='ms-2'>
                              <Button
                                variant='success'
                                size='sm'
                                onClick={() => this.addRegCount(true)}>
                                <FontAwesomeIcon
                                  className='me-1'
                                  icon={faPlus}
                                  color={
                                    brand_colors[defaultTheme.theme_id].color8
                                  }
                                />
                                {t('Whole Year Registration')}
                              </Button>
                            </div>
                          )}
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {registrations.map((v, i) => {
                      return (
                        <tr key={i}>
                          <td colSpan={'5'}>
                            <div
                              className='d-flex flex-column'
                              style={{
                                padding: 15,
                                paddingBottom: 20,
                              }}>
                              <div className='d-flex flex-row align-items-center'>
                                <div>
                                  <Badge>
                                    {t('Record')}: {i + 1}
                                  </Badge>
                                </div>
                                <div
                                  className='ms-4 d-flex flex-column align-items-center justify-content-center cursor-pointer'
                                  style={{ padding: '0.3rem 0.3rem' }}
                                  //! WRONG HERE WE SHOULD CHECK IN CASE OF REG_ID Ahmad
                                  onClick={() =>
                                    (v.reg_id)
                                      ? this.deleteRegistration(v, i)
                                      : this.deleteRegistrationIndex(i)
                                  }>
                                  <Image
                                    title={t('Remove')}
                                    src={require('../../../assets/images/remove-red.png')}
                                    style={{
                                      width: '1.2rem',
                                      height: '1.2rem',
                                    }}
                                  />
                                </div>
                                <div className='ms-4 d-flex flex-row align-items-center'>
                                  <Form.Check
                                    type='switch'
                                    checked={v.is_earned == 1 ? true : false}
                                    readOnly
                                  />
                                  <Form.Label
                                    className='no-style mb-0'
                                    style={{ opacity: 0.8, fontWeight: '600' }}>
                                    {t('Earned')}
                                  </Form.Label>
                                </div>
                              </div>
                              <div className='d-flex flex-row'>
                                <div style={{ flex: 1 }}>
                                  <Form.Label>{t('Period')}</Form.Label>
                                  <Select
                                    classNamePrefix='custom-styled-select'
                                    placeholder={t('Select Period')}
                                    options={getTermsData.map((vv) => {
                                      return {
                                        label: vv.term_name,
                                        value: vv.term_id,
                                        ...vv,
                                      };
                                    })}
                                    onChange={(event) => {
                                      let registrations =
                                        this.state.registrations;
                                      registrations[i]['term_id'] = event;
                                      registrations[i]['date_from'] =
                                        event.term_from;
                                      registrations[i]['date_to'] =
                                        event.term_to;
                                      registrations[i]['is_earned'] =
                                        event.term_active == 1 ? 1 : 0;
                                      this.setState(
                                        {
                                          registrations,
                                        },
                                        () => {
                                          this.getCatFee(i, event.term_id);
                                        }
                                      );
                                    }}
                                    value={v.term_id}
                                    menuPortalTarget={document.body}
                                    styles={{
                                      menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 9999,
                                      }),
                                    }}
                                    formatOptionLabel={(option) => (
                                      <div>
                                        <span style={{ fontSize: 14 }}>
                                          {option.label}
                                        </span>
                                        {option.term_active == 1 && (
                                          <Badge className='ms-1' bg='success'>
                                            Active
                                          </Badge>
                                        )}
                                      </div>
                                    )}
                                  />
                                  <Badge bg='info'>
                                    {t('Fee')}: {v.session_fee}
                                  </Badge>
                                </div>
                                <div style={{ flex: 1 }} className='mx-1'>
                                  <Form.Label>{t('Amount')}</Form.Label>
                                  <Form.Control
                                    type='text'
                                    value={v.term_amount}
                                    placeholder={t('Period Amount')}
                                    onChange={(e) =>
                                      this.setStateRegistration(
                                        i,
                                        'term_amount',
                                        e.target.value
                                      )
                                    }
                                  />
                                  {v.term_amount_text && (
                                    <Badge bg='info'>
                                      {v.term_amount_text}
                                    </Badge>
                                  )}
                                </div>
                                <div style={{ flex: 1 }} className='mx-1'>
                                  <Form.Label>{t('Discount %')}</Form.Label>
                                  <Select
                                    classNamePrefix='custom-styled-select'
                                    isClearable={true}
                                    placeholder={t('Select Discount')}
                                    options={getDiscountsData.data.map((vv) => {
                                      return {
                                        label: vv.discount_name,
                                        value: vv.discount_id,
                                        ...vv,
                                      };
                                    })}
                                    onChange={(event) =>
                                      this.setStateRegistration(
                                        i,
                                        'discount_id',
                                        event
                                      )
                                    }
                                    value={v.discount_id}
                                    menuPortalTarget={document.body}
                                    styles={{
                                      menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 9999,
                                      }),
                                    }}
                                    formatOptionLabel={(option) => (
                                      <div>
                                        <span style={{ fontSize: 14 }}>
                                          {option.label}
                                        </span>
                                      </div>
                                    )}
                                  />
                                  {v.discount_id && (
                                    <Badge bg='info'>
                                      {v.discount_id.discount_rate}% :{' '}
                                      {v.discount_amount}
                                    </Badge>
                                  )}
                                </div>
                                <div style={{ flex: 1 }} className='mx-1'>
                                  <Form.Label>{t('VAT')}</Form.Label>
                                  <Form.Control
                                    type='text'
                                    value={v.reg_vat}
                                    placeholder={t('VAT(%)')}
                                    readOnly
                                  />
                                  <Badge bg='info'>
                                    {t('Amount')}: {v.vat_amount}
                                  </Badge>
                                </div>
                                <div style={{ flex: 1 }}>
                                  <Form.Label>{t('Total')}</Form.Label>
                                  <Form.Control
                                    type='text'
                                    value={v.total}
                                    placeholder={t('Total')}
                                    readOnly
                                  />
                                </div>
                              </div>
                              <div className='d-flex flex-row'>
                                <div
                                  className='position-relative me-1'
                                  style={{ flex: 1 }}>
                                  <Form.Label>{t('From - To Date')}</Form.Label>
                                  <Form.Control
                                    placeholder={t('From - To Date')}
                                    type='text'
                                    readOnly
                                    value={v.date_from + ' - ' + v.date_to}
                                  />
                                  <FontAwesomeIcon
                                    icon={
                                      v.showDateRange
                                        ? faChevronUp
                                        : faChevronDown
                                    }
                                    color={
                                      brand_colors[defaultTheme.theme_id]
                                        .color18
                                    }
                                    className='filter-arrow'
                                    style={{
                                      position: 'absolute',
                                      top: 25,
                                      right: 20,
                                      cursor: 'pointer',
                                    }}
                                    onClick={() =>
                                      this.setStateRegistration(
                                        i,
                                        'showDateRange',
                                        !v.showDateRange
                                      )
                                    }
                                  />
                                  {v.showDateRange && (
                                    <DateRangePicker
                                      ranges={[
                                        {
                                          startDate: new Date(v.date_from),
                                          endDate: new Date(v.date_to),
                                        },
                                      ]}
                                      onChange={(date) => {
                                        let registrations =
                                          this.state.registrations;
                                        registrations[i]['date_from'] = moment(
                                          new Date(date.range1.startDate)
                                        ).format('YYYY-MM-DD');
                                        registrations[i]['date_to'] = moment(
                                          new Date(date.range1.endDate)
                                        ).format('YYYY-MM-DD');
                                        this.setState({
                                          registrations,
                                        });
                                      }}
                                      style={{ backgroundColor: '#fff' }}
                                    />
                                  )}
                                </div>
                                <div className='ms-1' style={{ flex: 1 }}>
                                  <Form.Label>{t('Notes')}</Form.Label>
                                  <Form.Control
                                    type='text'
                                    defaultValue={v.reg_notes}
                                    placeholder={t('Notes')}
                                    onBlur={(e) =>
                                      this.setStateRegistration(
                                        i,
                                        'reg_notes',
                                        e.target.value
                                      )
                                    }
                                  />
                                  {/* DAYS className='d-flex flex-row align-items-center justify-content-center' */}
                                  <div
                                    style={{ height: '60px', display: 'none' }}>
                                    <ButtonGroup size='sm'>
                                      {days.map((vv, ii) => {
                                        let sessionDayName =
                                          'session_' +
                                          vv.slice(0, 3).toLowerCase();
                                        let disabled = false;
                                        if (
                                          weekend_days.includes(
                                            vv.toLowerCase()
                                          )
                                        ) {
                                          disabled = true;
                                        }
                                        let checked = false;

                                        if (
                                          v[sessionDayName] == 1 ||
                                          v[sessionDayName] == true
                                        ) {
                                          checked = true;
                                        }
                                        return (
                                          <Button
                                            key={ii}
                                            variant={
                                              checked ? 'success' : 'danger'
                                            }
                                            disabled={disabled}
                                            onClick={(e) =>
                                              this.setStateRegistration(
                                                i,
                                                sessionDayName,
                                                !v[
                                                'session_' +
                                                vv.slice(0, 3).toLowerCase()
                                                ]
                                              )
                                            }>
                                            {vv}
                                          </Button>
                                        );
                                      })}
                                    </ButtonGroup>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={'5'} className='table-secondary'>
                        <div className='d-flex flex-row justify-content-end'>
                          <div style={{ fontWeight: 'bold', fontSize: '16px' }}>
                            {t('Registration Total')}
                          </div>
                          <div
                            className='ms-4'
                            style={{ fontWeight: 'bold', fontSize: '16px' }}>
                            {registrations
                              .reduce(
                                (previousValue, currentValue) =>
                                  previousValue +
                                  parseFloat(currentValue.total),
                                0
                              )
                              .toFixed(2)}
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </Table>
              </div>

              {/* SERVICE ADD BUTTONS */}
              <hr></hr>
              <div>
                <Table striped size='sm'>
                  <thead>
                    <tr>
                      <th colSpan='7' className='table-success'>
                        <div className='d-flex flex-row align-items-center'>
                          <div style={{ fontSize: 20, opacity: 0.8 }}>
                            {t('Services')}
                          </div>
                          <div className='ms-2'>
                            {(!editMode || fee_type == 32) && (
                              <Button
                                variant='success'
                                size='sm'
                                onClick={() => {
                                  addservices.push({
                                    addservices_id: 0,
                                    services_id: null,
                                    addservices_fee: 0,
                                    addservices_fee_modified: false,
                                    addservices_discount: 0,
                                    addservices_discount_amount: 0,
                                    addservices_vat: 0,
                                    addservices_vat_amount: 0,
                                    addservices_quantity: 1,
                                    addservices_type: 0,
                                    // cloc_id: null,
                                    cloc_id: {
                                      label: getCenterLocationsData.data
                                        ? getCenterLocationsData.data[0]
                                          .cloc_name
                                        : '',
                                      value: getCenterLocationsData.data
                                        ? getCenterLocationsData.data[0].cloc_id
                                        : '',
                                      ...getCenterLocationsData.data[0],
                                    },
                                    total: 0,
                                    service_acc_num: null,
                                    services_cost: 0,
                                    service_chapt_id: null,
                                    service_name: null,
                                    services_type: null,
                                  });
                                  this.setState({ addservices });
                                }}>
                                <FontAwesomeIcon
                                  className='me-1'
                                  icon={faPlus}
                                  color={
                                    brand_colors[defaultTheme.theme_id].color8
                                  }
                                />
                                {t('New Service')}
                              </Button>
                            )}
                          </div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {addservices.map((v, i) => {
                      return (
                        <tr key={i}>
                          <td colSpan={'7'}>
                            <div className='d-flex flex-row align-items-center'>
                              <div className='d-flex flex-row'>
                                <div>
                                  <Badge>
                                    {t('Service')}: {i + 1}
                                  </Badge>
                                </div>
                                <div
                                  className='mx-1 d-flex flex-column align-items-center justify-content-center cursor-pointer'
                                  style={{ padding: '0.3rem 0.3rem' }}
                                  onClick={() => this.deleteAddService(i)}>
                                  <Image
                                    title={t('Remove')}
                                    src={require('../../../assets/images/remove-red.png')}
                                    style={{
                                      width: '1.2rem',
                                      height: '1.2rem',
                                    }}
                                  />
                                </div>
                              </div>
                              <div style={{ flex: 2 }}>
                                <Form.Label>{t('Service')}</Form.Label>
                                <Select
                                  classNamePrefix='custom-styled-select'
                                  placeholder={t('Select Service')}
                                  options={getServicesData.data.map((vv) => {
                                    return {
                                      label: vv.services_name,
                                      value: vv.services_id,
                                      ...vv,
                                    };
                                  })}
                                  onChange={(event) => {
                                    this.setStateService(
                                      i,
                                      'services_id',
                                      event
                                    );
                                  }}
                                  value={v.services_id}
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                  formatOptionLabel={(option) => (
                                    <div>
                                      <span style={{ fontSize: 14 }}>
                                        {option.label}
                                      </span>
                                    </div>
                                  )}
                                />
                              </div>
                              {v.services_id?.services_type == 1 ||
                                v.services_id?.services_type == 2 ? (
                                <div style={{ flex: 1.5 }} className='mx-1'>
                                  <Form.Label>{t('Warehouse')}</Form.Label>
                                  <Select
                                    classNamePrefix='custom-styled-select'
                                    placeholder={t('Select Warehouse')}
                                    options={getCenterLocationsData.data.map(
                                      (vv) => {
                                        return {
                                          label: vv.cloc_name,
                                          value: vv.cloc_id,
                                          ...vv,
                                        };
                                      }
                                    )}
                                    onChange={(event) => {
                                      this.setStateService(i, 'cloc_id', event);
                                    }}
                                    // value={(v.cloc_id) ? v.cloc_id : {
                                    //   label: getCenterLocationsData.data[0].cloc_name,
                                    //   value: getCenterLocationsData.data[0].cloc_id,
                                    // }}
                                    value={v.cloc_id}
                                    menuPortalTarget={document.body}
                                    styles={{
                                      menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 9999,
                                      }),
                                    }}
                                    formatOptionLabel={(option) => (
                                      <div>
                                        <span style={{ fontSize: 14 }}>
                                          {option.label}
                                        </span>
                                      </div>
                                    )}
                                  />
                                </div>
                              ) : (
                                <div
                                  style={{ flex: 1.5 }}
                                  className='mx-1'></div>
                              )}
                              <div style={{ flex: 1 }} className='mx-1'>
                                <Form.Label>{t('Fee')}</Form.Label>
                                <Form.Control
                                  type='text'
                                  value={v.addservices_fee}
                                  placeholder={t('Fee')}
                                  onChange={(e) =>
                                    this.setStateService(
                                      i,
                                      'addservices_fee',
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                              <div style={{ flex: 1 }} className='mx-1'>
                                <Form.Label>{t('Quantity')}</Form.Label>
                                <Form.Control
                                  type='text'
                                  value={v.addservices_quantity}
                                  placeholder={t('Quantity')}
                                  onChange={(e) =>
                                    this.setStateService(
                                      i,
                                      'addservices_quantity',
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                              <div style={{ flex: 1 }} className='mx-1'>
                                <Form.Label>{t('Discount %')}</Form.Label>
                                <Form.Control
                                  type='text'
                                  value={v.addservices_discount}
                                  placeholder={t('Discount')}
                                  onChange={(e) =>
                                    this.setStateService(
                                      i,
                                      'addservices_discount',
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                              <div style={{ flex: 1 }} className='mx-1'>
                                <Form.Label>{t('VAT(%)')}</Form.Label>
                                <Form.Control
                                  type='text'
                                  value={v.addservices_vat}
                                  placeholder={t('VAT')}
                                  readOnly
                                />
                              </div>
                              <div style={{ flex: 1 }}>
                                <Form.Label>{t('Total')}</Form.Label>
                                <Form.Control
                                  type='text'
                                  value={v.total}
                                  placeholder={t('Total')}
                                  readOnly
                                />
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={'7'} className='table-secondary'>
                        <div className='d-flex flex-row justify-content-end'>
                          <div style={{ fontWeight: 'bold', fontSize: '16px' }}>
                            {t('Services Total')}
                          </div>
                          <div
                            className='ms-4'
                            style={{ fontWeight: 'bold', fontSize: '16px' }}>
                            {addservices
                              .reduce(
                                (previousValue, currentValue) =>
                                  previousValue +
                                  parseFloat(currentValue.total),
                                0
                              )
                              .toFixed(2)}
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </Table>
              </div>

              <div style={{ height: '30vh' }}></div>
            </div>
          </div>
        </Offcanvas.Body>
        <SweetAlert
          show={this.state.deleteModal.show}
          warning
          showCancel
          confirmBtnText={t('Yes, delete it!')}
          confirmBtnBsStyle='danger'
          title={t('Are you sure?')}
          onConfirm={this.state.deleteModal.action}
          onCancel={() =>
            this.setState({
              deleteModal: {
                ...this.state.deleteModal,
                show: false,
                id: 0,
                action: () => { },
              },
            })
          }
          focusCancelBtn>
          {t('Confirm Delete!')}
        </SweetAlert>
      </Offcanvas>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultTheme: state.theme.defaultTheme,
  selectedClass: state.selectedClass.data,
  defaultLanguage: state.language.defaultLanguage,
  addEditRegistrationAdvanceModal: state.addEditRegistrationAdvanceModal,
});

const mapDispatchToProps = () => ({
  toggleRegistrationAdvanceModal,
  updateChild,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(AddEditRegistrationAdvance);
