import React, { Component } from 'react';
import {
  Container,
  Card,
  Row,
  Col,
  Button,
  DropdownButton,
  Dropdown,
  ButtonGroup,
  Modal,
  Form,
  Badge,
  Alert,
} from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import {
  faBook,
  faChartBar,
  faChartLine,
  faListOl,
  faPlus,
  faSearch,
  faTimesCircle,
  faUserCheck,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { brand_colors } from '../../helpers/brand_colors_helper';
import { NavLink } from 'react-router-dom';
import {
  changeColorOpacity,
  ifNavAllowed,
} from '../../helpers/general_helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GeneralCard from '../../helpers/GeneralCard';
import { toast } from 'react-toastify';
import { GET, POST } from '../../api';
import axios from 'axios';
import imgDescription from '../../assets/images/description.png';
import Select from 'react-select';
import { handleFormErrors } from '../../helpers/form_helpers';
import ReportCard from './ReportCard';

const portalURL = process.env.REACT_APP_PORTAL_URL;

class ReportCardGenerator extends Component {
  repcard_stamp = React.createRef();

  constructor(props) {
    super(props);

    this.reportcards = {
      repcard_id: 0,
      repcard_name: '',
      repcard_pdf_title: '',
      repcard_pdf_title_2: '',
      term_d_ids: [],
      centerlic_id: null,
      repcardtype_id: null,
      ab_id: null,
    };

    this.state = {
      repcard_stamp: '',
      repcard_stamp_prev: '',
      repcard_stamp_delete_flag: null,
      Reportcards: {
        openSearch: false,
        toggleSearch: () =>
          this.setState(
            {
              Reportcards: {
                ...this.state.Reportcards,
                openSearch: !this.state.Reportcards.openSearch,
                search: '',
              },
            },
            () => {
              if (!this.state.Reportcards.openSearch) {
                this.getReportCards();
              }
            }
          ),
        changeSearch: (search = '') =>
          this.setState(
            { Reportcards: { ...this.state.Reportcards, search } },
            () => this.getReportCards()
          ),
        search: '',
        orderName: 'Newest First',
        order: 'reportcards.repcard_id',
        dir: 'DESC',
        changeOrder: (
          orderName = 'Newest First',
          order = 'reportcards.repcard_id',
          dir = 'DESC'
        ) =>
          this.setState(
            {
              Reportcards: { ...this.state.Reportcards, orderName, order, dir },
            },
            () => this.getReportCards()
          ),
        limit: 100,
        offset: 0,
        data: [],
        total_count: 0,
        total_count_filtered: 0,
        refreshing: false,
        finished: false,
      },
      termsDefault: [],
      reportcardtypeData: [],
      centerlicData: [],
      academicBoards: [],
      ReportcardsModal: {
        show: false,
        title: 'New Report Card',
        toggle: (
          data = this.reportcards,
          title = 'New Report Card',
          edit = false
        ) =>
          this.setState(
            {
              ReportcardsModal: {
                ...this.state.ReportcardsModal,
                show: !this.state.ReportcardsModal.show,
                data,
                title,
                edit,
              },
            },
            () => {
              if (this.state.ReportcardsModal.show) {
                this.getCenterlic();
                this.getAcademicBoards();
              } else {
                this.setState({
                  repcard_stamp: '',
                  repcard_stamp_prev: '',
                  repcard_stamp_delete_flag: null,
                });
              }
            }
          ),
        data: this.reportcards,
        setData: (field, value) =>
          this.setState({
            ReportcardsModal: {
              ...this.state.ReportcardsModal,
              data: { ...this.state.ReportcardsModal.data, [field]: value },
            },
          }),
        save: () => this.saveReportCard(),
        saving: false,
        edit: false,
      },
      activeReportCard: {
        repcard_id: 0,
        toggle: (repcard_id) =>
          this.setState({
            activeReportCard: {
              ...this.state.activeReportCard,
              repcard_id: repcard_id,
            },
          }),
      },
    };
  }

  componentDidMount() {
    this.getReportCards();
    this.getReportcardtype();
    this.getTermsDefault();
  }

  getTermsDefault = async () => {
    if (this.getTermsDefaultReq) {
      this.getTermsDefaultReq.abort();
    }
    this.getTermsDefaultReq = new AbortController();
    const res = await GET('report-card-generator/get-terms-default', {
      signal: this.getTermsDefaultReq.signal,
    });
    this.setState({
      termsDefault: res.data,
    });
  };

  getReportcardtype = async () => {
    if (this.getReportcardtypeReq) {
      this.getReportcardtypeReq.abort();
    }
    this.getReportcardtypeReq = new AbortController();

    try {
      const res = await GET('report-card-generator/get-reportcardtype', {
        signal: this.getReportcardtypeReq.signal,
      });

      this.setState({ reportcardtypeData: res.data });
    } catch (err) {
      console.log('err', err);
      if (axios.isCancel(err)) {
      } else {
        toast.error(t('Something went wrong while fetching data!'));
      }
    }
  };

  getCenterlic = async () => {
    if (this.getCenterlicReq) {
      this.getCenterlicReq.abort();
    }
    this.getCenterlicReq = new AbortController();

    try {
      const res = await GET('report-card-generator/get-centerlic', {
        signal: this.getCenterlicReq.signal,
      });

      this.setState({ centerlicData: res.data });
    } catch (err) {
      console.log('err', err);
      if (axios.isCancel(err)) {
      } else {
        toast.error(t('Something went wrong while fetching data!'));
      }
    }
  };

  getAcademicBoards = async () => {
    if (this.getAcademicBoardsReq) {
      this.getAcademicBoardsReq.abort();
    }
    this.getAcademicBoardsReq = new AbortController();

    try {
      const res = await GET('report-card-generator/get-academic-boards', {
        signal: this.getAcademicBoardsReq.signal,
      });

      this.setState({ academicBoards: res.data });
    } catch (err) {
      console.log('err', err);
      if (axios.isCancel(err)) {
      } else {
        toast.error(t('Something went wrong while fetching data!'));
      }
    }
  };

  getReportCards = async () => {
    if (this.getReportCardsReq) {
      this.getReportCardsReq.abort();
    }
    this.getReportCardsReq = new AbortController();

    this.setState({
      Reportcards: {
        ...this.state.Reportcards,
        refreshing: true,
      },
    });

    try {
      const res = await GET('report-card-generator/get', {
        params: {
          search: this.state.Reportcards.search,
          order: this.state.Reportcards.order,
          dir: this.state.Reportcards.dir,
          limit: this.state.Reportcards.limit,
          offset: this.state.Reportcards.offset,
        },
        signal: this.getReportCardsReq.signal,
      });

      this.setState({
        Reportcards: {
          ...this.state.Reportcards,
          data: res.data.data,
          total_count: res.data.total_count,
          total_count_filtered: res.data.total_count_filtered,
          refreshing: false,
          finished: true,
        },
      });
    } catch (err) {
      console.log('err', err);
      if (axios.isCancel(err)) {
      } else {
        toast.error(t('Something went wrong while fetching data!'));
      }
    }
  };

  editReportCard = (v, i) => {
    console.log('editReportCard', v, i);
    let data = { ...v };
    data.repcardtype_id = {
      label: data.repcardtype_title,
      value: data.repcardtype_id,
      repcardtype_id: data.repcardtype_id,
      repcardtype_desc: data.repcardtype_desc,
      repcardtype_gradeslevel: data.repcardtype_gradeslevel,
      repcardtype_title: data.repcardtype_title,
    };
    data.centerlic_id = {
      label: data.reg_year,
      value: data.centerlic_id,
      reg_year: data.reg_year,
      centerlic_id: data.centerlic_id,
    };
    data.ab_id = {
      label: data.ab_name,
      value: data.ab_id,
      ab_name: data.ab_name,
      ab_id: data.ab_id,
    };
    data.term_d_ids = JSON.parse(data.term_d_ids);

    this.state.ReportcardsModal.toggle(
      { ...this.reportcards, ...data },
      'Edit Report Card',
      true
    );
    this.setState({
      repcard_stamp_prev: v.repcard_stamp
        ? portalURL + this.props.authData.centerData.center_uuid + '/repcard_stamp/' + v.repcard_stamp
        : '',
    });
  };

  saveReportCard = async () => {
    if (this.saveReportCardReq) {
      this.saveReportCardReq.abort();
    }
    this.saveReportCardReq = new AbortController();

    this.setState({
      ReportcardsModal: { ...this.state.ReportcardsModal, saving: true },
    });
    let ReportcardsModal = JSON.parse(
      JSON.stringify(this.state.ReportcardsModal)
    );

    ReportcardsModal.data.centerlic_id =
      ReportcardsModal.data.centerlic_id.value;
    ReportcardsModal.data.ab_id = ReportcardsModal.data.ab_id.value;
    ReportcardsModal.data.repcardtype_id =
      ReportcardsModal.data.repcardtype_id.value;

    const fData = new FormData();
    fData.append('ReportcardsData', JSON.stringify(ReportcardsModal.data));
    fData.append('repcard_stamp', this.state.repcard_stamp);
    const res = await POST('report-card-generator/save', fData, {
      signal: this.saveReportCardReq.signal,
    });
    if (res) {
      let resData = res.data;
      if (resData.type) {
        let Reportcards = this.state.Reportcards;
        if (ReportcardsModal.edit) {
          Reportcards.data = Reportcards.data.map((v, i) => {
            if (v.repcard_id == resData.data.repcard_id) {
              return resData.data;
            }
            return v;
          });
        } else {
          Reportcards.data = [resData.data].concat(Reportcards.data);
        }
        this.setState({
          ReportcardsModal: {
            ...this.state.ReportcardsModal,
            show: false,
            saving: false,
            edit: false,
          },
          activeReportCard: {
            ...this.state.activeReportCard,
            repcard_id: 0,
          },
          Reportcards: Reportcards,
        });
        toast.success(t(res.data.message));
      } else {
        if (resData.data != null) {
          handleFormErrors(resData.data);
        } else {
          toast.error(res.data.message);
        }
        this.setState({
          ReportcardsModal: {
            ...this.state.ReportcardsModal,
            saving: false,
          },
        });
      }
    }
  };

  previewFile = (e) => {
    const reader = new FileReader();

    const selectedFile = e.target.files[0];

    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
    }

    if (!selectedFile.type.includes('image')) {
      toast.error(t('Only image file is allowed!'));
      return;
    }

    reader.onload = (readerEvent) => {
      if (selectedFile.type.includes('image')) {
        this.setState({
          [e.target.name + '_prev']: readerEvent.target.result,
          [e.target.name]: e.target.files[0],
        });
      }
    };
  };

  // Delete File Directly
  deleteStamp = async (id) => {
    if (id == 0) {
      return false;
    }

    try {
      const fData = new FormData();
      fData.append('id', id);
      const res = await POST('report-card-generator/delete-stamp', fData);
      console.log('deleteStamp response', res);
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    const {
      Reportcards,
      ReportcardsModal,
      reportcardtypeData,
      centerlicData,
      activeReportCard,
      academicBoards,
      termsDefault,
    } = this.state;
    const { lang_orientation: dir } = this.props.defaultLanguage;
    const { defaultTheme } = this.props;

    console.log('REPORTCARDGEN', this.state);
    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content'>
            <Header
              lite={true}
              heading={t('Report Card Generator')}
              backBtn={true}
            />
            <div className='grey-section'>
              <Row>
                {/* GRADES */}
                <div className='grey-section-header mt-2 flex-row align-items-center'>
                  <h5 className='mb-0'>
                    <span>{t('Reports')}</span>
                    <span
                      className='mx-1'
                      style={{ opacity: 0.6, fontWeight: 'normal' }}>
                      |
                    </span>
                    {/* <span className='mx-1' style={{ opacity: 0.8, fontSize: '1rem', fontWeight: 'normal' }}>{t('Please  ')}</span> */}
                    <ButtonGroup>
                      <div
                        className='cb-expandable-search'
                        style={{
                          width: Reportcards.openSearch ? 178 : 32,
                          borderRadius: 0,
                        }}>
                        <input
                          ref={this.searchFieldRef}
                          onChange={(e) =>
                            Reportcards.changeSearch(e.target.value)
                          }
                          value={Reportcards.search}
                          type='text'
                          style={{
                            width: Reportcards.openSearch ? 150 : 0,
                            paddingLeft: Reportcards.openSearch ? 15 : 0,
                            opacity: Reportcards.openSearch ? 1 : 0,
                            backgroundColor:
                              brand_colors[defaultTheme.theme_id].color8,
                            fontSize: 14,
                          }}
                          placeholder={t('Search')}
                        />
                        <Button
                          style={{ border: 'inherit' }}
                          size='sm'
                          title={t('Search')}
                          variant={Reportcards.search ? 'danger' : 'primary'}
                          onClick={() => Reportcards.toggleSearch()}>
                          <FontAwesomeIcon
                            icon={Reportcards.openSearch ? faXmark : faSearch}
                            color={brand_colors[defaultTheme.theme_id].color8}
                          />
                        </Button>
                      </div>
                      <DropdownButton
                        size='sm'
                        as={ButtonGroup}
                        title={'ORDER BY: ' + Reportcards.orderName}
                        id='bg-nested-dropdown'>
                        <Dropdown.Item
                          onClick={() =>
                            Reportcards.changeOrder(
                              'Newest First',
                              'reportcards.repcard_id',
                              'DESC'
                            )
                          }>
                          Newest First
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            Reportcards.changeOrder(
                              'Oldest First',
                              'reportcards.repcard_id',
                              'ASC'
                            )
                          }>
                          Oldest First
                        </Dropdown.Item>
                      </DropdownButton>
                    </ButtonGroup>
                  </h5>
                  <div className='d-flex flex-row align-items-center'>
                    <Button
                      variant='success'
                      className='btn-sm btn-add me-2'
                      onClick={() => ReportcardsModal.toggle()}>
                      <FontAwesomeIcon
                        icon={faPlus}
                        style={{ marginRight: 7 }}
                      />
                      {t('New Report')}
                    </Button>
                  </div>
                </div>
              </Row>

              {Reportcards.data.map((v, i) => {
                return (
                  <ReportCard
                    key={i}
                    index={i}
                    last={i == Reportcards.data.length - 1 ? true : false}
                    data={v}
                    activeReportCard={activeReportCard}
                    edit={this.editReportCard}
                  />
                );
              })}
            </div>
          </div>
        </div>

        {/* MODAL CREATE REPORT */}
        <Modal
          show={ReportcardsModal.show}
          onHide={() => ReportcardsModal.toggle()}
          size='lg'
          backdrop='static'
          keyboard={false}>
          <Modal.Header closeButton>
            <Modal.Title>{ReportcardsModal.title}</Modal.Title>
          </Modal.Header>
          {ReportcardsModal.show && (
            <Modal.Body className='custom-styled-form'>
              <Row>
                <Col md='4'>
                  <Form.Group>
                    <Form.Label>
                      <img src={imgDescription} />
                      {t('Type')}
                    </Form.Label>
                    <Select
                      value={ReportcardsModal.data.repcardtype_id}
                      options={reportcardtypeData.map((v, i) => {
                        return {
                          ...v,
                          label: v.repcardtype_title,
                          value: v.repcardtype_id,
                        };
                      })}
                      onChange={(obj) =>
                        ReportcardsModal.setData('repcardtype_id', obj)
                      }
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 99999999999999,
                        }),
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  {ReportcardsModal.data.repcardtype_id && (
                    <Alert variant='info' className='p-1'>
                      {ReportcardsModal.data.repcardtype_id.repcardtype_desc}
                      <br></br>
                      <Badge bg='info'>
                        {ReportcardsModal.data.repcardtype_id
                          .repcardtype_gradeslevel == 1
                          ? t('Using Marks Definition')
                          : t('By Marks')}
                      </Badge>
                    </Alert>
                  )}
                </Col>
              </Row>
              <Row>
                <Col md='4'>
                  <Form.Group>
                    <Form.Label>
                      <img src={imgDescription} />
                      {t('Name')}
                    </Form.Label>
                    <Form.Control
                      type='text'
                      defaultValue={ReportcardsModal.data.repcard_name}
                      onBlur={(event) =>
                        ReportcardsModal.setData(
                          'repcard_name',
                          event.target.value
                        )
                      }
                      placeholder={t('Name')}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      <img src={imgDescription} />
                      {t('Year')}
                    </Form.Label>
                    <Select
                      value={ReportcardsModal.data.centerlic_id}
                      options={centerlicData.map((v, i) => {
                        return {
                          ...v,
                          label: v.reg_year,
                          value: v.centerlic_id,
                        };
                      })}
                      onChange={(obj) =>
                        ReportcardsModal.setData('centerlic_id', obj)
                      }
                      formatOptionLabel={(option) => (
                        <div>
                          {option.label}
                          {option.reg_status == 1 && (
                            <Badge className='ms-2' bg='info'>
                              Active
                            </Badge>
                          )}
                        </div>
                      )}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 99999999999999,
                        }),
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      <img src={imgDescription} />
                      {t('Curriculum')}
                    </Form.Label>
                    <Select
                      value={ReportcardsModal.data.ab_id}
                      options={academicBoards.map((v, i) => {
                        return { ...v, label: v.ab_name, value: v.ab_id };
                      })}
                      onChange={(obj) => ReportcardsModal.setData('ab_id', obj)}
                      formatOptionLabel={(option) => <div>{option.label}</div>}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 99999999999999,
                        }),
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md='6'>
                  <Form.Group>
                    <Form.Label>
                      <img src={imgDescription} />
                      {t('PDF Title')}
                    </Form.Label>
                    <Form.Control
                      type='text'
                      defaultValue={ReportcardsModal.data.repcard_pdf_title}
                      onBlur={(event) =>
                        ReportcardsModal.setData(
                          'repcard_pdf_title',
                          event.target.value
                        )
                      }
                      placeholder={t('PDF Title')}
                    />
                  </Form.Group>
                </Col>
                <Col md='6'>
                  <Form.Group>
                    <Form.Label>
                      <img src={imgDescription} />
                      {t('Signatory Name')}
                    </Form.Label>
                    <Form.Control
                      type='text'
                      defaultValue={
                        ReportcardsModal.data.repcard_signatory_name
                      }
                      onBlur={(event) =>
                        ReportcardsModal.setData(
                          'repcard_signatory_name',
                          event.target.value
                        )
                      }
                      placeholder={t('Signatory Name')}
                    />
                  </Form.Group>
                </Col>
                <Col md='6'>
                  <Form.Group>
                    <Form.Label>
                      <img src={imgDescription} />
                      {t('PDF Title 2')}
                    </Form.Label>
                    <Form.Control
                      type='text'
                      defaultValue={ReportcardsModal.data.repcard_pdf_title_2}
                      onBlur={(event) =>
                        ReportcardsModal.setData(
                          'repcard_pdf_title_2',
                          event.target.value
                        )
                      }
                      placeholder={t('PDF Title 2')}
                    />
                  </Form.Group>
                </Col>
                <Col md='8'>
                  <Form.Group>
                    <Form.Label>
                      <img src={imgDescription} />
                      {t('Description')}{' '}
                      <span className='text-muted'>
                        {t('(Max 500 characters)')}
                      </span>
                    </Form.Label>
                    <Form.Control
                      maxLength={500}
                      as='textarea'
                      rows={8}
                      defaultValue={ReportcardsModal.data.repcard_desc}
                      onBlur={(event) =>
                        ReportcardsModal.setData(
                          'repcard_desc',
                          event.target.value
                        )
                      }
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className='mb-3'>
                    <Form.Label>{t('Stamp')}</Form.Label>
                    <div
                      style={{
                        flexDirection: 'column',
                      }}
                      className='pic-prev d-flex justify-content-center'>
                      <div
                        style={{
                          borderRadius: 5,
                          borderStyle: 'dashed',
                          borderWidth: 2,
                          borderColor: '#ced4da',
                          height: 204,
                          display: 'flex',
                          overflow: 'hidden',
                        }}>
                        {this.state.repcard_stamp_prev ? (
                          <div
                            className='d-flex justify-content-center align-items-center'
                            style={{
                              flex: 1,
                              position: 'relative',
                            }}>
                            <button
                              style={{
                                padding: 3,
                                border: 3,
                                background: 'none',
                                position: 'absolute',
                                top: 0,
                                right: 0,
                              }}
                              onClick={() => {
                                this.setState({
                                  repcard_stamp: '',
                                  repcard_stamp_prev: '',
                                  repcard_stamp_delete_flag: false,
                                });
                                this.repcard_stamp.current.value = null;
                                this.deleteStamp(
                                  this.state.ReportcardsModal.data.repcard_id
                                );
                              }}>
                              <FontAwesomeIcon
                                icon={faTimesCircle}
                                color='rgb(220, 53, 69)'
                                style={{
                                  fontSize: 20,
                                }}
                              />
                            </button>
                            <img
                              src={this.state.repcard_stamp_prev}
                              alt={t('Preview')}
                              width='100'
                            />
                          </div>
                        ) : (
                          <button
                            onClick={() => this.repcard_stamp.current.click()}
                            style={{
                              background: 'none',
                              border: 0,
                              flex: 1,
                            }}>
                            <FontAwesomeIcon
                              icon={faPlus}
                              style={{
                                fontSize: 30,
                              }}
                            />
                          </button>
                        )}
                      </div>
                      <Form.Control
                        className='d-none'
                        ref={this.repcard_stamp}
                        type='file'
                        onChange={this.previewFile}
                        name='repcard_stamp'
                        accept='.jpg, .jpeg, .png'
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row className='mt-3'>
                {termsDefault.map((v, i) => {
                  let term_d_id = parseInt(v.term_d_id);
                  let term_d_checked = false;
                  if (ReportcardsModal.data.term_d_ids.includes(term_d_id)) {
                    term_d_checked = true;
                  }
                  return (
                    <Col className='d-flex flex-column align-items-center justify-content-center'>
                      <Form.Check
                        type={'checkbox'}
                        checked={term_d_checked}
                        onChange={() => {
                          let term_d_ids = ReportcardsModal.data.term_d_ids;
                          if (term_d_checked) {
                            term_d_ids = term_d_ids.filter(
                              (vv, ii) => vv != term_d_id
                            );
                          } else {
                            term_d_ids.push(parseInt(term_d_id));
                          }
                          ReportcardsModal.setData('term_d_ids', term_d_ids);
                        }}
                      />
                      <div className='ms-3'>{v.term_d_name}</div>
                    </Col>
                  );
                })}
              </Row>
            </Modal.Body>
          )}
          <Modal.Footer>
            <Button
              variant='secondary'
              onClick={() => ReportcardsModal.toggle()}>
              {t('Close')}
            </Button>
            <Button
              variant={ReportcardsModal.edit ? 'warning' : 'primary'}
              onClick={ReportcardsModal.save}>
              {ReportcardsModal.edit ? t('Update Changes') : t('Save Changes')}
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(ReportCardGenerator);
