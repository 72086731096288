import React, { Component } from 'react';
import { Container, Card, Row, Col, Button, Tab, Tabs, Table, Badge, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDashboard, faUsers, faAddressCard, faUserTie, faChevronDown, faSchool, faInfoCircle, faEye } from '@fortawesome/free-solid-svg-icons';
import { t } from '../../../../helpers/translation_helper';
import Paginate from '../../../_partials/Paginate/_Paginate';

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from 'chart.js';
import { Doughnut, Bar } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { brand_colors } from '../../../../helpers/brand_colors_helper';
import axios from 'axios';
import { toast } from 'react-toastify';
import { LoadingPlaceholder } from '../../../_common/Loading/Loading';
import { Skeleton } from '../../../_common/Loading/Skeleton';
import moment from 'moment';
import { GET } from '../../../../api';
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!

// API URL
const apiURL = process.env.REACT_APP_API_URL;

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const barChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
      position: 'bottom',
    },
  },
  scales: {
    x: {
      ticks: {
        callback: function (value) {
          return this.getLabelForValue(value).substr(0, 10) + '...';
        }
      }
    }
  }
};

const doughnutChartOptions = {
  responsive: true,
  cutout: 90,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
      position: 'bottom',
    },
  },
};

class HR extends Component {
  state = {
    salariesData: null,
    totalAbsencesData: null,
    totalAbsencesLimit: 4,
    totalAbsencesTotalRecords: 0,
    totalAbsencesChart: null,
    pendingLeavesLimit: 5,
    pendingLeavesTotalRecords: 0,
    pendingLeavesData: null,
    pendingLeavesChart1: null,
    pendingLeavesChart2: null,
    pendingAdditionsLimit: 5,
    pendingAdditionsTotalRecords: 0,
    pendingAdditionsData: null,
    pendingAdditionsChart1: null,
    pendingAdditionsChart2: null,
    pendingAllowancesLimit: 5,
    pendingAllowancesTotalRecords: 0,
    pendingAllowancesData: null,
    pendingAllowancesChart1: null,
    pendingAllowancesChart2: null,
    events: null
  }

  componentDidMount() {
    this.reportSalaries();
    this.tableTotalAbsences();
    this.reportAbsenceTypes();
    this.tablePendingLeaves();
    this.reportLeavesStatus();
    this.reportLeavesTypes();
    this.tableTotalAdditions();
    this.reportAdditionsStatus();
    this.reportAdditionsTypes();
    this.tableTotalAllowances();
    this.reportAllowancesStatus();
    this.reportAllowancesTypes();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.authData.centerData.center_id !== this.props.authData.centerData.center_id) {
      this.reportSalaries();
      this.tableTotalAbsences();
      this.reportAbsenceTypes();
      this.tablePendingLeaves();
      this.reportLeavesStatus();
      this.reportLeavesTypes();
      this.tableTotalAdditions();
      this.reportAdditionsStatus();
      this.reportAdditionsTypes();
      this.tableTotalAllowances();
      this.reportAllowancesStatus();
      this.reportAllowancesTypes();
    }
  }

  reportSalaries = async () => {
    const { authData } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id } = authData.centerData;

    const request_params = {
      center_id,
      auth_key: auth_key,
      appname: process.env.REACT_APP_NAME,
    };

    try {
      const res = await GET('admin/report-salaries', {
        params: request_params,
      });

      if (res.status == 200) {

        let salariesData = {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: []
            }
          ]
        };

        salariesData.labels = res.data.employees.map((employee) => { return employee[0] + ', ' + employee[1] });
        salariesData.datasets[0].data = res.data.salaries.map((salary) => { return parseFloat(salary.replace(',', '')) });
        console.log('salariesData.datasets[0].data', salariesData.datasets[0].data);
        salariesData.datasets[0].backgroundColor = res.data.colors;

        this.setState({ salariesData });

      }

    } catch (err) {
      console.log('err', err)

      toast.error(t('Something went wrong!'));
    }
  }

  tableTotalAbsences = async (limit = this.state.totalAbsencesLimit, offset = 0) => {

    this.setState({ totalAbsencesData: null });

    const { authData } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id } = authData.centerData;

    const request_params = {
      center_id,
      search: '',
      limit,
      offset,
      auth_key: auth_key,
      appname: process.env.REACT_APP_NAME,
    };

    try {
      const res = await GET('admin/table-total-absences', {
        params: request_params,
      });

      if (res.status == 200) {

        this.setState({
          totalAbsencesData: res.data.data,
          totalAbsencesTotalRecords: parseInt(res.data.total_count)
        });

      }

    } catch (err) {
      console.log('err', err)

      toast.error(t('Something went wrong!'));
    }
  }

  reportAbsenceTypes = async () => {

    this.setState({
      totalAbsencesChart: null
    });

    const { authData } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id, center_timezone } = authData.centerData;

    const request_params = {
      center_id,
      auth_key: auth_key,
      appname: process.env.REACT_APP_NAME,
    };

    try {
      const res = await GET('admin/report-absence-types', {
        params: request_params,
      });

      if (res.status == 200) {

        let chart = {
          labels: res.data.labels,
          datasets: [
            {
              data: res.data.data,
              backgroundColor: [
                brand_colors[this.props.defaultTheme.theme_id].color1,
                brand_colors[this.props.defaultTheme.theme_id].color2,
                brand_colors[this.props.defaultTheme.theme_id].color4
              ]
            }
          ]
        };

        this.setState({
          totalAbsencesChart: chart
        });

      }

    } catch (err) {
      console.log('err', err)

      toast.error(t('Something went wrong!'));
    }
  }

  tablePendingLeaves = async (limit = this.state.pendingLeavesLimit, offset = 0) => {

    this.setState({
      pendingLeavesData: null
    });

    const { authData } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id } = authData.centerData;

    const request_params = {
      center_id,
      search: '',
      limit,
      offset,
      auth_key: auth_key,
      appname: process.env.REACT_APP_NAME,
    };

    try {
      const res = await GET('admin/table-pending-leaves', {
        params: request_params,
      });

      if (res.status == 200) {

        this.setState({
          pendingLeavesData: res.data.data,
          pendingLeavesTotalRecords: parseInt(res.data.total_count)
        });

      }

    } catch (err) {
      console.log('err', err)

      toast.error(t('Something went wrong!'));
    }
  }

  reportLeavesStatus = async () => {

    this.setState({
      pendingLeavesChart1: null
    });

    const { authData } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id, center_timezone } = authData.centerData;

    const request_params = {
      center_id,
      auth_key: auth_key,
      appname: process.env.REACT_APP_NAME,
    };

    try {
      const res = await GET('admin/report-leaves-status', {
        params: request_params,
      });

      if (res.status == 200) {

        let chart1 = {
          labels: res.data.labels,
          datasets: [
            {
              data: res.data.data,
              backgroundColor: [
                brand_colors[this.props.defaultTheme.theme_id].color1,
                brand_colors[this.props.defaultTheme.theme_id].color2,
                brand_colors[this.props.defaultTheme.theme_id].color4
              ]
            }
          ]
        };

        this.setState({
          pendingLeavesChart1: chart1
        });

      }

    } catch (err) {
      console.log('err', err)

      toast.error(t('Something went wrong!'));
    }
  }

  reportLeavesTypes = async () => {

    this.setState({
      pendingLeavesChart2: null
    });

    const { authData } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id, center_timezone } = authData.centerData;

    const request_params = {
      center_id,
      auth_key: auth_key,
      appname: process.env.REACT_APP_NAME,
    };

    try {
      const res = await GET('admin/report-leaves-types', {
        params: request_params,
      });

      if (res.status == 200) {

        let chart2 = {
          labels: res.data.labels,
          datasets: [
            {
              data: res.data.data,
              backgroundColor: [
                brand_colors[this.props.defaultTheme.theme_id].color1,
                brand_colors[this.props.defaultTheme.theme_id].color2,
                brand_colors[this.props.defaultTheme.theme_id].color4
              ]
            }
          ]
        };

        this.setState({
          pendingLeavesChart2: chart2
        });

      }

    } catch (err) {
      console.log('err', err)

      toast.error(t('Something went wrong!'));
    }
  }

  tableTotalAdditions = async (limit = this.state.pendingAdditionsLimit, offset = 0) => {

    this.setState({
      pendingAdditionsData: null
    });

    const { authData } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id } = authData.centerData;

    const request_params = {
      center_id,
      search: '',
      limit,
      offset,
      auth_key: auth_key,
      appname: process.env.REACT_APP_NAME,
    };

    try {
      const res = await GET('admin/table-total-additions', {
        params: request_params,
      });

      if (res.status == 200) {

        this.setState({
          pendingAdditionsTotalRecords: parseInt(res.data.total_count),
          pendingAdditionsData: res.data.data
        });


      }

    } catch (err) {
      console.log('err', err)

      toast.error(t('Something went wrong!'));
    }

  }

  reportAdditionsStatus = async () => {

    this.setState({
      pendingAdditionsChart1: null
    });

    const { authData } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id, center_timezone } = authData.centerData;

    const request_params = {
      center_id,
      auth_key: auth_key,
      appname: process.env.REACT_APP_NAME,
    };

    try {
      const res = await GET('admin/report-additions-status', {
        params: request_params,
      });

      if (res.status == 200) {

        let chart1 = {
          labels: res.data.labels,
          datasets: [
            {
              data: res.data.data,
              backgroundColor: [
                brand_colors[this.props.defaultTheme.theme_id].color1,
                brand_colors[this.props.defaultTheme.theme_id].color2,
                brand_colors[this.props.defaultTheme.theme_id].color4
              ]
            }
          ]
        };

        this.setState({
          pendingAdditionsChart1: chart1
        });

      }

    } catch (err) {
      console.log('err', err)

      toast.error(t('Something went wrong!'));
    }
  }

  reportAdditionsTypes = async () => {

    this.setState({
      pendingAdditionsChart2: null
    });

    const { authData } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id, center_timezone } = authData.centerData;

    const request_params = {
      center_id,
      auth_key: auth_key,
      appname: process.env.REACT_APP_NAME,
    };

    try {
      const res = await GET('admin/report-additions-types', {
        params: request_params,
      });

      if (res.status == 200) {

        let chart2 = {
          labels: res.data.labels,
          datasets: [
            {
              data: res.data.data,
              backgroundColor: [
                brand_colors[this.props.defaultTheme.theme_id].color1,
                brand_colors[this.props.defaultTheme.theme_id].color2,
                brand_colors[this.props.defaultTheme.theme_id].color4
              ]
            }
          ]
        };

        this.setState({
          pendingAdditionsChart2: chart2
        });

      }

    } catch (err) {
      console.log('err', err)

      toast.error(t('Something went wrong!'));
    }
  }

  tableTotalAllowances = async (limit = this.state.pendingAllowancesLimit, offset = 0) => {

    this.setState({
      pendingAllowancesData: null
    });

    const { authData } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id } = authData.centerData;

    const request_params = {
      center_id,
      search: '',
      limit,
      offset,
      auth_key: auth_key,
      appname: process.env.REACT_APP_NAME,
    };

    try {
      const res = await GET('admin/table-total-allowances', {
        params: request_params,
      });

      if (res.status == 200) {

        this.setState({
          pendingAllowancesTotalRecords: parseInt(res.data.total_count),
          pendingAllowancesData: res.data.data
        });


      }

    } catch (err) {
      console.log('err', err)

      toast.error(t('Something went wrong!'));
    }

  }

  reportAllowancesStatus = async () => {

    this.setState({
      pendingAllowancesChart1: null
    });

    const { authData } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id, center_timezone } = authData.centerData;

    const request_params = {
      center_id,
      auth_key: auth_key,
      appname: process.env.REACT_APP_NAME,
    };

    try {
      const res = await GET('admin/report-allowances-status', {
        params: request_params,
      });

      if (res.status == 200) {

        let chart1 = {
          labels: res.data.labels,
          datasets: [
            {
              data: res.data.data,
              backgroundColor: [
                brand_colors[this.props.defaultTheme.theme_id].color1,
                brand_colors[this.props.defaultTheme.theme_id].color2,
                brand_colors[this.props.defaultTheme.theme_id].color4
              ]
            }
          ]
        };

        this.setState({
          pendingAllowancesChart1: chart1
        });

      }

    } catch (err) {
      console.log('err', err)

      toast.error(t('Something went wrong!'));
    }
  }

  reportAllowancesTypes = async () => {

    this.setState({
      pendingAllowancesChart2: null
    });

    const { authData } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id, center_timezone } = authData.centerData;

    const request_params = {
      center_id,
      auth_key: auth_key,
      appname: process.env.REACT_APP_NAME,
    };

    try {
      const res = await GET('admin/report-allowances-types', {
        params: request_params,
      });

      if (res.status == 200) {

        let chart2 = {
          labels: res.data.labels,
          datasets: [
            {
              data: res.data.data,
              backgroundColor: [
                brand_colors[this.props.defaultTheme.theme_id].color1,
                brand_colors[this.props.defaultTheme.theme_id].color2,
                brand_colors[this.props.defaultTheme.theme_id].color4
              ]
            }
          ]
        };

        this.setState({
          pendingAllowancesChart2: chart2
        });

      }

    } catch (err) {
      console.log('err', err)

      toast.error(t('Something went wrong!'));
    }
  }

  reportCenterCalendar = async (arg) => {
    if (arg == undefined) {
    } else {
      this.dateStart = arg.start
      this.dateEnd = arg.end
    }
    this.setState({
      events: null,
    });
    const { authData } = this.props;
    const { auth_key, role_id } = authData.loginData;
    const { center_id } = authData.centerData;

    const request_params = {
      startdate: moment(this.dateStart).format('YYYY-MM-DD'),
      enddate: moment(this.dateEnd).format('YYYY-MM-DD'),
      role_id,
      actionType: 'hr_tab'
    };


    try {
      const res = await GET('admin/report-center-calendar', {
        params: request_params,
      });

      if (res.status == 200) {
        this.setState({
          events: res.data.data
        });
      }

    } catch (err) {
      console.log('err', err)

      toast.error(t('Something went wrong!'));
    }
  }

  render() {

    // const data = {
    //   labels: ['New', 'In Review', 'Need More Details', 'Placement Test', 'Test Passed', 'Accepted', 'Registered', 'Rejected', 'Parent Review', 'Parent Submit', 'Form Sent To'],
    //   datasets: [
    //     {
    //       label: 'Registrations',
    //       data: [20, 65, 34, 69, 40, 35, 55, 34, 20, 65, 34, 69, 40, 35, 55, 34],
    //       backgroundColor: [
    //         brand_colors[this.props.defaultTheme.theme_id].color1,
    //         brand_colors[this.props.defaultTheme.theme_id].color2,
    //         brand_colors[this.props.defaultTheme.theme_id].color4,
    //         brand_colors[this.props.defaultTheme.theme_id].color16,
    //         brand_colors[this.props.defaultTheme.theme_id].color22,
    //       ],
    //     },
    //   ],
    // };

    const dData = {
      labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
      datasets: [
        {
          label: '# of Votes',
          data: [12, 19, 3, 5, 2, 3],
          backgroundColor: [
            'rgba(255, 99, 132)',
            'rgba(54, 162, 235)',
            'rgba(255, 206, 86)',
            'rgba(75, 192, 192)',
            'rgba(153, 102, 255)',
            'rgba(255, 159, 64)',
          ],
        },
      ],
    };

    return (<>
      <Row>
        <Col md={6}>
          <Card
            className='border-0'
            style={{ borderRadius: 20, marginBottom: 20 }}>
            <Card.Body>
              <div className='text-center'><strong>{t('Salaries')}</strong></div>
              <div>
                {this.state.salariesData ? <Bar options={barChartOptions} data={this.state.salariesData} height={288} /> : <LoadingPlaceholder height={288} />}
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card
            className='border-0'
            style={{ borderRadius: 20, marginBottom: 20 }}>
            <Card.Body>
              <div className='text-center mb-2'><strong>{t('Overall Absences')}</strong></div>
              <Row>
                <Col md={7}>
                  <Table>
                    <thead>
                      <tr>
                        <th width='60%'>{t('Employee')}</th>
                        <th className='text-center'>{t('Total Absences')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.totalAbsencesData ? this.state.totalAbsencesData.map((item, index) =>
                        <tr key={index}>
                          <td>{item.employee_name}</td>
                          <td className='text-center'>{item.total_absences}</td>
                        </tr>
                      ) :
                        <>
                          {Array(4).fill(null).map((item, index) =>
                            <tr key={index}>
                              <td><Skeleton width={100} height={15} /></td>
                              <td className='text-center'><Skeleton width={40} height={15} /></td>
                            </tr>
                          )}
                        </>
                      }
                    </tbody>
                  </Table>
                  <Paginate
                    limit={this.state.totalAbsencesLimit}
                    totalRecords={this.state.totalAbsencesTotalRecords}
                    pageChange={this.tableTotalAbsences}
                  />
                </Col>
                <Col md={5}>
                  {this.state.totalAbsencesChart ? <Doughnut
                    data={this.state.totalAbsencesChart}
                    options={{ ...doughnutChartOptions, cutout: 100 }}
                    height={280}
                  /> : <LoadingPlaceholder height={280} />}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col md={12} style={{ marginBottom: 20 }}>
          <Card
            className='border-0'
            style={{ borderRadius: 20 }}>
            <Card.Body>
              <div style={{ height: 400 }}>
                <FullCalendar
                  plugins={[dayGridPlugin]}
                  initialView="dayGridMonth"
                  datesSet={this.reportCenterCalendar}
                  events={this.state.events}
                  height={'100%'}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={12}>
          <Card
            className='border-0'
            style={{ borderRadius: 20, marginBottom: 20 }}>
            <Card.Body>
              <div className='text-center mb-2'><strong>{t('Pending Leaves')}</strong></div>
              <Row>
                <Col md={6}>
                  <Table>
                    <thead>
                      <tr>
                        <th width='30%'>{t('Employee')}</th>
                        <th width='30%'>{t('Note')}</th>
                        <th width='20%'>{t('From')}</th>
                        <th width='20%'>{t('To')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.pendingLeavesData ? this.state.pendingLeavesData.map((item, index) =>
                        <tr key={index}>
                          <td>{item.employee_name}</td>
                          <td>{item.notes}</td>
                          <td>{moment(item.emplleave_date_from).format('DD-MM-YYYY')}</td>
                          <td>{moment(item.emplleave_date_to).format('DD-MM-YYYY')}</td>
                        </tr>
                      ) :
                        <>
                          {Array(5).fill(null).map((item, index) =>
                            <tr key={index}>
                              <td><Skeleton width={100} height={15} /></td>
                              <td><Skeleton width={100} height={15} /></td>
                              <td><Skeleton width={100} height={15} /></td>
                              <td><Skeleton width={100} height={15} /></td>
                            </tr>
                          )}
                        </>
                      }
                    </tbody>
                  </Table>
                  <Paginate
                    limit={this.state.pendingLeavesLimit}
                    totalRecords={this.state.pendingLeavesTotalRecords}
                    pageChange={this.tablePendingLeaves}
                  />
                </Col>
                <Col md={3}>
                  {this.state.pendingLeavesChart1 ? <Doughnut
                    data={this.state.pendingLeavesChart1}
                    options={{ ...doughnutChartOptions, cutout: 100 }}
                    height={300}
                  /> : <LoadingPlaceholder height={300} />}
                </Col>
                <Col md={3}>
                  {this.state.pendingLeavesChart2 ? <Doughnut
                    data={this.state.pendingLeavesChart2}
                    options={{ ...doughnutChartOptions, cutout: 90 }}
                    height={300}
                  /> : <LoadingPlaceholder height={300} />}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col md={12}>
          <Card
            className='border-0'
            style={{ borderRadius: 20, marginBottom: 20 }}>
            <Card.Body>
              <div className='text-center mb-2'><strong>{t('Pending Additions')}</strong></div>
              <Row>
                <Col md={6}>
                  <Table>
                    <thead>
                      <tr>
                        <th width='25%'>{t('Employee')}</th>
                        <th width='25%'>{t('Date')}</th>
                        <th width='25%'>{t('Note')}</th>
                        <th width='25%'>{t('Amount')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.pendingAdditionsData ? this.state.pendingAdditionsData.map((item, index) =>
                        <tr key={index}>
                          <td>{item.employee_name}</td>
                          <td>{moment(item.empladd_date).format('DD-MM-YYYY')}</td>
                          <td>{item.notes}</td>
                          <td>{item.empladd_amount}</td>
                        </tr>
                      ) :
                        <>
                          {Array(5).fill(null).map((item, index) =>
                            <tr key={index}>
                              <td><Skeleton width={100} height={15} /></td>
                              <td><Skeleton width={100} height={15} /></td>
                              <td><Skeleton width={100} height={15} /></td>
                              <td><Skeleton width={100} height={15} /></td>
                            </tr>
                          )}
                        </>
                      }
                    </tbody>
                  </Table>
                  <Paginate
                    limit={this.state.pendingAdditionsLimit}
                    totalRecords={this.state.pendingAdditionsTotalRecords}
                    pageChange={this.pendingAdditions}
                  />
                </Col>
                <Col md={3}>
                  {this.state.pendingAdditionsChart1 ? <Doughnut
                    data={this.state.pendingAdditionsChart1}
                    options={{ ...doughnutChartOptions, cutout: 100 }}
                    height={300}
                  /> : <LoadingPlaceholder height={300} />}
                </Col>
                <Col md={3}>
                  {this.state.pendingAdditionsChart2 ? <Doughnut
                    data={this.state.pendingAdditionsChart2}
                    options={{ ...doughnutChartOptions, cutout: 90 }}
                    height={300}
                  /> : <LoadingPlaceholder height={300} />}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        {/* <Col md={12}>
          <Card
            className='border-0'
            style={{ borderRadius: 20 }}>
            <Card.Body>
              <div className='text-center mb-2'><strong>{t('Pending Allowances')}</strong></div>
              <Row>
                <Col md={6}>
                  <Table>
                    <thead>
                      <tr>
                        <th>{t('Employee')}</th>
                        <th>{t('Date')}</th>
                        <th>{t('Note')}</th>
                        <th>{t('Amount')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.pendingAllowancesData ? this.state.pendingAllowancesData.map((item, index) =>
                        <tr key={index}>
                          <td>{item.employee_name}</td>
                          <td>{moment(item.emplallow_date).format('DD-MM-YYYY')}</td>
                          <td>{item.notes}</td>
                          <td>{item.emplallow_amount}</td>
                        </tr>
                      ) :
                        <>
                          {Array(5).fill(null).map((item, index) =>
                            <tr key={index}>
                              <td><Skeleton width={100} height={15} /></td>
                              <td><Skeleton width={100} height={15} /></td>
                              <td><Skeleton width={100} height={15} /></td>
                              <td><Skeleton width={100} height={15} /></td>
                            </tr>
                          )}
                        </>
                      }
                    </tbody>
                  </Table>
                  <Paginate
                    limit={this.state.pendingAllowancesLimit}
                    totalRecords={this.state.pendingAllowancesTotalRecords}
                    pageChange={this.tableTotalAllowances}
                  />
                </Col>
                <Col md={3}>
                  {this.state.pendingAllowancesChart1 ? <Doughnut
                    data={this.state.pendingAllowancesChart1}
                    options={{ ...doughnutChartOptions, cutout: 90 }}
                    height={300}
                  /> : <LoadingPlaceholder height={300} />}
                </Col>
                <Col md={3}>
                  {this.state.pendingAllowancesChart2 ? <Doughnut
                    data={this.state.pendingAllowancesChart2}
                    options={{ ...doughnutChartOptions, cutout: 90 }}
                    height={300}
                  /> : <LoadingPlaceholder height={300} />}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col> */}
      </Row>
    </>);
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(HR);